import React from 'react';
import PersonalGoals from './PersonalGoals';

const Calculate = () => {
    return (
        <div>
            <PersonalGoals />
        </div>
    );
};

export default Calculate;