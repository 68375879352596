import React from 'react';
import { useDrag } from 'react-dnd';

const ItemType = 'RECRUIT';

const Recruit = ({ recruit, index, advanceStep, onClick }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { id: recruit.id },  // Ensure recruit ID is passed here
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleClick = (e) => {
    if (e.target.tagName === 'BUTTON') {
      return; // Prevent onClick from firing when buttons are clicked
    }
    onClick(recruit); // Call onClick with the recruit data
  };

  return (
    <div 
      ref={drag} 
      className="recruit-card" 
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onClick={handleClick}
    >
      <h4>{recruit.recruit_first} {recruit.recruit_last}</h4>
      <div className="recruit-step">{recruit.step}</div>
      <div className="navigation-arrows">
        <button 
          onClick={() => advanceStep(recruit.id, -1)}  // Pass the recruit ID
          disabled={recruit.step === 'Overview'}
        >
          &lt;&lt;
        </button>
        <button 
          onClick={() => advanceStep(recruit.id, 1)}  // Pass the recruit ID
          disabled={recruit.step === 'VIP 3'}
        >
          &gt;&gt;
        </button>
      </div>
    </div>
  );
};

export default Recruit;
