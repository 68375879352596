import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TableView from './TableView';
import KanbanView from './KanbanView';
import RecruitDetailModal from './RecruitDetailModal';
import AddRecruit from './AddRecruit';
import Placeholder from '../utils/Placeholder'; // Import the Placeholder component
import { UNIFORM_STEPS } from './steps';
import './Recruiting.css';
import TableIcon from '../../img/table.svg';
import KanbanIcon from '../../img/kanban.svg'; // Adjust the path according to your folder structure
import Applicants from './Recruits/Applicants';
import TrainingView from './TrainingView';
import { FaUsers } from 'react-icons/fa';
const Pipeline = () => {
  const [customSteps, setCustomSteps] = useState([...UNIFORM_STEPS]);
  const [recruits, setRecruits] = useState([]);
  const [pipelineSteps, setPipelineSteps] = useState([]); // State for pipeline steps
  const [view, setView] = useState('applicants');
  const [selectedRecruit, setSelectedRecruit] = useState(null);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    fetchHierarchyAndRecruits();
  }, []);

  const fetchHierarchyAndRecruits = async () => {
    const userId = localStorage.getItem('userId');
    const userRole = localStorage.getItem('userRole');
    const timeZoneOffset = new Date().getTimezoneOffset(); // Capture user's timezone offset
  
    console.log('Fetching hierarchy and recruits for userId:', userId, 'with role:', userRole, 'and timezone offset:', timeZoneOffset);
  
    try {
      const [recruitsResponse, stepsResponse] = await Promise.all([
        fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/fetchRecruits', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId, userRole, timeZoneOffset }), // Include timezone offset
        }),
        fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/fetchPipelineSteps', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId, userRole, timeZoneOffset }), // Include timezone offset
        }),
      ]);
  
      const recruitsData = await recruitsResponse.json();
      const stepsData = await stepsResponse.json();
  
      // Convert dates from MST to local time
      const convertedRecruitsData = recruitsData.map(recruit => ({
        ...recruit,
        date_entered: convertMSTToLocalTime(recruit.date_entered),
        date_exited: recruit.date_exited ? convertMSTToLocalTime(recruit.date_exited) : null,
      }));
  
      const convertedStepsData = stepsData.map(step => ({
        ...step,
        date_entered: convertMSTToLocalTime(step.date_entered),
        date_exited: step.date_exited ? convertMSTToLocalTime(step.date_exited) : null,
      }));
  
      console.log('Recruits fetched:', convertedRecruitsData);
      console.log('Pipeline steps fetched:', convertedStepsData);
  
      setRecruits(convertedRecruitsData);
      setPipelineSteps(convertedStepsData);
      setLoading(false); // Set loading to false once data is fetched
  
    } catch (error) {
      console.error('Error fetching recruits or pipeline steps:', error);
      setLoading(false); // Set loading to false even if there's an error
    }
  };
  
  const convertMSTToLocalTime = (mstDateStr) => {
    // Assume the input date string is in 'YYYY-MM-DD HH:MM:SS' format in MST
    const mstDate = new Date(mstDateStr + ' GMT-0700'); // Treat as MST
    return mstDate.toString(); // Convert to the user's local time automatically
  };
  
  const addRecruit = async (newRecruit) => {
    const userId = localStorage.getItem('userId');
    const timezoneOffset = new Date().getTimezoneOffset(); // Capture user's timezone offset in minutes
    try {
      const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/recruits', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          recruiting_agent: userId,
          recruit_first: newRecruit.firstName,
          recruit_last: newRecruit.lastName,
          recruit_middle: newRecruit.middleName,
          step: newRecruit.step,
          email: newRecruit.email,
          phone: newRecruit.phone,
          overview_time: newRecruit.overviewTime,
          hire: newRecruit.hire,
          final_time: newRecruit.finalTime,
          resident_state: newRecruit.residentState,
          enrolled: newRecruit.enrolled,
          course: newRecruit.course,
          expected_complete_date: newRecruit.expectedCompleteDate,
          current_progress: newRecruit.currentProgress,
          last_log_prelic: newRecruit.lastLogPrelic,
          prelic_passed: newRecruit.prelicPassed,
          prelic_cert: newRecruit.prelicCert,
          test_date: newRecruit.testDate,
          test_passed: newRecruit.testPassed,
          test_cert: newRecruit.testCert,
          bg_date: newRecruit.bgDate,
          compliance1: newRecruit.compliance1,
          compliance2: newRecruit.compliance2,
          compliance3: newRecruit.compliance3,
          compliance4: newRecruit.compliance4,
          compliance5: newRecruit.compliance5,
          aob: newRecruit.aob,
          resident_license_number: newRecruit.residentLicenseNumber,
          npn: newRecruit.npn,
          agentnum: newRecruit.agentnum,
          impact_setup: newRecruit.impactSetup,
          training_start_date: newRecruit.trainingStartDate,
          coded: newRecruit.coded,
          code_to: newRecruit.codeTo,
          eapp_username: newRecruit.eappUsername,
          impact_username: newRecruit.impactUsername,
          timezoneOffset, // Include timezone offset
        }),
      });
      const newRecruitData = await response.json();
      console.log('New recruit added:', newRecruitData);
      setRecruits([...recruits, { ...newRecruitData, step: newRecruit.step }]);
    } catch (error) {
      console.error('Error adding recruit:', error);
    }
  };
  

  const advanceStep = async (recruitId, newStep) => {
    const recruitIndex = recruits.findIndex(recruit => recruit.id === recruitId);
    const recruit = recruits[recruitIndex];
  
    if (!recruit) {
      console.error('Recruit not found:', recruitId);
      return;
    }
  
    const newRecruits = [...recruits];
    newRecruits[recruitIndex].step = newStep;
    setRecruits(newRecruits);
  
    try {
      const timezoneOffset = new Date().getTimezoneOffset(); // Capture user's timezone offset in minutes
      await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/recruits/${recruitId}/step`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ new_step: newStep, timezoneOffset }), // Include timezone offset
      });
      console.log('Step updated successfully for recruit ID:', recruitId);
    } catch (error) {
      console.error('Error advancing step, reverting:', error);
      newRecruits[recruitIndex].step = recruit.step;
      setRecruits(newRecruits);
    }
  };
  
  const updateFinalTime = async (recruitId, finalDateTime) => {
    try {
      await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/recruits/${recruitId}/final-time`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ final_time: finalDateTime }),
      });
      console.log('Final time updated successfully for recruit ID:', recruitId);
    } catch (error) {
      console.error('Error updating final time:', error);
    }
  };
  
  const updateCallbackTime = async (recruitId, callbackDateTime) => {
    try {
      await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/recruits/${recruitId}/callback-time`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ callback_time: callbackDateTime }),
      });
      console.log('Callback time updated successfully for recruit ID:', recruitId);
    } catch (error) {
      console.error('Error updating callback time:', error);
    }
  };
  
  const handleSavePreLicInfo = async (recruitId, preLicData) => {
    try {
      await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/recruits/${recruitId}/pre-lic`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(preLicData),
      });
      console.log('Pre-licensing information updated successfully for recruit ID:', recruitId);
    } catch (error) {
      console.error('Error updating pre-licensing information:', error);
    }
  };

  
  const updateRecruitStep = (recruitId, newStep) => {
    advanceStep(recruitId, newStep);
  };

  if (loading) {
    return (
      <>
        <div className="view-buttons">
          <button onClick={() => setView('applicants')} className={`view-button ${view === 'applicants' ? 'active' : ''}`}>
            <img src={TableIcon} alt="Applicants" />
          </button>
          <button onClick={() => setView('table')} className={`view-button ${view === 'table' ? 'active' : ''}`}>
            <img src={TableIcon} alt="Table View" />
          </button>
          <button onClick={() => setView('kanban')} className={`view-button ${view === 'kanban' ? 'active' : ''}`}>
            <img src={KanbanIcon} alt="Kanban View" />
          </button>
        </div>
        <Placeholder /> {/* Display the Placeholder component while data is being fetched */}
      </>
    );
  }
  

  return (
    <div className="app-container">
      <div className="large-app-container">
        <div className="view-buttons">
        <button onClick={() => setView('applicants')} className={`view-button ${view === 'applicants' ? 'active' : ''}`}>
  <FaUsers /> {/* Replace the img element with the FaUsers icon */}
</button>
          <button hidden onClick={() => setView('table')} className={`view-button ${view === 'table' ? 'active' : ''}`}>
            <img src={TableIcon} alt="Table View" />
          </button>
          <button hidden onClick={() => setView('kanban')} className={`view-button ${view === 'kanban' ? 'active' : ''}`}>
            <img src={KanbanIcon} alt="Kanban View" />
          </button>
          <button hidden onClick={() => setView('training')} className={`view-button ${view === 'training' ? 'active' : ''}`}>
            Training
            </button>
        </div>
        <DndProvider backend={HTML5Backend}>
          <div className="view-container">

{view === 'applicants' && (
  <Applicants 
  applicants={recruits} 
  advanceStep={advanceStep} 
  updateFinalTime={updateFinalTime}
  updateCallbackTime={updateCallbackTime} 
  handleSavePreLicInfo={handleSavePreLicInfo}
/>

)}


            {view === 'table' && (
              <TableView recruits={recruits} steps={customSteps} pipelineSteps={pipelineSteps} updateRecruitStep={updateRecruitStep} />
            )}
            {view === 'kanban' && (
              <KanbanView recruits={recruits} steps={customSteps} moveRecruitToStep={advanceStep} onClick={setSelectedRecruit} columnInfo={[
                'Info about Step 1',
                'Info about Step 2',
                'Info about Step 3'
              ]}/>
            )}
            {view === 'training' && (
              <TrainingView recruits={recruits} />
            )}
          </div>
        </DndProvider>
        {selectedRecruit && (
          <RecruitDetailModal 
            recruit={selectedRecruit} 
            onClose={() => setSelectedRecruit(null)} 
          />
        )}
      </div>
    </div>
  );
};

export default Pipeline;
