import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Container } from 'react-bootstrap';
import logo from '../../img/globe1.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Enable loading overlay
    
        fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/newlogin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        })
        .then(response => response.json()) // Convert response to JSON
        .then(data => {
            setIsLoading(false); // Disable loading overlay
            if (data.success && data.message === "Login successful") {
                localStorage.setItem('userToken', data.token);
                localStorage.setItem('userRole', data.clname);
                localStorage.setItem('userId', data.userId);
                localStorage.setItem('agnName', data.agnName);
                localStorage.setItem('userInfo', JSON.stringify(data.userInfo)); // Storing as a JSON string

                // Check if mgaRgaData is part of the response and store it if present
                if (data.mgaRgaData) {
                    localStorage.setItem('mgaRgaData', JSON.stringify(data.mgaRgaData)); // Storing as a JSON string
                }
    
                // Check if userInfo is part of the response and store it if present

                
                
                navigate('/');
            } else if (data.success && data.message === "Please complete account setup") {
                // Redirect user to setup page if 'redeemed' is 0 or 'false'
                navigate('/agent-setup', { state: { esid: data.esid, lagnname: data.lagnname } });
            } else {
                // Alert the user if login was not successful for other reasons
                alert('Login failed: ' + (data.message || 'Unknown error'));
            }
        })
        .catch(error => {
            setIsLoading(false); // Disable loading overlay
            alert('Login failed: ' + error.message);
        });
    };
    
    

    return (
        <>
            {isLoading && (
                <div className="loading-overlay">
                    <img src={logo} alt="Loading" className="loading-animation" />
                </div>
            )}
            <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
                <Card className="w-100" style={{ maxWidth: "400px" }}>
                    <Card.Header className="text-center" style={{backgroundColor: "white" }}>
                        <img src={logo} alt="Logo" width="100" height="100" />
                    </Card.Header>
                    <Card.Body>
                        <h2 className="text-center mb-4">Activate Account</h2>
                        <form onSubmit={handleLogin}>
                            <div className="form-group">
                                <label>Username</label>
                                <input type="text" className="form-control" placeholder='BRAPPAPORT'required value={username} onChange={(e) => setUsername(e.target.value)} />
                            </div>
                            <div className="form-group mt-3">
                                <label>Password</label>
                                <input type="password" className="form-control" required value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <Button className="w-100 mt-4" style={{backgroundColor: "green"}} type="submit">Log In</Button>
                        </form>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
};

export default Register;
