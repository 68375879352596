import React, { useRef, useEffect, useState } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import './FiltersContainer.css'; // Ensure you have the CSS for styling

const FiltersContainer = ({ filters, setFilters, filterOptions }) => {
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const overlayRef = useRef(null);

    const handleButtonClick = () => {
        setIsOverlayOpen(true);
    };

    const handleCloseOverlay = () => {
        setIsOverlayOpen(false);
    };

    const handleClickOutside = (event) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            handleCloseOverlay();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleFilterChange = (filterType, selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterType]: selectedValues,
        }));
    };

    return (
        <div className="filters-container">
            <button onClick={handleButtonClick} className="filter-button" data-tooltip-id="filter-tooltip" data-tooltip-content="Filters" title='Filters'>
                <FontAwesomeIcon icon={faFilter} />
            </button>
            {isOverlayOpen && (
                <div className="filter-overlay">
                    <div ref={overlayRef} className="filter-content">
                        <button onClick={handleCloseOverlay} className="close-button">X</button>
                        <div className="filter-options">
                            <div className="select-container">
                                <label>Agent</label>
                                <Select
                                    isMulti
                                    options={filterOptions.agents}
                                    onChange={(selectedOptions) => handleFilterChange('agent', selectedOptions)}
                                    placeholder="Agent"
                                    value={filters.agent.map(value => ({ value, label: value }))}
                                />
                            </div>
                            <div className="select-container">
                                <label>MGA</label>
                                <Select
                                    isMulti
                                    options={filterOptions.mgas}
                                    onChange={(selectedOptions) => handleFilterChange('mga', selectedOptions)}
                                    placeholder="MGA"
                                    value={filters.mga.map(value => ({ value, label: value }))}
                                />
                            </div>
                            <div className="select-container">
                                <label>RGA</label>
                                <Select
                                    isMulti
                                    options={filterOptions.rgas}
                                    onChange={(selectedOptions) => handleFilterChange('rga', selectedOptions)}
                                    placeholder="RGA"
                                    value={filters.rga.map(value => ({ value, label: value }))}
                                />
                            </div>
                            <div className="select-container">
                                <label>Legacy</label>
                                <Select
                                    isMulti
                                    options={filterOptions.legacies}
                                    onChange={(selectedOptions) => handleFilterChange('legacy', selectedOptions)}
                                    placeholder="Legacy"
                                    value={filters.legacy.map(value => ({ value, label: value }))}
                                />
                            </div>
                            <div className="select-container">
                                <label>Tree</label>
                                <Select
                                    isMulti
                                    options={filterOptions.trees}
                                    onChange={(selectedOptions) => handleFilterChange('tree', selectedOptions)}
                                    placeholder="Tree"
                                    value={filters.tree.map(value => ({ value, label: value }))}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FiltersContainer;
