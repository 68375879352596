import React, { useState, useEffect } from 'react';
import { useFetchData } from '../../hooks/codesAndVips';
import '../../MaxBonus.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Select from 'react-select';

const calculateAriasSimonTotalsForLastMonth = (associatesData, monthOffset = 1) => {
    const uniqueValuesSet = new Set(); // This will store unique values
    const targetDate = new Date();
    targetDate.setMonth(targetDate.getMonth() - monthOffset);
    const targetYear = targetDate.getFullYear();
    const targetMonth = targetDate.getMonth(); // Last month

    associatesData.forEach(associate => {
        const associateDate = new Date(associate[5]); // Convert string date to Date object
        if (associate[0] === "ARIAS,SIMON" && associateDate.getMonth() === targetMonth && associateDate.getFullYear() === targetYear) {
            uniqueValuesSet.add(associate[3]); // Assuming you want to count unique values from this column
        }
    });
    return uniqueValuesSet.size; // Return the count of unique values
};

const calculateAriasSimonTotalsForThisMonth = (associatesData, monthOffset = 0) => { // default to current month
    const uniqueValuesSet = new Set(); // This will store unique values
    const targetDate = new Date();
    targetDate.setMonth(targetDate.getMonth() - monthOffset);
    const targetYear = targetDate.getFullYear();
    const targetMonth = targetDate.getMonth(); // Adjusted month based on monthOffset

    associatesData.forEach(associate => {
        const associateDate = new Date(associate[5]); // Convert string date to Date object
        if (associate[0] === "ARIAS,SIMON" && associateDate.getMonth() === targetMonth && associateDate.getFullYear() === targetYear) {
            uniqueValuesSet.add(associate[3]); // Assuming you want to count unique values from this column
        }
    });
    return uniqueValuesSet.size; // Return the count of unique values
};

const calculateAriasSimonDetailsTotals = (detailsData, monthOffset) => {
    const uniqueValuesSet = new Set(); // This will store unique values
    const targetDate = new Date();
    targetDate.setMonth(targetDate.getMonth() - monthOffset);
    const targetYear = targetDate.getFullYear();
    const targetMonth = targetDate.getMonth(); // Adjusted for monthOffset

    detailsData.forEach(detail => {
        const detailDate = new Date(detail[0]); // Convert string date to Date object
        if (detail[2] === "ARIAS,SIMON" && detailDate.getMonth() === targetMonth && detailDate.getFullYear() === targetYear) {
            uniqueValuesSet.add(detail[3]); // Assuming you want to count unique values from this column, adjust if necessary
        }
    });
    return uniqueValuesSet.size; // Return the count of unique values
};

const calculateObjectives = (mgas, associatesData, detailsData, monthOffset) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - monthOffset);
    const currentMonth = currentDate.getMonth(); // Adjusted for monthOffset
    const currentYear = currentDate.getFullYear();
    const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const lastYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    const year = lastMonth === 11 ? lastYear : currentYear; // Adjust year if last month was December

    return mgas.map(mga => {
        const mgaDisplayName = mga[0];
        const mgaName = mga[4]; // Assuming the MGA name is the fifth element
        const mgaTenure = parseInt(mga[6]); // Assuming tenure is the seventh element

        const codesLastMonth = associatesData.filter(associate => {
            const date = new Date(associate[5]); // Assuming the date is in the 6th position
            return associate[2] === mgaName && date.getMonth() === lastMonth && date.getFullYear() === lastYear;
        }).length;

        const codesThisMonth = associatesData.filter(associate => {
            const date = new Date(associate[5]);
            return associate[2] === mgaName && date.getMonth() === currentMonth && date.getFullYear() === currentYear;
        }).length;

        const vipsLastMonth = detailsData.filter(detail => {
            const date = new Date(detail[0]); // Now assuming the date is in the 1st position
            return detail[11] === mgaName && date.getMonth() === lastMonth && date.getFullYear() === lastYear;
        }).length;

        const vipsThisMonth = detailsData.filter(detail => {
            const date = new Date(detail[0]);
            return detail[11] === mgaName && date.getMonth() === currentMonth && date.getFullYear() === currentYear;
        }).length;

        const totalCodesAndVips = codesLastMonth + codesThisMonth + vipsLastMonth + vipsThisMonth;

        // Calculate rollup codes and VIPs for all MGAs
        let rollupCodes = 0;
        let rollupVips = 0;
        mgas.forEach(otherMga => {
            if (otherMga[1] === mga[0] && otherMga[6] === "1") { // Rollup condition
                const otherMgaName = otherMga[4];
                associatesData.forEach(associate => {
                    const date = new Date(associate[5]);
                    if (associate[2] === otherMgaName && ((date.getMonth() === lastMonth && date.getFullYear() === lastYear) || (date.getMonth() === currentMonth && date.getFullYear() === currentYear))) {
                        rollupCodes++;
                    }
                });
                detailsData.forEach(detail => {
                    const date = new Date(detail[0]);
                    if (detail[11] === otherMgaName && ((date.getMonth() === lastMonth && date.getFullYear() === lastYear) || (date.getMonth() === currentMonth && date.getFullYear() === currentYear))) {
                        rollupVips++;
                    }
                });
            }
        });

        // Determine if rollup should be shown based on tenure and combined codes and VIPs
        const showRollup = (mgaTenure === 2 && totalCodesAndVips >= 3) || (mgaTenure === 3 && totalCodesAndVips >= 5);
        const totalAchieved = codesLastMonth + codesThisMonth + vipsLastMonth + vipsThisMonth + (showRollup ? rollupCodes + rollupVips : 0);
        const neededFor100 = Math.max(0, mga[10] - totalAchieved);
        const neededFor125 = Math.max(0, parseFloat(mga[10]) + 5 - totalAchieved);
        const totalAchievedCodesAndVips = codesLastMonth + codesThisMonth + vipsLastMonth + vipsThisMonth + rollupCodes + rollupVips;
        const objective100 = parseInt(mga[10]); // Ensure this is an integer for comparison
        const objective125 = objective100 * 1.25; // Calculate the 125% objective

        let bonus = 0;

        if (totalAchievedCodesAndVips >= objective100) {
            bonus = 100; // Base bonus for hitting 100%
            const excess = totalAchievedCodesAndVips - objective100;
            // Calculate bonus increment for excess codes up to the limit for 125% objective
            const additionalCodes = Math.min(excess, objective125 - objective100); // The actual number of codes over 100% but within the 125% limit
            const bonusIncrements = Math.floor(additionalCodes / (objective100 * 0.05)); // Determine how many full 5% increments are applicable
            bonus += 5 * bonusIncrements; // Increment the bonus by 5% for each additional code over 100%, up to 125%
        }
        const combinedLastMonth = codesLastMonth + vipsLastMonth;
        const combinedThisMonth = codesThisMonth + vipsThisMonth;
        const combinedRollups = showRollup ? rollupCodes + rollupVips : 0;
        // Prepare a rollup text for hover-over breakdown
        const rollupText = `Codes: ${rollupCodes}, VIPs: ${rollupVips}`;

        // VIP Y/N
        const vipYN = (vipsLastMonth > 0 || vipsThisMonth > 0) ? 'Y' : 'N';
        const formatMonthYearShort = (month, year) => {
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return `${monthNames[month]} ${year}`;
        };
    
        const lastMonthFormatted = formatMonthYearShort(lastMonth, lastYear);
        const thisMonthFormatted = formatMonthYearShort(currentMonth, currentYear);
    
        return {
            name: mgaDisplayName,
            objective100: mga[10],
            objective125: parseFloat(mga[10]) + 5,
            codesLastMonth,
            codesThisMonth,
            vipsLastMonth,
            vipsThisMonth,
            showRollup, // Indicate whether rollup columns should be displayed
            rollupCodes: showRollup ? rollupCodes : null, // Conditional display based on showRollup
            rollupVips: showRollup ? rollupVips : null, // Conditional display based on showRollup
            neededFor100, // Include the calculated value for Needed for 100%
            neededFor125, // Include the calculated value for Needed for 125%
            bonus,
            vipYN, 
            combinedLastMonth,
            combinedThisMonth,
            combinedRollups,
            rollupText,
            lastMonthFormatted,
            thisMonthFormatted,
        };
    });
};

const getMonthYear = (offset = 0) => {
    const date = new Date();
    date.setMonth(date.getMonth() - offset);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
};

const AllMgasTable = () => {
    const { data, loading, error } = useFetchData();
    const [expandedGroups, setExpandedGroups] = useState({});
    const [monthOffset, setMonthOffset] = useState(0); // State to manage month navigation
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [selectedRgaFilter, setSelectedRgaFilter] = useState(null);

    const handleRgaFilterChange = selectedOption => {
        setSelectedRgaFilter(selectedOption); // This sets the selected RGA filter
    };
    
    
    const handleFilterChange = selectedOptions => {
        setSelectedFilters(selectedOptions || []);
    };
        const mgaNameOptions = data.mgas.map(mga => ({
        value: mga[0], // Assuming mga[0] is the MGA name
        label: mga[0] // Display name in the dropdown
    }));
    
    
    const rgaOptions = [...new Set(data.mgas.map(mga => mga[1]))] // Extract unique RGAs
    .map(rga => ({
        value: rga,
        label: rga
    }));

    

    useEffect(() => {
        // Initialize expandedGroups state to true for all groups
        const groups = data.mgas.reduce((acc, mga) => {
            const groupKey = mga[3]; // Assuming the fourth element is the grouping criterion
            acc[groupKey] = true; // Initially set all groups to expanded
            return acc;
        }, {});
        setExpandedGroups(groups);
    }, [data.mgas]); // Dependency on data.mgas to reset state when data changes
// Filter MGAs if any names are selected
// Filter MGAs based on selected RGA if any, otherwise include all MGAs
const filteredMgas = data.mgas.filter(mga =>
    (!selectedRgaFilter || mga[1] === selectedRgaFilter.value || mga[0] === selectedRgaFilter.value) && // Updated condition
    (!selectedFilters.length > 0 || selectedFilters.some(filter => filter.value === mga[0]))
);


const groupedMgas = filteredMgas.reduce((acc, mga) => {
    const groupKey = mga[3]; // Assuming the property for grouping (tree) is at index 3
    if (!acc[groupKey]) acc[groupKey] = [];
    acc[groupKey].push(mga);
    return acc;
}, {});


    const navigateMonths = (direction) => {
        if (direction === 'next' && monthOffset > 0) {
            setMonthOffset(monthOffset - 1);
        } else if (direction === 'prev') {
            setMonthOffset(monthOffset + 1);
        }
    };
    const arrowButtonStyle = {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        color: 'grey', // Adjust the color as needed
        fontSize: '24px', // Adjust the size as needed
    };

    // You could also define a hover style if desired
    const arrowButtonHoverStyle = {
        ...arrowButtonStyle,
        color: 'black', // Color on hover
    };

    const thisMonthYear = getMonthYear(monthOffset);
    const lastMonthYear = getMonthYear(monthOffset + 1);
    const sortedAndGroupedMgas = data.mgas
        .sort((a, b) => a[3].localeCompare(b[3])) // Sort MGAs by column D
        .reduce((acc, mga) => { // Group by column D
            const key = mga[3];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(mga);
            return acc;
        }, {});

    const toggleGroup = (key) => {
        setExpandedGroups(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };




    const calculateTotalCodesForGroup = (groupMgas, monthOffset) => {
        return groupMgas.reduce((acc, mga) => {
            const mgaData = calculateObjectives([mga], data.associatesData, data.detailsData, monthOffset)[0];
            acc.lastMonthCodes += mgaData.codesLastMonth;
            acc.thisMonthCodes += mgaData.codesThisMonth;
            acc.lastMonthVIPs += mgaData.vipsLastMonth;
            acc.thisMonthVIPs += mgaData.vipsThisMonth;
            return acc;
        }, { lastMonthCodes: 0, thisMonthCodes: 0, lastMonthVIPs: 0, thisMonthVIPs: 0 });
    };
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const [lastMonth, lastYear] = lastMonthYear.split(" ");
const [thisMonth, thisYear] = thisMonthYear.split(" ");



    return (
        <>
<div class="flex-row-container"> 
    <div style={{ width: '190px', marginLeft: '10px'}}>
        <button onClick={() => navigateMonths('prev')} style={arrowButtonStyle} onMouseEnter={e => e.target.style.color = arrowButtonHoverStyle.color} onMouseLeave={e => e.target.style.color = arrowButtonStyle.color}>
            <FaArrowLeft />
        </button>
        &nbsp;
        <button onClick={() => navigateMonths('next')} style={arrowButtonStyle} onMouseEnter={e => e.target.style.color = arrowButtonHoverStyle.color} onMouseLeave={e => e.target.style.color = arrowButtonStyle.color}>
            <FaArrowRight />
        </button>
    </div>
        <Select className='codes-dropdown-left'
            options={mgaNameOptions}
            onChange={handleFilterChange}
            value={selectedFilters}
            isMulti={true}
            isClearable={true}
            placeholder="Filter by MGA..."
            styles={{
                control: (base) => ({
                    ...base,
                    minHeight: '35px',
                }),
                dropdownIndicator: (base) => ({
                    ...base,
                    padding: '4px',
                }),
                clearIndicator: (base) => ({
                    ...base,
                    padding: '4px',
                }),
                multiValue: (base) => ({
                    ...base,
                    backgroundColor: 'lightgrey',
                }),
                multiValueLabel: (base) => ({
                    ...base,
                    color: 'black',
                }),
                multiValueRemove: (base) => ({
                    ...base,
                    ':hover': {
                        backgroundColor: 'red',
                        color: 'white',
                    },
                }),
            }}
        />
        <Select    className='codes-dropdown-right'
    options={rgaOptions}
    onChange={handleRgaFilterChange} // Make sure this is correct
    value={selectedRgaFilter}
    isMulti={false} // Assuming you can only select one RGA at a time
    isClearable={true}
    placeholder="Filter by RGA..."
            styles={{
                control: (base) => ({
                    ...base,
                    minHeight: '35px',
                }),
                dropdownIndicator: (base) => ({
                    ...base,
                    padding: '4px',
                }),
                clearIndicator: (base) => ({
                    ...base,
                    padding: '4px',
                }),
                multiValue: (base) => ({
                    ...base,
                    backgroundColor: 'lightgrey',
                }),
                multiValueLabel: (base) => ({
                    ...base,
                    color: 'black',
                }),
                multiValueRemove: (base) => ({
                    ...base,
                    ':hover': {
                        backgroundColor: 'red',
                        color: 'white',
                    },
                }),
            }}
        />
    </div>
        <div className="more-data-container">
            
            <table className="maxbonus-table">
                <thead className="more-table-thead">
                    <tr>
                    <th className="top-table-header first-column-header">

                        </th>
                        <th colSpan={3} className="top-table-header" style={{ fontSize: '18px' }}>
                            {lastMonth} {lastYear}
                        </th>
                        <th colSpan={3} className="top-table-header" style={{ fontSize: '18px' }}>
                            {thisMonth} {thisYear}
                    </th>
                    <th className="top-table-header">  {/* Style as needed */}
60 Day Total



</th>
                    </tr>
                    <tr>
                        <th className="green-top-table-header">MGA</th>
                        <th className="green-top-table-header">Codes</th>
                        <th className="green-top-table-header">VIPs</th>
                        <th className="green-top-table-header">Total</th>
                        <th className="green-top-table-header">Codes</th>
                        <th className="green-top-table-header">VIPs</th>
                        <th className="green-top-table-header">Total</th>
                        <th className="green-top-table-header">Totals</th>
                    </tr>
                </thead>
                <tbody>
                <th colSpan="1" style={{textAlign: '', backgroundColor: "#ED722F", fontSize: '10px'}} className="top-table-header">
                    Arias</th>
<td colSpan="1" className="more-table-input-like">
    {data && data.associatesData ? calculateAriasSimonTotalsForLastMonth(data.associatesData, monthOffset + 1) : 'Loading...'}
</td>
<td colSpan="1" className="more-table-input-like">
    {data && data.detailsData ? calculateAriasSimonDetailsTotals(data.detailsData, monthOffset + 1) : 'Loading...'} {/* For last month based on monthOffset */}
</td>
<td colSpan="1" className="more-table-input-like">
    {data ? (
        <>
            {(data.associatesData ? calculateAriasSimonTotalsForLastMonth(data.associatesData, monthOffset + 1) : 0) +
            (data.detailsData ? calculateAriasSimonDetailsTotals(data.detailsData, monthOffset + 1) : 0)}
        </>
    ) : 'Loading...'}
</td>

<td colSpan="1" className="more-table-input-like">
    {data && data.associatesData ? calculateAriasSimonTotalsForThisMonth(data.associatesData, monthOffset) : 'Loading...'}
</td>
<td colSpan="1" className="more-table-input-like">
    {data && data.detailsData ? calculateAriasSimonDetailsTotals(data.detailsData, monthOffset) : 'Loading...'} {/* For this month based on monthOffset */}
</td>
<td colSpan="1" className="more-table-input-like">
    {data && data.associatesData ? calculateAriasSimonTotalsForThisMonth(data.associatesData, monthOffset) : 'Loading...'}
</td>
<td colSpan="1" className="more-table-input-like">
    {data ? (
        <>
            {(data.associatesData ? calculateAriasSimonTotalsForLastMonth(data.associatesData, monthOffset + 1) + calculateAriasSimonTotalsForThisMonth(data.associatesData, monthOffset) : 0) +
            (data.detailsData ? calculateAriasSimonDetailsTotals(data.detailsData, monthOffset + 1) + calculateAriasSimonDetailsTotals(data.detailsData, monthOffset) : 0)}
        </>
    ) : 'Loading...'}
</td>


                {Object.entries(groupedMgas).map(([groupKey, groupMgas]) => {
                        const totals = calculateTotalCodesForGroup(groupMgas, monthOffset);
                        return (
                            <React.Fragment key={groupKey}>
                                <tr onClick={() => toggleGroup(groupKey)} style={{ cursor: 'pointer', backgroundColor: '#f0f0f0', color: 'black' }}>
                                    <th colSpan="1" style={{textAlign: '', backgroundColor: "#ED722F", fontSize: '10px'}} className="top-table-header">
                                        {expandedGroups[groupKey] ? '-' : '+'} {groupKey} Tree
                                    </th>
                                    <td className="more-table-input-like" >{totals.lastMonthCodes}</td>
                                    <td className="more-table-input-like" >{totals.lastMonthVIPs}</td>
                                    <td className="more-table-input-like" >{totals.lastMonthCodes + totals.lastMonthVIPs}</td>
                                    <td className="more-table-input-like" >{totals.thisMonthCodes}</td>
                                    <td className="more-table-input-like" >{totals.thisMonthVIPs}</td>
                                    <td className="more-table-input-like" >{totals.thisMonthCodes + totals.thisMonthVIPs} </td>
                                    <td className="more-table-input-like" >{totals.lastMonthCodes + totals.lastMonthVIPs + totals.thisMonthCodes + totals.thisMonthVIPs}</td>
                                </tr>
                                {expandedGroups[groupKey] && groupMgas.map((mga, index) => {
                                    const mgaData = calculateObjectives([mga], data.associatesData, data.detailsData, monthOffset)[0];
                                    return (
                                        <tr key={index}>
                                            <th className="grey-table-header">{mgaData.name}</th>
                                            <td className="more-table-input-like" >{mgaData.codesLastMonth}</td>
                                            <td className="more-table-input-like" >{mgaData.vipsLastMonth}</td>
                                            <td className="more-table-input-like" >{mgaData.combinedLastMonth}</td>
                                            <td className="more-table-input-like" >{mgaData.codesThisMonth}</td>
                                            <td className="more-table-input-like" >{mgaData.vipsThisMonth}</td>
                                            <td className="more-table-input-like" >{mgaData.combinedThisMonth}</td>
                                            <td className="more-table-input-like" >{mgaData.combinedLastMonth + mgaData.combinedThisMonth}</td>
                                        </tr>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
        </>
    );
};

export default AllMgasTable;
