import React, { useState, useEffect } from 'react';
import { useFetchData } from '../../hooks/codesAndVips';
import '../../MaxBonus.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Select from 'react-select'; // Make sure to import the Select component




const calculateObjectives = (mgas, associatesData, detailsData, monthOffset = 0) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - monthOffset);
    const currentMonth = currentDate.getMonth(); // Adjusted for month offset
    const currentYear = currentDate.getFullYear(); // Adjusted for month offset
    let lastMonth = currentMonth - 1;
    let lastYear = currentYear;
    if (lastMonth < 0) {
        lastMonth = 11; // December
        lastYear = currentYear - 1; // Previous year
    }

    return mgas.map(mga => {
        const mgaDisplayName = mga[0];
        const mgaName = mga[4]; // Assuming the MGA name is the fifth element
        const mgaTenure = parseInt(mga[6]); // Assuming tenure is the seventh element

        const codesLastMonth = associatesData.filter(associate => {
            const date = new Date(associate[5]); // Assuming the date is in the 6th position
            return associate[2] === mgaName && date.getMonth() === lastMonth && date.getFullYear() === lastYear;
        }).length;

        const codesThisMonth = associatesData.filter(associate => {
            const date = new Date(associate[5]);
            return associate[2] === mgaName && date.getMonth() === currentMonth && date.getFullYear() === currentYear;
        }).length;

        const vipsLastMonth = detailsData.filter(detail => {
            const date = new Date(detail[0]); // Now assuming the date is in the 1st position
            return detail[11] === mgaName && date.getMonth() === lastMonth && date.getFullYear() === lastYear;
        }).length;

        const vipsThisMonth = detailsData.filter(detail => {
            const date = new Date(detail[0]);
            return detail[11] === mgaName && date.getMonth() === currentMonth && date.getFullYear() === currentYear;
        }).length;

        const totalCodesAndVips = codesLastMonth + codesThisMonth + vipsLastMonth + vipsThisMonth;

        // Calculate rollup codes and VIPs for all MGAs
        let rollupCodes = 0;
        let rollupVips = 0;
        mgas.forEach(otherMga => {
            if (otherMga[1] === mga[0] && otherMga[6] === "1") { // Rollup condition
                const otherMgaName = otherMga[4];
                associatesData.forEach(associate => {
                    const date = new Date(associate[5]);
                    // Change 'year' to 'lastYear' or 'currentYear' as appropriate:
                    if (associate[2] === otherMgaName && ((date.getMonth() === lastMonth && date.getFullYear() === lastYear) || (date.getMonth() === currentMonth && date.getFullYear() === currentYear))) {
                        rollupCodes++;
                    }
                });
                detailsData.forEach(detail => {
                    const date = new Date(detail[0]);
                    // Change 'year' to 'lastYear' or 'currentYear' as appropriate:
                    if (detail[11] === otherMgaName && ((date.getMonth() === lastMonth && date.getFullYear() === lastYear) || (date.getMonth() === currentMonth && date.getFullYear() === currentYear))) {
                        rollupVips++;
                    }
                });
            }
        });
        

        // Determine if rollup should be shown based on tenure and combined codes and VIPs
        const showRollup = (mgaTenure === 2 && totalCodesAndVips >= 3) || (mgaTenure === 3 && totalCodesAndVips >= 5);
        const totalAchieved = codesLastMonth + codesThisMonth + vipsLastMonth + vipsThisMonth + (showRollup ? rollupCodes + rollupVips : 0);
        const neededFor100 = Math.max(0, mga[10] - totalAchieved);
        const neededFor125 = Math.max(0, parseFloat(mga[10]) + 5 - totalAchieved);
        const totalAchievedCodesAndVips = codesLastMonth + codesThisMonth + vipsLastMonth + vipsThisMonth + rollupCodes + rollupVips;
        const objective100 = parseInt(mga[10]); // Ensure this is an integer for comparison
        const objective125 = objective100 + 5; // Calculate the 125% objective

        let bonus = 0;

        if (totalAchievedCodesAndVips >= objective100) {
            bonus = 100; // Base bonus for hitting 100%
            const excess = totalAchievedCodesAndVips - objective100;
            // Calculate bonus increment for excess codes up to the limit for 125% objective
            const additionalCodes = Math.min(excess, objective125 - objective100); // The actual number of codes over 100% but within the 125% limit
            const bonusIncrements = Math.floor(additionalCodes / (objective100 * 0.05)); // Determine how many full 5% increments are applicable
            bonus += 5 * bonusIncrements; // Increment the bonus by 5% for each additional code over 100%, up to 125%
            // Ensure the bonus does not exceed 125%
            bonus = Math.min(bonus, 125); // Cap the bonus at 125%
        }
        
        const combinedLastMonth = codesLastMonth + vipsLastMonth;
        const combinedThisMonth = codesThisMonth + vipsThisMonth;
        const combinedRollups = showRollup ? rollupCodes + rollupVips : 0;
        // Prepare a rollup text for hover-over breakdown
        const rollupText = `Codes: ${rollupCodes}, VIPs: ${rollupVips}`;

        // VIP Y/N
        const vipYN = (vipsLastMonth > 0 || vipsThisMonth > 0) ? 'Y' : 'N';
        const formatMonthYearShort = (month, year) => {
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return `${monthNames[month]} ${year}`;
        };

        const lastMonthFormatted = formatMonthYearShort(lastMonth, lastYear);
        const thisMonthFormatted = formatMonthYearShort(currentMonth, currentYear);

        return {
            name: mgaDisplayName,
            objective100: mga[10],
            objective125: parseFloat(mga[10]) + 5,
            codesLastMonth,
            codesThisMonth,
            vipsLastMonth,
            vipsThisMonth,
            showRollup, // Indicate whether rollup columns should be displayed
            rollupCodes: showRollup ? rollupCodes : null, // Conditional display based on showRollup
            rollupVips: showRollup ? rollupVips : null, // Conditional display based on showRollup
            neededFor100, // Include the calculated value for Needed for 100%
            neededFor125, // Include the calculated value for Needed for 125%
            bonus,
            vipYN,
            combinedLastMonth,
            combinedThisMonth,
            combinedRollups,
            rollupText,
            lastMonthFormatted,
            thisMonthFormatted,

        };
    });
};

const getMonthYear = (offset = 0) => {
    const date = new Date();
    date.setMonth(date.getMonth() - offset);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
};

const AllMgasTable = () => {
    const { data, loading, error } = useFetchData();
    const [expandedGroups, setExpandedGroups] = useState({});
    const [monthOffset, setMonthOffset] = useState(0); // State to manage month navigation
    const [selectedFilters, setSelectedFilters] = useState([]); // State for selected options
    const [selectedRgaFilter, setSelectedRgaFilter] = useState(null);

    const handleRgaFilterChange = selectedOption => {
        setSelectedRgaFilter(selectedOption); // This sets the selected RGA filter
    };
    
    const mgaNameOptions = data.mgas ? data.mgas.map(mga => ({
        value: mga[4], // Assuming the MGA name is the unique identifier
        label: mga[0] // Assuming the MGA display name is what you want to show in the dropdown
    })) : [];

    const rgaOptions = [...new Set(data.mgas.map(mga => mga[1]))].map(rga => ({
        value: rga,
        label: rga
    }));
    

    useEffect(() => {
        // Initialize expandedGroups state to true for all groups
        const groups = data.mgas.reduce((acc, mga) => {
            const groupKey = mga[3]; // Assuming the fourth element is the grouping criterion
            acc[groupKey] = true; // Initially set all groups to expanded
            return acc;
        }, {});
        setExpandedGroups(groups);
    }, [data.mgas]);  // Dependency on data.mgas to reset state when data changes


    const handleFilterChange = selectedOptions => {
        setSelectedFilters(selectedOptions); // Update the state with the new selected options
    };

    const isMgaSelected = (mga) => {
        // If an RGA is selected, check if the MGA matches the selected RGA filter
        const rgaMatch = selectedRgaFilter ? mga[1] === selectedRgaFilter.value : true;
        
        // Check if the current MGA is the one associated with the selected RGA
        const isRgaMga = selectedRgaFilter ? mga[0] === selectedRgaFilter.label : false;
    
        // If no filters are selected, show all MGAs, but still consider RGA filter
        // Always return true for the MGA that matches the selected RGA
        if (selectedFilters.length === 0) {
            return rgaMatch || isRgaMga;
        }
    
        // Otherwise, check if the MGA is in the selected filters and matches the selected RGA
        // Still always return true for the MGA that matches the selected RGA
        return (rgaMatch && selectedFilters.some(filter => filter.value === mga[4])) || isRgaMga;
    };
    

    const filteredAndGroupedMgas = React.useMemo(() => {
        const filteredMgas = data.mgas.filter(isMgaSelected);
        return filteredMgas
            .sort((a, b) => a[3].localeCompare(b[3]))
            .reduce((acc, mga) => {
                const key = mga[3];
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(mga);
                return acc;
            }, {});
    }, [data.mgas, selectedFilters, selectedRgaFilter]); // Ensure dependencies are correctly listed here
    

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const sortedAndGroupedMgas = data.mgas
        .sort((a, b) => a[3].localeCompare(b[3])) // Sort MGAs by column D
        .reduce((acc, mga) => { // Group by column D
            const key = mga[3];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(mga);
            return acc;
        }, {});

    const toggleGroup = (key) => {
        setExpandedGroups(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };
    const calculateTotalCodesForGroup = (groupMgas) => {
        return groupMgas.reduce((acc, mga) => {
            const mgaData = calculateObjectives([mga], data.associatesData, data.detailsData)[0];
            acc.lastMonthCodes += mgaData.codesLastMonth;
            acc.thisMonthCodes += mgaData.codesThisMonth;
            acc.lastMonthVIPs += mgaData.vipsLastMonth;
            acc.thisMonthVIPs += mgaData.vipsThisMonth;
            return acc;
        }, { lastMonthCodes: 0, thisMonthCodes: 0, lastMonthVIPs: 0, thisMonthVIPs: 0 });
    };

    const thisMonthYear = getMonthYear(monthOffset);
    const lastMonthYear = getMonthYear(monthOffset + 1);

    const navigateMonths = (direction) => {
        if (direction === 'next' && monthOffset > 0) {
            setMonthOffset(monthOffset - 1);
        } else if (direction === 'prev') {
            setMonthOffset(monthOffset + 1);
        }
    };

    const arrowButtonStyle = {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        color: 'grey', // Adjust the color as needed
        fontSize: '24px', // Adjust the size as needed
    };

    // You could also define a hover style if desired
    const arrowButtonHoverStyle = {
        ...arrowButtonStyle,
        color: 'black', // Color on hover
    };
    return (
        <>
<div class="flex-row-container"> 
    <div style={{ width: '190px', marginLeft: '10px'}}>
        <button onClick={() => navigateMonths('prev')} style={arrowButtonStyle} onMouseEnter={e => e.target.style.color = arrowButtonHoverStyle.color} onMouseLeave={e => e.target.style.color = arrowButtonStyle.color}>
            <FaArrowLeft />
        </button>
        &nbsp;
        <button onClick={() => navigateMonths('next')} style={arrowButtonStyle} onMouseEnter={e => e.target.style.color = arrowButtonHoverStyle.color} onMouseLeave={e => e.target.style.color = arrowButtonStyle.color}>
            <FaArrowRight />
        </button>
    </div>
        <Select className='codes-dropdown-left'
            options={mgaNameOptions}
            onChange={handleFilterChange}
            value={selectedFilters}
            isMulti={true}
            isClearable={true}
            placeholder="Filter by MGA..."
            styles={{
                control: (base) => ({
                    ...base,
                    minHeight: '35px',
                }),
                dropdownIndicator: (base) => ({
                    ...base,
                    padding: '4px',
                }),
                clearIndicator: (base) => ({
                    ...base,
                    padding: '4px',
                }),
                multiValue: (base) => ({
                    ...base,
                    backgroundColor: 'lightgrey',
                }),
                multiValueLabel: (base) => ({
                    ...base,
                    color: 'black',
                }),
                multiValueRemove: (base) => ({
                    ...base,
                    ':hover': {
                        backgroundColor: 'red',
                        color: 'white',
                    },
                }),
            }}
        />
<Select className='codes-dropdown-right'
    options={rgaOptions}
    onChange={handleRgaFilterChange} // Make sure this is pointing to the correct handler
    value={selectedRgaFilter}
    isClearable={true}
    placeholder="Filter by RGA..."
    styles={{
        control: (base) => ({
            ...base,
            minHeight: '35px',
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: '4px',
        }),
        clearIndicator: (base) => ({
            ...base,
            padding: '4px',
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: 'lightgrey',
        }),
        multiValueLabel: (base) => ({
            ...base,
            color: 'black',
        }),
        multiValueRemove: (base) => ({
            ...base,
            ':hover': {
                backgroundColor: 'red',
                color: 'white',
            },
        }),
    }}
/>

    </div>


            <div className="more-data-container">

                <table className="maxbonus-table">
                    <thead className="more-table-thead">
                        <tr>
                            <th className="top-table-header ">

                            </th>
                            <th colSpan={2} className="top-table-header" style={{ backgroundColor: "#00548c" }}>Objective</th>
                            <th colSpan={2} className="top-table-header" style={{ backgroundColor: "#ED722F" }}>60 Day Window</th>
                            <th className="top-table-header" style={{ backgroundColor: "#ED722F" }}>Rollups</th>
                            <th colSpan={2} className="top-table-header" style={{ backgroundColor: "#B25271" }}>Needed</th>
                            <th colSpan={2} className="top-table-header" style={{ backgroundColor: "#00548cc3" }}>Bonus</th>
                        </tr>
                        {/* Second row of headers */}
                        <tr>
                            <th className="green-top-table-header">MGA</th>
                            <th className="green-top-table-header">100%</th>
                            <th className="green-top-table-header">125%</th>
                            <th className="green-top-table-header">{lastMonthYear}</th>
                            <th className="green-top-table-header">{thisMonthYear}</th>
                            <th className="green-top-table-header">Total</th>
                            <th className="green-top-table-header">100%</th>
                            <th className="green-top-table-header">125%</th>
                            <th className="green-top-table-header">%</th>
                            <th className="green-top-table-header">VIP?</th>
                        </tr>
                    </thead>
                    <tbody>


                        {Object.entries(filteredAndGroupedMgas).map(([groupKey, groupMgas]) => {
                            const totals = calculateTotalCodesForGroup(groupMgas);
                            return (
                                <React.Fragment key={groupKey}>
                                    <tr onClick={() => toggleGroup(groupKey)} style={{ cursor: 'pointer', backgroundColor: '#ED722F', color: 'black' }}>
                                        <th colSpan="1" className="top-table-header" style={{ color: 'white', backgroundColor: "#ED722F", fontSize: '10px' }}>
                                            {expandedGroups[groupKey] ? '-' : '+'} {groupKey} Tree
                                        </th>
                                        <th colSpan="9" style={{ color: 'white', backgroundColor: '#f0f0f0' }}>
                                        </th>
                                    </tr>


                                    {expandedGroups[groupKey] && calculateObjectives(groupMgas, data.associatesData, data.detailsData, monthOffset).map((mga, index) => (
                                        <tr key={index}>
                                            <th className="grey-table-header more-table-first-col ">{mga.name}</th>
                                            <td className="more-table-input-like">{mga.objective100}</td>
                                            <td className="more-table-input-like">{mga.objective125}</td>
                                            <td className="more-table-input-like">{mga.codesLastMonth + mga.vipsLastMonth}</td>
                                            <td className="more-table-input-like">{mga.codesThisMonth}</td>
                                            <td className="more-table-input-like">{mga.showRollup ? mga.combinedRollups : 'N/A'}</td>
                                            <td className="more-table-input-like">{mga.neededFor100}</td>
                                            <td className="more-table-input-like">{mga.neededFor125}</td>
                                            <td className="more-table-input-like">{mga.bonus}%</td>
                                            <td className="more-table-input-like">{mga.vipYN}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default AllMgasTable;
