import React, { useState, useMemo } from 'react';

const MoreStatsTable = ({ mgaData, hireToCodeRatio }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'descending' });

    const sortedData = useMemo(() => {
        const sortedItems = Object.entries(mgaData).sort(([mgaA, statsA], [mgaB, statsB]) => {
            if (!sortConfig.key) {
                return 0; // No sorting applied
            }
            const valueA = statsA[sortConfig.key];
            const valueB = statsB[sortConfig.key];

            if (valueA < valueB) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (valueA > valueB) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0; // Equal values don't change order
        });

        // Append the hireToCodeRatio to each MGA stats for rendering
        return sortedItems.map(([mga, stats]) => {
            return [mga, { ...stats, hireToCodeRatio }];
        });
    }, [mgaData, hireToCodeRatio, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return (
        <div className='more-data-container' style={{ marginBottom: '30px'}}>
        <div className="stats-table-container">
            <table className="stats-table">
                <thead className='more-table-thead'>
                    <tr>
                        <th className='more-table-header'>MGA Name</th>
                        <th className='more-table-header' style={{ cursor: 'pointer',  backgroundColor: '#319b43bb' }} onClick={() => requestSort('totalShowPercent')}>Overview Show {sortConfig.key === 'totalShowPercent' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}</th>
                        <th className='more-table-header' style={{ cursor: 'pointer',  backgroundColor: '#319b43bb' }} onClick={() => requestSort('finalShowPercent')}>Final Show {sortConfig.key === 'finalShowPercent' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}</th>
                        <th className='more-table-header' style={{ cursor: 'pointer',  backgroundColor: '#319b43bb' }} onClick={() => requestSort('finalOverTotalPercent')}>Final Show/Total Hires {sortConfig.key === 'finalOverTotalPercent' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}</th>
                        <th className='more-table-header' style={{ cursor: 'pointer',  backgroundColor: '#319b43bb' }} onClick={() => requestSort('percentPRHires')}>Percent PR Hires {sortConfig.key === 'percentPRHires' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}</th>
                        <th className='more-table-header' style={{ cursor: 'pointer',  backgroundColor: '#319b43bb' }} onClick={() => requestSort('setToHireRatio')}>Set to Hire Ratio {sortConfig.key === 'setToHireRatio' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}</th>
                        <th className='more-table-header' style={{ cursor: 'pointer',  backgroundColor: '#319b43bb' }} onClick={() => requestSort('avgHiresPerWeekPerMGA')}>Hires Per Week {sortConfig.key === 'avgHiresPerWeekPerMGA' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}</th>
                        <th className='more-table-header' style={{ cursor: 'pointer',  backgroundColor: '#319b43bb' }} onClick={() => requestSort('hireToCode')}>Hire to Code {sortConfig.key === 'hireToCode' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map(([mga, stats], index) => (
                        <tr key={index}>
                            <th className='more-table-header'>{mga}</th>
                            <td className='more-table-input-like'>{stats.totalShowPercent}%</td>
                            <td className='more-table-input-like'>{stats.finalShowPercent}%</td>
                            <td className='more-table-input-like'>{stats.finalOverTotalPercent}%</td>
                            <td className='more-table-input-like'>{stats.percentPRHires}%</td>
                            <td className='more-table-input-like'>{stats.setToHireRatio}</td>
                            <td className='more-table-input-like'>{stats.avgHiresPerWeekPerMGA}</td>
                            <td className='more-table-input-like'>{stats.hireToCodeRatio}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        </div>
    );
};

export default MoreStatsTable;
