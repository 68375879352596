import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './HierarchyTable.css'; // Import the CSS for styling
import { Modal, Button, Form } from 'react-bootstrap';

const AllUsers = () => {
    const [activeUsers, setActiveUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [email, setEmail] = useState('');

    useEffect(() => {
        const fetchActiveUsers = async () => {
            try {
                const response = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/getActiveUsers');
                
                if (response.data.success) {
                    console.log("Fetched Data:", response.data.data); // Log fetched data
                    setActiveUsers(response.data.data);
                } else {
                    setError(response.data.message);
                }
            } catch (err) {
                setError('Error fetching active users data');
            } finally {
                setLoading(false);
            }
        };

        fetchActiveUsers();
    }, []);

    const handleActiveChange = async (id, newActiveStatus) => {
        try {
            const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/updateActiveStatus', {
                id,
                managerActive: newActiveStatus,
            });

            if (response.data.success) {
                setActiveUsers((prevData) =>
                    prevData.map((item) => (item.id === id ? { ...item, managerActive: newActiveStatus } : item))
                );
            } else {
                alert(response.data.message);
            }
        } catch (err) {
            console.error('Error updating active status:', err);
            alert('Error updating active status');
        }
    };

    const openModal = (agent, agentNum) => {
        setModalData({ agent, agentNum });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalData({});
    };

    if (loading) return <div>Loading...</div>; // Display "Loading..." while loading
    if (error) return <div>{error}</div>;

    const renderUsers = (users) => {
        return users.map((user) => (
            <tr key={user.id} className={user.managerActive === 'n' ? 'inactive-row' : ''}>
                <td>{user.lagnname}</td>
                <td>{user.clname}</td>
                <td>{user.agtnum}</td>
                <td>
                    <select
                        value={user.managerActive}
                        onChange={(e) => handleActiveChange(user.id, e.target.value)}
                    >
                        <option value="y">Yes</option>
                        <option value="n">No</option>
                    </select>
                </td>
                <td>
                    {user.redeemed === 1 ? (
                        'Yes'
                    ) : (
                        <button onClick={() => openModal(user.lagnname, user.agtnum)}>Send Email</button>
                    )}
                </td>
            </tr>
        ));
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmitEmail = async () => {
        try {
            const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/send-account-email', {
                email: email,
                agentName: modalData.agent,
                agentNum: modalData.agentNum,
            });

            if (response.data.success) {
                alert('Email sent successfully');
                closeModal();
            } else {
                alert(response.data.message);
            }
        } catch (err) {
            console.error('Error sending email:', err);
            alert('Error sending email');
        }
    };

    return (
        <>
            <table className='hierarchyTable'>
                <thead>
                    <tr>
                        <th>Agent</th>
                        <th>Contract</th>
                        <th>Agent #</th>
                        <th>Active?</th>
                        <th>Redeemed</th>
                    </tr>
                </thead>
                <tbody>
                    {renderUsers(activeUsers)}
                </tbody>
            </table>
            {isModalOpen && (
                <Modal show={isModalOpen} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Account Setup Email</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Agent: {modalData.agent}</p>
                        <p>Agent #: {modalData.agentNum}</p>
                        <Form>
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                            </Form.Group>
                        </Form>
                        <Button
                            variant="primary"
                            onClick={handleSubmitEmail}
                            style={{
                                backgroundColor: '#00558c',
                                margin: '0 auto',
                                display: 'block',
                                marginTop: '20px',
                            }}
                        >
                            Submit
                        </Button>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default AllUsers;
