import React, { useState, useEffect } from 'react';
import MaxBonus from './MaxBonus';
import AllMgasTable from './AllMgasTable';
import CodesAndVipsTable from './CodesAndVipsTable';
import CodesCards from './CodesCards';
const Codes = ({ associatesData, pendingData }) => {
    useEffect(() => {
        document.title = 'Associates';
      }, []);    const [view, setView] = useState('Totals'); // Default view is 'Totals'

    const handleToggle = (event) => {
        setView(event.target.value); // Set view based on radio button
    };

    return (
        <div className="container">
            <div className="header-with-toggle" style={{ marginTop: '20px' }}>
                <div className="tabs">
                    <input 
                        type="radio" 
                        id="totals" 
                        name="view_type" 
                        value="Totals" 
                        checked={view === 'Totals'} 
                        onChange={handleToggle} 
                    />
                    <label htmlFor="totals">Totals</label>
                    <input 
                        type="radio" 
                        id="detail" 
                        name="view_type" 
                        value="Detail" 
                        checked={view === 'Detail'} 
                        onChange={handleToggle} 
                    />
                    <label htmlFor="detail">Details</label>
                </div>
            </div>
            {/* Render both tables but only display the one corresponding to the current view */}
            <div style={{ display: view === 'Totals' ? 'block' : 'none' }}>
                <CodesAndVipsTable />
            </div>
            <div style={{ display: view === 'Detail' ? 'block' : 'none' }}>
                <AllMgasTable />
            </div>
        </div>
    );
};

export default Codes;
