import React from 'react';
import './Placeholder.css'; // Assuming you want to add some styles

const Placeholder = () => {
    return (
        <div className="placeholder-container">
            <div className="placeholder-line"></div>
            <div className="placeholder-line"></div>
            <div className="placeholder-box"></div>
        </div>
    );
};

export default Placeholder;
