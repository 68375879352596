import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HierarchyComponent.css';
import Placeholder from '../utils/Placeholder';
import moment from 'moment';

const ActiveUsersTable = () => {
  const [data, setData] = useState([]);
  const [originalDailyActivity, setOriginalDailyActivity] = useState([]);
  const [dailyActivity, setDailyActivity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [weeks, setWeeks] = useState([]);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);
  const [editingCell, setEditingCell] = useState(null);
  const [formData, setFormData] = useState({});
  const previousCell = useRef(null); // Reference to store the previous cell being edited
  const inputRefs = useRef({}); // Reference to store input elements
  const [allowedAgentNumbers, setAllowedAgentNumbers] = useState([]); // Store allowed agent numbers
  const editingRowRef = useRef(null); // Declare and initialize editingRowRef
  const [isSaving, setIsSaving] = useState(false);
  const tableContainerRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
        try {
          const userId = localStorage.getItem('userId'); // Get userId from localStorage
          const responseRGA = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/rgaHierarchy', {
            params: { userId }
          });
          const responseDailyActivity = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/getAllDailyActivity');
      
          if (responseRGA.data.success && responseDailyActivity.data.success) {
            console.log("Fetched Data:", responseRGA.data.data); // Log fetched data
            setData(responseRGA.data.data);
            const activityData = responseDailyActivity.data.data.map(activity => ({
              ...activity,
              reportDate: new Date(new Date(activity.reportDate).getTime() + new Date(activity.reportDate).getTimezoneOffset() * 60000)
            }));
            setOriginalDailyActivity(activityData);
            const uniqueWeeks = getUniqueWeeks(activityData);
            setWeeks(uniqueWeeks);
            const newestWeekIndex = 0; // The newest week is at the start of the sorted array
            setSelectedWeekIndex(newestWeekIndex); // Set the newest week as default
          } else {
            setError('No data found');
          }
        } catch (error) {
          setError('Error fetching data');
        } finally {
          setLoading(false);
        }
      };
      

    fetchData();
  }, []);

  useEffect(() => {
    if (weeks.length > 0) {
      filterDailyActivity(selectedWeekIndex, originalDailyActivity);
    }
  }, [selectedWeekIndex, weeks, originalDailyActivity]);

  useEffect(() => {
    if (editingCell) {
      const { lagnname, dayIndex, columnIndex } = editingCell;
      const inputKey = `${lagnname}-${dayIndex}-${columnIndex}`;
      if (inputRefs.current[inputKey]) {
        inputRefs.current[inputKey].focus();
        inputRefs.current[inputKey].select();
      }
    }
  }, [editingCell]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editingCell && editingRowRef.current && !editingRowRef.current.contains(event.target)) {
        if (!isSaving) { // Check if saving is not already in progress
          console.log('Click outside detected, calling handleSave');
          setIsSaving(true); // Set isSaving to true before calling handleSave to prevent multiple calls
          setFormData((prevFormData) => {
            handleSave(prevFormData, 'handleClickOutside'); // Pass source as argument
            return prevFormData;
          });
        }
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      console.log('Cleaning up event listener');
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingCell, isSaving]);
  
  
  
  useEffect(() => {
    const fetchHierarchyInfo = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Get userId from localStorage
        const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/getHierarchyInfo', {
          userId,
        });
        if (response.data.success) {
          const allowedAgents = response.data.data.map(item => item.lagnname);
          setAllowedAgentNumbers(allowedAgents);
        } else {
          console.error('Failed to fetch hierarchy info');
        }
      } catch (error) {
        console.error('Error fetching hierarchy info:', error);
      }
    };

    fetchHierarchyInfo();
  }, []);

  useEffect(() => {
    if (weeks.length > 0) {
      filterDailyActivity(selectedWeekIndex, originalDailyActivity);
      const [mondayStr, sundayStr] = weeks[selectedWeekIndex].split(' - ');
      const monday = new Date(mondayStr);
      const sunday = new Date(sundayStr);
      const today = new Date();
      if (today >= monday && today <= sunday) {
        scrollToCurrentDay();
      }
    }
  }, [selectedWeekIndex, weeks, originalDailyActivity]);
  
  

  const getUniqueWeeks = (data) => {
    const weeks = {};
    data.forEach(item => {
      const date = new Date(item.reportDate);
      const monday = new Date(date.setDate(date.getDate() - ((date.getDay() + 6) % 7))); // Calculate the Monday of the current week
      const sunday = new Date(monday);
      sunday.setDate(monday.getDate() + 6); // Calculate the Sunday of the current week
      const weekKey = `${(monday.getMonth() + 1).toString().padStart(2, '0')}/${monday.getDate().toString().padStart(2, '0')}/${monday.getFullYear()} - ${(sunday.getMonth() + 1).toString().padStart(2, '0')}/${sunday.getDate().toString().padStart(2, '0')}/${sunday.getFullYear()}`;
      if (!weeks[weekKey]) {
        weeks[weekKey] = true;
      }
    });

    // Add the current week if not present
    const today = new Date();
    const currentMonday = new Date(today.setDate(today.getDate() - ((today.getDay() + 6) % 7)));
    const currentSunday = new Date(currentMonday);
    currentSunday.setDate(currentMonday.getDate() + 6);
    const currentWeekKey = `${(currentMonday.getMonth() + 1).toString().padStart(2, '0')}/${currentMonday.getDate().toString().padStart(2, '0')}/${currentMonday.getFullYear()} - ${(currentSunday.getMonth() + 1).toString().padStart(2, '0')}/${currentSunday.getDate().toString().padStart(2, '0')}/${currentSunday.getFullYear()}`;

    if (!weeks[currentWeekKey]) {
      weeks[currentWeekKey] = true;
    }

    return Object.keys(weeks).sort((a, b) => new Date(b.split(' - ')[0]) - new Date(a.split(' - ')[0]));
  };

  const handleWeekChange = (event) => {
    const newSelectedWeekIndex = weeks.indexOf(event.target.value);
    setSelectedWeekIndex(newSelectedWeekIndex);
    filterDailyActivity(newSelectedWeekIndex);
  };

  const handlePreviousWeek = () => {
    if (selectedWeekIndex < weeks.length - 1) {
      const newIndex = selectedWeekIndex + 1;
      setSelectedWeekIndex(newIndex);
      filterDailyActivity(newIndex, originalDailyActivity);
    }
  };

  const handleNextWeek = () => {
    if (selectedWeekIndex > 0) {
      const newIndex = selectedWeekIndex - 1;
      setSelectedWeekIndex(newIndex);
      filterDailyActivity(newIndex, originalDailyActivity);
    }
  };

  const filterDailyActivity = (weekIndex, data = originalDailyActivity) => {
    const [mondayStr, sundayStr] = weeks[weekIndex].split(' - ');
    const monday = new Date(mondayStr);
    const sunday = new Date(sundayStr);


    const filteredActivity = data.filter(activity => {
      const reportDate = new Date(activity.reportDate);
      return reportDate >= monday && reportDate <= sunday;
    });

    setDailyActivity(filteredActivity);
  };

  const handleCellClick = async (agentData, dayIndex, columnIndex) => {
    if (!allowedAgentNumbers.includes(agentData.lagnname)) {
      alert('This agent is outside of your hierarchy.');
      return;
    }
  
    if (editingCell && !isSaving) {
      console.log('handleCellClick: Saving previous cell before editing new cell');
      await handleSave(formData, 'handleCellClick'); // Pass source as argument
    }
  
    const activity = getDailyActivityForAgent(agentData.lagnname, dayIndex + 1);
  
    setEditingCell({
      lagnname: agentData.lagnname,
      dayIndex,
      columnIndex,
      esid: agentData.esid,
      sa: agentData.sa,
      ga: agentData.ga,
      mga: agentData.mga
    });
  
    const newFormData = {
      calls: activity.calls && activity.calls !== 'N/A' ? activity.calls : '0',
      appts: activity.appts && activity.appts !== 'N/A' ? activity.appts : '0',
      sits: activity.sits && activity.sits !== 'N/A' ? activity.sits : '0',
      sales: activity.sales && activity.sales !== 'N/A' ? activity.sales : '0',
      alp: activity.alp && activity.alp !== 'N/A' ? activity.alp : '0',
      refs: activity.refs && activity.refs !== 'N/A' ? activity.refs : '0',
      refAppt: activity.refAppt && activity.refAppt !== 'N/A' ? activity.refAppt : '0',
      refSit: activity.refSit && activity.refSit !== 'N/A' ? activity.refSit : '0',
      refSale: activity.refSale && activity.refSale !== 'N/A' ? activity.refSale : '0',
      refAlp: activity.refAlp && activity.refAlp !== 'N/A' ? activity.refAlp : '0'
    };
    setFormData(newFormData);
    previousCell.current = { lagnname: agentData.lagnname, dayIndex, columnIndex }; // Update the reference to the current cell being edited
    editingRowRef.current = document.querySelector(`#row-${agentData.lagnname}`); // Set the current row reference
  };
  
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  

  const handleSave = async (updatedFormData = formData, source = 'unknown') => {
    if (isSaving) return; // Prevent multiple calls if already saving
    console.log(`handleSave called from ${source}`);
    
    try {
      const [mondayStr] = weeks[selectedWeekIndex].split(' - ');
      const monday = new Date(mondayStr);
      const reportDate = new Date(monday);
      reportDate.setDate(reportDate.getDate() + editingCell.dayIndex);
      const formattedReportDate = reportDate.toISOString().split('T')[0]; // Format to yyyy-mm-dd
  
      const newActivity = {
        reportDate: formattedReportDate,
        esid: editingCell.esid,
        MGA: editingCell.mga,
        Work: new Date().toISOString().split('T')[0],
        HC_Appt: 0,
        HC_Sit: 0,
        HC_Sale: 0,
        HC_ALP: 0,
        POS_Appt: 0,
        POS_Sit: 0,
        POS_Sale: 0,
        POS_ALP: 0,
        refAppt: parseInt(updatedFormData.refAppt) || 0,
        refSit: parseInt(updatedFormData.refSit) || 0,
        refSale: parseInt(updatedFormData.refSale) || 0,
        refAlp: parseInt(updatedFormData.refAlp) || 0,
        Vendor_Appt: 0,
        Vendor_Sit: 0,
        Vendor_Sale: 0,
        Vendor_ALP: 0,
        calls: parseInt(updatedFormData.calls) || 0,
        appts: parseInt(updatedFormData.appts) || 0,
        sits: parseInt(updatedFormData.sits) || 0,
        sales: parseInt(updatedFormData.sales) || 0,
        alp: parseInt(updatedFormData.alp) || 0,
        refs: parseInt(updatedFormData.refs) || 0,
        rga: editingCell.rga,
        agent: editingCell.lagnname,
        Legacy: '',
        Tree: '',
        SA: editingCell.sa,
        GA: editingCell.ga,
      };
      console.log('New Activity:', newActivity); // Log the new activity data
  
      const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/submitDailyActivity', newActivity);
      if (response.data.success) {
        console.log('Creating new entry');
      }
  
      const responseDailyActivity = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/getAllDailyActivity');
      if (responseDailyActivity.data.success) {
        const activityData = responseDailyActivity.data.data.map(activity => ({
          ...activity,
          reportDate: new Date(new Date(activity.reportDate).getTime() + new Date(activity.reportDate).getTimezoneOffset() * 60000)
        }));
        setOriginalDailyActivity(activityData);
        filterDailyActivity(selectedWeekIndex, activityData);
        console.log('Daily activity data updated');
      } else {
        setError('Error fetching updated daily activity data');
      }
  
      setEditingCell(null);
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setIsSaving(false); // Reset flag after save is complete
    }
  };
  
  
  const scrollToCurrentDay = () => {
    const today = new Date();
    const dayIndex = today.getDay() - 1; // getDay() returns 0 for Sunday, 1 for Monday, etc.
    if (dayIndex === 0) return; // Do not scroll if today is Sunday or Monday
  
    if (tableContainerRef.current) {
      const yesterdayDayIndex = dayIndex - 1;
      const yesterdayHeader = tableContainerRef.current.querySelector(`th[data-day-index="${yesterdayDayIndex}"]`);
      if (yesterdayHeader) {
        const additionalOffset = 171; // Adjust this value as needed
        const offset = yesterdayHeader.offsetLeft + yesterdayHeader.offsetWidth - additionalOffset;
        tableContainerRef.current.scrollTo({ left: offset, behavior: 'instant' });
      }
    }
  };
  
  
  
  const handleCancel = () => {
    setEditingCell(null);
    setFormData({});
  };

  const getDailyActivityForAgent = (lagnname, day) => {
    const activity = dailyActivity.find(activity => {
      const reportDate = new Date(activity.reportDate);
      return activity.agent === lagnname && reportDate.getDay() === day;
    });
    return activity || {
      appts: 'N/A',
      sits: 'N/A',
      sales: 'N/A',
      alp: 'N/A',
      refs: 'N/A',
      refAppt: 'N/A',
      refSit: 'N/A',
      refSale: 'N/A',
      refAlp: 'N/A'
    };
  };

  const groupedData = data.reduce((acc, item) => {
    const rgaKey = item.RGA || 'No RGA';
    if (!acc[rgaKey]) {
      acc[rgaKey] = [];
    }
    acc[rgaKey].push(item);
    return acc;
  }, {});

  data.forEach((item) => {
    const mgaRGA = item.MGA;
    if (groupedData[mgaRGA]) {
      groupedData[mgaRGA].push(item);
    }
  });

  const renderTabs = () => {
    const sortedRGAs = Object.keys(groupedData).sort((a, b) => {
      if (a === 'No RGA') return 1; // Place 'No RGA' at the end
      if (b === 'No RGA') return -1;
      return a.localeCompare(b);
    });
  
    return sortedRGAs.map((rga) => {
      if (rga !== 'No RGA') {
        const abbreviatedName = rga.split(' ')[0];
        return (
          <Tab eventKey={rga} title={abbreviatedName} key={rga}>
            <MGAWithAGTs
              mgas={groupedData[rga]}
              dailyActivity={dailyActivity}
              selectedWeek={weeks[selectedWeekIndex]}
              editingCell={editingCell}
              formData={formData}
              handleCellClick={handleCellClick}
              handleInputChange={handleInputChange}
              handleSave={handleSave}
              handleCancel={handleCancel}
              inputRefs={inputRefs} // Pass the inputRefs to the child component
              editingRowRef={editingRowRef} // Pass the editingRowRef to the child component
              tableContainerRef={tableContainerRef} // Pass the tableContainerRef to the child component
            />
          </Tab>
        );
      }
      return null;
    });
  };
  
  

  return (
    <div>
        {loading ? (
            <Placeholder />
        ) : (
            <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
                    <button className='hier-change-week-left' onClick={handlePreviousWeek} disabled={selectedWeekIndex === weeks.length - 1}>{'<'}</button>
                    <select onChange={handleWeekChange} value={weeks[selectedWeekIndex]}>
                        {weeks.map(week => (
                            <option value={week} key={week}>{week}</option>
                        ))}
                    </select>
                    <button className='hier-change-week-right' onClick={handleNextWeek} disabled={selectedWeekIndex === 0}>{'>'}</button>
                </div>
                          
                <Tabs defaultActiveKey={Object.keys(groupedData).find(rga => rga !== 'No RGA')} id="rga-tabs">
                    {renderTabs()}
                </Tabs>
            </>
        )}
    </div>
);

};

const reformatAgentName = (name) => {
  if (!name) return ''; // Check if name is undefined or null
  const parts = name.split(' ');
  if (parts.length < 2) return name;
  const [last, first, ...rest] = parts;
  return `${first} ${rest.join(' ')} ${last}`;
};

const MGAWithAGTs = ({ mgas, dailyActivity, selectedWeek, editingCell, formData, handleCellClick, handleInputChange, handleSave, handleCancel, inputRefs, editingRowRef, tableContainerRef }) => {
  const [mondayStr, sundayStr] = selectedWeek.split(' - ');
  const monday = new Date(mondayStr);
  const sunday = new Date(sundayStr);

  const getDailyActivityForAgent = (lagnname, day) => {
    const activity = dailyActivity.find(activity => {
      const reportDate = new Date(activity.reportDate);
      return activity.agent === lagnname && reportDate.getDay() === day;
    });
    return activity || {
        calls: 'N/A',
      appts: 'N/A',
      sits: 'N/A',
      sales: 'N/A',
      alp: 'N/A',
      refs: 'N/A',
      refAppt: 'N/A',
      refSit: 'N/A',
      refSale: 'N/A',
      refAlp: 'N/A'
    };
  };

  const calculateTotalsForAgent = (lagnname) => {
    return dailyActivity.reduce((acc, activity) => {
      if (activity.agent === lagnname) {
        acc.calls += parseInt(activity.calls, 10) || 0;
        acc.appts += parseInt(activity.appts, 10) || 0;
        acc.sits += parseInt(activity.sits, 10) || 0;
        acc.sales += parseInt(activity.sales, 10) || 0;
        acc.alp += parseInt(activity.alp, 10) || 0;
        acc.refs += parseInt(activity.refs, 10) || 0;
        acc.refAppt += parseInt(activity.refAppt, 10) || 0;
        acc.refSit += parseInt(activity.refSit, 10) || 0;
        acc.refSale += parseInt(activity.refSale, 10) || 0;
        acc.refAlp += parseInt(activity.refAlp, 10) || 0;
      }
      return acc;
    }, {
        calls: 0,
      appts: 0,
      sits: 0,
      sales: 0,
      alp: 0,
      refs: 0,
      refAppt: 0,
      refSit: 0,
      refSale: 0,
      refAlp: 0
    });
  };

  const calculateWeeklyTotals = (agents) => {
    const totals = {
        calls: 0,
      appts: 0,
      sits: 0,
      sales: 0,
      alp: 0,
      refs: 0,
      refAppt: 0,
      refSit: 0,
      refSale: 0,
      refAlp: 0
    };
  
    agents.forEach(agent => {
      const agentTotals = calculateTotalsForAgent(agent.lagnname);
      Object.keys(totals).forEach(key => {
        totals[key] += agentTotals[key];
      });
    });
  
    return totals;
  };
  
const calculateDailyTotals = (agents) => {
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const dailyTotals = daysOfWeek.map(() => ({
    calls: 0,
    appts: 0,
    sits: 0,
    sales: 0,
    alp: 0,
    refs: 0,
    refAppt: 0,
    refSit: 0,
    refSale: 0,
    refAlp: 0
  }));

  const accumulateAgentData = (agent) => {
    daysOfWeek.forEach((_, dayIndex) => {
      const activity = getDailyActivityForAgent(agent.lagnname, dayIndex + 1);
      Object.keys(dailyTotals[dayIndex]).forEach(key => {
        const value = parseInt(activity[key], 10) || 0;
        dailyTotals[dayIndex][key] += value;
      });
    });

    if (agent.children && agent.children.length > 0) {
      agent.children.forEach(child => accumulateAgentData(child));
    }
  };

  agents.forEach(agent => {
    accumulateAgentData(agent);
  });

  return dailyTotals;
};

const isWithinFirstThreeMonths = (esid) => {
  const startDate = getStartDateFromEsid(esid); // Implement this based on how the esid stores the start date
  const now = moment();
  const startMoment = moment(startDate);
  const monthsDiff = now.diff(startMoment, 'months');
  return monthsDiff < 3;
};
const getStartDateFromEsid = (esid) => {
  // Extract the start date from esid if it's part of the esid or get it from a separate data source
  // Placeholder example (adjust this logic according to your actual esid structure):
  return moment(esid, 'YYYY-MM-DD'); // Assuming esid contains a date in this format
};
  
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const buildHierarchy = (data) => {
    const hierarchy = [];
    const map = {};
    const inactiveNodes = [];

    // Initialize map with each item
    data.forEach(item => {
      map[item.lagnname] = { ...item, children: [] };
    });

    // Group children under their respective parents based on the hierarchy rules
    data.forEach(item => {
      if (item.managerActive === 'n') {
        inactiveNodes.push(map[item.lagnname]);
        return;
      }

      if (item.sa && map[item.sa]) {
        map[item.sa].children.push(map[item.lagnname]);
      } else if (item.ga && map[item.ga]) {
        map[item.ga].children.push(map[item.lagnname]);
      } else if (item.mga && map[item.mga]) {
        map[item.mga].children.push(map[item.lagnname]);
      } else if (item.rga && map[item.rga]) {
        map[item.rga].children.push(map[item.lagnname]);
      } else {
        hierarchy.push(map[item.lagnname]);
      }
    });

    // Ensure the hierarchy follows the specified order
    const sortHierarchy = (nodes) => {
      return nodes.sort((a, b) => {
        const order = ['RGA', 'MGA', 'GA', 'AGT', 'SA'];
        const orderA = order.indexOf(a.clname);
        const orderB = order.indexOf(b.clname);

        // Custom order for AGT with null sa and non-null ga before SA with null sa and non-null ga
        if (orderA === orderB) {
          if (a.clname === 'AGT' && b.clname === 'SA') {
            if (a.sa === null && b.sa === null && a.ga !== null && b.ga !== null) {
              return -1;
            }
          } else if (a.clname === 'SA' && b.clname === 'AGT') {
            if (a.sa === null && b.sa === null && a.ga !== null && b.ga !== null) {
              return 1;
            }
          }
          return a.managerActive === 'n' ? 1 : -1;
        }

        return orderA - orderB;
      }).map(node => {
        node.children = sortHierarchy(node.children);
        return node;
      });
    };

    const sortedHierarchy = sortHierarchy(hierarchy);
    const sortedInactiveNodes = sortHierarchy(inactiveNodes);


    return { sortedHierarchy, sortedInactiveNodes };
  };

  const getColorForClname = (clname) => {
    switch (clname) {
      case 'RGA':
        return 'lightblue';
      case 'MGA':
        return '#68B675';
      case 'GA':
        return '#ED722F';
      case 'AGT':
        return 'black';
      case 'SA':
        return '#B25271';
      default:
        return 'black';
    }
  };
  
  const getFontWeightForClname = (clname) => {
    switch (clname) {
      case 'MGA':
      case 'GA':
      case 'SA':
        return 'bold';
      default:
        return 'normal';
    }
  };
  
  const loggedInAgentName = localStorage.getItem('agnName'); // Retrieve logged-in agent's name

  
  const renderAgents = (agent) => {
    const agentTotals = calculateTotalsForAgent(agent.lagnname);
    const clnameColor = getColorForClname(agent.clname);
    const clnameFontWeight = getFontWeightForClname(agent.clname);
    const isLoggedInAgent = agent.lagnname === loggedInAgentName;
    const isNewAgent = isWithinFirstThreeMonths(agent.esid); // Check if the agent is new
  
    return (
      <React.Fragment key={agent.lagnname}>
        <tr
          id={`row-${agent.lagnname}`}
          ref={editingCell && editingCell.lagnname === agent.lagnname ? editingRowRef : null}
          className={`${editingCell && editingCell.lagnname === agent.lagnname ? 'editing-row' : ''} ${isNewAgent ? 'new-agent' : ''}`} // Apply new-agent class conditionally
          style={{ backgroundColor: isNewAgent ? 'yellow' : '' }} // Highlight with yellow if within first 3 months
        >
        <td
          className="first-column"
          style={{
            backgroundColor: isNewAgent
              ? 'black' // Highlight if within first 3 months
              : isLoggedInAgent
              ? '#ED722F'
              : '#00548C',
          }}
        >            {reformatAgentName(agent.lagnname)}
          </td>
          <td style={{ color: clnameColor, fontWeight: clnameFontWeight }}>{agent.additionalDetails}</td>
          {daysOfWeek.map((_, dayIndex) => {
            const activity = getDailyActivityForAgent(agent.lagnname, dayIndex + 1);
            const isEditing = editingCell && editingCell.lagnname === agent.lagnname && editingCell.dayIndex === dayIndex;
            const inputKey = `${agent.lagnname}-${dayIndex}-`; // Unique key for each input field
  
            return (
              <React.Fragment key={`${dayIndex}-${agent.lagnname}`}>
                {isEditing ? (
                  <>
                    <td className="editing-cell"><input type="text" name="calls" value={formData.calls} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 0} ref={(el) => inputRefs.current[`${inputKey}0`] = el} /></td>
                    <td className="editing-cell"><input type="text" name="appts" value={formData.appts} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 1} ref={(el) => inputRefs.current[`${inputKey}1`] = el} /></td>
                    <td className="editing-cell"><input type="text" name="sits" value={formData.sits} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 2} ref={(el) => inputRefs.current[`${inputKey}2`] = el} /></td>
                    <td className="editing-cell"><input type="text" name="sales" value={formData.sales} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 3} ref={(el) => inputRefs.current[`${inputKey}3`] = el} /></td>
                    <td className="editing-cell"><input type="text" name="alp" value={formData.alp} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 4} ref={(el) => inputRefs.current[`${inputKey}4`] = el} /></td>
                    <td className="editing-cell"><input type="text" name="refs" value={formData.refs} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 5} ref={(el) => inputRefs.current[`${inputKey}5`] = el} /></td>
                    <td className="editing-cell"><input type="text" name="refAppts" value={formData.refAppt} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 6} ref={(el) => inputRefs.current[`${inputKey}6`] = el} /></td>
                    <td className="editing-cell"><input type="text" name="refSits" value={formData.refSit} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 7} ref={(el) => inputRefs.current[`${inputKey}7`] = el} /></td>
                    <td className="editing-cell"><input type="text" name="refSales" value={formData.refSale} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 8} ref={(el) => inputRefs.current[`${inputKey}8`] = el} /></td>
                    <td className="editing-cell"><input type="text" name="refAlp" value={formData.refAlp} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 9} ref={(el) => inputRefs.current[`${inputKey}9`] = el} /></td>
                  </>
                ) : (
                  <>
                    <td onClick={() => handleCellClick(agent, dayIndex, 0)}>{activity.calls !== 'N/A' ? activity.calls : 'N/A'}</td>
                    <td onClick={() => handleCellClick(agent, dayIndex, 1)}>{activity.appts !== 'N/A' ? activity.appts : 'N/A'}</td>
                    <td onClick={() => handleCellClick(agent, dayIndex, 2)}>{activity.sits !== 'N/A' ? activity.sits : 'N/A'}</td>
                    <td onClick={() => handleCellClick(agent, dayIndex, 3)}>{activity.sales !== 'N/A' ? activity.sales : 'N/A'}</td>
                    <td onClick={() => handleCellClick(agent, dayIndex, 4)}>{activity.alp !== 'N/A' ? activity.alp : 'N/A'}</td>
                    <td onClick={() => handleCellClick(agent, dayIndex, 5)}>{activity.refs !== 'N/A' ? activity.refs : 'N/A'}</td>
                    <td onClick={() => handleCellClick(agent, dayIndex, 6)}>{activity.refAppt !== 'N/A' ? activity.refAppt : 'N/A'}</td>
                    <td onClick={() => handleCellClick(agent, dayIndex, 7)}>{activity.refSit !== 'N/A' ? activity.refSit : 'N/A'}</td>
                    <td onClick={() => handleCellClick(agent, dayIndex, 8)}>{activity.refSale !== 'N/A' ? activity.refSale : 'N/A'}</td>
                    <td onClick={() => handleCellClick(agent, dayIndex, 9)}>{activity.refAlp !== 'N/A' ? activity.refAlp : 'N/A'}</td>
                  </>
                )}
              </React.Fragment>
            );
          })}
    
          <td>{agentTotals.calls}</td>
          <td>{agentTotals.appts}</td>
          <td>{agentTotals.sits}</td>
          <td>{agentTotals.sales}</td>
          <td>{agentTotals.alp}</td>
          <td>{agentTotals.refs}</td>
          <td>{agentTotals.refAppt}</td>
          <td>{agentTotals.refSit}</td>
          <td>{agentTotals.refSale}</td>
          <td>{agentTotals.refAlp}</td>
        </tr>
        {agent.children && agent.children.map(subordinate => renderAgents(subordinate))}
      </React.Fragment>
    );
  };
  

  const renderTotalsRow = (dailyTotals) => {
    return (
      <tr className="totals-row">
        <td>Totals</td>
        <td></td>
        {daysOfWeek.map((_, dayIndex) => (
          <React.Fragment key={`totals-${dayIndex}`}>
            <td>{dailyTotals[dayIndex].calls}</td>
            <td>{dailyTotals[dayIndex].appts}</td>
            <td>{dailyTotals[dayIndex].sits}</td>
            <td>{dailyTotals[dayIndex].sales}</td>
            <td>{dailyTotals[dayIndex].alp}</td>
            <td>{dailyTotals[dayIndex].refs}</td>
            <td>{dailyTotals[dayIndex].refAppt}</td>
            <td>{dailyTotals[dayIndex].refSit}</td>
            <td>{dailyTotals[dayIndex].refSale}</td>
            <td>{dailyTotals[dayIndex].refAlp}</td>
          </React.Fragment>
        ))}
        <td>{dailyTotals.reduce((acc, day) => acc + day.calls, 0)}</td>
        <td>{dailyTotals.reduce((acc, day) => acc + day.appts, 0)}</td>
        <td>{dailyTotals.reduce((acc, day) => acc + day.sits, 0)}</td>
        <td>{dailyTotals.reduce((acc, day) => acc + day.sales, 0)}</td>
        <td>{dailyTotals.reduce((acc, day) => acc + day.alp, 0)}</td>
        <td>{dailyTotals.reduce((acc, day) => acc + day.refs, 0)}</td>
        <td>{dailyTotals.reduce((acc, day) => acc + day.refAppt, 0)}</td>
        <td>{dailyTotals.reduce((acc, day) => acc + day.refSit, 0)}</td>
        <td>{dailyTotals.reduce((acc, day) => acc + day.refSale, 0)}</td>
        <td>{dailyTotals.reduce((acc, day) => acc + day.refAlp, 0)}</td>
      </tr>
    );
  };

  return (
<div className="hier-table-container" ref={tableContainerRef}>
    {mgas.map((mga) => {
      // Split the AGTs string into individual agent strings
      const parts = mga.AGTs.split('; ');
      const agents = parts.map((part, index) => {
        if (index === 0) {
          // Handle the first part of AGTs, which is the MGA details
          const [lagnname, agtnum, esid, clname] = part.split(' - ');
          return { lagnname, agtnum, esid, clname: 'MGA', additionalDetails: 'MGA' };
        } else {
          // Handle subsequent parts of AGTs, which are agent details
          return part.split('-- ').map(agt => {
            const [lagnname, agtnum, esid, sa, ga, mga, clname] = agt.split(' - ');
            return { lagnname, agtnum, esid, sa, ga, mga, clname, additionalDetails: clname };
          });
        }
      }).flat();

      const { sortedHierarchy } = buildHierarchy(agents);
      const dailyTotals = calculateDailyTotals(sortedHierarchy);

        return (
          <div key={mga.MGA}>
            <table className="hierarchyTable">
              <thead>
                <tr className="first-row">
                  <th className='day-header'>
                    <h5 className='hier-mga-header'>
                      {mga.MGA.split(' ')[0]}
                    </h5>
                  </th>
                  <th className='day-header'>MGA</th>
                  {daysOfWeek.map((day, index) => (
                    <th colSpan="10" className="day-header" key={day} data-day-index={index}>
  {day} - {(new Date(monday.getTime() + index * 86400000)).toLocaleDateString()}
</th>

                  ))}
                  <th colSpan="10" className="day-header">Totals</th>
                </tr>
                <tr className="second-row">
                  <th style={{backgroundColor: 'transparent'}} className=''></th>
                  <th style={{backgroundColor: 'transparent'}} className=''></th>
                  {daysOfWeek.map(day => (
                    <React.Fragment key={day}>
                        <th style={{backgroundColor: '#68B675'}} className="header-appts">Calls</th>
                      <th style={{backgroundColor: '#68B675'}} className="header-appts">Tot Appts</th>
                      <th style={{backgroundColor: '#68B675'}} className="header-sits">Tot Sits</th>
                      <th style={{backgroundColor: '#68B675'}} className="header-sales">Tot Sales</th>
                      <th style={{backgroundColor: '#68B675'}} className="header-alp">Tot ALP</th>
                      <th style={{backgroundColor: '#68B675'}} className="header-refs">Refs</th>
                      <th style={{backgroundColor: '#68B675'}} className="header-refAppts">Ref Appts</th>
                      <th style={{backgroundColor: '#68B675'}} className="header-refSits">Ref Sits</th>
                      <th style={{backgroundColor: '#68B675'}} className="header-refSales">Ref Sales</th>
                      <th style={{backgroundColor: '#68B675'}} className="header-refAlp">Ref ALP</th>
                    </React.Fragment>
                  ))}
                    <th className="header-appts">Calls</th>
                  <th className="header-appts">Tot Appts</th>
                  <th className="header-sits">Tot Sits</th>
                  <th className="header-sales">Tot Sales</th>
                  <th className="header-alp">Tot ALP</th>
                  <th className="header-refs">Refs</th>
                  <th className="header-refAppts">Ref Appts</th>
                  <th className="header-refSits">Ref Sits</th>
                  <th className="header-refSales">Ref Sales</th>
                  <th className="header-refAlp">Ref ALP</th>
                </tr>
              </thead>
              <tbody>
                {sortedHierarchy.map(agent => renderAgents(agent))}
                {renderTotalsRow(dailyTotals)}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};
 


export default ActiveUsersTable;
