import React from 'react';
import Modal from '../utils/Modal';

const formatCurrency = (value) => {
    return `$${Number(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

const DetailContent = ({ detail }) => {
    return (
        <div className='form-container' style={{
            zIndex: 1000, 
            backgroundColor: 'white', 
            padding: '20px', 
            border: '1px solid black', 
            maxWidth: '600px', // Limit the width of the modal
            overflow: 'auto'  // Enable scrolling if the content is too large
        }}>
            <h2>Details for {detail.month} {detail.dataKey}</h2>
            <p>Total Value: {formatCurrency(detail.value)}</p>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr style={{ backgroundColor: '#244062', textAlign: 'left' }}>
                        <th>MGA</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {detail.contributions && Object.entries(detail.contributions).map(([key, value]) => (
                        <tr key={key}>
                            <td style={{ padding: '8px', border: '1px solid #ddd' }}>{key}</td>
                            <td style={{ padding: '8px', border: '1px solid #ddd' }}>{formatCurrency(value)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


const DetailModal = ({ isOpen, onClose, detail }) => {
    if (!isOpen) return null;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <DetailContent detail={detail} />
        </Modal>
    );
};

export default DetailModal;
