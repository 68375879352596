import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

const AddRecruit = ({ addRecruit }) => {

  const [hierarchyData, setHierarchyData] = useState([]);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);

const states = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
  'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
  'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
  'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
  'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 
  'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
  'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia',
  'Wisconsin', 'Wyoming'
];

useEffect(() => {
  const fetchHierarchyData = async () => {
      try {
          // Retrieve userId from local storage
          const userId = localStorage.getItem('userId');

          // Check if userId is available
          if (!userId) {
              setError('User ID not found in local storage');
              setLoading(false);
              return;
          }

          // Send userId to the backend
          const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/getHierarchyInfo', { userId });

          if (response.data.success) {
              console.log("Fetched Data:", response.data.data); // Log fetched data
              setHierarchyData(response.data.data);
          } else {
              setError(response.data.message);
          }
      } catch (err) {
          setError('Error fetching hierarchy data');
      } finally {
          setLoading(false);
      }
  };

  fetchHierarchyData();
}, []);

  const [isFormVisible, setIsFormVisible] = useState(false);
  
  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };
  
  const [newRecruit, setNewRecruit] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    step: 'Overview',
    email: '',
    phone: '',
    overviewTime: '',
    hire: '',
    finalTime: '',
    residentState: '',
    enrolled: '',
    course: '',
    expectedCompleteDate: '',
    currentProgress: '',
    lastLogPrelic: '',
    prelicPassed: '',
    prelicCert: '',
    testDate: '',
    testPassed: '',
    testCert: '',
    bgDate: '',
    compliance1: '',
    compliance2: '',
    compliance3: '',
    compliance4: '',
    compliance5: '',
    aob: '',
    residentLicenseNumber: '',
    npn: '',
    agentnum: '',
    impactSetup: '',
    trainingStartDate: '',
    coded: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRecruit(prevState => ({ ...prevState, [name]: value }));
  };

  const handleAddRecruit = async () => {
    await addRecruit(newRecruit);
    setNewRecruit({
      firstName: '',
      lastName: '',
      middleName: '',
      step: 'Overview',
      email: '',
      phone: '',
      overviewTime: '',
      hire: '',
      finalTime: '',
      residentState: '',
      enrolled: '',
      course: '',
      expectedCompleteDate: '',
      currentProgress: '',
      lastLogPrelic: '',
      prelicPassed: '',
      prelicCert: '',
      testDate: '',
      testPassed: '',
      testCert: '',
      bgDate: '',
      compliance1: '',
      compliance2: '',
      compliance3: '',
      compliance4: '',
      compliance5: '',
      aob: '',
      residentLicenseNumber: '',
      npn: '',
      agentnum: '',
      impactSetup: '',
      trainingStartDate: '',
      coded: ''
    });
    setIsFormVisible(false); // Close the modal after adding a recruit
  };

  return (
    <>
      <Button onClick={toggleFormVisibility} className="toggle-form-button">
        {isFormVisible ? '-' : '+'}
      </Button>

      <Modal show={isFormVisible} onHide={toggleFormVisibility}>
      <h4 className="text-center">Add Recruit</h4>
        <Modal.Body>
          <label>First Name</label> 
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={newRecruit.firstName}
            onChange={handleInputChange}
            className="form-control mb-2"
          />
          <label>Middle Initial</label>
            <input
              type="text"
              name="middleName"
              placeholder="Middle Initial"
              value={newRecruit.middleName}
              onChange={handleInputChange}
              className="form-control mb-2"
            />
            <label>Last Name</label>
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={newRecruit.lastName}
            onChange={handleInputChange}
            className="form-control mb-2"
          />
          <label>Suffix</label>
                    <input
            type="text"
            name="suffix"
            placeholder="Suffix"
            value={newRecruit.suffix}
            onChange={handleInputChange}
            className="form-control mb-2"
          />
          <label>Email</label>  
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={newRecruit.email}
            onChange={handleInputChange}
            className="form-control mb-2"
          />
          <label>Phone</label>
          <input
            type="text"
            name="phone"
            placeholder="Phone"
            value={newRecruit.phone}
            onChange={handleInputChange}
            className="form-control mb-2"
          />
          <label>Code To</label>
<select
    name="codeTo"
    value={newRecruit.codeTo}
    onChange={handleInputChange}
    className="form-control mb-2"
    disabled={loading || !hierarchyData.length}
>
    <option value="" disabled>Select Code To</option>
    {hierarchyData.map((item, index) => (
        <option key={index} value={item.id}>
            {item.lagnname}
        </option>
    ))}
</select>
          <label>Step in Pipeline</label>
          <select
            name="step"
            value={newRecruit.step}
            onChange={handleInputChange}
            className="form-control mb-2"
          >
            {['Overview', 'Final', 'Pre-Lic', 'Test', 'BG Check', 'Compliance', 'AOB', 'VIP 1', 'VIP 2', 'VIP 3'].map((step, index) => (
              <option key={index} value={step}>{step}</option>
            ))}
          </select>

          {newRecruit.step === 'Overview' && (
  <>
  <label>Overview Time</label>
    <input
      type="datetime-local"
      name="overviewTime"
      placeholder="Overview Time"
      value={newRecruit.overviewTime}
      onChange={handleInputChange}
      className="form-control mb-2"
    />
    <label>Resident State</label>
    <select
      name="residentState"
      value={newRecruit.residentState}
      onChange={handleInputChange}
      className="form-control mb-2"
    >
      <option value="" disabled>Select State</option>
      {states.map((state, index) => (
        <option key={index} value={state}>
          {state}
        </option>
      ))}
    </select>
  </>
)}


{newRecruit.step === 'Final' && (
  <>
    <input
      type="datetime-local"
      name="finalTime"
      placeholder="Final Time"
      value={newRecruit.finalTime}
      onChange={handleInputChange}
      className="form-control mb-2"
    />
    <select
      name="residentState"
      value={newRecruit.residentState}
      onChange={handleInputChange}
      className="form-control mb-2"
    >
      <option value="" disabled>Select State</option>
      {states.map((state, index) => (
        <option key={index} value={state}>
          {state}
        </option>
      ))}
    </select>
    <div className="form-group mb-2">
      <label>Hire:</label>
      <div>
        <div className="form-check form-check-inline">
          <input
            type="radio"
            name="hire"
            id="hireYes"
            value="y"
            checked={newRecruit.hire === 'y'}
            onChange={handleInputChange}
            className="form-check-input"
          />
          <label htmlFor="hireYes" className="form-check-label">Yes</label>
        </div>
        <div className="form-check form-check-inline">
          <input
            type="radio"
            name="hire"
            id="hireNo"
            value="n"
            checked={newRecruit.hire === 'n'}
            onChange={handleInputChange}
            className="form-check-input"
          />
          <label htmlFor="hireNo" className="form-check-label">No</label>
        </div>
      </div>
    </div>
  </>
)}


          {newRecruit.step === 'Pre-Lic' && (
            <>
              <input
                type="text"
                name="course"
                placeholder="Course"
                value={newRecruit.course}
                onChange={handleInputChange}
                className="form-control mb-2"
              />
              <input
                type="text"
                name="enrolled"
                placeholder="Enrolled (y/n)"
                value={newRecruit.enrolled}
                onChange={handleInputChange}
                className="form-control mb-2"
              />
<select
    name="residentState"
    value={newRecruit.residentState}
    onChange={handleInputChange}
    className="form-control mb-2"
>
    <option value="" disabled>Select State</option>
    {states.map((state, index) => (
        <option key={index} value={state}>
            {state}
        </option>
    ))}
</select>

            </>
          )}

{newRecruit.step === 'Test' && (
  <>
    <input
      type="datetime-local"
      name="testDate"
      placeholder="Test Date"
      value={newRecruit.testDate}
      onChange={handleInputChange}
      className="form-control mb-2"
    />
  </>
)}

{newRecruit.step === 'BG Check' && (
  <>
    <input
      type="datetime-local"
      name="bgDate"
      placeholder="BG Date"
      value={newRecruit.bgDate}
      onChange={handleInputChange}
      className="form-control mb-2"
    />
  </>
)}


          {['Compliance', 'AOB'].includes(newRecruit.step) && (
            <>
              <input
                type="text"
                name="residentLicenseNumber"
                placeholder="Resident License Number"
                value={newRecruit.residentLicenseNumber}
                onChange={handleInputChange}
                className="form-control mb-2"
              />
<label htmlFor="npn">
  NPN 
  <a href="https://nipr.com/help/look-up-your-npn" target="_blank" rel="noopener noreferrer">
    (NPN Lookup)
  </a>
</label>
<input
  type="text"
  name="npn"
  placeholder="NPN"
  value={newRecruit.npn}
  onChange={handleInputChange}
  className="form-control mb-2"
/>

            </>
          )}
        </Modal.Body>
          <Button variant="primary" onClick={handleAddRecruit}>
            Add Recruit
          </Button>

      </Modal>
    </>
  );
};

export default AddRecruit;
