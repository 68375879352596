import React, { useState, useEffect } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import './AccountCreationForm.css'; // Import the CSS file

const AccountCreationForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    middleInitial: '', // Changed to middleInitial
    lastName: '',
    suffix: '', // Added suffix field
    email: '',
    rga: '',
    mga: '',
    ga: '',
    sa: '',
  });

  const [activeUsers, setActiveUsers] = useState([]);
  const [rgas, setRgas] = useState([]);
  const [mgas, setMgas] = useState([]);
  const [gas, setGas] = useState([]);
  const [sas, setSas] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    const fetchActiveUsers = async () => {
      try {
        const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/getActiveUsers');
        const data = await response.json();
        if (data.success && Array.isArray(data.data)) {
          setActiveUsers(data.data);

          // Fetch unique RGAs
          const uniqueRgas = data.data
            .filter(user => user.clname === 'RGA')
            .map(user => user.lagnname)
            .filter((value, index, self) => self.indexOf(value) === index);
          setRgas(uniqueRgas);

          // Fetch MGAs
          const uniqueMgas = data.data
            .filter(user => user.clname === 'MGA')
            .map(user => user.lagnname)
            .filter((value, index, self) => self.indexOf(value) === index);
          setMgas(uniqueMgas);
        } else {
          console.error('Error fetching active users:', data.message);
        }
      } catch (error) {
        console.error('Error fetching active users:', error);
      }
    };

    fetchActiveUsers();
  }, []);

  useEffect(() => {
    if (formData.rga && formData.rga !== 'Agency') {
      const filteredMgas = activeUsers
        .filter(user => user.rga === formData.rga)
        .map(user => user.mga)
        .filter((value, index, self) => self.indexOf(value) === index);
      setMgas(filteredMgas);
    } else if (formData.rga === 'Agency') {
      const rgasNotAsMGA = activeUsers
        .filter(user => user.clname === 'RGA' && !activeUsers.some(u => u.mga === user.lagnname && u.rga))
        .map(user => user.lagnname)
        .filter((value, index, self) => self.indexOf(value) === index);
      
      const mgasWithNullRga = activeUsers
        .filter(user => user.clname === 'MGA' && user.rga === null)
        .map(user => user.lagnname)
        .filter((value, index, self) => self.indexOf(value) === index);
  
      const combinedMgas = [...new Set([...rgasNotAsMGA, ...mgasWithNullRga])];
      setMgas(combinedMgas);
    } else {
      setMgas([]);
    }
  }, [formData.rga, activeUsers]);

  useEffect(() => {
    if (formData.mga) {
      const filteredGas = activeUsers
        .filter(user => user.mga === formData.mga && user.clname === 'GA')
        .map(user => user.lagnname)
        .filter((value, index, self) => self.indexOf(value) === index);
      setGas(filteredGas);
    } else {
      setGas([]);
    }
  }, [formData.mga, activeUsers]);

  useEffect(() => {
    if (formData.ga) {
      const filteredSas = activeUsers
        .filter(user => user.ga === formData.ga && user.clname === 'SA')
        .map(user => user.lagnname)
        .filter((value, index, self) => self.indexOf(value) === index);
      setSas(filteredSas);
    } else {
      setSas([]);
    }
  }, [formData.ga, activeUsers]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const generateRandomAgentNumber = () => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const randomLetter = letters.charAt(Math.floor(Math.random() * letters.length));
    const randomNumbers = Math.floor(1000 + Math.random() * 9000); // generates a 4-digit number
    return `${randomLetter}${randomNumbers}`;
  };

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const agtnum = generateRandomAgentNumber();
    const today = new Date();
    const esid = formatDate(today);
    const dateCreated = `${esid} 00:00:00`;

    const submissionData = {
      ...formData,
      reg_dir: 'ZOPHIN',
      rept_name: 'ARIAS,SIMON',
      agtnum,
      clname: 'AGT',
      esid,
      Redeemed: 0,
      date_created: dateCreated,
      Active: 'y',
      Role: null,
      LastLoggedIn: null,
      Password: 'default',
      managerActive: 'y',
      profpic: null,
    };

    fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/create-active-user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(submissionData),
    })
    .then(response => response.json())
    .then(data => {
        setLoading(false); // Stop loading
        if (data.success) {
            console.log('Account created successfully');
            window.location.reload(); // Refresh the page
        } else {
            console.error('Error creating account:', data.message);
        }
    })
    .catch(error => {
        setLoading(false); // Stop loading
        console.error('Error creating account:', error);
    });
    

    console.log('Form submitted with data:', submissionData);
  };

  return (
    <Container>
      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <h1 className="mt-4">Create Account</h1>
      <p>Please ensure you fill out the trainee's full government name. </p>
      <p>The trainee does not have to have an SA or GA selected.</p>
      <p>Once the account is created, the email entered will receive an email with the trainee's login information.</p>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formFirstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            name="firstName"
            placeholder="Enter first name"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formMiddleInitial">
          <Form.Label>Middle Initial</Form.Label>
          <Form.Control
            type="text"
            name="middleInitial"
            placeholder="Enter middle initial"
            value={formData.middleInitial}
            onChange={handleChange}
            maxLength={1} // Ensuring only one character is allowed
          />
        </Form.Group>

        <Form.Group controlId="formLastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            name="lastName"
            placeholder="Enter last name"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formSuffix">
          <Form.Label>Suffix</Form.Label>
          <Form.Control
            type="text"
            name="suffix"
            placeholder="Enter suffix (e.g., Jr., Sr., III)"
            value={formData.suffix}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Enter email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formRGA" className="form-control-select">
          <Form.Label>RGA</Form.Label>
          <Form.Control
            as="select"
            name="rga"
            value={formData.rga}
            onChange={handleChange}
            required
          >
            <option value="">Select RGA</option>
            {rgas.map(rga => (
              <option key={rga} value={rga}>
                {rga}
              </option>
            ))}
            <option value="Agency">Agency</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formMGA" className="form-control-select">
          <Form.Label>MGA</Form.Label>
          <Form.Control
            as="select"
            name="mga"
            value={formData.mga}
            onChange={handleChange}
            required
          >
            <option value="">Select MGA</option>
            {mgas.map(mga => (
              <option key={mga} value={mga}>
                {mga}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formGA" className="form-control-select">
          <Form.Label>GA</Form.Label>
          <Form.Control
            as="select"
            name="ga"
            value={formData.ga}
            onChange={handleChange}
          >
            <option value="">Select GA</option>
            {gas.map(ga => (
              <option key={ga} value={ga}>
                {ga}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formSA" className="form-control-select">
          <Form.Label>SA</Form.Label>
          <Form.Control
            as="select"
            name="sa"
            value={formData.sa}
            onChange={handleChange}
          >
            <option value="">Select SA</option>
            {sas.map(sa => (
              <option key={sa} value={sa}>
                {sa}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3">
          Create Account
        </Button>
      </Form>
    </Container>
  );
};

export default AccountCreationForm;
