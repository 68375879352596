// analytics.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

// Initialize Google Analytics
export const initGA = () => {
  ReactGA.initialize('G-RMX1JZW7YW'); // Replace with your actual Measurement ID
};

// Custom hook to track page views
export function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    // Track page view on route change
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);
}
export const trackEvent = (eventName, eventParams = {}) => {
    ReactGA.event(eventName, eventParams);
  };