import React, { useState } from 'react';
import './RecruitDetailModal.css';

const RecruitDetailModal = ({ recruit, onClose }) => {
  const [showAll, setShowAll] = useState(false);

  const renderFieldsForStep = () => {
    if (!recruit) return null;

    switch (recruit.step) {
      case 'Overview':
        return (
          <>
            <p>Email: {recruit.email}</p>
            <p>Phone: {recruit.phone}</p>
            <p>Overview Time: {recruit.overview_time}</p>
            <p>Resident State: {recruit.resident_state}</p>
          </>
        );
      case 'Final':
        return (
          <>
            <p>Email: {recruit.email}</p>
            <p>Phone: {recruit.phone}</p>
            <p>Final Time: {recruit.final_time}</p>
            <p>Hire: {recruit.hire}</p>
            <p>Resident State: {recruit.resident_state}</p>
          </>
        );
      case 'Pre-Lic':
        return (
          <>
            <p>Email: {recruit.email}</p>
            <p>Phone: {recruit.phone}</p>
            <p>Course: {recruit.course}</p>
            <p>Enrolled: {recruit.enrolled}</p>
            <p>Resident State: {recruit.resident_state}</p>
          </>
        );
      case 'Test':
        return (
          <>
            <p>Email: {recruit.email}</p>
            <p>Phone: {recruit.phone}</p>
            <p>Test Date: {recruit.test_date}</p>
          </>
        );
      case 'BG Check':
        return (
          <>
            <p>Email: {recruit.email}</p>
            <p>Phone: {recruit.phone}</p>
            <p>BG Date: {recruit.bg_date}</p>
          </>
        );
      case 'Compliance':
      case 'AOB':
        return (
          <>
            <p>Email: {recruit.email}</p>
            <p>Phone: {recruit.phone}</p>
            <p>Resident License Number: {recruit.resident_license_number}</p>
            <p>NPN: {recruit.npn}</p>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        {recruit ? (
          <>
            <h2>{recruit.recruit_first} {recruit.recruit_last}</h2>
            {showAll ? (
              <>
                <p>Email: {recruit.email}</p>
                <p>Phone: {recruit.phone}</p>
                <p>Overview Time: {recruit.overview_time}</p>
                <p>Hire: {recruit.hire}</p>
                <p>Final Time: {recruit.final_time}</p>
                <p>Resident State: {recruit.resident_state}</p>
                <p>Enrolled: {recruit.enrolled}</p>
                <p>Course: {recruit.course}</p>
                <p>Expected Complete Date: {recruit.expected_complete_date}</p>
                <p>Current Progress: {recruit.current_progress}</p>
                <p>Last Log Prelic: {recruit.last_log_prelic}</p>
                <p>Prelic Passed: {recruit.prelic_passed}</p>
                <p>Prelic Cert: {recruit.prelic_cert}</p>
                <p>Test Date: {recruit.test_date}</p>
                <p>Test Passed: {recruit.test_passed}</p>
                <p>Test Cert: {recruit.test_cert}</p>
                <p>BG Date: {recruit.bg_date}</p>
                <p>Compliance1: {recruit.compliance1}</p>
                <p>Compliance2: {recruit.compliance2}</p>
                <p>Compliance3: {recruit.compliance3}</p>
                <p>Compliance4: {recruit.compliance4}</p>
                <p>Compliance5: {recruit.compliance5}</p>
                <p>AOB: {recruit.aob}</p>
                <p>Resident License Number: {recruit.resident_license_number}</p>
                <p>NPN: {recruit.npn}</p>
                <p>Agent Num: {recruit.agentnum}</p>
                <p>Impact Setup: {recruit.impact_setup}</p>
                <p>Training Start Date: {recruit.training_start_date}</p>
                <p>Coded: {recruit.coded}</p>
                <p>Code To: {recruit.code_to}</p>
                <p>Eapp Username: {recruit.eapp_username}</p>
                <p>Impact Username: {recruit.impact_username}</p>
              </>
            ) : (
              renderFieldsForStep()
            )}
            <button onClick={() => setShowAll(!showAll)}>
              {showAll ? 'Show Less' : 'Show All'}
            </button>
          </>
        ) : (
          <p>Loading...</p>
        )}
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default RecruitDetailModal;
