import React, { useState, useEffect } from 'react';

const TrainingView = () => {
  const [trainingData, setTrainingData] = useState([]);
  const [unclaimedData, setUnclaimedData] = useState([]);
  const [selectedUnclaimed, setSelectedUnclaimed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchHierarchyAndTrainingData();
  }, []);

  const fetchHierarchyAndTrainingData = async () => {
    try {
      const userId = localStorage.getItem('userId'); // Fetch userId from localStorage
      const userRole = localStorage.getItem('userRole'); // Fetch userRole from localStorage
      const agnName = localStorage.getItem('agnName'); // Fetch agnName from localStorage

      if (!userId) {
        throw new Error('User ID not found in localStorage');
      }

      // Fetch hierarchy data
      const hierarchyResponse = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/getHierarchyInfo', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId }), // Send userId in the request body
      });

      if (!hierarchyResponse.ok) {
        throw new Error('Failed to fetch hierarchy data');
      }

      const { success: hierarchySuccess, data: hierarchyData } = await hierarchyResponse.json();
      if (!hierarchySuccess) {
        throw new Error('Error retrieving hierarchy data');
      }

      // Extract emails from the hierarchy data
      const hierarchyEmails = hierarchyData.map(recruit => recruit.email);

      // Fetch training progress data
      const trainingResponse = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/getprogress', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!trainingResponse.ok) {
        throw new Error('Failed to fetch training data');
      }

      const { data: trainingData } = await trainingResponse.json();

      // Filter the training data based on whether the email exists in the hierarchy data OR matches the agnName for MGA
      const filteredTrainingData = trainingData.filter(recruit =>
        hierarchyEmails.includes(recruit.Email) || recruit.MGA === agnName
      );

      // Identify unclaimed data (those without MGA)
      const unclaimed = trainingData.filter(recruit => !recruit.MGA);

      setTrainingData(filteredTrainingData); // Set filtered data based on hierarchy and MGA
      setUnclaimedData(unclaimed); // Set unclaimed data (without MGA)
      setLoading(false); // Stop loading once data is fetched
    } catch (error) {
      setError(error.message);
      setLoading(false); // Stop loading even if there's an error
    }
  };

  const handleUnclaimedSelect = (email) => {
    setSelectedUnclaimed(prev =>
      prev.includes(email)
        ? prev.filter(item => item !== email)
        : [...prev, email]
    );
  };

  const handleClaim = async () => {
    const agnName = localStorage.getItem('agnName'); // Fetch agnName from localStorage

    try {
      const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/claimMGA', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emails: selectedUnclaimed, agnName }), // Send selected emails and agnName to the backend
      });

      if (!response.ok) {
        throw new Error('Failed to claim MGA');
      }

      // Re-fetch data to update the tables
      fetchHierarchyAndTrainingData();
    } catch (error) {
      console.error('Error claiming MGA:', error.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const userRole = localStorage.getItem('userRole'); // Fetch userRole to determine if "Unclaimed" table should be shown

  return (
    <div className="training-view">
      <h2>Filtered Training Progress</h2>
      <table>
        <thead>
          <tr>
            <th className='recruiting-table-header'>Name</th>
            <th className='recruiting-table-header'>Email</th>
            <th className='recruiting-table-header'>Product Progress (%)</th>
            <th className='recruiting-table-header'>Logins</th>
            <th className='recruiting-table-header'>Start Date</th>
            <th className='recruiting-table-header'>Last Activity</th>
            <th className='recruiting-table-header'>MGA</th>
          </tr>
        </thead>
        <tbody>
          {trainingData.length === 0 ? (
            <tr>
              <td colSpan="7">No matching training data available</td>
            </tr>
          ) : (
            trainingData.map((recruit, index) => (
              <tr key={index}>
                <td>{recruit.Name || 'N/A'}</td>
                <td>{recruit.Email || 'N/A'}</td>
                <td>{recruit.ProductProgress !== null ? `${recruit.ProductProgress}%` : 'N/A'}</td>
                <td>{recruit.Logins || 'N/A'}</td>
                <td>{recruit.StartDate || 'N/A'}</td>
                <td>{recruit.LastActivityAt || 'N/A'}</td>
                <td>{recruit.MGA || 'N/A'}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {/* Unclaimed table is shown only if userRole is MGA or RGA */}
      {(userRole === 'MGA' || userRole === 'RGA') && (
        <>
          <h2>Unclaimed Recruits</h2>
          <table>
            <thead>
              <tr>
                <th>Select</th>
                <th>Name</th>
                <th>Email</th>
                <th>Product Progress (%)</th>
              </tr>
            </thead>
            <tbody>
              {unclaimedData.length === 0 ? (
                <tr>
                  <td colSpan="4">No unclaimed recruits available</td>
                </tr>
              ) : (
                unclaimedData.map((recruit, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedUnclaimed.includes(recruit.Email)}
                        onChange={() => handleUnclaimedSelect(recruit.Email)}
                      />
                    </td>
                    <td>{recruit.Name || 'N/A'}</td>
                    <td>{recruit.Email || 'N/A'}</td>
                    <td>{recruit.ProductProgress !== null ? `${recruit.ProductProgress}%` : 'N/A'}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          {/* Claim button is shown when at least one recruit is selected */}
          {selectedUnclaimed.length > 0 && (
            <button onClick={handleClaim}>Claim Selected</button>
          )}
        </>
      )}
    </div>
  );
};

export default TrainingView;
