// useFetchSQLData.js
import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchMGARGA = () => {
    const [mgaRgaData, setMgaRgaData] = useState();  // Simplify initial state
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/all-mga-rga');
                console.log("Fetched MGA RGA data:", response.data);  // Ensure data is logged
                setMgaRgaData(response.data);  // Adjust according to actual response structure
            } catch (err) {
                console.error("Fetching MGA/RGA data error:", err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { mgaRgaData, loading, error };
};


export { useFetchMGARGA };
