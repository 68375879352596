import React, { useState, useEffect } from 'react';
import { Form, Button, Container } from 'react-bootstrap';

const ReportForm = () => {
  const [formData, setFormData] = useState({
    report: 'Weekly Recap',
    sga: 'ARIAS,SIMON',
    MGA_NAME: '',
    lagnName: '',
    ctlno: '',
    cl_name: 'AGT',
    lvl_1_gross: '',
    lvl_1_net: '',
    lvl_2_gross: '',
    lvl_2_net: '',
    lvl_2_f6_gross: '',
    lvl_2_f6_net: '',
    lvl_3_gross: '',
    lvl_3_net: '',
    lvl_3_f6_gross: '',
    lvl_3_f6_net: '',
    reportdate: '',
    month: '',
  });

  const [activeUsers, setActiveUsers] = useState([]);

  useEffect(() => {
    const fetchActiveUsers = async () => {
      try {
        const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/getActiveUsers');
        const data = await response.json();
        if (data.success && Array.isArray(data.data)) {
          setActiveUsers(data.data);
        } else {
          console.error('Error fetching active users:', data.message);
        }
      } catch (error) {
        console.error('Error fetching active users:', error);
      }
    };

    fetchActiveUsers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'report') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        reportdate: value === 'Weekly Recap' ? prevData.reportdate : '',
        month: value === 'MONTH RECAP' ? prevData.month : '',
      }));
    } else if (name === 'lagnName') {
      const selectedAgent = activeUsers.find(user => user.lagnname === value);
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        MGA_NAME: selectedAgent && (selectedAgent.clname === 'MGA' || selectedAgent.clname === 'RGA') ? '' : selectedAgent ? selectedAgent.mga : '',
        ctlno: selectedAgent ? selectedAgent.agtnum : '',
        cl_name: selectedAgent ? selectedAgent.clname : 'AGT',
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Update hidden fields to 0 before submission
    const updatedFormData = { ...formData };

    if (formData.cl_name === 'AGT') {
      updatedFormData.lvl_2_gross = '0';
      updatedFormData.lvl_2_net = '0';
      updatedFormData.lvl_2_f6_gross = '0';
      updatedFormData.lvl_2_f6_net = '0';
      updatedFormData.lvl_3_gross = '0';
      updatedFormData.lvl_3_net = '0';
      updatedFormData.lvl_3_f6_gross = '0';
      updatedFormData.lvl_3_f6_net = '0';
    } else if (formData.cl_name === 'SA') {
      updatedFormData.lvl_3_gross = '0';
      updatedFormData.lvl_3_net = '0';
      updatedFormData.lvl_3_f6_gross = '0';
      updatedFormData.lvl_3_f6_net = '0';
    } else if (formData.cl_name === 'GA') {
      updatedFormData.lvl_3_gross = '0';
      updatedFormData.lvl_3_net = '0';
      updatedFormData.lvl_3_f6_gross = '0';
      updatedFormData.lvl_3_f6_net = '0';
    }

    fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/submit-report', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedFormData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log('Report submitted successfully');
        } else {
          console.error('Error submitting report:', data.message);
        }
      })
      .catch(error => {
        console.error('Error submitting report:', error);
      });

    console.log('Form submitted with data:', updatedFormData);
  };
  return (
    <Container>
      <h1 className="mt-4">Report Form</h1>
      <Form onSubmit={handleSubmit}>
  <Form.Group controlId="formReport">
    <Form.Label>Report</Form.Label>
    <Form.Control
      as="select"
      name="report"
      value={formData.report}
      onChange={handleChange}
      required
    >
      <option value="Weekly Recap">Weekly Recap</option>
      <option value="MONTH RECAP">MONTH RECAP</option>
    </Form.Control>
  </Form.Group>

  {formData.report === 'Weekly Recap' && (
    <Form.Group controlId="formReportDate">
      <Form.Label>Report Date</Form.Label>
      <Form.Control
        type="text"
        name="reportdate"
        placeholder="Enter report date (mm/dd/yyyy)"
        value={formData.reportdate}
        onChange={handleChange}
        required
      />
    </Form.Group>
  )}

  {formData.report === 'MONTH RECAP' && (
    <Form.Group controlId="formMonth">
      <Form.Label>Month</Form.Label>
      <Form.Control
        type="text"
        name="month"
        placeholder="Enter month (mm/yyyy)"
        value={formData.month}
        onChange={handleChange}
        required
      />
    </Form.Group>
  )}

  <Form.Group controlId="formSGA">
    <Form.Label>SGA</Form.Label>
    <Form.Control
      type="text"
      name="sga"
      placeholder="Enter SGA"
      value={formData.sga}
      onChange={handleChange}
      required
    />
  </Form.Group>
  
  <Form.Group controlId="formMGANAME">
    <Form.Label>MGA Name</Form.Label>
    <Form.Control
      type="text"
      name="MGA_NAME"
      placeholder="Enter MGA Name"
      value={formData.MGA_NAME}
      onChange={handleChange}
      required
    />
  </Form.Group>

  <Form.Group controlId="formLagnName">
  <Form.Label>Agent</Form.Label>
  <Form.Control
    as="select"
    name="lagnName"
    value={formData.lagnName}
    onChange={handleChange}
    required
  >
    <option value="">Select Agent</option>
    {activeUsers.map(user => (
      <option key={user.lagnname} value={user.lagnname}>
        {user.lagnname}
      </option>
    ))}
  </Form.Control>
</Form.Group>


  <Form.Group controlId="formCTLNO">
    <Form.Label>CTLNO</Form.Label>
    <Form.Control
      type="text"
      name="ctlno"
      placeholder="Enter CTLNO"
      value={formData.ctlno}
      onChange={handleChange}
      required
    />
  </Form.Group>

  <Form.Group controlId="formCLName">
    <Form.Label>CL Name</Form.Label>
    <Form.Control
      as="select"
      name="cl_name"
      value={formData.cl_name}
      onChange={handleChange}
      required
    >
      <option value="AGT">AGT</option>
      <option value="SA">SA</option>
      <option value="GA">GA</option>
      <option value="MGA">MGA</option>
      <option value="RGA">RGA</option>
    </Form.Control>
  </Form.Group>

  <Form.Group controlId="formLVL1Gross">
    <Form.Label>LVL 1 Gross</Form.Label>
    <Form.Control
      type="text"
      name="lvl_1_gross"
      placeholder="Enter LVL 1 Gross"
      value={formData.lvl_1_gross}
      onChange={handleChange}
      required={true}
    />
  </Form.Group>

  <Form.Group controlId="formLVL1Net">
    <Form.Label>LVL 1 Net</Form.Label>
    <Form.Control
      type="text"
      name="lvl_1_net"
      placeholder="Enter LVL 1 Net"
      value={formData.lvl_1_net}
      onChange={handleChange}
      required={true}
    />
  </Form.Group>

  {formData.cl_name !== 'AGT' && (
    <>
      <Form.Group controlId="formLVL2Gross">
        <Form.Label>LVL 2 Gross</Form.Label>
        <Form.Control
          type="text"
          name="lvl_2_gross"
          placeholder="Enter LVL 2 Gross"
          value={formData.lvl_2_gross}
          onChange={handleChange}
          required={formData.cl_name !== 'AGT'}
        />
      </Form.Group>

      <Form.Group controlId="formLVL2Net">
        <Form.Label>LVL 2 Net</Form.Label>
        <Form.Control
          type="text"
          name="lvl_2_net"
          placeholder="Enter LVL 2 Net"
          value={formData.lvl_2_net}
          onChange={handleChange}
          required={formData.cl_name !== 'AGT'}
        />
      </Form.Group>

      {formData.cl_name === 'GA' || formData.cl_name === 'MGA' || formData.cl_name === 'RGA' ? (
        <>
          <Form.Group controlId="formLVL2F6Gross">
            <Form.Label>LVL 2 F6 Gross</Form.Label>
            <Form.Control
              type="text"
              name="lvl_2_f6_gross"
              placeholder="Enter LVL 2 F6 Gross"
              value={formData.lvl_2_f6_gross}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formLVL2F6Net">
            <Form.Label>LVL 2 F6 Net</Form.Label>
            <Form.Control
              type="text"
              name="lvl_2_f6_net"
              placeholder="Enter LVL 2 F6 Net"
              value={formData.lvl_2_f6_net}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formLVL3Gross">
            <Form.Label>LVL 3 Gross</Form.Label>
            <Form.Control
              type="text"
              name="lvl_3_gross"
              placeholder="Enter LVL 3 Gross"
              value={formData.lvl_3_gross}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formLVL3Net">
            <Form.Label>LVL 3 Net</Form.Label>
            <Form.Control
              type="text"
              name="lvl_3_net"
              placeholder="Enter LVL 3 Net"
              value={formData.lvl_3_net}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formLVL3F6Gross">
            <Form.Label>LVL 3 F6 Gross</Form.Label>
            <Form.Control
              type="text"
              name="lvl_3_f6_gross"
              placeholder="Enter LVL 3 F6 Gross"
              value={formData.lvl_3_f6_gross}
              onChange={handleChange}
              required
            />
          </Form.Group>

                <Form.Group controlId="formLVL3F6Net">
                  <Form.Label>LVL 3 F6 Net</Form.Label>
                  <Form.Control
                    type="text"
                    name="lvl_3_f6_net"
                    placeholder="Enter LVL 3 F6 Net"
                    value={formData.lvl_3_f6_net}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </>
            ) : null}
          </>
        )}



        <Button variant="primary" type="submit" className="mt-3">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default ReportForm;
