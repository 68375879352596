import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const AddResource = () => {
  const [sectionname, setSectionName] = useState('');
  const [customSection, setCustomSection] = useState(''); // For the custom section
  const [name, setName] = useState('');
  const [file, setFile] = useState(null);
  const [MGAList, setMGAList] = useState([]);
  const [selectedMGAs, setSelectedMGAs] = useState([]);

  // Default section name options
  const sectionOptions = [
    { value: 'training', label: 'Training' },
    { value: 'learning', label: 'Learning' },
    { value: 'meeting links', label: 'Meeting Links' },
    { value: 'rebuttals', label: 'Rebuttals' },
    { value: 'growth', label: 'Growth' },
    { value: 'managers', label: 'Managers' },
    { value: 'misc', label: 'Misc' },
    { value: 'mods & bulletins', label: 'Mods & Bulletins' },
    { value: 'paperwork', label: 'Paperwork' },
    { value: 'retention', label: 'Retention' },
    { value: 'underwriting', label: 'Underwriting' },
    { value: 'custom', label: 'Custom Category' } // Option for custom category
  ];

  // Get userRole and userId from localStorage
  const userRole = localStorage.getItem('userRole');
  const userId = localStorage.getItem('userId');

  // Fetch hierarchy information for RGA role
  useEffect(() => {
    if (userRole === 'RGA') {
      fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/getHierarchyInfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId })
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            const uniqueMGAs = [...new Set(data.data
              .map(item => item.mga)
              .filter(mga => mga && mga.trim() !== ''))]; // Filter out empty or blank MGAs
            const formattedMGAs = uniqueMGAs.map(mga => ({ value: mga, label: mga }));
            setMGAList(formattedMGAs);
          } else {
            console.error('Error fetching hierarchy data:', data.message);
          }
        })
        .catch(err => console.error('Error:', err));
    }
  }, [userId, userRole]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('sectionname', sectionname === 'custom' ? customSection : sectionname);
    formData.append('name', name);
    formData.append('MGAs', JSON.stringify(selectedMGAs.map(mga => mga.value))); // Send selected MGAs as JSON
    if (file) {
      formData.append('file', file);
    }

    fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/resources/add', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        // Clear inputs
        setSectionName('');
        setCustomSection('');
        setName('');
        setSelectedMGAs([]);
        setFile(null);
      })
      .catch(err => console.error('Error uploading resource:', err));
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Section Name Dropdown */}
      <Select
        options={sectionOptions}
        value={sectionOptions.find(option => option.value === sectionname)}
        onChange={selectedOption => setSectionName(selectedOption.value)}
        placeholder="Select a Section"
        isSearchable
      />

      {/* Show custom section name input only if 'Custom Category' is selected */}
      {sectionname === 'custom' && (
        <input
          type="text"
          value={customSection}
          placeholder="Enter Custom Section Name"
          onChange={(e) => setCustomSection(e.target.value)}
          required
        />
      )}

      <input
        type="text"
        value={name}
        placeholder="Resource Name"
        onChange={(e) => setName(e.target.value)}
        required
      />

      <input
        type="file"
        onChange={handleFileChange}
        required
      />

      {/* MGA Select Dropdown */}
      <Select
        isMulti
        options={MGAList}
        value={selectedMGAs}
        onChange={setSelectedMGAs}
        placeholder="Select MGAs"
        isSearchable
      />

      <button type="submit">Add/Update Resource</button>
    </form>
  );
};

export default AddResource;
