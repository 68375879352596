import React, { useState, useEffect, useRef } from 'react';
import './Leaderboards.css';
import { TailSpin } from 'react-loader-spinner';
import axios from 'axios';
import TrophyCaseModal from './TrophyCaseModal'; // Import the TrophyCaseModal component

const formatWeeklyDateRange = (dateString) => {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    const monday = new Date(date);
    monday.setDate(date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)); // Adjust to Monday

    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);

    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    const formattedMonday = monday.toLocaleDateString('en-US', options);
    const formattedSunday = sunday.toLocaleDateString('en-US', options);

    return `Week of ${formattedMonday} - ${formattedSunday}`;
};

const formatMTDDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
};

const formatYTDDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
};

const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

const reformatAgtNameWithMGA = (name, mga, clname) => {
    if (!name) return '';

    const suffixes = ['Jr', 'Sr', 'II', 'III', 'IV'];
    const parts = name.split(' ');
    if (parts.length < 2) return name;

    const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    let [last, first, middle, ...rest] = parts.length === 3 && parts[2].length === 1 ? parts : [parts[0], parts[1], ...parts.slice(2)];

    if (suffixes.includes(rest[rest.length - 1])) {
        rest.pop();
    }

    const abbreviatedLast = `${capitalize(last.charAt(0))}.`;
    const formattedName = rest.length > 0 
        ? `${capitalize(first)} ${rest.map(capitalize).join(' ')} ${abbreviatedLast}` 
        : `${capitalize(first)} ${abbreviatedLast}`;

    let displayMGA = mga ? mga.split(' ')[0].toUpperCase() : '';
    if (!mga && (clname === 'MGA' || clname === 'RGA')) {
        displayMGA = last.toUpperCase();
    }

    return (
        <>
            <div>{formattedName}</div>
            <div className="mga-value">{displayMGA}</div>
        </>
    );
};

const PowerReported = ({ view }) => {
    const [reportedData, setReportedData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [teamData, setTeamData] = useState([]);
    const [selectedPeriodTab, setSelectedPeriodTab] = useState('Weekly Recap');
    const [selectedDate, setSelectedDate] = useState('');
    const [uniqueReportedDates, setUniqueReportedDates] = useState([]);
    const [uniqueReportedMonths, setUniqueReportedMonths] = useState([]);
    const [uniqueReportedYears, setUniqueReportedYears] = useState([]);
    const agnName = localStorage.getItem('agnName'); // Get agent name from localStorage
    const myViewRowRef = useRef(null);
    const [isRowInView, setIsRowInView] = useState(true);
    const tableBodyRef = useRef(null);
    const [agentReportDates, setAgentReportDates] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [selectedAgentName, setSelectedAgentName] = useState(null);

    const handleRowClick = (agentName) => {
        setSelectedAgentName(agentName);
        setShowModal(true);
    };

    useEffect(() => {
        const fetchReportedData = async () => {
            try {
                const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/getAllDailyActivity');
                const data = await response.json();
                if (data.success && Array.isArray(data.data)) {
                    const reportedData = data.data.filter(item => item.alp > 0);
                    setReportedData(reportedData);
                    console.log('Reported Data:', reportedData);
                } else {
                    setError('Failed to load reported data');
                }
                setLoading(false);
            } catch (err) {
                setError('Failed to load reported data');
                setLoading(false);
            }
        };

        fetchReportedData();
    }, []);

    useEffect(() => {
        const fetchUniqueDates = () => {
            if (reportedData.length > 0) {
                const dates = reportedData.map(item => new Date(item.reportDate).toISOString().slice(0, 10));
                console.log('Dates extracted from reportedData:', dates); // Log extracted dates
                const groupedDates = groupDatesByWeek(dates);
                console.log('Grouped Dates:', groupedDates); // Log grouped dates by week
                const sortedWeeks = Object.keys(groupedDates).sort((a, b) => new Date(b) - new Date(a));
                console.log('Sorted Weeks:', sortedWeeks); // Log sorted weeks
                setUniqueReportedDates(sortedWeeks);
    
                if (selectedPeriodTab === 'MTD Recap') {
                    const months = [...new Set(dates.map(d => {
                        const date = new Date(d);
                        return `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(1, '0')}`;
                    }))].sort((a, b) => new Date(b) - new Date(a));
                    console.log('Unique Reported Months:', months); // Log unique reported months
                    setUniqueReportedMonths(months);
                    if (months.length > 0) {
                        setSelectedDate(months[0]);
                    }
                } else if (selectedPeriodTab === 'YTD Recap') {
                    const years = [...new Set(dates.map(d => new Date(d).getUTCFullYear().toString()))].sort((a, b) => parseInt(b) - parseInt(a));
                    console.log('Unique Reported Years:', years); // Log unique reported years
                    setUniqueReportedYears(years);
                    if (years.length > 0) {
                        setSelectedDate(years[0]);
                    }
                } else {
                    if (sortedWeeks.length > 0) {
                        setSelectedDate(sortedWeeks[0]);
                    }
                }
            }
        };
    
        fetchUniqueDates();
    }, [reportedData, selectedPeriodTab]);

    useEffect(() => {
        if (view === 'team') {
            fetchTeamData();
        }
    }, [view]);

    const fetchTeamData = async () => {
        try {
            const userId = localStorage.getItem('userId'); // Get userId from localStorage
            const response = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/rgaHierarchy', {
                params: { userId }
            });
            const data = response.data;
            if (data.success && Array.isArray(data.data)) {
                const teamNames = data.data.flatMap(item => {
                    const agtString = item.AGTs;
                    const names = [];
    
                    // Extract the first part
                    const [mgaDetails, ...agtParts] = agtString.split('; ');
                    const firstPart = mgaDetails.split(' - ')[0];
                    names.push(firstPart);
    
                    // Extract other parts
                    agtParts.flatMap(part => 
                        part.split('-- ').map(subPart => {
                            const name = subPart.split(' - ')[0];
                            names.push(name);
                        })
                    );
    
                    return names;
                });
                setTeamData(teamNames);
            } else {
                console.error('Failed to load team data');
            }
        } catch (err) {
            console.error('Error fetching team data:', err);
        }
    };
    

    useEffect(() => {
        if (reportedData.length > 0 && selectedDate) {
            const processed = filterAndProcessData(reportedData, selectedPeriodTab, selectedDate);
            setFilteredData(processed);
        }
    }, [reportedData, selectedPeriodTab, selectedDate]);

    const filterAndProcessData = (reportedData, reportType, date) => {
        let filteredByDate = [];
        if (reportType === 'Weekly Recap') {
            const selectedWeekDate = new Date(date);
            const dayOfWeek = selectedWeekDate.getDay();
            const startOfWeek = new Date(selectedWeekDate);
            startOfWeek.setDate(selectedWeekDate.getDate() - dayOfWeek); // Adjust to Monday
            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6); // Adjust to Sunday
    
            filteredByDate = reportedData.filter(item => {
                const itemDate = new Date(item.reportDate);
                const isInRange = itemDate >= startOfWeek && itemDate <= endOfWeek;
                if (isInRange) {
                    console.log(`Included reportDate for Weekly Recap: ${item.reportDate}`);
                }
                return isInRange;
            });
        } else if (reportType === 'MTD Recap') {
            const [year, month] = date.split('-').map(Number); // Split and parse the date to get the year and month
            const firstDayOfMonth = new Date(Date.UTC(year, month - 1, 1)); // Ensure UTC date creation
            const lastDayOfMonth = new Date(Date.UTC(year, month, 0, 23, 59, 59)); // Ensure UTC date creation
    
            filteredByDate = reportedData.filter(item => {
                const itemDate = new Date(item.reportDate);
                return itemDate >= firstDayOfMonth && itemDate <= lastDayOfMonth;
            });
        } else if (reportType === 'YTD Recap') {
            const selectedYear = parseInt(date, 10);
            filteredByDate = reportedData.filter(item => new Date(item.reportDate).getFullYear() === selectedYear);
        }
    
        const data = {};
        filteredByDate.forEach(agtItem => {
            const { agent, alp, MGA, clname } = agtItem;
            if (!data[agent]) {
                data[agent] = { agent, MGA, clname, alp: 0 };
            }
            data[agent].alp += parseFloat(agtItem.alp || 0);
        });
    
        return data;
    };

    const getRankedData = (data) => {
        const rankedData = Object.keys(data).map(agent => data[agent])
            .sort((a, b) => b.alp - a.alp)
            .map((item, index) => ({ ...item, rank: index + 1 }));

        if (view === 'team') {
            return rankedData.filter(item => teamData.includes(item.agent));
        }

        return rankedData;
    };

    useEffect(() => {
        const handleScroll = () => {
            if (myViewRowRef.current && tableBodyRef.current) {
                const tableBodyRect = tableBodyRef.current.getBoundingClientRect();
                const rowRect = myViewRowRef.current.getBoundingClientRect();
                const isVisible = rowRect.top >= tableBodyRect.top && rowRect.bottom <= tableBodyRect.bottom;
                setIsRowInView(isVisible);
            }
        };

        if (tableBodyRef.current) {
            tableBodyRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableBodyRef.current) {
                tableBodyRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const scrollToMyViewRow = () => {
        if (myViewRowRef.current && tableBodyRef.current) {
            const row = myViewRowRef.current;
            const tableBody = tableBodyRef.current;
            const offset = row.offsetTop - tableBody.offsetTop;

            console.log('Scrolling to row:', row);

            tableBody.scrollTo({
                top: offset,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        if (view === 'agency' && myViewRowRef.current) {
            scrollToMyViewRow();
        }
    }, [filteredData, view]);

    const groupDatesByWeek = (dates) => {
        const weeks = {};
        dates.forEach(dateString => {
            const date = new Date(dateString);
            const dayOfWeek = date.getDay();
            const monday = new Date(date);
            monday.setDate(date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)); // Adjust to Monday
            const weekKey = monday.toISOString().slice(0, 10); // Format as 'YYYY-MM-DD'
            if (!weeks[weekKey]) {
                weeks[weekKey] = [];
            }
            weeks[weekKey].push(dateString);
        });
        console.log('Weeks grouped:', weeks); // Log the weeks grouping
        return weeks;
    };

    const handleDateChange = (direction) => {
        let dateArray;
        if (selectedPeriodTab === 'MTD Recap') {
            dateArray = uniqueReportedMonths;
        } else if (selectedPeriodTab === 'YTD Recap') {
            dateArray = uniqueReportedYears;
        } else {
            dateArray = uniqueReportedDates;
        }

        const currentIndex = dateArray.indexOf(selectedDate);
        const newIndex = direction === 'prev' ? currentIndex + 1 : currentIndex - 1;
        if (newIndex >= 0 && newIndex < dateArray.length) {
            setSelectedDate(dateArray[newIndex]);
        }
    };

    const handleSelectDateChange = (e) => {
        const date = e.target.value;
        setSelectedDate(date);
    
        let formattedDate;
        if (selectedPeriodTab === 'Weekly Recap') {
            const selectedDate = new Date(date);
            const dayOfWeek = selectedDate.getDay();
            const monday = new Date(selectedDate);
            monday.setDate(selectedDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)); // Adjust to Monday
            setSelectedDate(monday.toISOString().slice(0, 10)); // Set the selected date to the Monday of the selected week
            formattedDate = formatWeeklyDateRange(monday.toISOString().slice(0, 10));
        } else if (selectedPeriodTab === 'MTD Recap') {
            formattedDate = formatMTDDate(date);
        } else if (selectedPeriodTab === 'YTD Recap') {
            formattedDate = date; // Year format
        }
    
        console.log(`Selected Date: ${date}`);
        console.log(`Selected Label: ${formattedDate}`);
    };

    if (loading) {
        return (
            <div className="leaderboard-card table-overlay">
                <div className="loading-overlay">
                    <TailSpin
                        type="ThreeDots"
                        color="black"
                        height={40}
                        width={40}
                    />
                </div>
                <table className="leaderboard-table">
                    <thead>
                        <tr>
                            <th>
                                Rank
                                {!isRowInView && (
                                    <button className="scroll-button" onClick={scrollToMyViewRow}>
                                        ↓
                                    </button>
                                )}
                            </th>
                            <th>AGT</th>
                            <th>ALP</th>
                        </tr>
                    </thead>
                    <tbody ref={tableBodyRef}>
                        <tr><td colSpan="3">&nbsp;</td></tr>
                        <tr><td colSpan="3">&nbsp;</td></tr>
                        <tr><td colSpan="3">&nbsp;</td></tr>
                    </tbody>
                </table>
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    const rankedReportedData = getRankedData(filteredData);

    const renderReportedTableRows = (rankedData) => {
        if (rankedData.length === 0) {
            return (
                <tr>
                    <td></td>
                    <td colSpan="1" className="no-data-row">Waiting for activity to be reported.</td>
                    <td></td>
                </tr>
            );
        }

        return rankedData.map((item, index) => {
            let rankDisplay;
            if (index === 0) {
                rankDisplay = '🥇';
            } else if (index === 1) {
                rankDisplay = '🥈';
            } else if (index === 2) {
                rankDisplay = '🥉';
            } else {
                rankDisplay = item.rank;
            }

            const mgaName = item.MGA ? item.MGA.split(' ')[0].toUpperCase() : item.agent.split(' ')[0].toUpperCase();

            const isMyViewRow = (view === 'myview' && item.agent === agnName) || (view === 'team' && item.agent === agnName) || (view === 'agency' && item.agent === agnName);
            const rowClass = isMyViewRow ? 'highlighted-row' : '';

            return (
                <tr key={index} ref={isMyViewRow ? myViewRowRef : null} className={rowClass} onClick={() => handleRowClick(item.agent)}>
                    <td className='rank-column-header'>{rankDisplay}</td>
                    <td className='rank-agent-name'>
                        {reformatAgtNameWithMGA(item.agent, item.MGA, item.clname)}
                    </td>
                    <td className='rank-alp'>{formatCurrency(item.alp)}</td>
                </tr>
            );
        });
    };

    const renderStickyRow = (rankedData) => {
        const stickyItem = rankedData.find(item => item.agent === agnName);

        if (!stickyItem) return null;

        let rankDisplay;
        if (stickyItem.rank === 1) {
            rankDisplay = '🥇';
        } else if (stickyItem.rank === 2) {
            rankDisplay = '🥈';
        } else if (stickyItem.rank === 3) {
            rankDisplay = '🥉';
        } else {
            rankDisplay = stickyItem.rank;
        }

        const mgaName = stickyItem.MGA ? stickyItem.MGA.split(' ')[0].toUpperCase() : stickyItem.agent.split(' ')[0].toUpperCase();

        return (
            <table className="sticky-row-table" onClick={() => handleRowClick(stickyItem.agent)}>
                <tbody>
                    <tr>
                        <td className='rank-column-header'>{rankDisplay}</td>
                        <td className='rank-agent-name'>
                            {reformatAgtNameWithMGA(stickyItem.agent, stickyItem.MGA, stickyItem.clname)}
                        </td>
                        <td className='rank-alp'>{formatCurrency(stickyItem.alp)}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    return (
        <>
            <div className="tabs">
                {['Weekly Recap', 'MTD Recap', 'YTD Recap'].map(tab => (
                    <React.Fragment key={tab}>
                        <input
                            type="radio"
                            id={tab}
                            name="periodTabToggle"
                            checked={selectedPeriodTab === tab}
                            onChange={() => setSelectedPeriodTab(tab)}
                        />
                        <label htmlFor={tab}>{tab}</label>
                    </React.Fragment>
                ))}
            </div>
            <div className="date-filter-container">
                <button className="date-nav-button" onClick={() => handleDateChange('prev')}>{'<'}</button>
                <select className="date-select" value={selectedDate} onChange={handleSelectDateChange}>
                    {(selectedPeriodTab === 'MTD Recap' ? uniqueReportedMonths : (selectedPeriodTab === 'YTD Recap' ? uniqueReportedYears : uniqueReportedDates)).map((date, index) => {
                        return (
                            <option key={index} value={date}>
                                {selectedPeriodTab === 'Weekly Recap' ? formatWeeklyDateRange(date) : (selectedPeriodTab === 'MTD Recap' ? formatMTDDate(date) : date)}
                            </option>
                        );
                    })}
                </select>
                <button className="date-nav-button" onClick={() => handleDateChange('next')}>{'>'}</button>
            </div>
            <div className={`leaderboard-card ${view === 'myview' ? 'myview-leaderboard-card' : ''}`}>
                <table className="leaderboard-table">
                    <thead>
                        <tr>
                            <th className='rank-column-header'>
                                {!isRowInView && (
                                    <button className="scroll-button" onClick={scrollToMyViewRow}>
                                        ↓
                                    </button>
                                )}
                            </th>
                            <th className='rank-agent-name'>AGT</th>
                            <th className='rank-alp'>ALP</th>
                        </tr>
                    </thead>
                    <tbody ref={tableBodyRef}>
                        {renderReportedTableRows(rankedReportedData)}
                    </tbody>
                </table>
                {view === 'myview' && renderStickyRow(rankedReportedData)}
            </div>
            {showModal && (
                <TrophyCaseModal 
                    agentName={selectedAgentName} 
                    onClose={() => setShowModal(false)} 
                />
            )}
        </>
    );
};

export default PowerReported;
