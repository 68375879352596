import React, { useState } from 'react';
import './Leaderboards.css';
import './CombinedRank.css';
import PowerOfficial from './PowerOfficial';
import PowerReported from './PowerReported';
import MyTrophyCase from '../Account/MyTrophyCase';

const CombinedRank = ({ view }) => {
    const [selectedViewTab, setSelectedViewTab] = useState('Official');

    const getHeaderText = () => {
        if (selectedViewTab === 'Official') {
            return 'Official Rankings';
        } else if (selectedViewTab === 'Reported') {
            return 'Reported Rankings';
        }
        return '';
    };

    return (
        <>
            <div className="combined-rank-card">
                <div className="header-tabs-container">
                    <h5 style={{ margin: 0 }}>{getHeaderText()}</h5>
                    <div className="tabs">
                        {['Official', 'Reported'].map(viewTab => (
                            <React.Fragment key={viewTab}>
                                <input
                                    type="radio"
                                    id={viewTab}
                                    name="viewTabToggle"
                                    checked={selectedViewTab === viewTab}
                                    onChange={() => setSelectedViewTab(viewTab)}
                                    style={{ marginLeft: 0 }}
                                />
                                <label htmlFor={viewTab}>{viewTab}</label>
                            </React.Fragment>
                        ))}
                    </div>
                </div>

                {selectedViewTab === 'Official' ? (
                    <PowerOfficial view={view} />
                ) : (
                    <PowerReported view={view} />
                )}
            </div>
            {view === 'myview' && (
                <MyTrophyCase />
            )}
        </>
    );
};

export default CombinedRank;
