// codesAndVips.js

import { useState, useEffect } from 'react';

const useFetchData = () => {
  const [data, setData] = useState({
    mgas: [],
    associatesData: [],
    vipData: [],
    detailsData: [],
    pendingData: [],
    weeklyProd: [], // Add this line to include your new combined data
    monthlyProd: [], // Add this line to include your new combined data
    companyMore: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Assuming you're using a similar approach to fetching data as shown earlier
        const apiKey = 'AIzaSyAUQ8D5K1kc2CBTEd0RM3WUOhcI5OJGydg'; // Place your actual API key here
        const sheetId = '1OIHKR6KyA5gLrNSTQVff6_g1DMzsjppQxTAgfJvh7Ks'; // Place your actual Sheet ID here
        const baseUrl = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values:batchGet`;
        const ranges = [
          'MGA/RGA',
          'ASSOCIATES $1 WITH HIERARCHY',
          'Potential VIP',
          'Details',
          'PENDING',
          'NewMORE',
          'Weekly Recap All', // Only the existing range
          'MTD Recap All', // Only the existing range
          'Company More',

        ];
        const url = `${baseUrl}?ranges=${ranges.join('&ranges=')}&key=${apiKey}`;

        const response = await fetch(url);
        const result = await response.json();

        // Combine data from the existing ranges
        const weeklyRecapAll = result.valueRanges[6].values.slice(1); // Assuming data starts from the second row
        const mtdRecapAll = result.valueRanges[7].values.slice(1); // Assuming data starts from the second row

        // Transform the result to fit your state structure
        const fetchedData = {
          mgas: result.valueRanges[0].values.slice(1), // Skip the first row
          associatesData: result.valueRanges[1].values,
          vipData: result.valueRanges[2].values,
          detailsData: result.valueRanges[3].values,
          pendingData: result.valueRanges[4].values,
          moreData: result.valueRanges[5].values,
          weeklyProd: weeklyRecapAll, // Use the existing data here
          monthlyProd: mtdRecapAll, // Use the existing data here
          companyMore: result.valueRanges[8].values, 
        };

        setData(fetchedData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs once on component mount

  return { data, loading, error };
};

export { useFetchData };