// useFetchSQLData.js
import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchSQLData = () => {
    const [data, setData] = useState(undefined);  // Set initial state to undefined
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/all-more-data');
                setData(response.data);  // Make sure you're setting the correct part of the response
                console.log("API Response:", response.data);  // Log the response data to debug
            } catch (err) {
                console.error("Fetching error:", err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);  // Dependency array is empty to ensure this runs only once

    return { data, loading, error };
};

export { useFetchSQLData };
