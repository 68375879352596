import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Container, Modal } from 'react-bootstrap';
import logo from '../../img/globe1.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showAriasModal, setShowAriasModal] = useState(false); // New state for Arias modal

    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        if (username.toLowerCase() === 'arias') {
            setShowAriasModal(true); // Show special modal for "arias" username
            return; // Prevent form submission and API call
        }

        setIsLoading(true); // Enable loading overlay

        try {
            const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/newlogin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });
            const data = await response.json(); // Convert response to JSON

            setIsLoading(false); // Disable loading overlay

            if (data.success && data.message === "Login successful") {
                console.log('Login successful:', data);
                localStorage.setItem('userToken', data.token);
                localStorage.setItem('userRole', data.clname);
                localStorage.setItem('userId', data.userId);
                localStorage.setItem('agnName', data.agnName);
                localStorage.setItem('userInfo', JSON.stringify(data.userInfo)); // Storing as a JSON string

                // Check if mgaRgaData is part of the response and store it if present
                if (data.mgaRgaData) {
                    localStorage.setItem('mgaRgaData', JSON.stringify(data.mgaRgaData)); // Storing as a JSON string
                }

                navigate('/');
            } else if (data.success && data.message === "Please complete account setup") {
                // Redirect user to setup page if 'redeemed' is 0 or 'false'
                navigate('/agent-setup', { state: { esid: data.esid, lagnname: data.lagnname } });
            } else {
                // Alert the user if login was not successful for other reasons
                alert('Login failed: ' + (data.message || 'Unknown error'));
            }
        } catch (error) {
            setIsLoading(false); // Disable loading overlay
            alert('Login failed: ' + error.message);
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value.toUpperCase());
    };

    return (
        <>
            {isLoading && (
                <div className="globe-loading-overlay">
                    <img src={logo} alt="Loading" className="globe-loading-animation" />
                </div>
            )}
            <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
                <Card className="w-100" style={{ maxWidth: "400px" }}>
                    <Card.Header className="text-center" style={{ backgroundColor: "white" }}>
                        <img src={logo} alt="Logo" width="100" height="100" />
                    </Card.Header>
                    <Card.Body>
                        <h2 className="text-center mb-4">Agent Login</h2>
                        <form onSubmit={handleLogin}>
                            <div className="form-group">
                                <label>Username</label>
                                <input type="text" className="form-control" required value={username} onChange={(e) => setUsername(e.target.value)} />
                            </div>
                            <div className="form-group mt-3">
                                <label>Password</label>
                                <input type="password" className="form-control" required value={password} onChange={handlePasswordChange} style={{ textTransform: 'uppercase' }} />
                            </div>
                            <Button className="w-100 mt-4" style={{ backgroundColor: "#00558c" }} type="submit">Log In</Button>
                        </form>
                        <div className="text-center mt-3">
                            <a href="#" onClick={() => setShowInfoModal(true)}>I don't know my login/don't have one</a>
                            <a href="https://player.vimeo.com/video/981507363" className="d-block mt-2">Login Tutorial</a>
                        </div>
                    </Card.Body>
                </Card>
            </Container>

            <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Login Help</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <p>Your account may be accessed the day after receiving your agent number.</p>
                    <p>Your username is the first letter of your first name + your full government last name.</p>
                    <p>Example: Bernard Rapoport's username would be</p>
                    <input type="text" id="fileInput" value={"BRAPOPORT"} disabled style={{ display: '' }} />
                    <p>Your default password is your most current agent number.</p>
                </Modal.Body>
            </Modal>

            <Modal show={showAriasModal} onHide={() => setShowAriasModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Important Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <h1>The Agent Portal has undergone an update</h1>
                    <h3>The default usernames <strong>arias, mga, and big4</strong> will no longer work.</h3>
                    <h5>Please login using your personal agent account. Information on how to access this account is below.</h5>
                    <p>Your account may be accessed the day after receiving your agent number.</p>
                    <p>Your username is the first letter of your first name + your full government last name.</p>
                    <p>Example: Bernard Rapoport's username would be</p>
                    <input type="text" id="fileInput" value={"BRAPOPORT"} disabled style={{ display: '' }} />
                    <p>Your default password is your most current agent number.</p>     
                    <p>If you have any questions, please contact your MGA, or email us as account@ariaslife.com</p>
                               </Modal.Body>

            </Modal>
        </>
    );
};

export default Login;
