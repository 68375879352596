import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';
import './EmailForm.css';

const EmailForm = () => {
  const [allEmails, setAllEmails] = useState([]);
  const [activeEmails, setActiveEmails] = useState([]);
  const [inactiveEmails, setInactiveEmails] = useState([]);
  const [emails, setEmails] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [subject, setSubject] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [emailType, setEmailType] = useState('active'); // new state to manage email type

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const activeResponse = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/getActiveEmails');
        const allResponse = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/getAllEmails');
        
        const activeEmails = activeResponse.data.sort((a, b) => a.localeCompare(b));
        const allEmails = allResponse.data.sort((a, b) => a.localeCompare(b));
        const inactiveEmails = allEmails.filter(email => !activeEmails.includes(email));

        setActiveEmails(activeEmails.map(email => ({ value: email, label: email })));
        setAllEmails(allEmails.map(email => ({ value: email, label: email })));
        setInactiveEmails(inactiveEmails.map(email => ({ value: email, label: email })));

        // Initially set emails to activeEmails
        setEmails(activeEmails.map(email => ({ value: email, label: email })));
      } catch (error) {
        console.error('Failed to fetch emails:', error);
      }
    };

    fetchEmails();
  }, []); // fetch emails once on component mount

  useEffect(() => {
    switch (emailType) {
      case 'active':
        setEmails(activeEmails);
        break;
      case 'all':
        setEmails(allEmails);
        break;
      case 'inactive':
        setEmails(inactiveEmails);
        break;
      default:
        setEmails([]);
        break;
    }
  }, [emailType, activeEmails, allEmails, inactiveEmails]); // update emails list when emailType changes or data changes

  const handleSendEmail = async (e) => {
    e.preventDefault();
    try {
      const emailsToSend = selectedEmails.find(option => option.value === 'ALL')
        ? emails.map(option => option.value)
        : selectedEmails.map(option => option.value);
      await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/sendEmail', {
        subject,
        htmlContent,
        emails: emailsToSend,
      });
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Failed to send email:', error);
      alert('Failed to send email.');
    }
  };

  const handleChange = (selectedOptions) => {
    setSelectedEmails(selectedOptions);
  };

  return (
    <div className="email-form">
      <h2>Send Custom Email</h2>
      <form onSubmit={handleSendEmail}>
        <div>
          <label>Subject:</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <div style={{height: '400px'}}>
          <label>HTML Content:</label>
          <CKEditor
            editor={ClassicEditor}
            data={htmlContent}
            onChange={(event, editor) => {
              const data = editor.getData();
              setHtmlContent(data);
            }}
          />
        </div>
        <div>
          <label>Email Type:</label>
          <select
            value={emailType}
            onChange={(e) => setEmailType(e.target.value)}
          >
            <option value="active">Active Emails</option>
            <option value="all">All Emails</option>
            <option value="inactive">Inactive Emails</option>
          </select>
        </div>
        <div>
          <label>Select Recipients:</label>
          <Select
            isMulti
            options={[{ value: 'ALL', label: 'Send To All' }, ...emails]}
            value={selectedEmails}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Send Email</button>
      </form>
    </div>
  );
};

export default EmailForm;
