import React, { useState, useEffect, useRef } from 'react';
import './Leaderboards.css';
import { TailSpin } from 'react-loader-spinner';
import axios from 'axios';
import TrophyCaseModal
 from './TrophyCaseModal';
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
 import { faInfoCircle, faMedal, faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
 import { Modal, Button } from 'react-bootstrap';

const formatWeeklyDateRange = (dateString) => {
    const date = new Date(dateString);
    const priorSunday = new Date(date);
    priorSunday.setDate(date.getDate() - date.getDay());
    const priorMonday = new Date(priorSunday);
    priorMonday.setDate(priorSunday.getDate() - 6);

    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    const formattedMonday = priorMonday.toLocaleDateString('en-US', options);
    const formattedSunday = priorSunday.toLocaleDateString('en-US', options);

    return `Week of ${formattedMonday} - ${formattedSunday}`;
};


const formatMTDDate = (dateString) => {
    const date = new Date(dateString);
    const priorSunday = new Date(date);

    priorSunday.setDate(date.getDate() - date.getDay());

    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    const formattedSunday = priorSunday.toLocaleDateString('en-US', options);

    return `MTD as of ${formattedSunday}`;
};

const formatYTDDate = (dateString) => {
    const date = new Date(dateString);
    const priorSunday = new Date(date);

    priorSunday.setDate(date.getDate() - date.getDay());

    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    const formattedSunday = priorSunday.toLocaleDateString('en-US', options);

    return `YTD as of ${formattedSunday}`;
};

const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

const reformatAgtNameWithMGA = (name, mga, clname) => {
    if (!name) return '';

    const suffixes = ['Jr', 'Sr', 'II', 'III', 'IV'];
    const parts = name.split(' ');
    if (parts.length < 2) return name;

    const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    let [last, first, middle, ...rest] = parts.length === 3 && parts[2].length === 1 ? parts : [parts[0], parts[1], ...parts.slice(2)];

    if (suffixes.includes(rest[rest.length - 1])) {
        rest.pop();
    }

    const abbreviatedLast = `${capitalize(last.charAt(0))}.`;
    const formattedName = rest.length > 0 
        ? `${capitalize(first)} ${rest.map(capitalize).join(' ')} ${abbreviatedLast}` 
        : `${capitalize(first)} ${abbreviatedLast}`;

    let displayMGA = mga ? mga.split(' ')[0].toUpperCase() : '';
    if (!mga && (clname === 'MGA' || clname === 'RGA')) {
        displayMGA = last.toUpperCase();
    }

    return (
        <>
            <div>{formattedName}</div>
            <div className="mga-value">{displayMGA}</div>
        </>
    );
};

const PowerOfficial = ({ view }) => {
    const [officialData, setOfficialData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [teamData, setTeamData] = useState([]);
    const [selectedPeriodTab, setSelectedPeriodTab] = useState('Weekly Recap');
    const [selectedDate, setSelectedDate] = useState('');
    const [uniqueOfficialDates, setUniqueOfficialDates] = useState([]);
    const agnName = localStorage.getItem('agnName'); // Get agent name from localStorage
    const myViewRowRef = useRef(null);
    const [isRowInView, setIsRowInView] = useState(true);
    const tableBodyRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedAgentName, setSelectedAgentName] = useState(null);
    const [originalAgentName, setOriginalAgentName] = useState(null);
    const [showInfoModal, setShowInfoModal] = useState(false);

    const handleRowClick = (agentName) => {
        setSelectedAgentName(agentName); // Use the original agent name directly
        setShowModal(true);
    };
    
    
    const transformAgentName = (name) => {
        console.log('Original name:', name);
        const nameParts = name.trim().split(' ');
        if (nameParts.length < 2) return name.toUpperCase(); // If the name has fewer than 2 parts, return as is
    
        const firstName = nameParts.shift();
        const lastName = nameParts.pop();
        const transformedName = `${lastName} ${firstName} ${nameParts.join(' ')}`.toUpperCase().trim();
        console.log('Transformed name:', transformedName);
        return transformedName;
    };
    
    useEffect(() => {
        const fetchOfficialData = async () => {
            try {
                const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/getOfficialActivity');
                const data = await response.json();
                if (data.success && Array.isArray(data.data)) {
                    setOfficialData(data.data);
                } else {
                    setError('Failed to load official data');
                }
                setLoading(false);
            } catch (err) {
                setError('Failed to load official data');
                setLoading(false);
            }
        };

        fetchOfficialData();
    }, []);

    useEffect(() => {
        const fetchUniqueDates = () => {
            if (officialData.length > 0) {
                const dates = officialData
                    .filter(item => item.REPORT === selectedPeriodTab)
                    .map(item => item.reportdate);
                const uniqueDates = [...new Set(dates)].sort((a, b) => new Date(b) - new Date(a));
                setUniqueOfficialDates(uniqueDates);
                if (uniqueDates.length > 0) {
                    setSelectedDate(uniqueDates[0]);
                }
            }
        };
        fetchUniqueDates();
    }, [officialData, selectedPeriodTab]);

    useEffect(() => {
        if (view === 'team') {
            fetchTeamData();
        }
    }, [view]);

    const fetchTeamData = async () => {
        try {
            const userId = localStorage.getItem('userId'); // Get userId from localStorage
            const response = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/rgaHierarchy', {
                params: { userId }
            });
            const data = response.data;
            if (data.success && Array.isArray(data.data)) {
                const teamNames = data.data.flatMap(item => {
                    const agtString = item.AGTs;
                    const names = [];
    
                    // Extract the first part
                    const [mgaDetails, ...agtParts] = agtString.split('; ');
                    const firstPart = mgaDetails.split(' - ')[0];
                    names.push(firstPart);
    
                    // Extract other parts
                    agtParts.flatMap(part => 
                        part.split('-- ').map(subPart => {
                            const name = subPart.split(' - ')[0];
                            names.push(name);
                        })
                    );
    
                    return names;
                });
                setTeamData(teamNames);
            } else {
                console.error('Failed to load team data');
            }
        } catch (err) {
            console.error('Error fetching team data:', err);
        }
    };
    

    useEffect(() => {
        if (officialData.length > 0 && selectedDate) {
            const processed = filterAndProcessData(officialData, selectedPeriodTab, selectedDate);
            setFilteredData(processed);
        }
    }, [officialData, selectedPeriodTab, selectedDate]);

    const filterAndProcessData = (officialData, reportType, date) => {
        const filteredByReport = officialData.filter(item => item.REPORT === reportType);

        const filteredByDate = filteredByReport.filter(item => {
            if (reportType === 'Weekly Recap') {
                return item.reportdate === date; // Direct match for the report date
            } else if (reportType === 'MTD Recap') {
                return formatMTDDate(item.reportdate) === formatMTDDate(date);
            } else if (reportType === 'YTD Recap') {
                return formatYTDDate(item.reportdate) === formatYTDDate(date);
            }
            return false;
        });

        const data = {};
        filteredByDate.forEach(agtItem => {
            const { LagnName, LVL_1_NET, MGA, clname, MGA_NAME } = agtItem;
            if (!data[LagnName]) {
                data[LagnName] = agtItem;
            } else if (agtItem.clname === 'MGA') {
                data[LagnName] = agtItem;
            }
        });

        // Convert LVL_1_NET to a float for all entries
        Object.keys(data).forEach(key => {
            data[key].LVL_1_NET = parseFloat(data[key].LVL_1_NET || 0);
        });

        return data;
    };

    const getRankedData = (data) => {
        const rankedData = Object.keys(data).map(agtName => {
            const { LVL_1_NET, MGA, clname, MGA_NAME } = data[agtName];
            return { AGT: agtName, LVL_1_NET, MGA, clname, MGA_NAME };
        }).sort((a, b) => b.LVL_1_NET - a.LVL_1_NET)
            .map((item, index) => ({ ...item, rank: index + 1 }));

        if (view === 'team') {
            return rankedData.filter(item => teamData.includes(item.AGT));
        }

        return rankedData;
    };

    useEffect(() => {
        const handleScroll = () => {
            if (myViewRowRef.current && tableBodyRef.current) {
                const tableBodyRect = tableBodyRef.current.getBoundingClientRect();
                const rowRect = myViewRowRef.current.getBoundingClientRect();
                const isVisible = rowRect.top >= tableBodyRect.top && rowRect.bottom <= tableBodyRect.bottom;
                setIsRowInView(isVisible);
            }
        };

        if (tableBodyRef.current) {
            tableBodyRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableBodyRef.current) {
                tableBodyRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const scrollToMyViewRow = () => {
        if (myViewRowRef.current && tableBodyRef.current) {
            const row = myViewRowRef.current;
            const tableBody = tableBodyRef.current;
            const offset = row.offsetTop - tableBody.offsetTop;

            tableBody.scrollTo({
                top: offset,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        if (view === 'agency' && myViewRowRef.current) {
            scrollToMyViewRow();
        }
    }, [filteredData, view]);

    const handleDateChange = (direction) => {
        const currentIndex = uniqueOfficialDates.indexOf(selectedDate);
        const newIndex = direction === 'prev' ? currentIndex + 1 : currentIndex - 1;
        if (newIndex >= 0 && newIndex < uniqueOfficialDates.length) {
            setSelectedDate(uniqueOfficialDates[newIndex]);
        }
    };

    const handleSelectDateChange = (e) => {
        const date = e.target.value;
        setSelectedDate(date);
    };

    if (loading) {
        return (
            <div className="leaderboard-card table-overlay">
                <div className="loading-overlay">
                    <TailSpin
                        type="ThreeDots"
                        color="black"
                        height={40}
                        width={40}
                    />
                </div>
                <table className="leaderboard-table">
                <thead>
    <tr>
        <th className='rank-column-header'>
            {!isRowInView && (
                <button className="scroll-button" onClick={scrollToMyViewRow}>
                    ↓
                </button>
            )}
        </th>
        <th className='rank-agent-name'>AGT</th>
        <th className='rank-alp'>
            NET ALP
            <FontAwesomeIcon
                icon={faInfoCircle}
                className="info-icon"
                onClick={() => setShowInfoModal(true)}
                style={{ marginLeft: '8px', cursor: 'pointer' }}
            />
        </th>
    </tr>
</thead>

                    <tbody ref={tableBodyRef}>
                        <tr><td colSpan="3">&nbsp;</td></tr>
                        <tr><td colSpan="3">&nbsp;</td></tr>
                        <tr><td colSpan="3">&nbsp;</td></tr>
                    </tbody>
                </table>
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    const rankedOfficialData = getRankedData(filteredData);

    const renderOfficialTableRows = (rankedData) => {
        if (rankedData.length === 0) {
            return (
                <tr>
                    <td></td>
                    <td colSpan="1" className="no-data-row">Waiting for activity to be reported.</td>
                    <td></td>
                </tr>
            );
        }
    
        return rankedData.map((item, index) => {
            let rankDisplay;
            if (index === 0) {
                rankDisplay = '🥇';
            } else if (index === 1) {
                rankDisplay = '🥈';
            } else if (index === 2) {
                rankDisplay = '🥉';
            } else {
                rankDisplay = item.rank;
            }
    
            const mgaName = item.MGA_NAME ? item.MGA_NAME.split(' ')[0].toUpperCase() : item.AGT.split(' ')[0].toUpperCase();
    
            const isMyViewRow = (view === 'myview' && item.AGT === agnName) || (view === 'team' && item.AGT === agnName) || (view === 'agency' && item.AGT === agnName);
            const rowClass = isMyViewRow ? 'highlighted-row' : '';
    
            return (
                <tr key={index} ref={isMyViewRow ? myViewRowRef : null} className={rowClass} onClick={() => handleRowClick(item.AGT)}>
                    <td className='rank-column-header'>{rankDisplay}</td>
                    <td className='rank-agent-name'>
                        {reformatAgtNameWithMGA(item.AGT, item.MGA, item.clname)}
                        <div className="mga-value">{mgaName}</div>
                    </td>
                    <td className='rank-alp'>{formatCurrency(item.LVL_1_NET)}</td>
                </tr>
            );
        });
    };
    

    const renderStickyRow = (rankedData) => {
        const stickyItem = rankedData.find(item => item.AGT === agnName);
    
        if (!stickyItem) return null;
    
        let rankDisplay;
        if (stickyItem.rank === 1) {
            rankDisplay = '🥇';
        } else if (stickyItem.rank === 2) {
            rankDisplay = '🥈';
        } else if (stickyItem.rank === 3) {
            rankDisplay = '🥉';
        } else {
            rankDisplay = stickyItem.rank;
        }
    
        const mgaName = stickyItem.MGA_NAME ? stickyItem.MGA_NAME.split(' ')[0].toUpperCase() : stickyItem.AGT.split(' ')[0].toUpperCase();
    
        return (
            <table className="sticky-row-table" onClick={() => handleRowClick(stickyItem.AGT)}>
                <tbody>
                    <tr>
                        <td className='rank-column-header'>{rankDisplay}</td>
                        <td className='rank-agent-name'>
                            {reformatAgtNameWithMGA(stickyItem.AGT, stickyItem.MGA, stickyItem.clname)}
                            <div className="mga-value">{mgaName}</div>
                        </td>
                        <td className='rank-alp'>{formatCurrency(stickyItem.LVL_1_NET)}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    return (
        <>
            <div className="tabs">
                {['Weekly Recap', 'MTD Recap', 'YTD Recap'].map(tab => (
                    <React.Fragment key={tab}>
                        <input
                            type="radio"
                            id={tab}
                            name="periodTabToggle"
                            checked={selectedPeriodTab === tab}
                            onChange={() => setSelectedPeriodTab(tab)}
                        />
                        <label htmlFor={tab}>{tab}</label>
                    </React.Fragment>
                ))}
            </div>
            <div className="date-filter-container">
                <button className="date-nav-button" onClick={() => handleDateChange('prev')}>{'<'}</button>
                <select className="date-select" value={selectedDate} onChange={handleSelectDateChange}>
                    {uniqueOfficialDates.map((date, index) => (
                        <option key={index} value={date}>
                            {selectedPeriodTab === 'Weekly Recap' ? formatWeeklyDateRange(date) : (selectedPeriodTab === 'MTD Recap' ? formatMTDDate(date) : formatYTDDate(date))}
                        </option>
                    ))}
                </select>
                <button className="date-nav-button" onClick={() => handleDateChange('next')}>{'>'}</button>
            </div>
            <div className={`leaderboard-card ${view === 'myview' ? 'myview-leaderboard-card' : ''}`}>
                <table className="leaderboard-table">
                <thead>
    <tr>
        <th className='rank-column-header'>
            {!isRowInView && (
                <button className="scroll-button" onClick={scrollToMyViewRow}>
                    ↓
                </button>
            )}
        </th>
        <th className='rank-agent-name'>AGT</th>
        <th className='rank-alp'>
            NET ALP
            <FontAwesomeIcon
                icon={faInfoCircle}
                className="info-icon"
                onClick={() => setShowInfoModal(true)}
                style={{ marginLeft: '8px', cursor: 'pointer' }}
            />
        </th>
    </tr>
</thead>

                    <tbody ref={tableBodyRef}>
                        {renderOfficialTableRows(rankedOfficialData)}
                    </tbody>
                </table>
                {view === 'myview' && renderStickyRow(rankedOfficialData)}
            </div>
            {showInfoModal && (
    <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Official Leaderboards</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>The Net ALP in this ranking is based off of reports from Home Office, which generally come out Thursdays. This list will be updated shortly after these reports come out.</p>
        </Modal.Body>

    </Modal>
)}

            {showModal && (
    <TrophyCaseModal 
        agentName={selectedAgentName} 
        originalAgentName={originalAgentName} // Pass the original agent name
        onClose={() => setShowModal(false)} 
    />
    
)}

        </>
    );
    
};

export default PowerOfficial;
