import React, { useState, useEffect } from 'react';
import { useFetchSQLData } from '../../hooks/moreBackend';
import { useFetchMGARGA } from '../../hooks/mgaRgaBackend';
import MoreDataTable from './MoreDataTable';
import MoreStats from './MoreStats';
import MoreLeaderboard from './MoreLeaderboard';
import { FaAngleRight } from 'react-icons/fa';
import MoreCards from './MoreCards';
import Select from 'react-select';

const FilterableMore = () => {
    useEffect(() => {
        document.title = 'M.O.R.E.';
    }, []);
    const { data: responseData, loading: dataLoading, error: dataError } = useFetchSQLData();
    const { mgaRgaData, loading: mgaRgaLoading, error: mgaRgaError } = useFetchMGARGA();
    const [data, setData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedMGA, setSelectedMGA] = useState(null);
    const [selectedRGA, setSelectedRGA] = useState(null);
    const [selectedTree, setSelectedTree] = useState(null);
    const [isMoreTableOpen, setIsMoreTableOpen] = useState(false);
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedMGAs, setSelectedMGAs] = useState([]);
    const [selectedRGAs, setSelectedRGAs] = useState([]);
    const [selectedTrees, setSelectedTrees] = useState([]);
    const toggleMoreTable = () => {
        setIsMoreTableOpen(!isMoreTableOpen);
    };
    useEffect(() => {
        if (responseData && responseData.data) {
            setData(responseData.data);
            // Set the most recent date on initial load
            const mostRecentDate = responseData.data.reduce((max, item) =>
                new Date(item.MORE_Date) > new Date(max.MORE_Date) ? item : max, responseData.data[0]);
            setSelectedDates([{ value: mostRecentDate.MORE_Date, label: mostRecentDate.MORE_Date }]);
        }
    }, [responseData]);
    

    const filteredData = data.filter(item => {
        const dateMatch = !selectedDates.length || selectedDates.some(selected => item.MORE_Date === selected.value);
        const mgaMatch = !selectedMGAs.length || selectedMGAs.some(selected => item.MGA === selected.value);
        const rgaMatch = !selectedRGAs.length || selectedRGAs.some(selected => item.RGA === selected.value || item.MGA === selected.value);
        const treeMatch = !selectedTrees.length || selectedTrees.some(selected => item.Tree === selected.value);
        return dateMatch && mgaMatch && rgaMatch && treeMatch;
    });
    
    
    const getDateRange = (preset) => {
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    
        switch (preset) {
            case 'YTD':
                return { start: new Date(today.getFullYear(), 0, 1), end: today };
            case 'This Month':
                return { start: startOfMonth, end: endOfMonth };
            case 'Last Month':
                const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                return { start: lastMonth, end: endOfLastMonth };
            default:
                return { start: null, end: null };
        }
    };
    
    const handleDatePresetChange = (option) => {
        if (!option) {
            setSelectedDates([]);
            return;
        }
    
        const { start, end } = getDateRange(option.value);
        const uniqueDates = new Set();
    
        // Populate uniqueDates with dates from the data that fall within the range
        data.forEach(item => {
            const itemDate = new Date(item.MORE_Date);
            if (itemDate >= start && itemDate <= end) {
                uniqueDates.add(item.MORE_Date);
            }
        });
    
        const dateOptions = Array.from(uniqueDates).map(date => ({
            value: date,
            label: new Date(date).toLocaleDateString()
        }));
    
        setSelectedDates(dateOptions);
    };
    
    
    const dateOptions = data.map(item => ({ value: item.MORE_Date, label: item.MORE_Date }))
        .filter((option, index, self) => index === self.findIndex((t) => t.value === option.value));

    const mgaOptions = data.map(item => ({ value: item.MGA, label: item.MGA }))
        .filter((option, index, self) => index === self.findIndex((t) => t.value === option.value));

    const rgaOptions = data.map(item => ({ value: item.RGA, label: item.RGA }))
        .filter((option, index, self) => index === self.findIndex((t) => t.value === option.value));

    const treeOptions = data.map(item => ({ value: item.Tree, label: item.Tree }))
        .filter((option, index, self) => index === self.findIndex((t) => t.value === option.value));

        const createOptions = (key) => {
            return [...new Set(data.map(item => item[key]))].map(value => ({ value, label: value }));
        };

    if (dataLoading || mgaRgaLoading) return <div>Loading data...</div>;
    if (dataError) return <div>Error fetching MORE data: {dataError.message}</div>;
    if (mgaRgaError) return <div>Error fetching MGA/RGA data: {mgaRgaError.message}</div>;

    return (
        <div className='container'>
            <div className="header-controls" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                <a href="https://ariaslife.com/MORE" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <button style={{ padding: '10px', fontSize: '16px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px' }}>
                        Report MORE Numbers
                    </button>
                </a>
            </div>
<div style={{ marginBottom: '20px', marginTop: '20px' }}>
<div style={{ marginBottom: '10px' }}>
    <Select
        options={[
            { value: 'YTD', label: 'Year to Date' },
            { value: 'This Month', label: 'This Month' },
            { value: 'Last Month', label: 'Last Month' }
        ]}
        onChange={handleDatePresetChange}
        placeholder="Select Date Range Preset"
        isClearable
    />
</div>


    <div style={{ marginBottom: '10px' }}>
        <Select
            options={createOptions('MORE_Date')}
            value={selectedDates}
            onChange={setSelectedDates}
            placeholder="Filter by Date"
            isClearable
            isMulti
        />
    </div>
    <div style={{ marginBottom: '10px' }}>
        <Select
            options={createOptions('MGA')}
            value={selectedMGAs}
            onChange={setSelectedMGAs}
            placeholder="Filter by MGA"
            isClearable
            isMulti
        />
    </div>
    <div style={{ marginBottom: '10px' }}>
        <Select
            options={createOptions('RGA')}
            value={selectedRGAs}
            onChange={setSelectedRGAs}
            placeholder="Filter by RGA"
            isClearable
            isMulti
        />
    </div>
    <div style={{ marginBottom: '10px' }}> {/* No need for margin on the last element if not necessary */}
        <Select
            options={createOptions('Tree')}
            value={selectedTrees}
            onChange={setSelectedTrees}
            placeholder="Filter by Tree"
            isClearable
            isMulti
        />
    </div>
</div>

            <div className="activity-header">
                <h4>Activity 📞<span className="toggle-more-view" onClick={toggleMoreTable}>
                    <FaAngleRight className={`toggle-icon ${isMoreTableOpen ? 'open' : ''}`} /></span>
                </h4>
            </div>
            <MoreCards moreData={filteredData} />
            <div className={`classic-view-container ${isMoreTableOpen ? 'open' : ''}`}>

                {isMoreTableOpen &&
                    <MoreDataTable moreData={filteredData} />}
            </div>
            <MoreStats moreData={filteredData} selectedDates={selectedDates} selectedMGAs={selectedMGAs} />
            <MoreLeaderboard moreData={filteredData} />

        </div>
    );
};

export default FilterableMore;
