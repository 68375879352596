import React, { useEffect, useState } from 'react';
import '../../ActivityCards.css';
import { useFetchData } from '../../hooks/codesAndVips';
import MoreStatsTable from './MoreStatsTable';
import { FaAngleRight } from 'react-icons/fa';
const MoreStats = ({ moreData, selectedDates, selectedMGAs }) => {
    const { data: fetchData, loading, error } = useFetchData(); // Fetch additional data using the hook
    const [mgaStatistics, setMgaStatistics] = useState({});
    const [processedData, setProcessedData] = useState({
        totalShowPercent: 0,
        finalShowPercent: 0,
        finalOverTotalPercent: 0,
        setToHireRatio: 0,
        avgHiresPerWeekPerMGA: 0,
        // Initialize state for companyMore fields
        companyMore: {
            overviewShow: 'N/A',
            groupInvitePercent: 'N/A',
            finalSetOverGroup: 'N/A',
            finalShow: 'N/A',
            finalInterviewPercent: 'N/A',
            hireToCode: 'N/A',
        },
    });
    const [hireToCodeRatio, setHireToCodeRatio] = useState(0); // New state for hire to code ratio
    const [isMoreTableOpen, setIsMoreTableOpen] = useState(false);
    const toggleMoreTable = () => {
        setIsMoreTableOpen(!isMoreTableOpen);
    };
    const [showDetails, setShowDetails] = useState(false);

    const handleToggleDetails = () => {
        setShowDetails(!showDetails);
    };


    const processData = (data) => {
        if (!data || data.length <= 1) return;

        const uniqueDates = new Set();
        const uniqueMGAs = new Set();
        const sums = data.reduce((acc, item) => {
            const date = item[0]; // Assuming date is in the first column
            const mga = item[1]; // Assuming MGA is in the second column
            uniqueDates.add(date);
            uniqueMGAs.add(mga);

            const overviewsSet = parseFloat(item.Total_Set || 0);
            const overviewsShow = parseFloat(item.Total_Show || 0);
            const finalsSet = parseFloat(item.Finals_Set || 0);
            const finalsShow = parseFloat(item.Finals_Show || 0);
            const prHires = parseFloat(item.PR_Hires || 0);
            const totalHires = parseFloat(item.Total_Hires || 0);

            acc.overviewsSet += overviewsSet;
            acc.overviewsShow += overviewsShow;
            acc.finalsSet += finalsSet;
            acc.finalsShow += finalsShow;
            acc.totalHires += totalHires;
            acc.prHires += prHires;
            return acc;
        }, {
            overviewsSet: 0,
            overviewsShow: 0,
            finalsSet: 0,
            finalsShow: 0,
            totalHires: 0,
            prHires: 0,
        });

        const weeks = uniqueDates.size; // Total number of unique weeks
        const MGAs = uniqueMGAs.size; // Total number of unique MGAs

        const result = {
            totalShowPercent: (sums.overviewsSet > 0) ? (sums.overviewsShow / sums.overviewsSet) * 100 : 0,
            finalShowPercent: (sums.finalsShow > 0) ? (sums.totalHires / sums.finalsShow) * 100 : 0,
            finalOverTotalPercent: (sums.overviewsShow > 0) ? (sums.finalsSet / sums.overviewsShow) * 100 : 0,
            setToHireRatio: (sums.overviewsSet > 0) ? (sums.totalHires / sums.overviewsSet) : 0,
            avgHiresPerWeekPerMGA: (weeks > 0 && MGAs > 0) ? (sums.totalHires / weeks / MGAs) : 0,
            percentPRHires: (sums.totalHires > 0) ? (sums.prHires / sums.totalHires) * 100 : 0,
        };

        setProcessedData(result);
    };

    useEffect(() => {
        // Process moreData as before
        processData(moreData);

        // Process companyMore data from the fetched data
        if (fetchData.companyMore && fetchData.companyMore.length > 1) {
            const companyDataRow = fetchData.companyMore[1]; // Get the second row for companyMore data
            setProcessedData(prevData => ({
                ...prevData,
                companyMore: {
                    overviewShow: companyDataRow[0], 
                    groupInvitePercent: companyDataRow[1],
                    finalSetOverGroup: companyDataRow[2],
                    finalShow: companyDataRow[3],
                    finalInterviewPercent: companyDataRow[4],
                    hireToCode: companyDataRow[5],
                }
            }));
        }
    }, [moreData, fetchData.companyMore]);

        useEffect(() => {
            if (!fetchData.associatesData || fetchData.associatesData.length === 0) {
                console.log("Waiting for data: Associates data is not available.");
                return;
            }
        
            let totalCodes;
            const uniqueColumnCValues = new Set();
        
            if (!selectedMGAs.length && (!selectedDates || !selectedDates.start || !selectedDates.end)) {
                // When no MGAs or dates are selected, consider all unique entries in Column C as total codes
                fetchData.associatesData.forEach(associate => {
                    uniqueColumnCValues.add(associate[3]); // Column C contains unique identifiers
                });
                totalCodes = uniqueColumnCValues.size;
                console.log(`Total unique codes (based on Column C without MGA/date selection): ${totalCodes}`);
            } else {
                // Handle filtering by MGAs and/or dates
                const formattedSelectedDates = selectedDates ? selectedDates.map(dateObj => {
                    if (typeof dateObj.value === 'string') {
                        const parts = dateObj.value.split('/');
                        return `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
                    } else {

                        console.error('Expected dateObj.value to be a string, received:', typeof dateObj.value, dateObj);
                        return null;
                        
                    }
                    
                }).filter(date => date !== null) : [];
                
                const startDate = formattedSelectedDates.length > 0 ? new Date(formattedSelectedDates[0]) : null;
                const endDate = formattedSelectedDates.length > 0 ? new Date(formattedSelectedDates[formattedSelectedDates.length - 1]) : null;
                if (endDate) endDate.setHours(23, 59, 59, 999);
        
                const columnEValues = selectedMGAs.length > 0 ? fetchData.mgas
                    .filter(row => selectedMGAs.some(selectedMGA => selectedMGA.value === row[0]))
                    .map(row => row[4]) : [];
        
                const uniqueColumnDValues = new Set();
                fetchData.associatesData.forEach(associate => {
                    const associateDate = associate[5] ? new Date(associate[5].split(' ')[0]) : null;
                    const dateMatch = startDate && endDate ? associateDate >= startDate && associateDate <= endDate : true;
                    const columnEMatch = columnEValues.length > 0 ? columnEValues.includes(associate[2]) : true;
                    const columnDUnique = !uniqueColumnDValues.has(associate[3]);
        
                    if (dateMatch && columnEMatch && columnDUnique) {
                        uniqueColumnDValues.add(associate[3]); // Column D contains unique identifiers
                    }
                });
                totalCodes = uniqueColumnDValues.size;
                console.log(`Filtered unique codes: ${totalCodes}`);
            }
        
            // Assuming totalHires is calculated elsewhere and available. Replace this with actual calculation/logic as necessary.
            const totalHires = processedData.totalHires || moreData.reduce((acc, item) => acc + parseFloat(item[22] || 0), 0);
            const hireToCodeRatio = totalHires > 0 ? totalHires / totalCodes : 0;
            setHireToCodeRatio(hireToCodeRatio);
            console.log(`Hire to Code Ratio: ${hireToCodeRatio}`);
        }, [fetchData.associatesData, fetchData.mgas, selectedMGAs, selectedDates, processedData.totalHires, moreData]);
        
        useEffect(() => {
            const updatedStats = {};
            moreData.forEach(item => {
                const mga = item.MGA;
                if (!updatedStats[mga]) {
                    updatedStats[mga] = {
                        totalShowPercent: 0,
                        finalShowPercent: 0,
                        finalOverTotalPercent: 0,
                        percentPRHires: 0,
                        setToHireRatio: 0,
                        avgHiresPerWeekPerMGA: 0,
                        dataCount: 0
                    };
                }
                
                const totalShows = parseFloat(item.Total_Show) || 0;
                const totalSets = parseFloat(item.Total_Set) || 0;
                const finalsShow = parseFloat(item.Finals_Show) || 0;
                const totalHires = parseFloat(item.Total_Hires) || 0;
                const prHires = parseFloat(item.PR_Hires) || 0;
    
                updatedStats[mga].totalShowPercent += totalShows / totalSets * 100;
                updatedStats[mga].finalShowPercent += finalsShow / totalSets * 100;
                updatedStats[mga].finalOverTotalPercent += totalHires / finalsShow * 100;
                updatedStats[mga].percentPRHires += prHires / totalHires * 100;
                updatedStats[mga].setToHireRatio += totalHires / totalSets;
                updatedStats[mga].avgHiresPerWeekPerMGA += totalHires; // Simplified for example
                updatedStats[mga].dataCount++;
            });
    
            // Averaging the percentages
            Object.keys(updatedStats).forEach(mga => {
                updatedStats[mga].totalShowPercent /= updatedStats[mga].dataCount;
                updatedStats[mga].finalShowPercent /= updatedStats[mga].dataCount;
                updatedStats[mga].finalOverTotalPercent /= updatedStats[mga].dataCount;
                updatedStats[mga].percentPRHires /= updatedStats[mga].dataCount;
                updatedStats[mga].setToHireRatio /= updatedStats[mga].dataCount;
                updatedStats[mga].avgHiresPerWeekPerMGA /= updatedStats[mga].dataCount;
            });
    
            setMgaStatistics(updatedStats);
        }, [moreData]);
    
    const sortedMgaStatistics = Object.entries(mgaStatistics)
    .sort(([mgaA], [mgaB]) => mgaA.localeCompare(mgaB)) // Sort alphabetically by MGA name
    .reduce((obj, [mga, stats]) => ({ ...obj, [mga]: stats }), {});

    return (
        <div>
                        <div className="activity-header">
    <h4>Statistics 📈<span className="toggle-more-view" onClick={toggleMoreTable}>
        <FaAngleRight className={`toggle-icon ${isMoreTableOpen ? 'open' : ''}`} /></span>
    </h4>
</div>
            <div className="cards-row">
                <div className="card">
                    <h5 className="card-title">Overview Show</h5>
                    <p style={{marginBottom: '10px'}} className="big-card-text">{processedData.totalShowPercent.toFixed(2)}%</p>
                    <p className="insight-card-text">Co Avg: 47.3%</p>

                    <p className="lower-card-text">Recruiting Quality</p>
                </div>
                <div className="card">
                    <h5 className="card-title">Overview Show/Final Set</h5>
                    <p style={{marginBottom: '10px'}} className="big-card-text">{processedData.finalOverTotalPercent.toFixed(2)}%</p>
                    <p className="lower-card-text">Overview Quality</p>

                </div>
                <div className="card">
                    <h5 className="card-title">Final Show/Total Hires</h5>
                    <p style={{marginBottom: '10px'}} className="big-card-text">{processedData.finalShowPercent.toFixed(2)}%</p>
                    <p className="insight-card-text">Co Avg: 61.7%</p>
                    <p className="lower-card-text">Final Quality</p>

                </div>

                <div className="card">
    <h5 className="card-title">PR Hires</h5>
    <p style={{marginBottom: '10px'}} className="big-card-text">{(Number(processedData.percentPRHires) || 0).toFixed(2)}%</p>
    <p className="lower-card-text"> Personals to Total Hires </p>
</div>

                <div className="card">
                    <h5 className="card-title">Hires per Week</h5>
                    <p style={{marginBottom: '10px'}} className="big-card-text">{processedData.avgHiresPerWeekPerMGA.toFixed(2)}</p>
                    <p className="lower-card-text">Hiring Consistency</p>

                </div>
                <div className="card">
                    <h5 className="card-title">Hire to Code</h5>
                    <p style={{marginBottom: '10px'}} className="big-card-text">Coming Soon</p>
                    <p className="insight-card-text">Co Avg: 4.0</p> {/* Placeholder, adjust as needed */}
                    <p className="lower-card-text">Pipeline Management</p>
                </div>
            </div>
                        {/* Stats Table */}
                        <div className={`classic-view-container ${isMoreTableOpen ? 'open' : ''}`}>

{isMoreTableOpen &&
<MoreStatsTable mgaData={sortedMgaStatistics} hireToCodeRatio={hireToCodeRatio.toFixed(2)}/>}
</div>

        </div>
    );
};

export default MoreStats;
