import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import Recruit from './Recruit';
import { FaInfoCircle } from 'react-icons/fa';

const ItemType = 'RECRUIT';

const KanbanView = ({ recruits, steps, moveRecruitToStep, onClick, columnInfo }) => {
  return (
    <div className="kanban-view">
      {steps.map((step, stepIndex) => (
        <div key={stepIndex} className="kanban-column-wrapper">
          <h3 className="kanban-column-title">{step}</h3>
          <KanbanColumn 
            step={step} 
            recruits={recruits.filter(recruit => recruit.step === step)} 
            moveRecruitToStep={moveRecruitToStep} 
            onClick={onClick} 
            info={columnInfo[stepIndex]}  // Pass the specific info for each column
          />
        </div>
      ))}
    </div>
  );
};

const KanbanColumn = ({ step, recruits, moveRecruitToStep, onClick, info }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemType,
    drop: (item) => {
      if (item && item.id) {
        moveRecruitToStep(item.id, step); // Pass the new step here
      } else {
        console.error('Recruit not found:', item.id);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [showInfo, setShowInfo] = useState(false);

  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div 
      ref={drop} 
      className={`kanban-column ${isOver && canDrop ? 'kanban-column-hover' : ''}`}
    >
      <div className="kanban-column-header">
        <FaInfoCircle className="info-icon" onClick={toggleInfo} />
      </div>
      {showInfo && (
        <div className="kanban-info-overlay">
          <div className="kanban-info-content">
            <h2>{step} Information</h2>
            <p>{info}</p>
            <button onClick={toggleInfo}>Close</button>
          </div>
        </div>
      )}
      {recruits.map((recruit, index) => (
        <Recruit 
          key={index} 
          recruit={recruit} 
          index={index} 
          advanceStep={moveRecruitToStep} 
          onClick={onClick} 
        />
      ))}
    </div>
  );
};

export default KanbanView;
