import React, { useEffect, useState } from 'react';
import { Modal, Button, Card, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TrophyCaseModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faMedal, faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { TailSpin } from 'react-loader-spinner';
import bronzeMedalImage from '../../img/bronzecoin.png';
import silverMedalImage from '../../img/silvercoin.png';
import goldMedalImage from '../../img/goldcoin.png';
import platinumMedalImage from '../../img/platinumcoin.png';
import diamondMedalImage from '../../img/diamondcoin.png';
import StatCard from '../utils/StatCard';
const TrophyCaseModal = ({ agentName, onClose }) => {
    const [trophyCaseData, setTrophyCaseData] = useState([]);
    const [allTrophyData, setAllTrophyData] = useState([]);
    const [recordWeekData, setRecordWeekData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [expandedClubs, setExpandedClubs] = useState({
        Bronze: true,
        Silver: true,
        Gold: true,
        Platinum: true,
        Diamond: true
    });
    const [profileData, setProfileData] = useState({
        profilePicture: '',
        bio: '',
        clname: '',
        esid: '',
        mga: '',
    });
    const [reportingStreak, setReportingStreak] = useState(0);
    const [allTimeDaysReported, setAllTimeDaysReported] = useState(0);
    
    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/profile', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ agnName: agentName }),
                });
                const result = await response.json();
                if (result.success) {
                    setProfileData({
                        profilePicture: result.data.profpic || silverMedalImage,
                        bio: result.data.bio || '',
                        clname: result.data.clname || '',
                        esid: result.data.esid || '',
                        mga: result.data.mga || '',
                    });
                } else {
                    setProfileData({
                        profilePicture: silverMedalImage,
                        bio: '',
                        clname: '',
                        esid: '',
                        mga: '',
                    });
                }
            } catch (error) {
                console.error('Error fetching profile data:', error);
                setProfileData({
                    profilePicture: silverMedalImage,
                    bio: '',
                    clname: '',
                    esid: '',
                    mga: '',
                });
            }
        };
    
        const fetchTrophyCaseData = async () => {
            try {
                const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/trophy-case', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ agnName: agentName }),
                });
                const result = await response.json();
                if (result.success && result.data.length > 0) {
                    setTrophyCaseData(result.data);
                }
            } catch (error) {
                console.error('Error fetching trophy case data:', error);
            }
        };
    
        const fetchAllTrophyData = async () => {
            try {
                const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/all-trophy-case');
                const result = await response.json();
                if (result.success) {
                    setAllTrophyData(result.data);
                }
            } catch (error) {
                console.error('Error fetching all trophy case data:', error);
            }
        };
    
        const fetchRecordWeekData = async () => {
            try {
                const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/weekly-trophy-case', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ agnName: agentName }),
                });
                const result = await response.json();
                if (result.success) {
                    const highestWeek = result.data.reduce((max, row) => {
                        return parseFloat(String(row.LVL_1_NET).replace(/[\$,()]/g, '').trim()) > parseFloat(String(max.LVL_1_NET).replace(/[\$,()]/g, '').trim()) ? row : max;
                    }, result.data[0]);
                    setRecordWeekData(highestWeek);
                }
            } catch (error) {
                console.error('Error fetching record week data:', error);
            }
        };

        const fetchDailyActivityData = async () => {
            try {
                const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/getAllDailyActivity');
                const result = await response.json();
                if (result.success) {
                    const activityData = result.data.filter(activity => activity.agent === agentName).map(activity => new Date(activity.reportDate).toISOString().slice(0, 10));
                    calculateReportingStreak(activityData);
                    calculateAllTimeDaysReported(activityData);
                }
            } catch (error) {
                console.error('Error fetching daily activity data:', error);
            }
        };

        fetchProfileData();
        fetchTrophyCaseData();
        fetchAllTrophyData();
        fetchRecordWeekData();
        fetchDailyActivityData();
        setLoading(false);
    }, [agentName]);

    const calculateReportingStreak = (activityDates) => {
        const sortedDates = activityDates.sort((a, b) => new Date(a) - new Date(b));
        let streak = 0;
        let currentStreak = 0;
        const today = new Date().toISOString().slice(0, 10);

        for (let i = sortedDates.length - 1; i >= 0; i--) {
            const date = sortedDates[i];
            const previousDate = sortedDates[i - 1] ? new Date(sortedDates[i - 1]) : null;
            const currentDate = new Date(date);

            if (currentDate.toISOString().slice(0, 10) > today) {
                continue;
            }

            if (previousDate) {
                previousDate.setDate(previousDate.getDate() + 1);
                if (currentDate.toISOString().slice(0, 10) === previousDate.toISOString().slice(0, 10)) {
                    currentStreak++;
                } else if (currentDate.toISOString().slice(0, 10) === today) {
                    streak = Math.max(streak, currentStreak + 1);
                    currentStreak = 0;
                } else {
                    streak = Math.max(streak, currentStreak);
                    currentStreak = 0;
                }
            } else if (currentDate.toISOString().slice(0, 10) === today) {
                streak = Math.max(streak, currentStreak + 1);
            } else {
                streak = Math.max(streak, currentStreak);
            }
        }

        setReportingStreak(streak);
    };

    const calculateAllTimeDaysReported = (activityDates) => {
        const today = new Date().toISOString().slice(0, 10);
        const pastDates = activityDates.filter(date => date <= today);
        const uniqueDates = new Set(pastDates);
        setAllTimeDaysReported(uniqueDates.size);
    };

    const revertAgentName = (name) => {
        const nameParts = name.split(' ');
        if (nameParts.length < 2) return name;

        const firstName = nameParts[1];
        const lastName = nameParts[0];
        return `${firstName} ${lastName}`.trim();
    };

    const originalAgentName = revertAgentName(agentName);

    const getLastName = (fullName) => {
        if (!fullName) return '';
        const nameParts = fullName.split(' ');
        return nameParts[0]; 
    };
    
    const formatCurrency = (value) => {
        const numericValue = parseFloat(value.replace(/[\$,()]/g, '').trim());
        return numericValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const formatDateRange = (reportdate) => {
        const date = new Date(reportdate);
        const dayOfWeek = date.getDay();
        const lastSunday = new Date(date);
        lastSunday.setDate(date.getDate() - dayOfWeek);
        const previousMonday = new Date(lastSunday);
        previousMonday.setDate(lastSunday.getDate() - 6);

        const options = { month: 'numeric', day: 'numeric', year: '2-digit' };
        return `${previousMonday.toLocaleDateString('en-US', options)} - ${lastSunday.toLocaleDateString('en-US', options)}`;
    };

    const getClubName = (value) => {
        const numericValue = parseFloat(value.replace(/[\$,()]/g, '').trim());
        if (numericValue >= 100000) return 'Diamond';
        if (numericValue >= 50000) return 'Platinum';
        if (numericValue >= 25000) return 'Gold';
        if (numericValue >= 20000) return 'Silver';
        if (numericValue >= 15000) return 'Bronze';
        return 'Record Month';
    };

    const categorizeData = (data) => {
        const categories = {
            'Record Month': [],
            'Bronze': [],
            'Silver': [],
            'Gold': [],
            'Platinum': [],
            'Diamond': []
        };

        const highestRecordMonth = data.reduce((max, row) => {
            const netValue = parseFloat(row.LVL_1_NET.replace(/[\$,()]/g, '').trim());
            return netValue > max.LVL_1_NET ? { ...row, LVL_1_NET: netValue } : max;
        }, { LVL_1_NET: 0 });

        categories['Record Month'].push(highestRecordMonth);

        data.forEach(row => {
            const clubName = getClubName(row.LVL_1_NET);
            if (clubName !== 'Record Month') {
                categories[clubName].push({ ...row, LVL_1_NET: parseFloat(row.LVL_1_NET.replace(/[\$,()]/g, '').trim()) });
            }
        });

        return categories;
    };

    const sortEntriesByDate = (entries) => {
        return entries.sort((a, b) => {
            const [monthA, yearA] = a.month.split('/').map(Number);
            const [monthB, yearB] = b.month.split('/').map(Number);
            return new Date(yearB, monthB - 1) - new Date(yearA, monthA - 1);
        });
    };

    const categorizedData = categorizeData(trophyCaseData);
    Object.keys(categorizedData).forEach(key => {
        categorizedData[key] = sortEntriesByDate(categorizedData[key]);
    });

    const calculateRankings = (data) => {
        const rankings = {};

        const groupedByMonth = data.reduce((acc, row) => {
            const { month } = row;
            if (!acc[month]) acc[month] = [];
            acc[month].push({ ...row, LVL_1_NET: parseFloat(row.LVL_1_NET.replace(/[\$,()]/g, '').trim()) });
            return acc;
        }, {});

        for (const [month, rows] of Object.entries(groupedByMonth)) {
            rows.sort((a, b) => b.LVL_1_NET - a.LVL_1_NET);
            rows.forEach((row, index) => {
                rankings[row.LagnName + month] = index + 1;
            });
        }

        return rankings;
    };

    const rankings = calculateRankings(allTrophyData);

    const clubOrder = ['Diamond', 'Platinum', 'Gold', 'Silver', 'Bronze'];

    const toggleClubExpansion = (club) => {
        setExpandedClubs((prevState) => ({
            ...prevState,
            [club]: !prevState[club]
        }));
    };

    const calculateStatus = (esid) => {
        const currentDate = new Date();
        const esidDate = new Date(esid);
        const diffTime = Math.abs(currentDate - esidDate);
        const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
    
        if (diffMonths < 12) {
            return `${diffMonths} month${diffMonths > 1 ? 's' : ''} Rookie`;
        } else {
            const diffYears = Math.floor(diffMonths / 12);
            return `${diffYears} year${diffYears > 1 ? 's' : ''} Veteran`;
        }
    };
    
    return (
        <Modal 
            show={true} 
            onHide={onClose} 
            dialogClassName="modal-dialog" 
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >   
            <Modal.Body className="modal-body">
                {loading ? (
                    <div className="spinner-container">
                        <TailSpin height="80" width="80" color="#00BFFF" ariaLabel="loading" />
                    </div>
                ) : (
                    <>
                        <div className="text-center">
                            <Image src={profileData.profilePicture} roundedCircle className="profile-picture" />
                            <h4><strong>{originalAgentName} {profileData.clname && !['MGA', 'RGA'].includes(profileData.clname) ? `- ${profileData.clname}` : ''}</strong></h4>
                            <p>{getLastName(profileData.mga)}</p>
                            <h5>{calculateStatus(profileData.esid)}</h5>
                            <div className="stat-cards-container">
                    <StatCard title="Reporting Streak" value={`${reportingStreak} day${reportingStreak !== 1 ? 's' : ''}`} />
                    <StatCard title="All-Time Days Reported" value={`${allTimeDaysReported} day${allTimeDaysReported !== 1 ? 's' : ''}`} />
                </div>
                                        </div>
                        <h3 className="text-center" style={{marginTop: '35px'}}>Trophy Case</h3>
                        {trophyCaseData.length > 0 ? (
                            <>
                                <div className="trophy-card-container">
                                    {categorizedData['Record Month'].map((row, index) => (
                                        <Card key={`record-${index}`} className="record-trophy trophy-card">
                                            <Card.Body className="trophy-card-body">
                                                <Card.Title className="trophy-card-title">Record Month</Card.Title>
                                                <div className="trophy-lvl1net">{formatCurrency(row.LVL_1_NET.toString())}</div>
                                                <div>{row.month}</div>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                    {recordWeekData && (
                                        <Card className="record-week-trophy trophy-card">
                                            <Card.Body className="trophy-card-body">
                                                <Card.Title className="trophy-card-title">Record Week</Card.Title>
                                                <div className="trophy-lvl1net">{formatCurrency(recordWeekData.LVL_1_NET.toString())}</div>
                                                <div>{formatDateRange(recordWeekData.reportdate)}</div>
                                            </Card.Body>
                                        </Card>
                                    )}
                                </div>

                                {clubOrder.map((club) => (
                                    categorizedData[club].length > 0 && (
                                        <div key={club} className="club-section">
                                            <div className="club-title-container" onClick={() => toggleClubExpansion(club)} style={{ cursor: 'pointer' }}>
                                                <h5 className="club-title">
                                                    {club} Club
                                                </h5>
                                                <div className="club-icons">
                                                    {categorizedData[club].map((_, idx) => (
                                                        <FontAwesomeIcon key={idx} icon={faMedal} className="club-icon" />
                                                    ))}
                                                </div>
                                                <div style={{ marginLeft: '5px' }}>
                                                    <FontAwesomeIcon
                                                        icon={expandedClubs[club] ? faChevronDown : faChevronRight}
                                                        className="chevron-icon"
                                                    />
                                                </div>
                                            </div>
                                            {expandedClubs[club] && (
                                                <div className="trophy-card-container">
                                                    {categorizedData[club].map((row, idx) => (
                                                        <Card key={`${club}-${idx}`} className={`${club.toLowerCase()}-trophy trophy-card`}>
                                                            <Card.Body className="trophy-card-body">
                                                                <div className="trophy-lvl1net">{formatCurrency(row.LVL_1_NET.toString())}</div>
                                                                <div>{row.month}</div>
                                                                {rankings[row.LagnName + row.month] <= 3 && (
                                                                    <div className="ranked-message">Ranked #{rankings[row.LagnName + row.month]}</div>
                                                                )}
                                                            </Card.Body>
                                                        </Card>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    )
                                ))}
                            </>
                        ) : (
                            <p className="text-center">No data available for {originalAgentName}.</p>
                        )}
                    </>
                )}
            </Modal.Body>
            <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Trophy Case Information</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-content">
                    <p>Your Trophy Case is an easy way to keep track of your production records achieved in your career. Being a part of one of these clubs is a display of your commitment to your own success and a reminder of the effort it took to attain this accolade. Below are the <strong>Net ALP</strong> levels needed in a month needed to reach each club level:</p>
                    <ul>
                        <li>
                            <img src={bronzeMedalImage} alt="Bronze Medal" />
                            Bronze Club: $15,000 - $19,999
                        </li>
                        <li>
                            <img src={silverMedalImage} alt="Silver Medal" />
                            Silver Club: $20,000 - $24,999
                        </li>
                        <li>
                            <img src={goldMedalImage} alt="Gold Medal" />
                            Gold Club: $25,000 - $49,999
                        </li>
                        <li>
                            <img src={platinumMedalImage} alt="Platinum Medal" />
                            Platinum Club: $50,000 - $99,999
                        </li>
                        <li>
                            <img src={diamondMedalImage} alt="Diamond Medal" />
                            Diamond Club: $100,000 and above
                        </li>
                    </ul>
                    <p>If you have a record week or month that is not currently showing, please have your MGA send proof to kvanbibber@ariasagencies.com using either a P&P or week certificate from the Home Office.</p>
                </Modal.Body>
            </Modal>
        </Modal>
    );
};

export default TrophyCaseModal;
