import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HierarchyComponent.css';
import Placeholder from '../utils/Placeholder';

const LoggedInAgentForm = ({ onAgentChange }) => {
  const [data, setData] = useState(null);
  const [originalDailyActivity, setOriginalDailyActivity] = useState([]);
  const [dailyActivity, setDailyActivity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [weeks, setWeeks] = useState([]);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);
  const [editingCell, setEditingCell] = useState(null);
  const [formData, setFormData] = useState({});
  const previousCell = useRef(null);
  const inputRefs = useRef({});
  const editingRowRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const tableContainerRef = useRef(null);
  const [lagnnames, setLagnnames] = useState([]);
  const [selectedLagnname, setSelectedLagnname] = useState('');
  
  useEffect(() => {
    const fetchHierarchyInfo = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const storedAgent = localStorage.getItem('agnName'); // Get the agent name from local storage
        const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/getHierarchyInfo', {
          userId,
        });
        if (response.data.success) {
          const allowedAgents = response.data.data.map(item => item.lagnname);
          setLagnnames(allowedAgents);
          
          // Set the default agent to either the stored agent or the first agent in the list
          const defaultAgent = storedAgent || allowedAgents[0];
          setSelectedLagnname(defaultAgent);
          onAgentChange(defaultAgent); // Notify parent about the selected agent
        } else {
          console.error('Failed to fetch hierarchy info');
        }
      } catch (error) {
        console.error('Error fetching hierarchy info:', error);
      }
    };
  
    fetchHierarchyInfo();
  }, [onAgentChange]);
  
  
  useEffect(() => {
    const fetchData = async () => {
        try {
            const userId = localStorage.getItem('userId');
            const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/getHierarchyInfo', { userId });
            const responseDailyActivity = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/getAllDailyActivity');
            
            if (response.data.success && responseDailyActivity.data.success) {
                const activityData = responseDailyActivity.data.data.map(activity => ({
                    ...activity,
                    reportDate: new Date(new Date(activity.reportDate).getTime() + new Date(activity.reportDate).getTimezoneOffset() * 60000)
                }));
                setOriginalDailyActivity(activityData);
                const uniqueWeeks = getUniqueWeeks(activityData);
                setWeeks(uniqueWeeks);
                const newestWeekIndex = 0;
                setSelectedWeekIndex(newestWeekIndex);

                const agentData = response.data.data.find(agent => agent.lagnname === selectedLagnname);
                if (agentData) {
                    // Check if the selected agent has a clname of MGA and set MGA value to their own name
                    if (agentData.clname === 'MGA') {
                        agentData.MGA = agentData.lagnname;
                    }
                    setData(agentData);
                } else {
                    setError('No data found for the selected agent');
                }
            } else {
                setError('No data found');
            }
        } catch (error) {
            setError('Error fetching data');
        } finally {
            setLoading(false);
        }
    };

    if (selectedLagnname) {
        fetchData();
    }
}, [selectedLagnname]);

useEffect(() => {
    if (weeks.length > 0) {
        filterDailyActivity(selectedWeekIndex, originalDailyActivity);
    }
}, [selectedWeekIndex, weeks, originalDailyActivity, selectedLagnname]);

  useEffect(() => {
    if (editingCell) {
      const { lagnname, dayIndex, columnIndex } = editingCell;
      const inputKey = `${lagnname}-${dayIndex}-${columnIndex}`;
      if (inputRefs.current[inputKey]) {
        inputRefs.current[inputKey].focus();
        inputRefs.current[inputKey].select();
      }
    }
  }, [editingCell]);

  useEffect(() => {
    const handleClickOutside = (event) => {
        if (editingCell && editingRowRef.current && !editingRowRef.current.contains(event.target)) {
            if (event.target && event.target.className.includes('cancel-button')) {
                return; // Do nothing if the cancel button is clicked
            }
            if (!isSaving) {
                setIsSaving(true);
                setFormData((prevFormData) => {
                    handleSave(prevFormData, 'handleClickOutside');
                    return prevFormData;
                });
            }
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [editingCell, isSaving]);


  useEffect(() => {
    const fetchHierarchyInfo = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/getHierarchyInfo', {
          userId,
        });
        if (response.data.success) {
          const allowedAgents = response.data.data.map(item => item.lagnname);
        } else {
          console.error('Failed to fetch hierarchy info');
        }
      } catch (error) {
        console.error('Error fetching hierarchy info:', error);
      }
    };

    fetchHierarchyInfo();
  }, []);

  useEffect(() => {
    if (weeks.length > 0) {
      filterDailyActivity(selectedWeekIndex, originalDailyActivity);
      const [mondayStr, sundayStr] = weeks[selectedWeekIndex].split(' - ');
      const monday = new Date(mondayStr);
      const sunday = new Date(sundayStr);
      const today = new Date();
      if (today >= monday && today <= sunday) {
        scrollToCurrentDay();
      }
    }
  }, [selectedWeekIndex, weeks, originalDailyActivity]);

  const getUniqueWeeks = (data) => {
    const weeks = {};
    data.forEach(item => {
      const date = new Date(item.reportDate);
      const monday = new Date(date.setDate(date.getDate() - ((date.getDay() + 6) % 7)));
      const sunday = new Date(monday);
      sunday.setDate(monday.getDate() + 6);
      const weekKey = `${(monday.getMonth() + 1).toString().padStart(2, '0')}/${monday.getDate().toString().padStart(2, '0')}/${monday.getFullYear()} - ${(sunday.getMonth() + 1).toString().padStart(2, '0')}/${sunday.getDate().toString().padStart(2, '0')}/${sunday.getFullYear()}`;
      if (!weeks[weekKey]) {
        weeks[weekKey] = true;
      }
    });

    const today = new Date();
    const currentMonday = new Date(today.setDate(today.getDate() - ((today.getDay() + 6) % 7)));
    const currentSunday = new Date(currentMonday);
    currentSunday.setDate(currentMonday.getDate() + 6);
    const currentWeekKey = `${(currentMonday.getMonth() + 1).toString().padStart(2, '0')}/${currentMonday.getDate().toString().padStart(2, '0')}/${currentMonday.getFullYear()} - ${(currentSunday.getMonth() + 1).toString().padStart(2, '0')}/${currentSunday.getDate().toString().padStart(2, '0')}/${currentSunday.getFullYear()}`;

    if (!weeks[currentWeekKey]) {
      weeks[currentWeekKey] = true;
    }

    return Object.keys(weeks).sort((a, b) => new Date(b.split(' - ')[0]) - new Date(a.split(' - ')[0]));
  };

  const handleWeekChange = (event) => {
    const newSelectedWeekIndex = weeks.indexOf(event.target.value);
    setSelectedWeekIndex(newSelectedWeekIndex);
    filterDailyActivity(newSelectedWeekIndex);
  };

  const handlePreviousWeek = () => {
    if (selectedWeekIndex < weeks.length - 1) {
      const newIndex = selectedWeekIndex + 1;
      setSelectedWeekIndex(newIndex);
      filterDailyActivity(newIndex, originalDailyActivity);
    }
  };

  const handleNextWeek = () => {
    if (selectedWeekIndex > 0) {
      const newIndex = selectedWeekIndex - 1;
      setSelectedWeekIndex(newIndex);
      filterDailyActivity(newIndex, originalDailyActivity);
    }
  };

  const getDatesOfWeek = (mondayDate) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(mondayDate);
      date.setDate(date.getDate() + i);
      dates.push(date);
    }
    return dates;
  };
  
  const handleLagnnameChange = (event) => {
    const newLagnname = event.target.value;
    setSelectedLagnname(newLagnname);
    onAgentChange(newLagnname); // Notify parent about the selected agent
  };

  const filterDailyActivity = (weekIndex, data = originalDailyActivity) => {
    const [mondayStr, sundayStr] = weeks[weekIndex].split(' - ');
    const monday = new Date(mondayStr);
    const sunday = new Date(sundayStr);
    
    const filteredActivity = data.filter(activity => {
        const reportDate = new Date(activity.reportDate);
        return reportDate >= monday && reportDate <= sunday && activity.agent === selectedLagnname;
    });
    
    setDailyActivity(filteredActivity);
};

  
  const handleCellClick = async (agentData, dayIndex, columnIndex) => {


    if (editingCell && !isSaving) {
      await handleSave(formData, 'handleCellClick');
    }

    const activity = getDailyActivityForAgent(agentData.lagnname, dayIndex + 1);

    setEditingCell({
      lagnname: agentData.lagnname,
      dayIndex,
      columnIndex,
      esid: agentData.esid,
      sa: agentData.sa,
      ga: agentData.ga,
      mga: agentData.mga
    });

    const newFormData = {
      calls: activity.calls && activity.calls !== 'N/A' ? activity.calls : '0',
      appts: activity.appts && activity.appts !== 'N/A' ? activity.appts : '0',
      sits: activity.sits && activity.sits !== 'N/A' ? activity.sits : '0',
      sales: activity.sales && activity.sales !== 'N/A' ? activity.sales : '0',
      alp: activity.alp && activity.alp !== 'N/A' ? activity.alp : '0',
      refs: activity.refs && activity.refs !== 'N/A' ? activity.refs : '0',
      refAppt: activity.refAppt && activity.refAppt !== 'N/A' ? activity.refAppt : '0',
      refSit: activity.refSit && activity.refSit !== 'N/A' ? activity.refSit : '0',
      refSale: activity.refSale && activity.refSale !== 'N/A' ? activity.refSale : '0',
      refAlp: activity.refAlp && activity.refAlp !== 'N/A' ? activity.refAlp : '0'
    };
    setFormData(newFormData);
    previousCell.current = { lagnname: agentData.lagnname, dayIndex, columnIndex };
    editingRowRef.current = document.querySelector(`#row-${agentData.lagnname}`);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSave = async (updatedFormData = formData, source = 'unknown') => {
    if (isSaving) return;

    try {
      const [mondayStr] = weeks[selectedWeekIndex].split(' - ');
      const monday = new Date(mondayStr);
      const reportDate = new Date(monday);
      reportDate.setDate(reportDate.getDate() + editingCell.dayIndex);
      const formattedReportDate = reportDate.toISOString().split('T')[0]; // Format to yyyy-mm-dd

      // Format today's date as yyyy-mm-dd
        const today = new Date();
        const todayDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

      const newActivity = {
        reportDate: formattedReportDate,
        esid: editingCell.esid,
        MGA: editingCell.mga,
        Work: todayDate,
        HC_Appt: 0,
        HC_Sit: 0,
        HC_Sale: 0,
        HC_ALP: 0,
        POS_Appt: 0,
        POS_Sit: 0,
        POS_Sale: 0,
        POS_ALP: 0,
        refAppt: parseInt(updatedFormData.refAppt) || 0,
        refSit: parseInt(updatedFormData.refSit) || 0,
        refSale: parseInt(updatedFormData.refSale) || 0,
        refAlp: parseInt(updatedFormData.refAlp) || 0,
        Vendor_Appt: 0,
        Vendor_Sit: 0,
        Vendor_Sale: 0,
        Vendor_ALP: 0,
        calls: parseInt(updatedFormData.calls) || 0,
        appts: parseInt(updatedFormData.appts) || 0,
        sits: parseInt(updatedFormData.sits) || 0,
        sales: parseInt(updatedFormData.sales) || 0,
        alp: parseInt(updatedFormData.alp) || 0,
        refs: parseInt(updatedFormData.refs) || 0,
        rga: editingCell.rga,
        agent: editingCell.lagnname,
        Legacy: '',
        Tree: '',
        SA: editingCell.sa,
        GA: editingCell.ga,
      };

      const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/submitDailyActivity', newActivity);
      if (response.data.success) {
      }

      const responseDailyActivity = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/getAllDailyActivity');
      if (responseDailyActivity.data.success) {
        const activityData = responseDailyActivity.data.data.map(activity => ({
          ...activity,
          reportDate: new Date(new Date(activity.reportDate).getTime() + new Date(activity.reportDate).getTimezoneOffset() * 60000)
        }));
        setOriginalDailyActivity(activityData);
        filterDailyActivity(selectedWeekIndex, activityData);
      } else {
        setError('Error fetching updated daily activity data');
      }

      setEditingCell(null);
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const scrollToCurrentDay = () => {
    const today = new Date();
    const dayIndex = today.getDay() - 1;
    if (dayIndex === 0) return;

    if (tableContainerRef.current) {
      const yesterdayDayIndex = dayIndex - 1;
      const yesterdayHeader = tableContainerRef.current.querySelector(`th[data-day-index="${yesterdayDayIndex}"]`);
      if (yesterdayHeader) {
        const additionalOffset = 171;
        const offset = yesterdayHeader.offsetLeft + yesterdayHeader.offsetWidth - additionalOffset;
        tableContainerRef.current.scrollTo({ left: offset, behavior: 'instant' });
      }
    }
  };

  const handleCancel = () => {
    setEditingCell(null);
    setFormData({});
  };

  const getDailyActivityForAgent = (lagnname, day) => {
    // Adjust the day to match the index from reportDate.getDay()
    const adjustedDay = (day % 7);
    const activity = dailyActivity.find(activity => {
      const reportDate = new Date(activity.reportDate);
      // Logging to see the reportDate and adjusted day
      return activity.agent === lagnname && reportDate.getDay() === adjustedDay;
    });
  
    // Log the activity data being returned
  
    return activity || {
      calls: 'N/A',
      appts: 'N/A',
      sits: 'N/A',
      sales: 'N/A',
      alp: 'N/A',
      refs: 'N/A',
      refAppt: 'N/A',
      refSit: 'N/A',
      refSale: 'N/A',
      refAlp: 'N/A'
    };
  };

  const reformatAgentName = (name) => {
    if (!name) return '';
    const parts = name.split(' ');
    if (parts.length < 2) return name;
    const [last, first, ...rest] = parts;
    return `${first} ${rest.join(' ')} ${last}`;
  };

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const renderAgentRow = (agent) => {
    const [mondayStr] = weeks[selectedWeekIndex].split(' - ');
    const datesOfWeek = getDatesOfWeek(new Date(mondayStr));
  
    return datesOfWeek.map((date, dayIndex) => {
      const activity = getDailyActivityForAgent(agent.lagnname, dayIndex + 1); // Updated here to match getDay() logic
      const isEditing = editingCell && editingCell.lagnname === agent.lagnname && editingCell.dayIndex === dayIndex;
      const inputKey = `${agent.lagnname}-${dayIndex}-`;
  
      // Logging to debug which data is being rendered
  
      return (
        <tr
          id={`row-${agent.lagnname}-${dayIndex}`}
          ref={editingCell && editingCell.lagnname === agent.lagnname && editingCell.dayIndex === dayIndex ? editingRowRef : null}
          className={editingCell && editingCell.lagnname === agent.lagnname && editingCell.dayIndex === dayIndex ? 'editing-row' : ''}
          key={`${agent.lagnname}-${dayIndex}`}
        >
          <td className="day-column">{date.toLocaleDateString()}</td>
          {isEditing ? (
            <>
              <td className="editing-cell"><input type="text" name="calls" value={formData.calls} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 0} ref={(el) => inputRefs.current[`${inputKey}0`] = el} /></td>
              <td className="editing-cell"><input type="text" name="appts" value={formData.appts} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 1} ref={(el) => inputRefs.current[`${inputKey}1`] = el} /></td>
              <td className="editing-cell"><input type="text" name="sits" value={formData.sits} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 2} ref={(el) => inputRefs.current[`${inputKey}2`] = el} /></td>
              <td className="editing-cell"><input type="text" name="sales" value={formData.sales} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 3} ref={(el) => inputRefs.current[`${inputKey}3`] = el} /></td>
              <td className="editing-cell"><input type="text" name="alp" value={formData.alp} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 4} ref={(el) => inputRefs.current[`${inputKey}4`] = el} /></td>
              <td className="editing-cell"><input type="text" name="refs" value={formData.refs} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 5} ref={(el) => inputRefs.current[`${inputKey}5`] = el} /></td>
              <td className="editing-cell"><input type="text" name="refAppt" value={formData.refAppt} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 6} ref={(el) => inputRefs.current[`${inputKey}6`] = el} /></td>
              <td className="editing-cell"><input type="text" name="refSit" value={formData.refSit} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 7} ref={(el) => inputRefs.current[`${inputKey}7`] = el} /></td>
              <td className="editing-cell"><input type="text" name="refSale" value={formData.refSale} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 8} ref={(el) => inputRefs.current[`${inputKey}8`] = el} /></td>
              <td className="editing-cell"><input type="text" name="refAlp" value={formData.refAlp} onChange={handleInputChange} autoFocus={editingCell.columnIndex === 9} ref={(el) => inputRefs.current[`${inputKey}9`] = el} /></td>
            </>
          ) : (
            <>
              <td onClick={() => handleCellClick(agent, dayIndex, 0)}>{activity.calls !== 'N/A' ? activity.calls : 'N/A'}</td>
              <td onClick={() => handleCellClick(agent, dayIndex, 1)}>{activity.appts !== 'N/A' ? activity.appts : 'N/A'}</td>
              <td onClick={() => handleCellClick(agent, dayIndex, 2)}>{activity.sits !== 'N/A' ? activity.sits : 'N/A'}</td>
              <td onClick={() => handleCellClick(agent, dayIndex, 3)}>{activity.sales !== 'N/A' ? activity.sales : 'N/A'}</td>
              <td onClick={() => handleCellClick(agent, dayIndex, 4)}>{activity.alp !== 'N/A' ? activity.alp : 'N/A'}</td>
              <td onClick={() => handleCellClick(agent, dayIndex, 5)}>{activity.refs !== 'N/A' ? activity.refs : 'N/A'}</td>
              <td onClick={() => handleCellClick(agent, dayIndex, 6)}>{activity.refAppt !== 'N/A' ? activity.refAppt : 'N/A'}</td>
              <td onClick={() => handleCellClick(agent, dayIndex, 7)}>{activity.refSit !== 'N/A' ? activity.refSit : 'N/A'}</td>
              <td onClick={() => handleCellClick(agent, dayIndex, 8)}>{activity.refSale !== 'N/A' ? activity.refSale : 'N/A'}</td>
              <td onClick={() => handleCellClick(agent, dayIndex, 9)}>{activity.refAlp !== 'N/A' ? activity.refAlp : 'N/A'}</td>
            </>
          )}
        </tr>
      );
    });
  };
  

  const renderTotalsRow = (dailyActivity) => {
    const totals = dailyActivity.reduce((acc, activity) => {
      acc.calls += parseInt(activity.calls, 10) || 0;
      acc.appts += parseInt(activity.appts, 10) || 0;
      acc.sits += parseInt(activity.sits, 10) || 0;
      acc.sales += parseInt(activity.sales, 10) || 0;
      acc.alp += parseInt(activity.alp, 10) || 0;
      acc.refs += parseInt(activity.refs, 10) || 0;
      acc.refAppt += parseInt(activity.refAppt, 10) || 0;
      acc.refSit += parseInt(activity.refSit, 10) || 0;
      acc.refSale += parseInt(activity.refSale, 10) || 0;
      acc.refAlp += parseInt(activity.refAlp, 10) || 0;
  
      // Log the data source for each total
      
      return acc;
    }, {
      calls: 0,
      appts: 0,
      sits: 0,
      sales: 0,
      alp: 0,
      refs: 0,
      refAppt: 0,
      refSit: 0,
      refSale: 0,
      refAlp: 0
    });
  
    // Log the totals calculated
  
    return (
      <tr className="totals-row">
        <td>Totals</td>
        <td>{totals.calls}</td>
        <td style={{backgroundColor: '#f0f0f0'}}>{totals.appts}</td>
        <td style={{backgroundColor: '#f0f0f0'}}>{totals.sits}</td>
        <td style={{backgroundColor: '#f0f0f0'}}>{totals.sales}</td>
        <td style={{backgroundColor: '#f0f0f0'}}>{totals.alp}</td>
        <td style={{backgroundColor: '#f0f0f0'}}>{totals.refs}</td>
        <td style={{backgroundColor: '#f0f0f0'}}>{totals.refAppt}</td>
        <td style={{backgroundColor: '#f0f0f0'}}>{totals.refSit}</td>
        <td style={{backgroundColor: '#f0f0f0'}}>{totals.refSale}</td>
        <td style={{backgroundColor: '#f0f0f0'}}>{totals.refAlp}</td>
      </tr>
    );
  };
  

  if (loading) return <Placeholder />;
  if (error) return <div>{error}</div>;
  if (!data) return <div>No data available</div>;
  

  return (
    <div className="hier-table-container" ref={tableContainerRef}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <label htmlFor="lagnname-select" style={{ marginRight: '10px' }}>Select Agent:</label>
                <select id="lagnname-select" onChange={handleLagnnameChange} value={selectedLagnname}>
                    {lagnnames.map(name => (
                        <option key={name} value={name}>{name}</option>
                    ))}
                </select>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <button className='hier-change-week-left' onClick={handlePreviousWeek} disabled={selectedWeekIndex === weeks.length - 1}>{'<'}</button>
                <select onChange={handleWeekChange} value={weeks[selectedWeekIndex]}>
                    {weeks.map(week => (
                        <option value={week} key={week}>{week}</option>
                    ))}
                </select>
                <button className='hier-change-week-right' onClick={handleNextWeek} disabled={selectedWeekIndex === 0}>{'>'}</button>
            </div>
        </div>
        <table className="hierarchyTable">
            <thead>
                <tr className="second-row">
                    <th>Day</th>
                    <th style={{ backgroundColor: '#68B675' }} className="header-appts">Calls</th>
                    <th style={{ backgroundColor: '#68B675' }} className="header-appts">Tot Appts</th>
                    <th style={{ backgroundColor: '#68B675' }} className="header-sits">Tot Sits</th>
                    <th style={{ backgroundColor: '#68B675' }} className="header-sales">Tot Sales</th>
                    <th style={{ backgroundColor: '#68B675' }} className="header-alp">Tot ALP</th>
                    <th style={{ backgroundColor: '#68B675' }} className="header-refs">Refs</th>
                    <th style={{ backgroundColor: '#68B675' }} className="header-refAppts">Ref Appts</th>
                    <th style={{ backgroundColor: '#68B675' }} className="header-refSits">Ref Sits</th>
                    <th style={{ backgroundColor: '#68B675' }} className="header-refSales">Ref Sales</th>
                    <th style={{ backgroundColor: '#68B675' }} className="header-refAlp">Ref ALP</th>
                </tr>
            </thead>
            <tbody>
                {renderAgentRow(data)}
                {renderTotalsRow(dailyActivity)}
            </tbody>
        </table>
        {editingCell && (
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
                <button onClick={() => handleSave(formData, 'submit-button')} className="btn" style={{backgroundColor: '#00558c', color: 'white', margin: '2.5px'}}>Submit</button>
                <button onClick={handleCancel} className="btn btn-secondary cancel-button">Cancel</button>
            </div>
        )}
    </div>
);

  
};

export default LoggedInAgentForm;
