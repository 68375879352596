// Production.js
import React from 'react';
import ActivityCards from './Dashboard/ActivityCard';

const Production = () => {
    return (
        <div>
            <h1>Production</h1>
            <ActivityCards />
        </div>
    );
};

export default Production;
