import React, { useEffect, useState } from 'react';
import '../../ActivityCards.css'; // Ensure this path is correct for your styling

const MoreCards = ({ moreData }) => { // Accept moreData as a prop
    const [processedData, setProcessedData] = useState({
        overviewsSet: 0,
        overviewsShow: 0,
        finalsSet: 0,
        finalsShow: 0,
        prHires: 0,
        totalHires: 0,
    });

    const [showDetails, setShowDetails] = useState(false);

    const handleToggleDetails = () => {
        setShowDetails(!showDetails);
    };

    const processData = (data) => {
        if (!data || data.length <= 1) return; // Check if data has more than just headers

        const sums = data.reduce((acc, item) => { // Process the entire data
            acc.overviewsSet += parseFloat(item.Total_Set || 0);
            acc.overviewsShow += parseFloat(item.Total_Show || 0);
            acc.finalsSet += parseFloat(item.Finals_Set || 0);
            acc.finalsShow += parseFloat(item.Finals_Show || 0);
            acc.prHires += parseFloat(item.PR_Hires || 0);
            acc.totalHires += parseFloat(item.Total_Hires || 0);
            return acc;
        }, {
            overviewsSet: 0,
            overviewsShow: 0,
            finalsSet: 0,
            finalsShow: 0,
            prHires: 0,
            totalHires: 0,
        });

        const result = showDetails ? {
            overviewsSet: sums.overviewsSet / data.length,
            overviewsShow: sums.overviewsShow / data.length,
            finalsSet: sums.finalsSet / data.length,
            finalsShow: sums.finalsShow / data.length,
            prHires: sums.prHires / data.length,
            totalHires: sums.totalHires / data.length,
        } : sums;

        setProcessedData({
            overviewsSet: result.overviewsSet.toLocaleString(),
            overviewsShow: result.overviewsShow.toLocaleString(),
            finalsSet: result.finalsSet.toLocaleString(),
            finalsShow: result.finalsShow.toLocaleString(),
            prHires: result.prHires.toLocaleString(),
            totalHires: result.totalHires.toLocaleString(),
        });
    };

    useEffect(() => {
        processData(moreData); // Use the passed moreData for processing
    }, [moreData, showDetails]); // Recalculate when moreData or showDetails changes

    // The rendering part remains unchanged
    return (
        <div>
            <div className="cards-row">
                <div className="card">
                    <h5 className="card-title">Overviews Set</h5>
                    <p className="card-text">{processedData.overviewsSet}</p>
                </div>
                <div className="card">
                    <h5 className="card-title">Overviews Show</h5>
                    <p className="card-text">{processedData.overviewsShow}</p>
                </div>
                <div className="card">
                    <h5 className="card-title">Finals Set</h5>
                    <p className="card-text">{processedData.finalsSet}</p>
                </div>
                <div className="card">
                    <h5 className="card-title">Finals Show</h5>
                    <p className="card-text">{processedData.finalsShow}</p>
                </div>
                <div className="card">
                    <h5 className="card-title">PR Hires</h5>
                    <p className="card-text">{processedData.prHires}</p>
                </div>
                <div className="card">
                    <h5 className="card-title">Total Hires</h5>
                    <p className="card-text">{processedData.totalHires}</p>
                </div>
            </div>
        </div>
    );
};

export default MoreCards;
