import React, { useEffect, useState } from 'react';
import MyTrophyCase from './MyTrophyCase';
import HierarchyTable from './HierarchyTable';
import ContributionsTable from './Contributions';
import AccountCreationForm from '../Admin/CreateAccount'
import { Modal, Button } from 'react-bootstrap';
import './Account.css';
import StatCard from '../utils/StatCard';
import ManagerStats from './ManagerStats';

const Account = () => {
  const [userData, setUserData] = useState(null);
  const [profilePicture, setProfilePicture] = useState('');
  const [tenure, setTenure] = useState('');
  const [anniversaryMessage, setAnniversaryMessage] = useState('');
  const [reportingStreak, setReportingStreak] = useState(0); // Add state for reporting streak
  const [allTimeDaysReported, setAllTimeDaysReported] = useState(0); // Add state for all-time days reported
  const [showModal, setShowModal] = useState(false); // State for modal visibility

  useEffect(() => {
    const fetchDailyActivity = async () => {
      try {
        const agnName = localStorage.getItem('agnName');
        const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/getAllDailyActivity');
        const data = await response.json();
        if (data.success && Array.isArray(data.data)) {
          const agentActivities = data.data
            .filter(item => item.agent === agnName)
            .map(item => new Date(item.reportDate).toISOString().slice(0, 10));
          calculateReportingStreak(agentActivities);
          calculateAllTimeDaysReported(agentActivities);
        } else {
          console.error('Failed to load daily activity data');
        }
      } catch (error) {
        console.error('Error fetching daily activity:', error);
      }
    };

    fetchDailyActivity();
  }, []);

  const calculateReportingStreak = (activityDates) => {
    const sortedDates = activityDates.sort((a, b) => new Date(a) - new Date(b));
    let streak = 0;
    let currentStreak = 0;
    const today = new Date().toISOString().slice(0, 10);

    for (let i = sortedDates.length - 1; i >= 0; i--) {
      const date = sortedDates[i];
      const previousDate = sortedDates[i - 1] ? new Date(sortedDates[i - 1]) : null;
      const currentDate = new Date(date);

      // Stop processing if we encounter a future date
      if (currentDate.toISOString().slice(0, 10) > today) {
        continue;
      }

      if (previousDate) {
        previousDate.setDate(previousDate.getDate() + 1);
        if (currentDate.toISOString().slice(0, 10) === previousDate.toISOString().slice(0, 10)) {
          currentStreak++;
        } else if (currentDate.toISOString().slice(0, 10) === today) {
          streak = Math.max(streak, currentStreak + 1);
          currentStreak = 0;
        } else {
          streak = Math.max(streak, currentStreak);
          currentStreak = 0;
        }
      } else if (currentDate.toISOString().slice(0, 10) === today) {
        streak = Math.max(streak, currentStreak + 1);
      } else {
        streak = Math.max(streak, currentStreak);
      }
    }

    setReportingStreak(streak);
  };

  const calculateAllTimeDaysReported = (activityDates) => {
    const today = new Date().toISOString().slice(0, 10);
    const pastDates = activityDates.filter(date => date <= today);
    const uniqueDates = new Set(pastDates);
    setAllTimeDaysReported(uniqueDates.size);
  };

  useEffect(() => {
    // Fetch user data from local storage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      setUserData(userInfo);
      setProfilePicture(userInfo.profpic || 'https://via.placeholder.com/150');
      calculateTenure(userInfo.esid);
    }
  }, []);

  const calculateTenure = (esid) => {
    const startDate = new Date(esid);
    const now = new Date();

    // Reset time components to 0 for comparison
    startDate.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);

    const diffTime = now - startDate;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const years = Math.floor(diffDays / 365);
    const months = Math.floor((diffDays % 365) / 30);
    const weeks = Math.floor((diffDays % 30) / 7);
    const days = diffDays % 7;

    let tenureString = '';

    if (years > 0) {
      tenureString += `${years} year${years > 1 ? 's' : ''}`;
    }

    if (months > 0) {
      if (tenureString) {
        tenureString += `, `;
      }
      tenureString += `${months} month${months > 1 ? 's' : ''}`;
    }

    if (weeks > 0) {
      if (tenureString) {
        tenureString += `, `;
      }
      tenureString += `${weeks} week${weeks > 1 ? 's' : ''}`;
    }

    if (days > 0) {
      if (tenureString) {
        tenureString += `, `;
      }
      tenureString += `${days} day${days > 1 ? 's' : ''}`;
    }

    setTenure(tenureString);

    // Check for anniversary
    const startMonthDay = startDate.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });
    const nowMonthDay = now.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });

    if (startMonthDay === nowMonthDay) {
      setAnniversaryMessage(`Happy ${years} year${years > 1 ? 's' : ''} Anniversary!`);
    } else {
      setAnniversaryMessage('');
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('profilePic', file);
      formData.append('userId', userData.id);

      try {
        const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/upload-profile-pic', {
          method: 'POST',
          body: formData,
        });
        const result = await response.json();
        if (result.success) {
          setProfilePicture(result.filePath);
          // Update userInfo in localStorage
          const updatedUserInfo = { ...userData, profpic: result.filePath };
          setUserData(updatedUserInfo); // Update state
          localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const handleRemovePicture = async () => {
    try {
      const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/remove-profile-picture', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: userData.id }),
      });
      const result = await response.json();
      if (result.success) {
        setProfilePicture('https://via.placeholder.com/150');
        // Update userInfo in localStorage
        const updatedUserInfo = { ...userData, profpic: '' };
        setUserData(updatedUserInfo); // Update state
        localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
      }
    } catch (error) {
      console.error('Error removing profile picture:', error);
    }
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  if (!userData) {
    return <div>Loading...</div>;
  }

  const userRole = localStorage.getItem('userRole');

  return (
    <div className='container'>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
        <div className="image-container">
          <img
            src={profilePicture}
            alt="Profile"
            className="profile-image"
          />
          <button className="edit-button">✎</button>
          <div className="dropdown-menu">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById('fileInput').click();
              }}
            >
              Upload
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleRemovePicture();
              }}
            >
              Remove
            </a>
          </div>
        </div>
        <div className="user-info">
          <h2>{userData.screen_name}</h2>
          {anniversaryMessage && <p className="anniversary-message">{anniversaryMessage}</p>}
          <p><strong>Tenure: {tenure}</strong></p>
          <p>{userData.email}</p>
          <p>{userData.phone}</p>
        </div>
        <div className="stat-cards-container">
          <StatCard title="Reporting Streak" value={`${reportingStreak} day${reportingStreak !== 1 ? 's' : ''}`} />
          <StatCard title="All-Time Days Reported" value={`${allTimeDaysReported} day${allTimeDaysReported !== 1 ? 's' : ''}`} />
        </div>
        <input
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleFileChange}
        />
      </div>
      <HierarchyTable />
      {['GA', 'MGA', 'RGA'].includes(userRole) && (
        <button onClick={handleOpenModal} className="dashboard-button">
          Create Trainee Account
        </button>
      )}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Body>
        <AccountCreationForm />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Account;
