// In ./components/ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const token = localStorage.getItem('userToken');

  if (!token) {
    // Redirect to login if there's no token
    return <Navigate to="/login" />;
  }

  // Render the element as a component if token is present
  return <Element {...rest} />;
};

export default ProtectedRoute;
