import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Card, Form, Button, Row, Col, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import "./AgentSetupPage.css";
import logo from '../../img/globe1.png';

const AgentSetupPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalContent, setModalContent] = useState({
        conflictMessage: '',
        email: '',
        phone: '',
        screenName: '',
        isDecisionMade: false,
    });
    const [formData, setFormData] = useState({
        screenName: '',
        email: '',
        phoneArea: '',
        phonePrefix: '',
        phoneLine: '',
        lagnname: '',
        esid: '',
    });
    const [notification, setNotification] = useState({
        message: '',
        type: '' // 'error' or 'success'
    });

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const storedData = {
            name: localStorage.getItem('userName') || '',
            role: localStorage.getItem('userRole') || '',
            email: localStorage.getItem('userEmail') || '',
            globeID: localStorage.getItem('userGlobeID') || '',
            upline: localStorage.getItem('userManager') || '',
        };
        setFormData(prev => ({ ...prev, ...storedData }));

        // Additional check for esid and lagnname passed through location state
        if (location.state?.esid && location.state?.lagnname) {
            // Format esid as yyyy-mm-dd
            const esidFormatted = location.state.esid.split('T')[0]; // Assuming esid is in ISO string format
            
            const updatedFormData = {
                ...formData,
                esid: esidFormatted,
                lagnname: location.state.lagnname,
            };
            
            setFormData(updatedFormData);
            autoGenerateScreenName(updatedFormData.lagnname);
        }
    }, [location.state]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };
        setFormData(updatedFormData);

        if (name === 'lagnname') {
            autoGenerateScreenName(value);
        }
    };

    const autoGenerateScreenName = (lagnname) => {
        const parts = lagnname.split(' ');

        let firstName = '';
        let lastName = '';
        let middleInitial = '';
        let suffix = '';

        if (parts.length > 0) {
            lastName = parts[0];
            if (parts.length > 1) {
                firstName = parts[1];
                if (parts.length === 3 && parts[2].length === 1) {
                    middleInitial = parts[2];
                } else if (parts.length === 4) {
                    middleInitial = parts[2];
                    suffix = parts[3];
                }
            }
        }

        const screenName = `${firstName} ${lastName}`.trim();
        setFormData(prev => ({ ...prev, screenName }));
    };

    const handlePhoneInput = (e, nextFieldId, previousFieldId) => {
        const value = e.target.value;
        setFormData({ ...formData, [e.target.name]: value });

        if (value.length === e.target.maxLength) {
            if (nextFieldId) {
                document.getElementById(nextFieldId).focus();
            }
        } else if (value.length === 0) {
            if (previousFieldId && e.keyCode === 8) {
                document.getElementById(previousFieldId).focus();
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const fullPhoneNumber = `1(${formData.phoneArea})${formData.phonePrefix}-${formData.phoneLine}`;
        try {
            const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/checkUserInfo', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: formData.email,
                    phone: fullPhoneNumber,
                    esid: formData.esid,
                    lagnname: formData.lagnname,
                }),
            });
            const data = await response.json();
            if (data.success) {
                // No existing user, trigger user creation
                const createResponse = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/handleUserInfo', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        screenName: formData.screenName,
                        email: formData.email,
                        phone: fullPhoneNumber,
                        esid: formData.esid,
                        lagnname: formData.lagnname,
                        decision: 'new',
                    }),
                });
                const createData = await createResponse.json();
                if (createData.success) {
                    alert('User created successfully.');
                    navigate('/successRoute'); // or wherever you want to redirect
                } else {
                    alert(createData.message || 'Failed to create user. Please try again.');
                }
            } else {
                // Handle existing user scenario
                setNotification({ message: data.message, type: 'error' });
                setShowModal(true);
                setModalContent({ ...data.data, conflictMessage: data.message });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while processing your request. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleConfirmExistingUser = async () => {
        const { screenName, email, phoneArea, phonePrefix, phoneLine, lagnname, esid } = formData;
        const fullPhoneNumber = `${phoneArea}-${phonePrefix}-${phoneLine}`;

        setIsLoading(true); // Show loading indicator

        try {
            const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/handleUserInfo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    screenName,
                    email,
                    phone: fullPhoneNumber,
                    esid,
                    lagnname,
                    decision: 'existing',
                }),
            });

            const data = await response.json();

            setIsLoading(false); // Hide loading indicator

            if (data.success) {
                alert('Your information has been updated successfully.');
                navigate('/successRoute'); // Update with your success route
            } else {
                alert(data.message || 'Failed to update your information. Please try again.');
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error confirming existing user:', error);
            alert('An error occurred while processing your request. Please try again.');
        }
    };

    const handleConfirmFreshStart = async () => {
        setIsLoading(true);

        try {
            // Check for uniqueness of ESID and Lagnname
            const checkResponse = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/confirmStartFresh', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    esid: formData.esid,
                    lagnname: formData.lagnname,
                }),
            });

            if (checkResponse.status === 409) {
                // If ESID and Lagnname combination already exists
                alert('This ESID and Lagnname combination is already in use. Please contact support for assistance.');
                setIsLoading(false);
            } else {
                // Proceed to create a new user since ESID and Lagnname are unique
                const createData = await checkResponse.json();

                if (createData.success) {
                    // Handle success
                    alert('A new account has been successfully created.');
                    navigate('/login'); // Redirect or perform other success actions
                } else {
                    // Handle failure to create a new user
                    alert('Failed to create a new account. Please try again.');
                }
            }
        } catch (error) {
            console.error('Error processing fresh start:', error);
            alert('An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
            setShowModal(false); // Close the modal
        }
    };

    const submitForm = async () => {
        console.log("Submitting new user form data", formData);
        const fullPhoneNumber = `1(${formData.phoneArea})${formData.phonePrefix}-${formData.phoneLine}`;

        try {
            const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/handleUserInfo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    screenName: formData.screenName,
                    email: formData.email,
                    phone: fullPhoneNumber,
                    esid: formData.esid,
                    lagnname: formData.lagnname,
                    decision: 'new', // Ensure this aligns with your backend logic
                }),
            });
            const data = await response.json();
            console.log("submitForm response", data);

            if (data.success) {
                alert('User created successfully.');
                // Redirect or handle success
            } else {
                alert(data.message || 'Failed to create user. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting new user form:', error);
            alert('An error occurred while processing your request. Please try again.');
        }
    };

    return (
        <Container className="d-flex justify-content-center align-items-center py-4 py-xl-5">
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalContent.isDecisionMade ? 'Are You Sure?' : 'User Information Exists'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {modalContent.isDecisionMade ? (
                        <>
                            <p>Are you sure this is not you? If this is you and you opt to start fresh, you will lose all reported activity so far in your career.</p>
                            <div className="mt-4">
                                <Button variant="danger" size="lg" className="w-75 mb-2" onClick={handleConfirmFreshStart}>Confirm Start Fresh</Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p>{modalContent.conflictMessage}</p>
                            <ul className="list-unstyled">
                                {modalContent.email && <li><strong>Email:</strong> {modalContent.email}</li>}
                                {modalContent.phone && <li><strong>Phone:</strong> {modalContent.phone}</li>}
                                {modalContent.screenName && <li><strong>Screen Name:</strong> {modalContent.screenName}</li>}
                            </ul>
                            <p>Do not worry if this information is outdated, we can update this later. This step is just to link your existing activity data to your new account.</p>
                            <div className="mt-4">
                                <Button variant="primary" size="lg" className="w-75 mb-2" onClick={handleConfirmExistingUser}>Yes, this is me</Button>
                                <Button variant="warning" size="lg" className="w-75" onClick={() => setModalContent({ ...modalContent, isDecisionMade: true })}>No, this is not me</Button>
                            </div>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {modalContent.isDecisionMade ? (
                        <>
                            <Button variant="secondary" onClick={() => setModalContent({ ...modalContent, isDecisionMade: false })}>Back</Button>
                        </>
                    ) : (
                        <>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
            {isLoading && (
                <div className="loading-overlay">
                    <img src={logo} alt="Loading" className="loading-animation" />
                </div>
            )}
            <Card className="col-md-6 col-xl-4 mb-5">
                {notification.message && (
                    <div className={`alert ${notification.type === 'error' ? 'alert-danger' : 'alert-success'}`} role="alert">
                        {notification.message}
                    </div>
                )}
                <Card.Body className="d-flex flex-column align-items-center">
                    <Card.Img variant="top" src={logo} className="bs-icon-xl bs-icon-circle bs-icon-primary bs-icon my-4" style={{ backgroundImage: `url(${logo})`, width: '79px', height: '79px' }} />
                    <h4 className="mb-4">Profile Setup for</h4>
                    <h5 className="mb-4">{formData.screenName}</h5>
                    <Form onSubmit={handleSubmit} className="text-center w-100">
                        <Form.Group className="mb-3 text-start" style={{ display: 'none' }}>
                            <div className="d-flex justify-content-start align-items-center">
                                <Form.Label className="form-label-small">Screen Name</Form.Label>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip id="screenName-tooltip">This is the name that will be displayed on agency and team leaderboards.</Tooltip>}
                                >
                                    <Button variant="transparent" className="ms-2 p-0" style={{ background: 'none', boxShadow: 'none', verticalAlign: 'baseline' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" className="bi bi-info-circle-fill">
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM7 6.5a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm.635 3.125c-.07-.035-.108-.047-.118-.05a.13.13 0 0 0-.07.012.146.146 0 0 0-.055.05.61.61 0 0 0-.078.085c-.02.03-.037.057-.05.077a.683.683 0 0 0-.07.183.75.75 0 0 0-.022.079.224.224 0 0 0-.01.079v.002c0 .004.002.008.002.01 0 .004-.002.007-.002.01v.5c0 .004.002.008.002.012a.224.224 0 0 0 .01.078c.003.027.014.053.022.078a.7.7 0 0 0 .07.183c.013.02.03.047.05.077.013.018.036.05.063.083a.147.147 0 0 0 .055.05c.02.01.028.015.07.012.042-.003.098-.016.118-.05a.59.59 0 0 0 .065-.085.704.704 0 0 0 .05-.077.752.752 0 0 0 .022-.078.225.225 0 0 0 .01-.079v-.5a.225.225 0 0 0-.01-.079.752.752 0 0 0-.022-.079.704.704 0 0 0-.05-.077.59.59 0 0 0-.065-.085z"/>
                                        </svg>
                                    </Button>
                                </OverlayTrigger>
                            </div>
                            <Form.Control type="text" placeholder="Screen Name" name="screenName" value={formData.screenName} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control type="hidden" name="lagnname" value={formData.lagnname} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control type="hidden" name="esid" value={formData.esid} />
                        </Form.Group>
                        <Form.Group className="mb-3 text-start">
                            <Form.Label className="form-label-small">Email</Form.Label>
                            <Form.Control type="email" placeholder="Your email" name="email" value={formData.email} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group className="mb-3 text-start">
                            <Form.Label className="form-label-small">Phone Number</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control
                                        id="phoneArea"
                                        type="text"
                                        placeholder="xxx"
                                        name="phoneArea"
                                        value={formData.phoneArea}
                                        maxLength={3}
                                        onChange={handleChange}
                                        required
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        id="phonePrefix"
                                        type="text"
                                        placeholder="xxx"
                                        name="phonePrefix"
                                        value={formData.phonePrefix}
                                        maxLength={3}
                                        onChange={handleChange}
                                        required
                                    />
                                </Col>
                                <Col>
                                    <Form.Control
                                        id="phoneLine"
                                        type="text"
                                        placeholder="xxxx"
                                        name="phoneLine"
                                        value={formData.phoneLine}
                                        maxLength={4}
                                        onChange={handleChange}
                                        required
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="d-block w-100" style={{ backgroundColor: 'green' }}>
                            Submit
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default AgentSetupPage;
