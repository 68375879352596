import React, { useState } from 'react';
import './TableView.css';
import AddRecruit from './AddRecruit';
import { UNIFORM_STEPS } from './steps';

const calculateTimeInStep = (stepEntries) => {
  let totalDuration = 0;

  stepEntries.forEach(entry => {
    // Parse the entered and exited times
    const entered = new Date(entry.date_entered.replace(' ', 'T')); // Convert to ISO format
    const exited = entry.date_exited ? new Date(entry.date_exited.replace(' ', 'T')) : new Date();

    console.log(`Parsed Entered Time: ${entered}`);
    console.log(`Parsed Exited Time: ${exited}`);

    // Calculate duration directly using the parsed dates
    const duration = exited - entered;

    totalDuration += duration;

    console.log(`Calculated Duration for this step (ms): ${duration}`);
  });

  const days = Math.floor(totalDuration / (24 * 60 * 60 * 1000));
  const hours = Math.floor((totalDuration % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  const minutes = Math.floor((totalDuration % (60 * 60 * 1000)) / (60 * 1000));

  let formatted = '';
  if (days > 0) formatted += `${days}d `;
  if (hours > 0) formatted += `${hours}h `;
  if (minutes > 0) formatted += `${minutes}m`;

  console.log(`Final Total Duration (ms): ${totalDuration}`);
  console.log(`Formatted Duration: ${formatted.trim()}`);

  return { totalDuration, formatted: formatted.trim() };
};


const TableView = ({ recruits, pipelineSteps }) => {
  const [recruitsState, setRecruits] = useState(recruits);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [editStepId, setEditStepId] = useState(null);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleStepChange = async (recruitId, newStep) => {
    const recruitIndex = recruitsState.findIndex(recruit => recruit.id === recruitId);
    const previousStep = recruitsState[recruitIndex].step;

    const updatedRecruits = [...recruitsState];
    updatedRecruits[recruitIndex].step = newStep;

    const currentStepEntries = pipelineSteps.filter(
      step =>
        step.recruit_id === recruitId &&
        step.step === previousStep
    );

    updatedRecruits[recruitIndex].timeInStep = currentStepEntries.length > 0
      ? calculateTimeInStep(currentStepEntries)
      : { duration: 0, formatted: 'N/A' };

    setRecruits(updatedRecruits);
    setEditStepId(null);

    try {
      const timeZoneOffset = new Date().getTimezoneOffset(); // Capture user's timezone offset
      await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/recruits/${recruitId}/step`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ new_step: newStep, timeZoneOffset }), // Include timezone offset
      });
      console.log(`Step updated successfully for recruit ID: ${recruitId}`);
    } catch (error) {
      console.error('Error updating step on the backend:', error);
      const revertedRecruits = [...recruitsState];
      revertedRecruits[recruitIndex].step = previousStep;
      setRecruits(revertedRecruits);
    }
  };

  const sortedRecruits = [...recruitsState].map(recruit => {
    const currentStepEntries = pipelineSteps.filter(
      step => step.recruit_id === recruit.id && step.step === recruit.step
    );

    const timeInStep = currentStepEntries.length > 0
      ? calculateTimeInStep(currentStepEntries)
      : { duration: 0, formatted: 'N/A' };

    return { ...recruit, timeInStep };
  }).sort((a, b) => {
    if (!sortColumn) return 0;

    const aValue = sortColumn === 'timeInStep' ? a.timeInStep.totalDuration : a[sortColumn];
    const bValue = sortColumn === 'timeInStep' ? b.timeInStep.totalDuration : b[sortColumn];

    if (sortDirection === 'asc') {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  const filteredRecruits = sortedRecruits.filter(recruit =>
    `${recruit.recruit_first} ${recruit.recruit_middle} ${recruit.recruit_last}`.toLowerCase().includes(searchTerm) ||
    (recruit.step || '').toLowerCase().includes(searchTerm) ||
    (recruit.email || '').toLowerCase().includes(searchTerm) ||
    (recruit.phone || '').toLowerCase().includes(searchTerm)
  );

  return (
    <div className="recruiting-table-container">
      <div className="search-add-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        <AddRecruit />
      </div>
      <table className="recruiting-table">
        <thead>
          <tr>
            <th className="recruiting-table-header sortable" onClick={() => handleSort('recruit_first')}>Recruit</th>
            <th className="recruiting-table-header sortable" onClick={() => handleSort('step')}>Step</th>
            <th className="recruiting-table-header sortable" onClick={() => handleSort('timeInStep')}>Time in Step</th>
            <th className="recruiting-table-header sortable" onClick={() => handleSort('email')}>Email</th>
            <th className="recruiting-table-header sortable" onClick={() => handleSort('phone')}>Phone</th>
          </tr>
        </thead>
        <tbody>
          {filteredRecruits.map((recruit, index) => (
            <tr key={index}>
              <td>{`${recruit.recruit_first} ${recruit.recruit_middle ? recruit.recruit_middle + ' ' : ''}${recruit.recruit_last}`}</td>
              <td className='step-column'
                onMouseEnter={() => setEditStepId(recruit.id)}
                onMouseLeave={() => setEditStepId(null)}
              >
                {editStepId === recruit.id ? (
                  <select
                    value={recruit.step}
                    onChange={(e) => handleStepChange(recruit.id, e.target.value)}
                  >
                    {UNIFORM_STEPS.map((step, index) => (
                      <option key={index} value={step}>
                        {step}
                      </option>
                    ))}
                  </select>
                ) : (
                  recruit.step
                )}
              </td>
              <td>{recruit.timeInStep.formatted}</td>
              <td>{recruit.email}</td>
              <td>{recruit.phone}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableView;
