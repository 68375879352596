import React, { useState, useEffect } from 'react';
import { useFetchData } from '../../hooks/codesAndVips';
import '../../MoreDataTable.css';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const getColorForShowPercentage = (percentage) => {
    if (percentage >= 70) return 'rgba(102, 204, 102, 0.6)'; // Pastel green
    if (percentage >= 50 && percentage < 70) return 'rgba(255, 255, 102, 0.6)'; // Pastel yellow
    return 'rgba(255, 102, 102, 0.6)'; // Pastel red
};

const getColorForGroupToHire = (percentage) => {
    if (percentage >= 50) return 'rgba(102, 204, 102, 0.6)'; // Pastel green
    if (percentage >= 35 && percentage < 50) return 'rgba(255, 255, 102, 0.6)'; // Pastel yellow
    return 'rgba(255, 102, 102, 0.6)'; // Pastel red
};

const getColorForSetToHire = (value) => {
    if (value <= 10) return 'rgba(102, 204, 102, 0.6)'; // Pastel green
    if (value >= 11 && value <= 15) return 'rgba(255, 255, 102, 0.6)'; // Pastel yellow
    return 'rgba(255, 102, 102, 0.6)'; // Pastel red
};


const MoreDataTable = ({ moreData }) => {
    const [tableTitle, setTableTitle] = useState('');
    const [showDetails, setShowDetails] = useState(true);
    const [preparedData, setPreparedData] = useState([]);
    const [mgaSums, setMgaSums] = useState({});
    const [rgaSums, setRgaSums] = useState({});
    const [sortCategory, setSortCategory] = useState('MGA'); // New state for sorting

    const downloadExcel = () => {
        const wb = XLSX.utils.book_new(); // Create a new workbook

        // Assume preparedData is your table's data that you want to export
        // Mapping preparedData to an array of arrays for SheetJS
        const wsData = preparedData.map(item => ([
            item.mgaName, item.externalSets, item.externalShows, item.internalSets, item.internalShows, item.personalSets, item.personalShows, item.totalSet, item.totalShow, item.groupInvite, item.percentGroup, item.finalsSet, item.scheduled, item.finalsShow, item.ratio, item.nonPRHires, item.PRHires, item.totalHires, item.percentFinal, item.percentGroupInvite, item.setToHire, item.RGA, item.Legacy, item.Tree
            // Add other data points as necessary
        ]));

        // Add column headers at the start of wsData
        wsData.unshift(["MGA Name", "External Sets", "External Shows", "Internal Sets", "Internal Shows", "Personal Sets", "Personal Shows", "Total Set", "Total Show", "Group Invite", "Percent Group", "Finals Set", "Scheduled", "Finals Show", "Ratio", "Non-PR Hires", "PR Hires", "Total Hires", "Percent Final", "Percent Group Invite", "Set to Hire", "RGA", "Legacy", "Tree"  
            // Add other column headers as necessary
        ]);

        const ws = XLSX.utils.aoa_to_sheet(wsData); // Convert data to worksheet
        XLSX.utils.book_append_sheet(wb, ws, "MORE Data"); // Add worksheet to workbook

        // Define file name with date range or other dynamic content
        const fileName = `${tableTitle} MORE.xlsx`;

        XLSX.writeFile(wb, fileName); // Trigger file download
    };

    useEffect(() => {
        if (moreData && moreData.length > 0) {
            const dates = moreData.map(item => new Date(item[0]));

            // Find the minimum and maximum dates
            const minDate = new Date(Math.min(...dates));
            const maxDate = new Date(Math.max(...dates));
    
            // Function to format a date into "m/d/yy"
            const formatDate = (date) => {
                const day = date.getDate();
                const month = date.getMonth() + 1; // Months are zero-indexed
                const year = date.getFullYear() % 100; // Get the last two digits of the year
                return `${month}/${day}/${year < 10 ? '0' + year : year}`;
            };
    
            // Set the title based on the number of unique dates
            const uniqueDates = new Set(dates.map(date => date.toDateString())).size;
            const title = uniqueDates > 1 
                ? `${formatDate(minDate)}-${formatDate(maxDate)}`
                : formatDate(minDate);
    
            setTableTitle(title);
            // Prepare the data for display
            let prepared = moreData.map(item => ({
                mgaName: item.MGA, // MGA Name
                externalSets: parseInt(item.External_Sets, 10) || 0,
                externalShows: parseInt(item.External_Shows, 10) || 0,
                internalSets: parseInt(item.Internal_Sets, 10) || 0,
                internalShows: parseInt(item.Internal_Shows, 10) || 0,
                personalSets: parseInt(item.Personal_Sets, 10) || 0,
                personalShows: parseInt(item.Personal_Shows, 10) || 0,
                totalSet: parseInt(item.Total_Set, 10) || 0,
                totalShow: parseInt(item.Total_Show, 10) || 0,
                groupInvite: parseInt(item.Group_Invite, 10) || 0,
                percentGroup: parseFloat(item.Percent_Group) || 0.0,
                finalsSet: parseInt(item.Finals_Set, 10) || 0,
                scheduled: parseInt(item.Scheduled, 10) || 0,
                finalsShow: parseInt(item.Finals_Show, 10) || 0,
                ratio: parseFloat(item.Finals_Show_Ratio) || 0.0,
                nonPRHires: parseInt(item.Non_PR_Hires, 10) || 0,
                PRHires: parseInt(item.PR_Hires, 10) || 0,
                totalHires: parseInt(item.Total_Hires, 10) || 0,
                percentFinal: parseFloat(item.Percent_Final) || 0.0,
                percentGroupInvite: parseFloat(item.Percent_Group_Invite) || 0.0,
                setToHire: parseFloat(item.Set_To_Hire) || 0.0,
                RGA: item.RGA,
                Legacy: item.Legacy,
                Tree: item.Tree,
                Office: item.Office
            }));

            // Sort the prepared data based on the selected category
            prepared.sort((a, b) => {
                const sortKey = sortCategory;
                if (a[sortKey] < b[sortKey]) return -1;
                if (a[sortKey] > b[sortKey]) return 1;
                return 0;
            });


            // Group data by selected category and MGA name, then calculate sums
            const groupedData = {};
            prepared.forEach(item => {
                const key = `${item[sortCategory]}_${item.mgaName}`;
                if (!groupedData[key]) {
                    groupedData[key] = { ...item, key: item[sortCategory] }; // Initialize with the first item data
                } else {
                    // Aggregate data
                    groupedData[key].externalSets += item.externalSets;
                    groupedData[key].externalShows += item.externalShows;
                    groupedData[key].internalSets += item.internalSets;
                    groupedData[key].internalShows += item.internalShows;
                    groupedData[key].personalSets += item.personalSets;
                    groupedData[key].personalShows += item.personalShows;
                    groupedData[key].totalSet += item.totalSet;
                    groupedData[key].totalShow += item.totalShow;
                    groupedData[key].groupInvite += item.groupInvite;
                    // For percentages, we will calculate them later based on totals
                    groupedData[key].finalsSet += item.finalsSet;
                    groupedData[key].scheduled += item.scheduled;
                    groupedData[key].finalsShow += item.finalsShow;
                    groupedData[key].nonPRHires += item.nonPRHires;
                    groupedData[key].PRHires += item.PRHires;
                    groupedData[key].totalHires += item.totalHires;
                    // For ratios, we will calculate them later based on totals
                }
            });

            const preparedArray = Object.values(groupedData); // Convert grouped data into an array
            setPreparedData(preparedArray);
        }
    }, [moreData, sortCategory]);  // Dependency on moreData to refresh when it changes
    const totals = preparedData.reduce((acc, curr) => {
        // Loop over each key in the accumulator (initial totals)
        Object.keys(acc).forEach(key => {
            // Increase each total by the current item's corresponding value
            // Use '|| 0' to avoid NaN errors when data is missing
            acc[key] += curr[key] || 0;
        });
        return acc;
    }, {
        // Initialize totals for all data points you are summing
        externalSets: 0, externalShows: 0, internalSets: 0, internalShows: 0,
        personalSets: 0, personalShows: 0, totalSet: 0, totalShow: 0,
        groupInvite: 0, finalsSet: 0, scheduled: 0, finalsShow: 0,
        nonPRHires: 0, PRHires: 0, totalHires: 0
    });
    return (
        <>
        <div className="more-header-container">
            <div>
                Sort by: 
                <select value={sortCategory} onChange={(e) => setSortCategory(e.target.value)}>
                    <option value="MGA">MGA</option>
                    <option value="RGA">RGA</option>
                    <option value="Tree">Tree</option>
                    <option value="Office">Office</option>
                </select>
            </div>
            <button className='detailsToggleBtn' onClick={downloadExcel} title="Download as XLSX">
                    <FontAwesomeIcon icon={faDownload} />
                </button>
        </div>
        <div className="more-data-container">


            <table className="morebonus-table">
                <thead className="more-table-thead">
                    <tr>
                        <th>
                            <button
                                onClick={() => setShowDetails(!showDetails)}
                                className="detailsToggleBtn"
                            >
                                {showDetails ? "Hide Details" : "Show Details"}
                            </button>
                        </th>
                        {showDetails && <>
                            <th style={{ backgroundColor: '#00548c' }} colSpan="3" className="more-table-header">Vendor Data</th>
                            <th style={{ backgroundColor: '#ED722F' }} colSpan="3" className="more-table-header">Resume Data</th>
                            <th style={{ backgroundColor: '#B25271' }} colSpan="3" className="more-table-header">Personal Data</th>

                        </>}

                        <th colSpan="9" className="more-table-header" >Show & Invite Data</th>

                        <th style={{ backgroundColor: '#474c55' }} colSpan="6" className="more-table-header" >Hiring Data</th>
                    </tr>
                    <tr>
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >MGA Name</th>
                        {showDetails && <>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">Sets</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">Shows</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">%</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">Sets</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">Shows</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">%</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">Sets</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">Shows</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">%</th>

                        </>}
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Total Set</th>
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Total Show</th>
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Total Show</th>
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Group Invite</th>
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Percent Group</th>
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Finals Set</th>
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Scheduled</th>
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Finals Show</th>
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Ratio</th>
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Non-PR Hires</th>
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >PR Hires</th>
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Total Hires</th>
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >% Final</th>
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >% Group Invite</th>
                        <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Set to Hire</th>
                    </tr>
                </thead>
<tbody>
    {Object.entries(preparedData.reduce((acc, curr) => {
        // Group by the selected category (e.g., MGA, RGA, etc.)
        const key = curr[sortCategory];
        if (!acc[key]) {
            acc[key] = {};
        }
        const mgaKey = curr.mgaName;
        if (!acc[key][mgaKey]) {
            acc[key][mgaKey] = { ...curr, count: 1 }; // Initialize with the current item
        } else {
            // Aggregate data for items with the same MGA under the current category
            acc[key][mgaKey].externalSets += curr.externalSets;
            acc[key][mgaKey].externalShows += curr.externalShows;
            acc[key][mgaKey].internalSets += curr.internalSets;
            acc[key][mgaKey].internalShows += curr.internalShows;
            acc[key][mgaKey].personalSets += curr.personalSets;
            acc[key][mgaKey].personalShows += curr.personalShows;
            acc[key][mgaKey].totalSet += curr.totalSet;
            acc[key][mgaKey].totalShow += curr.totalShow;
            acc[key][mgaKey].groupInvite += curr.groupInvite;
            acc[key][mgaKey].finalsSet += curr.finalsSet;
            acc[key][mgaKey].scheduled += curr.scheduled;
            acc[key][mgaKey].finalsShow += curr.finalsShow;
            acc[key][mgaKey].nonPRHires += curr.nonPRHires;
            acc[key][mgaKey].PRHires += curr.PRHires;
            acc[key][mgaKey].totalHires += curr.totalHires;
            acc[key][mgaKey].count++;
        }
        return acc;
    }, {})).map(([category, mgas]) => (
        <React.Fragment key={category}>
            {sortCategory !== 'MGA' && (
                <tr>
                    <th colSpan="1" className="more-table-header" style={{ backgroundColor: '#ED722F' }}>{category}</th>
                </tr>
            )}
            {Object.entries(mgas).map(([mgaName, item], index) => (
                <tr key={index}>
                    <th className="more-table-header more-table-first-col">{mgaName}</th>
                            {showDetails && <>
                                <td className="more-table-input-like">{item.externalSets}</td>
                                <td className="more-table-input-like">{item.externalShows}</td>
                                <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(item.externalShows / item.externalSets * 100) }}>{item.externalSets ? `${(item.externalShows / item.externalSets * 100).toFixed(2)}%` : '0%'}</td>
                                <td className="more-table-input-like">{item.internalSets}</td>
                                <td className="more-table-input-like">{item.internalShows}</td>
                                <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(item.internalShows / item.internalSets * 100) }}>{item.internalSets ? `${(item.internalShows / item.internalSets * 100).toFixed(2)}%` : '0%'}</td>
                                <td className="more-table-input-like">{item.personalSets}</td>
                                <td className="more-table-input-like">{item.personalShows}</td>
                                <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(item.personalShows / item.personalSets * 100) }}>{item.personalSets ? `${(item.personalShows / item.personalSets * 100).toFixed(2)}%` : '0%'}</td>
                            </>}
                            <td className="more-table-input-like">{item.totalSet}</td>
                            <td className="more-table-input-like">{item.totalShow}</td>
                            <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(item.totalShow / item.totalSet * 100) }}>{item.totalSet ? `${(item.totalShow / item.totalSet * 100).toFixed(2)}%` : '0%'}</td>
                            <td className="more-table-input-like">{item.groupInvite}</td>
                            <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((item.groupInvite / item.totalShow * 100)) }}>{item.totalShow ? `${(item.groupInvite / item.totalShow * 100).toFixed(2)}%` : '0%'}</td>
                            <td className="more-table-input-like">{item.finalsSet}</td>
                            <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((item.finalsSet / item.groupInvite * 100)) }}>{item.groupInvite ? `${(item.finalsSet / item.groupInvite * 100).toFixed(2)}%` : '0%'}</td>
                            <td className="more-table-input-like">{item.finalsShow}</td>
                            <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((item.finalsShow / item.finalsSet * 100)) }}>{item.finalsSet ? `${(item.finalsShow / item.finalsSet * 100).toFixed(2)}%` : '0%'}</td>
                            <td className="more-table-input-like">{item.nonPRHires}</td>
                            <td className="more-table-input-like">{item.PRHires}</td>
                            <td className="more-table-input-like">{item.totalHires}</td>
                            <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((item.totalHires / item.finalsShow * 100)) }}>{item.finalsShow ? `${(item.totalHires / item.finalsShow * 100).toFixed(2)}%` : '0%'}</td>
                            <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((item.totalHires / item.groupInvite * 100)) }}>{item.groupInvite ? `${(item.totalHires / item.groupInvite * 100).toFixed(2)}%` : '0%'}</td>
                            <td className="more-table-input-like" style={{ backgroundColor: getColorForSetToHire(parseInt(item.setToHire)) }}>{item.setToHire}</td>
                            </tr>
                            
            ))}
            
        </React.Fragment>
    ))}
<tr className="totals-row">
    <th className="more-table-header" style={{ backgroundColor: '#ED722F' }}>Totals</th>
    {showDetails && <>
        <td className="more-table-input-like">{totals.externalSets}</td>
        <td className="more-table-input-like">{totals.externalShows}</td>
        <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(totals.externalShows / totals.externalSets * 100) }}>{(totals.externalShows && totals.externalSets) ? `${(totals.externalShows / totals.externalSets * 100).toFixed(2)}%` : '0%'}</td>
        <td className="more-table-input-like">{totals.internalSets}</td>
        <td className="more-table-input-like">{totals.internalShows}</td>
        <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(totals.internalShows / totals.internalSets * 100) }}>{(totals.internalShows && totals.internalSets) ? `${(totals.internalShows / totals.internalSets * 100).toFixed(2)}%` : '0%'}</td>
        <td className="more-table-input-like">{totals.personalSets}</td>
        <td className="more-table-input-like">{totals.personalShows}</td>
        <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(totals.personalShows / totals.personalSets * 100) }}>{(totals.personalShows && totals.personalSets) ? `${(totals.personalShows / totals.personalSets * 100).toFixed(2)}%` : '0%'}</td>
    </>}
    <td className="more-table-input-like">{totals.totalSet}</td>
    <td className="more-table-input-like">{totals.totalShow}</td>
    <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(totals.totalShow / totals.totalSet * 100) }}>{(totals.totalShow && totals.totalSet) ? `${(totals.totalShow / totals.totalSet * 100).toFixed(2)}%` : '0%'}</td>
    <td className="more-table-input-like">{totals.groupInvite}</td>
    <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((totals.groupInvite / totals.totalShow * 100)) }}>{(totals.groupInvite && totals.totalShow) ? `${(totals.groupInvite / totals.totalShow * 100).toFixed(2)}%` : '0%'}</td>
    <td className="more-table-input-like">{totals.finalsSet}</td>
    <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((totals.finalsSet / totals.groupInvite * 100)) }}>{(totals.finalsSet && totals.groupInvite) ? `${(totals.finalsSet / totals.groupInvite * 100).toFixed(2)}%` : '0%'}</td>
    <td className="more-table-input-like">{totals.finalsShow}</td>
    <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire(totals.finalsShow / totals.finalsSet * 100) }}>{(totals.finalsShow && totals.finalsSet) ? `${(totals.finalsShow / totals.finalsSet * 100).toFixed(2)}%` : '0%'}</td>
    <td className="more-table-input-like">{totals.nonPRHires}</td>
    <td className="more-table-input-like">{totals.PRHires}</td>
    <td className="more-table-input-like">{totals.totalHires}</td>
    <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((totals.totalHires / totals.finalsShow * 100)) }}>{(totals.totalHires && totals.finalsShow) ? `${(totals.totalHires / totals.finalsShow * 100).toFixed(2)}%` : '0%'}</td>
    <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((totals.totalHires / totals.groupInvite * 100)) }}>{(totals.totalHires && totals.groupInvite) ? `${(totals.totalHires / totals.groupInvite * 100).toFixed(2)}%` : '0%'}</td>
    <td className="more-table-input-like" style={{ backgroundColor: getColorForSetToHire(totals.setToHire) }}>{(totals.setToHire) ? totals.setToHire.toFixed(2) : '0'}</td>
</tr>

</tbody>
            </table>
        </div>
        </>
    );


};

export default MoreDataTable;
