import React, { useState, useEffect } from 'react';
import '../../Compliance.css';
function Compliance() {
    const [data, setData] = useState([]);
    const [mgaData, setMGAData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [selectedMGA, setSelectedMGA] = useState('');
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [sendingEmails, setSendingEmails] = useState(false);

    useEffect(() => {
        const fetchData = async (url) => {
            try {
                const response = await fetch(url);
                if (!response.ok) throw new Error(`Network response was not ok from ${url}`);
                const data = await response.json();
                return data;
            } catch (err) {
                throw new Error('Failed to fetch data: ' + err.message);
            }
        };

        Promise.all([
            fetchData('https://ariaslogin-4a95935f6093.herokuapp.com/api/compliance-data'),
            fetchData('https://ariaslogin-4a95935f6093.herokuapp.com/api/aob-updates'),
            fetchData('https://ariaslogin-4a95935f6093.herokuapp.com/api/all-mga-rga')
        ])
            .then(([complianceData, aobUpdates, allMGAData]) => {
                console.log(allMGAData);
                setData(aobUpdates.filter(item => !/\d/.test(item.Agent) && !/\d/.test(item.MGA)));
                setMGAData(allMGAData);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setError(error.toString());
                setLoading(false);
            });
    }, []);

    const handleSelectAgent = (agent) => {
        setSelectedAgents(prev => {
            if (prev.some(a => a.Agent === agent.Agent)) {
                return prev.filter(a => a.Agent !== agent.Agent);
            } else {
                return [...prev, agent];
            }
        });
    };

    const handleSendEmails = () => {
        if (sendingEmails || selectedAgents.length === 0) return;
        setSendingEmails(true);

        const emailsToSend = selectedAgents.map(agent => {
            const mgaDetails = mgaData.find(mga => mga.AOBName === agent.MGA) || {};
            return {
                email: agent.EmailAddress, // Email of the Agent to send to
                cc: mgaDetails.Email, // Email of the MGA to CC
                name: agent.MGA,
                agentName: agent.Agent
            };
        });
        console.log('Sending the following data:', emailsToSend);

        fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/send-compliance-requests', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(emailsToSend)
        })
            .then(response => response.json())
            .then(() => {
                alert('Emails sent successfully!');
                setSelectedAgents([]); // Clear selected agents after sending
            })
            .catch(error => {
                console.error('Failed to send emails:', error);
                alert(`Failed to send emails: ${error.message || error.toString()}`);
            })
            .finally(() => {
                setSendingEmails(false);
            });
    };

    const mgaOptions = [...new Set(data.map(item => item.MGA || ''))];

    const getFilteredData = () => {
        return data.filter(item => {
            const agent = item.Agent || '';
            const mga = item.MGA || '';
            return (
                (mga === selectedMGA || selectedMGA === '') &&
                (agent.toLowerCase().includes(filterText.toLowerCase()) ||
                    mga.toLowerCase().includes(filterText.toLowerCase()))
            );
        });
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className='container'>
            <div className="search-container">
                <select
                    value={selectedMGA}
                    onChange={(e) => setSelectedMGA(e.target.value)}
                    style={{ width: '20%' }}
                >
                    <option value="">All MGAs</option>
                    {mgaOptions.map((mga, index) => (
                        <option key={index} value={mga}>{mga}</option>
                    ))}
                </select>
                <input
                    type="text"
                    placeholder="Search..."
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    style={{ width: '75%' }}
                />
            </div>

            {getFilteredData().length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th className='top-table-header'>MGA</th>
                            <th className='top-table-header'>Agent Name</th>
                            <th className='top-table-header'>State/Province</th>
                            <th>
                                <button
                                    className='send-email-button'
                                    onClick={handleSendEmails}
                                    disabled={sendingEmails || selectedAgents.length === 0}
                                >
                                    {sendingEmails ? 'Sending...' : (selectedAgents.length === 1 ? 'Send Email' : 'Send Emails')}
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {getFilteredData().map((agent, index) => (
                            <tr key={index}>
                                <td className='compliance-table-input-like'>{agent.MGA}</td>
                                <td className='compliance-table-input-like'>{agent.Agent}</td>
                                <td className='compliance-table-input-like'>{agent.STPROV}</td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedAgents.includes(agent)}
                                        onChange={() => handleSelectAgent(agent)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No matching records found.</p>
            )}
        </div>
    );
}

export default Compliance;
