import React, { useEffect, useState } from 'react';
import './PersonalGoals.css'; // Import the CSS file
import { Button, Modal, Form, Spinner, ProgressBar, Accordion, Card } from 'react-bootstrap'; // Correct import for ProgressBar
import axios from 'axios';
const PersonalGoals = () => {
  const [calendar, setCalendar] = useState(generateInitialCalendar());
  const [selectedDay, setSelectedDay] = useState(null); // State to track the selected day
  const [alpGoal, setAlpGoal] = useState(null); // State to track the ALP goal
  const [error, setError] = useState(null);
  const [remainingMetrics, setRemainingMetrics] = useState({
    remainingAlp: 0,
    remainingCalls: 0,
    remainingAppts: 0,
    remainingSits: 0,
    remainingSales: 0,
    remainingRefs: 0
  });
  const [showAlpGoalModal, setShowAlpGoalModal] = useState(false); // State to control the ALP goal prompt modal
  const [editMode, setEditMode] = useState(false); // State to toggle edit mode
const [loading, setLoading] = useState(false); // State to track loading status
const [success, setSuccess] = useState(false); // State to track success status
const [totalAlp, setTotalAlp] = useState(0); // Add this state
const [selectedMetrics, setSelectedMetrics] = useState('standard');

const handleEditToggle = () => {
  if (editMode) {
    setLoading(true);
    handleSubmit()
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => setSuccess(false), 3000); // Remove success indicator after 3 seconds
      })
      .catch(() => {
        setLoading(false);
      });
  }
  setEditMode(!editMode);
};
  
const [customOption, setCustomOption] = useState('agency');
const [agencyStats, setAgencyStats] = useState({
  callsToContact: 0,
  showRatio: 0,
  closeRatio: 0,
  alpPerSale: 0
});
const [myStats, setMyStats] = useState({
  callsToContact: 0,
  showRatio: 0,
  closeRatio: 0,
  alpPerSale: 0
});

useEffect(() => {
  const fetchAverages = async () => {
    try {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo) {
        const agent = userInfo.lagnname;

        // Fetch all daily activities
        const activityResponse = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/getAllDailyActivity');
        const activities = activityResponse.data.data;

        // Calculate agency averages
        const agencyStats = calculateAverages(activities);
        setAgencyStats(agencyStats);

        // Calculate agent-specific averages
        const agentActivities = activities.filter(item => item.agent === agent);
        const agentStats = calculateAverages(agentActivities);
        setMyStats(agentStats);
      }
    } catch (error) {
      console.error('Error fetching averages:', error);
    }
  };

  fetchAverages();
}, []);

const calculateAverages = (activities) => {
  // Calculate averages based on the provided activities
  let totalCalls = 0, totalAppts = 0, totalSits = 0, totalSales = 0, totalAlp = 0;
  activities.forEach(item => {
    totalCalls += item.calls || 0;
    totalAppts += item.appts || 0;
    totalSits += item.sits || 0;
    totalSales += item.sales || 0;
    totalAlp += item.alp || 0;
  });

  const callsToContact = (totalCalls / totalAppts).toFixed(2);
  const showRatio = ((totalSits / totalAppts) * 100).toFixed(2);
  const closeRatio = ((totalSales / totalSits) * 100).toFixed(2);
  const alpPerSale = totalSales > 0 ? (totalAlp / totalSales).toFixed(2) : 0;

  return { callsToContact, showRatio, closeRatio, alpPerSale };
};

  
useEffect(() => {
  const fetchData = async () => {
    try {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo) {
        const agent = userInfo.lagnname;
        const esid = userInfo.esid;
        const currentMonth = new Date().toLocaleDateString('en-CA').slice(0, 7);

        // Fetch activities data
        const activityResponse = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/getAllDailyActivity');
        const activities = activityResponse.data.data;

        if (!Array.isArray(activities)) {
          throw new Error('Fetched data is not an array');
        }

        const updatedCalendar = { ...generateInitialCalendar() };

        activities.forEach(item => {
          if (item.reportDate.startsWith(currentMonth) && item.agent === agent) {
            const date = item.reportDate;
            updatedCalendar[date].calls += item.calls || 0;
            updatedCalendar[date].appts += item.appts || 0;
            updatedCalendar[date].sits += item.sits || 0;
            updatedCalendar[date].sales += item.sales || 0;
            updatedCalendar[date].alp += item.alp || 0;
            updatedCalendar[date].refs += item.refs || 0;
          }
        });

        // Fetch personal goal
        const goalResponse = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/getPersonalGoal', {
          params: { agent, esid, month: currentMonth }
        });
        if (goalResponse.data.success) {
          setAlpGoal(goalResponse.data.data.alpgoal);
          setSelectedMetrics(goalResponse.data.data.metricsType || 'standard'); // Set the metrics type
          calculateRemainingMetrics(updatedCalendar, goalResponse.data.data.alpgoal);

          // Reset all dates to non-working
          Object.keys(updatedCalendar).forEach(date => {
            updatedCalendar[date].isWorkingDay = false;
          });

          // Update calendar with the daysincluded from the response
          const daysIncluded = goalResponse.data.data.daysincluded.split(',');
          if (daysIncluded.length > 0 && daysIncluded[0] !== '') {
            daysIncluded.forEach(date => {
              if (updatedCalendar[date]) {
                updatedCalendar[date].isWorkingDay = true;
              }
            });
          } else {
            // If no daysincluded exist, default to all days in the current month
            Object.keys(updatedCalendar).forEach(date => {
              if (new Date(date).getMonth() === new Date().getMonth()) {
                updatedCalendar[date].isWorkingDay = true;
              }
            });
          }
        } else {
          // If no goal exists, default to all days in the current month as working days
          Object.keys(updatedCalendar).forEach(date => {
            if (new Date(date).getMonth() === new Date().getMonth()) {
              updatedCalendar[date].isWorkingDay = true;
            }
          });
          setShowAlpGoalModal(true); // Show the ALP goal prompt modal
        }

        setCalendar(updatedCalendar);
      }
    } catch (error) {
      setError(error.message);
      console.error('Error fetching or processing data:', error);
    }
  };

  fetchData();
}, []);

useEffect(() => {
  calculateRemainingMetrics(calendar, alpGoal);
}, [selectedMetrics]); // Recalculate metrics when selectedMetrics changes

  const handleDayClick = (date) => {
    setSelectedDay({ ...calendar[date], formattedDate: formatDate(date) });
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const handleCloseModal = () => {
    setSelectedDay(null);
  };

  const getCurrentWeek = () => {
    const today = new Date();

    const dayOfWeek = today.getDay();
    const difference = dayOfWeek === 0 ? 6 : dayOfWeek - 0; // Calculate difference to get to Monday
    const startOfWeek = new Date(today.setDate(today.getDate() - difference));
    startOfWeek.setHours(0, 0, 0, 0); // Set to start of the day

    return startOfWeek;
  };

  const isCurrentWeek = (date) => {
    const currentWeekStart = getCurrentWeek();
    const nextWeekStart = new Date(currentWeekStart);
    nextWeekStart.setDate(nextWeekStart.getDate() + 7);

    const currentDate = new Date(date);
    return currentDate >= currentWeekStart && currentDate < nextWeekStart;
  };

  const today = new Date();
  const todayDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`; // Use local date string in ISO format (yyyy-mm-dd)

  const handleAlpGoalChange = (e) => {
    const newAlpGoal = parseFloat(e.target.value);
    setAlpGoal(newAlpGoal);
    calculateRemainingMetrics(calendar, newAlpGoal);
  };

  const handleWorkingDayChange = (date) => {
    const updatedCalendar = { ...calendar };
    updatedCalendar[date].isWorkingDay = !updatedCalendar[date].isWorkingDay;
    setCalendar(updatedCalendar);
    calculateRemainingMetrics(updatedCalendar, alpGoal);
    console.log(`Date: ${date}, isWorkingDay: ${updatedCalendar[date].isWorkingDay}`);
    console.log(`Number of working days checked: ${countWorkingDays(updatedCalendar)}`);
  };

  const handleMetricsChange = (metrics, option = null) => {
    if (metrics === 'custom' && option) {
      setCustomOption(option);
      setSelectedMetrics('custom');
    } else {
      setSelectedMetrics(metrics === selectedMetrics ? null : metrics);
    }
  };
  
  
  const calculateRemainingMetrics = (calendarData, goal = alpGoal) => {
    let totalAlp = 0, totalCalls = 0, totalAppts = 0, totalSits = 0, totalSales = 0, totalRefs = 0;
  
    Object.keys(calendarData).forEach(date => {
      if (calendarData[date] && new Date(date).getMonth() === new Date().getMonth()) { // Only consider days in the current month
        totalAlp += calendarData[date].alp;
        totalCalls += calendarData[date].calls;
        totalAppts += calendarData[date].appts;
        totalSits += calendarData[date].sits;
        totalSales += calendarData[date].sales;
        totalRefs += calendarData[date].refs;
      }
    });
  
    setTotalAlp(totalAlp); // Update totalAlp state
  
    const remainingAlp = goal - totalAlp;
  
    let metrics;
    switch (selectedMetrics) {
      case 'pessimistic':
        metrics = { callsToContact: 50, showRatio: 0.15, closeRatio: 0.20, alpPerSale: 700 };
        break;
      case 'standard':
        metrics = { callsToContact: 35, showRatio: 0.30, closeRatio: 0.33, alpPerSale: 1000 };
        break;
      case 'optimistic':
        metrics = { callsToContact: 25, showRatio: 0.40, closeRatio: 0.45, alpPerSale: 1250 };
        break;
      case 'custom':
        metrics = customOption === 'agency' ? agencyStats : myStats;
        break;
      default:
        metrics = { callsToContact: 35, showRatio: 0.30, closeRatio: 0.33, alpPerSale: 1000 };
    }
  
    const remainingSales = Math.ceil(remainingAlp / metrics.alpPerSale);
    const remainingSits = Math.ceil(remainingSales / (metrics.closeRatio));
    const remainingAppts = Math.ceil(remainingSits / (metrics.showRatio));
    const remainingCalls = Math.ceil(remainingAppts * metrics.callsToContact);
  
    const daysRemaining = getWorkingDaysInMonth(calendarData);
    const dailyCalls = Math.ceil(remainingCalls / daysRemaining);
    const dailyAppts = Math.ceil(remainingAppts / daysRemaining);
    const dailySits = Math.ceil(remainingSits / daysRemaining);
    const dailySales = Math.ceil(remainingSales / daysRemaining);
    const dailyAlp = Math.ceil(remainingAlp / daysRemaining);
  
    setRemainingMetrics({
      remainingAlp,
      remainingCalls,
      remainingAppts,
      remainingSits,
      remainingSales,
      remainingRefs: 0 // Assuming no calculation for remaining refs in this context
    });
  
    // Update calendar with daily required metrics
    const updatedCalendar = { ...calendarData };
    Object.keys(updatedCalendar).forEach(date => {
      if (new Date(date) >= new Date(todayDate) && updatedCalendar[date] && updatedCalendar[date].isWorkingDay && new Date(date).getMonth() === new Date().getMonth()) {
        updatedCalendar[date].calls = dailyCalls;
        updatedCalendar[date].appts = dailyAppts;
        updatedCalendar[date].sits = dailySits;
        updatedCalendar[date].sales = dailySales;
        updatedCalendar[date].alp = dailyAlp;
      }
    });
  
    setCalendar(updatedCalendar);
  };
  
  
  const getWorkingDaysInMonth = (calendarData) => {
    return Object.keys(calendarData).filter(date => 
      calendarData[date] && calendarData[date].isWorkingDay && new Date(date).getMonth() === new Date().getMonth()
    ).length;
  };
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };
  const handleSubmit = async () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const agent = userInfo.lagnname;
    const esid = userInfo.esid;
    const currentMonth = new Date().toLocaleDateString('en-CA').slice(0, 7);
  
    // Gather the selected working days
    const daysincluded = Object.keys(calendar)
      .filter(date => calendar[date].isWorkingDay)
      .join(',');
  
    try {
      const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/upsertPersonalGoal', {
        month: currentMonth,
        workdays: getWorkingDaysInMonth(calendar),
        alpgoal: alpGoal,
        agent,
        esid,
        agtnum: '', // Set agtnum if you have it, otherwise leave it empty
        daysincluded,
        metricsType: selectedMetrics // Include metrics type
      });
  
      if (response.data.success) {
        setShowAlpGoalModal(false); // Close the ALP goal prompt modal
      } else {
        alert('Error submitting ALP goal: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error submitting ALP goal:', error);
      alert('Error submitting ALP goal');
    }
  };
  
  const handleAlpGoalSubmit = () => {
    handleSubmit();
    setShowAlpGoalModal(false);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const countWorkingDays = (calendarData) => {
    return Object.keys(calendarData).filter(date => 
      calendarData[date] && calendarData[date].isWorkingDay && new Date(date).getMonth() === today.getMonth()
    ).length;
  };

  return (
    <div>
      <div>
      <div className="alp-goal-container">
  <ProgressBar now={(totalAlp / alpGoal) * 100} />
  {editMode ? (
    <div className="alp-goal-text">
      {formatCurrency(totalAlp)} / 
      <input
        type="number"
        id="alpGoal"
        value={alpGoal}
        onChange={handleAlpGoalChange}
        placeholder="Enter ALP Goal"
        className="alp-goal-input"
      /> ALP
    </div>
  ) : (
    <div className="alp-goal-text">
      {formatCurrency(totalAlp)} / {formatCurrency(alpGoal)} ALP
    </div>
  )}
  <button onClick={handleEditToggle} className="edit-button" title='Edit Goal/Dates'>
    {editMode ? 'Save' : ''}
    {loading && <Spinner as="span" animation="border" size="sm" />}
    {success && <span className="success-indicator">✔</span>}
  </button>
</div>
{editMode && (
  <div className="stats-container">
    <div className="d-flex flex-wrap justify-content-around">
      <Card
        className={`stats-card custom-card ${selectedMetrics === 'custom' ? 'selected-card custom' : selectedMetrics ? 'dimmer-expanded' : 'dimmer-collapsed'}`}
        onClick={() => handleMetricsChange('custom')}
      >
        <Card.Body className="custom-card-body">
          <h4>Custom</h4>
          {customOption && (
            <div>
              {customOption === 'agency' ? (
                <div>
                  <p>Calls to Appt: {agencyStats.callsToContact}</p>
                  <p>Show Ratio: {agencyStats.showRatio}%</p>
                  <p>Close Ratio: {agencyStats.closeRatio}%</p>
                  <p>ALP per Sale: {formatCurrency(agencyStats.alpPerSale)}</p>
                </div>
              ) : (
                <div>
                  <p>Calls to Appt: {myStats.callsToContact}</p>
                  <p>Show Ratio: {myStats.showRatio}%</p>
                  <p>Close Ratio: {myStats.closeRatio}%</p>
                  <p>ALP per Sale: {formatCurrency(myStats.alpPerSale)}</p>
                </div>
              )}
            </div>
          )}
          <div className="custom-options">
            <button
              className={customOption === 'agency' ? 'selected' : ''}
              onClick={(e) => { e.stopPropagation(); setCustomOption('agency'); }}
            >
              Agency
            </button>
            <button
              className={customOption === 'my' ? 'selected' : ''}
              onClick={(e) => { e.stopPropagation(); setCustomOption('my'); }}
            >
              Personal
            </button>
          </div>
        </Card.Body>
      </Card>
      <Card
        className={`stats-card custom-card ${selectedMetrics === 'pessimistic' ? 'selected-card pessimistic' : selectedMetrics ? 'dimmer-expanded' : 'dimmer-collapsed'}`}
        onClick={() => handleMetricsChange('pessimistic')}
      >
        <Card.Body className="custom-card-body">
          <h4>Pessimistic</h4>
          <p>Calls to Appt: 50</p>
          <p>Show Ratio: 15%</p>
          <p>Close Ratio: 20%</p>
          <p>ALP per Sale: $700</p>
        </Card.Body>
      </Card>
      <Card
        className={`stats-card custom-card ${selectedMetrics === 'standard' ? 'selected-card standard' : selectedMetrics ? 'dimmer-expanded' : 'dimmer-collapsed'}`}
        onClick={() => handleMetricsChange('standard')}
      >
        <Card.Body className="custom-card-body">
          <h4>Standard</h4>
          <p>Calls to Appt: 35</p>
          <p>Show Ratio: 30%</p>
          <p>Close Ratio: 33%</p>
          <p>ALP per Sale: $1,000</p>
        </Card.Body>
      </Card>
      <Card
        className={`stats-card custom-card ${selectedMetrics === 'optimistic' ? 'selected-card optimistic' : selectedMetrics ? 'dimmer-expanded' : 'dimmer-collapsed'}`}
        onClick={() => handleMetricsChange('optimistic')}
      >
        <Card.Body className="custom-card-body">
          <h4>Optimistic</h4>
          <p>Calls to Appt: 25</p>
          <p>Show Ratio: 40%</p>
          <p>Close Ratio: 45%</p>
          <p>ALP per Sale: $1,250</p>
        </Card.Body>
      </Card>
    </div>

    <div>
      <h2>Remaining Metrics to Hit Your ALP Goal</h2>
      <table className="hierarchyTable">
        <tbody>
          <tr>
            <td>Remaining ALP</td>
            <td>{remainingMetrics.remainingAlp}</td>
          </tr>
          <tr>
            <td>Remaining Calls</td>
            <td>{remainingMetrics.remainingCalls}</td>
          </tr>
          <tr>
            <td>Remaining Appts</td>
            <td>{remainingMetrics.remainingAppts}</td>
          </tr>
          <tr>
            <td>Remaining Sits</td>
            <td>{remainingMetrics.remainingSits}</td>
          </tr>
          <tr>
            <td>Remaining Sales</td>
            <td>{remainingMetrics.remainingSales}</td>
          </tr>
          <tr>
            <td>Remaining Refs</td>
            <td>{remainingMetrics.remainingRefs}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)}

</div>
      <div className="calendar">
        <div className="calendar-header">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div key={day} className="calendar-header-day">{day}</div>
          ))}
        </div>
        <div className="calendar-body">
  {Object.keys(calendar).map((date, index) => {
    if (calendar[date] === null) {
      return <div key={index} className="calendar-day empty-day"></div>;
    }

    const day = new Date(date).getUTCDate(); // Use getUTCDate to avoid timezone issues
    const isToday = new Date(date).toISOString().slice(0, 10) === new Date().toISOString().slice(0, 10);
    const isThisWeek = isCurrentWeek(date);
    const isPast = new Date(date) < new Date(todayDate);
    const isFuture = new Date(date) > new Date(todayDate);
    const isDifferentMonth = new Date(date).getMonth() !== today.getMonth();

    return (
      <div key={date} className={`calendar-day ${isThisWeek ? 'highlight-week' : ''} ${!calendar[date].isWorkingDay && !editMode ? 'greyed-out' : ''}`} onClick={() => handleDayClick(date)}>
        <div className={`calendar-day-header ${isThisWeek ? 'highlight-week-header' : ''}`} onClick={(e) => e.stopPropagation()}>
          {editMode && (
            <input 
              type="checkbox" 
              checked={calendar[date].isWorkingDay} 
              onChange={() => handleWorkingDayChange(date)} 
              onClick={(e) => e.stopPropagation()} // Prevent checkbox click from triggering the modal
            />
          )}
          <span className={`calendar-day-date ${isToday ? 'highlight-today' : ''}`}>{day}</span>
        </div>
        {isPast ? (
          <div className="alp-only">
            <p>${calendar[date].alp}</p>
          </div>
        ) : (
          <div className="full-details">
            <table className='hierarchyTable'>
              <tbody>
                <tr>
                  <td>Calls</td>
                  <td>{calendar[date].calls}</td>
                </tr>
                <tr>
                  <td>Appts</td>
                  <td>{calendar[date].appts}</td>
                </tr>
                <tr>
                  <td>Sits</td>
                  <td>{calendar[date].sits}</td>
                </tr>
                <tr>
                  <td>Sales</td>
                  <td>{calendar[date].sales}</td>
                </tr>
                <tr>
                  <td>ALP</td>
                  <td>{calendar[date].alp}</td>
                </tr>
                <tr>
                  <td>Refs</td>
                  <td>{calendar[date].refs}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  })}
</div>

      </div>
      {selectedDay && (
  <Modal show={selectedDay !== null} onHide={handleCloseModal} className="custom-modal">
    <Modal.Body>
      <h5>Activity for {selectedDay.formattedDate}</h5>
      <table className="hierarchyTable">
        <thead>
          <tr>
            <th>Calls</th>
            <th>Appts</th>
            <th>Sits</th>
            <th>Sales</th>
            <th>ALP</th>
            <th>Refs</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{selectedDay.calls}</td>
            <td>{selectedDay.appts}</td>
            <td>{selectedDay.sits}</td>
            <td>{selectedDay.sales}</td>
            <td>{selectedDay.alp}</td>
            <td>{selectedDay.refs}</td>
          </tr>
        </tbody>
      </table>
    </Modal.Body>
  </Modal>
)}


  
      {/* Modal for prompting ALP goal */}
      <Modal show={showAlpGoalModal} onHide={() => setShowAlpGoalModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Set Your ALP Goal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="alpGoalInput">
              <Form.Label>ALP Goal for the Month</Form.Label>
              <Form.Control
                type="number"
                value={alpGoal || ''}
                onChange={(e) => setAlpGoal(parseFloat(e.target.value))}
                placeholder="Enter ALP Goal"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAlpGoalModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAlpGoalSubmit}>
            Submit ALP Goal
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
  
};


const generateInitialCalendar = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();

  const calendar = {};
  // Fill in the empty days at the start of the month to align with Sunday
  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const prevMonth = month === 0 ? 11 : month - 1;
  const prevYear = month === 0 ? year - 1 : year;
  const daysInPrevMonth = new Date(prevYear, prevMonth + 1, 0).getDate();

  for (let i = firstDayOfMonth - 1; i >= 0; i--) {
    const date = new Date(prevYear, prevMonth, daysInPrevMonth - i).toISOString().slice(0, 10);
    calendar[date] = {
      date,
      calls: 0,
      appts: 0,
      sits: 0,
      sales: 0,
      alp: 0,
      refs: 0,
      isWorkingDay: false // Default to non-working days
    };
  }

  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(year, month, day).toISOString().slice(0, 10);
    calendar[date] = {
      date,
      calls: 0,
      appts: 0,
      sits: 0,
      sales: 0,
      alp: 0,
      refs: 0,
      isWorkingDay: true // Auto-select current month's days as working days
    };
  }

  // Fill in the empty days at the end of the month to complete the last week
  const lastDayOfMonth = new Date(year, month, daysInMonth).getDay();
  const emptyDaysEnd = 6 - lastDayOfMonth;
  const nextMonth = month + 1;
  const nextYear = nextMonth === 12 ? year + 1 : year;
  const nextMonthCorrected = nextMonth % 12;

  for (let i = 1; i <= emptyDaysEnd; i++) {
    const nextMonthDate = new Date(nextYear, nextMonthCorrected, i).toISOString().slice(0, 10);
    calendar[nextMonthDate] = {
      date: nextMonthDate,
      calls: 0,
      appts: 0,
      sits: 0,
      sales: 0,
      alp: 0,
      refs: 0,
      isWorkingDay: false // Default to non-working days
    };
  }

  return calendar;
};

export default PersonalGoals;
