import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';  // Make sure to install react-select
import '../../RGaGrowth.css';
import DetailModal
 from './DetailsModal';
const RGaGrowth = () => {
    const [allData, setAllData] = useState({});
    const [agentCounts, setAgentCounts] = useState({});
    const [managerCounts, setManagerCounts] = useState({});
    const [submittingCounts, setSubmittingCounts] = useState({});
    const [data, setData] = useState({});
    const [vipData, setVipData] = useState({});
    const [codeData, setCodeData] = useState({});
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [options, setOptions] = useState([]);
    const [selectedRga, setSelectedRga] = useState(null);
    const [rgaValues, setRgaValues] = useState([]);
    const [rgaOptions, setRgaOptions] = useState([]);
    const [rollups, setRollups] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedDetail, setSelectedDetail] = useState(null);
    
    useEffect(() => {
        setLoading(true);
        Promise.all([
            axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/net-by-mga-month'),
            axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/agent-count-by-mga-and-date'),
            axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/manager-count-by-mga-and-date'),
            axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/vip-by-mga-and-date'), // Fetch VIP data
            axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/code-by-mga-and-date'),
            axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/submitting-by-mga-and-date')
        ]).then(([netResponse, agentResponse, managerResponse, vipResponse, codeResponse, submittingResponse]) => {
            const netData = netResponse.data;
            const agentData = agentResponse.data;
            const managerData = managerResponse.data;
            const vipData = vipResponse.data;
            const codeData = codeResponse.data;
            const submittingData = submittingResponse.data;
console.log('Net Data:', netData);
            setAllData(netData);
            setAgentCounts(agentData);

            const defaultData = reformatData(netData, 'Total');
            setData(defaultData);
            const defaultAgentCounts = reformatAgentCounts(agentData, 'Total');
            setAgentCounts(defaultAgentCounts);
            const defaultManagerCounts = processData(managerData, 'Total');
            setManagerCounts(defaultManagerCounts);
            const defaultVipData = processVipData(vipData, 'Total');
            setVipData(defaultVipData);
            const defaultCodeData = processCodeData(codeData, 'Total');
            setCodeData(defaultCodeData);
            const defaultSubmittingData = processSubmittingData(submittingData, 'Total');
            setSubmittingCounts(defaultSubmittingData);


            const fetchedOptions = Object.keys(netData).map(key => ({
                value: key,
                label: key
            }));
            setOptions(fetchedOptions);

            setLoading(false);
        }).catch(err => {
            setError(err);
            setLoading(false);
        });
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch RGA data from your endpoint
                const response = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/all-mga-rga');
                const options = response.data.map(item => ({
                    value: item.RGA, // Use RGA as the value for the dropdown
                    label: item.Name, // Use the MGA's name for the label
                    rgaValue: item.RGAvalue, // Store the corresponding RGAvalue
                    rollups: item.Rollups, // Store the rollups for this MGA
                    rgaRollups: item.RGA_Rollups // Store the RGA rollups for this MGA
                }));
                setRgaOptions(options);
                console.log('RGA Options:', response.data);
            } catch (error) {
                console.error('Error fetching RGA data:', error);
            }
        };

        fetchData();
    }, []);



    //net alp RGAs
const processMultipleRgaData = (data, rgaKeys, rgaRollups) => {
    console.log("Received rgaRollups: ", rgaRollups);
    
    const parsedRgaRollups = parseRollups(rgaRollups);
    console.log("Parsed RGA Rollups: ", parsedRgaRollups);
    
    const processedKeys = new Set(); // Set to track processed keys
    const output = {
        lastYear: initMonthData(new Date().getFullYear() - 1),
        thisYear: initMonthData(new Date().getFullYear())
    };

    // Process each key from rgaKeys normally, ensuring they are not processed again if found in rollups
    rgaKeys.forEach(key => {
        if (data[key] && !processedKeys.has(key)) {
            console.log(`Processing key from RGA Keys (no date filter): ${key}`);
            processKeyData(key, data[key], output, new Date().getFullYear(), new Date().getFullYear() - 1);
            processedKeys.add(key); // Mark this key as processed
        }
    });

    // Process each key from rgaRollups with date range checks
    parsedRgaRollups.forEach(({ name, start, end }) => {
        if (!processedKeys.has(name) && data[name]) { // Check if key has not been processed and data exists
            console.log(`Processing key from RGA Rollups with date range: ${name}`);
            // Filter data by date range before processing
            Object.entries(data[name]).forEach(([date, value]) => {
                const normalizedDate = `${date.split('/')[1]}-${date.split('/')[0]}-01`;
                if (normalizedDate >= start && normalizedDate <= end) {
                    processKeyData(name, { [date]: value }, output, new Date().getFullYear(), new Date().getFullYear() - 1);
                }
            });
            processedKeys.add(name); // Mark this key as processed
        }
    });

    console.log('Processed RGA Data:', output);
    return output;
};

    
    function processKeyData(key, data, output, currentYear, lastYear) {
        Object.entries(data).forEach(([date, value]) => {
            const [month, year] = date.split('/');
            const monthIndex = parseInt(month) - 1;
            const dateObj = new Date(year, monthIndex);
            const monthName = dateObj.toLocaleString('default', { month: 'short' });
            const yearNum = parseInt(year);
    
            // Initialize if not already done
            if (!output[yearNum]) {
                output[yearNum] = {};
            }
            if (!output[yearNum][monthName]) {
                output[yearNum][monthName] = { total: 0, contributions: {} };
            }
    
            // Convert value to a float to ensure addition works correctly
            value = parseFloat(value);
    
            // Update totals and contributions
            output[yearNum][monthName].total += value;
            output[yearNum][monthName].contributions[key] = (output[yearNum][monthName].contributions[key] || 0) + value;
        });
    
        // Aggregate totals for thisYear and lastYear
        output.lastYear = {};
        output.thisYear = {};
        for (let year in output) {
            if (parseInt(year) === lastYear) {
                for (let month in output[year]) {
                    output.lastYear[month] = (output.lastYear[month] || 0) + output[year][month].total;
                }
            }
            if (parseInt(year) === currentYear) {
                for (let month in output[year]) {
                    output.thisYear[month] = (output.thisYear[month] || 0) + output[year][month].total;
                }
            }
        }
    }
    

const parseRollups = (rollupStr) => {
    if (!rollupStr) {
        return [];  // Return an empty array if the input string is empty or undefined
    }

    return rollupStr.split(', ').map(rollup => {
        const regex = /(\d{4}-\d{2}\/\d{4}-\d{2})$/;
        const datePart = rollup.match(regex)[0];
        const name = rollup.replace(regex, '').trim();
        const [start, end] = datePart.split('/');

        return {
            name,
            start: start + '-01',
            end: end + '-01'
        };
    });
};



//for agent count RGAs
const reformatAgentCountsRGAs = (agentData, rgaKeys) => {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    const output = {
        lastYear: initMonthData(lastYear),
        thisYear: initMonthData(currentYear)
    };

    rgaKeys.forEach(key => {
        if (agentData[key]) {
            Object.entries(agentData[key]).forEach(([date, count]) => {
                // Standardize date format from YYYY-MM (assumed format)
                const [year, month] = date.split('-');
                const monthIndex = parseInt(month, 10) - 1;
                const monthName = new Date(year, monthIndex).toLocaleString('default', { month: 'short' });

                if (parseInt(year) === currentYear) {
                    output.thisYear[monthName] = (output.thisYear[monthName] || 0) + count;
                } else if (parseInt(year) === lastYear) {
                    output.lastYear[monthName] = (output.lastYear[monthName] || 0) + count;
                }
            });
        } else {
            console.log(`No data for RGA key: ${key}`);
        }
    });

    return output;
};

//manager count RGAs
const processDataRGAs = (data, rgaKeys) => {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    const output = {
        lastYear: initMonthData(lastYear),
        thisYear: initMonthData(currentYear)
    };

    // Default to 'Total' if no keys are provided
    if (rgaKeys.length === 0) {
        rgaKeys = ['Total'];
    }

    rgaKeys.forEach(key => {
        if (data[key]) {
            Object.entries(data[key]).forEach(([date, details]) => {
                const [year, month] = date.split('-');
                const monthIndex = parseInt(month, 10) - 1;
                const monthName = new Date(year, monthIndex).toLocaleString('default', { month: 'short' });

                const yearNum = parseInt(year);
                if (!output.thisYear[monthName]) {
                    output.thisYear[monthName] = initMonthDetails();
                }
                if (!output.lastYear[monthName]) {
                    output.lastYear[monthName] = initMonthDetails();
                }

                const monthData = {
                    uniqueGAs: details.uniqueGAs || 0,
                    uniqueSAs: details.uniqueSAs || 0,
                    uniqueMGAs: details.uniqueMGAs || 0,
                    MGAs: details.MGAs || 'N/A',
                    GAs: details.GAs || 'N/A',
                    SAs: details.SAs || 'N/A',
                    uniqueLagnNames: details.uniqueLagnNames || 0,
                    LagnNames: details.LagnNames || 'N/A',
                    totalUniqueManagers: details.uniqueGAs + details.uniqueSAs + details.uniqueMGAs
                };

                if (yearNum === currentYear) {
                    output.thisYear[monthName] = aggregateMonthData(output.thisYear[monthName], monthData);
                } else if (yearNum === lastYear) {
                    output.lastYear[monthName] = aggregateMonthData(output.lastYear[monthName], monthData);
                }
            });
        } else {
            console.log(`No data for RGA key: ${key}, defaulting to Total data if available`);
            // Optionally, handle cases where even 'Total' data might be missing
        }
    });

    return output;
};

const initMonthDetails = () => ({
    uniqueGAs: 0,
    uniqueSAs: 0,
    uniqueMGAs: 0,
    MGAs: 'N/A',
    GAs: 'N/A',
    SAs: 'N/A',
    uniqueLagnNames: 0,
    LagnNames: 'N/A'
});
const aggregateMonthData = (existingData, newData) => {
    // Aggregate existing and new data
    let updatedData = {
        ...existingData,
        uniqueGAs: existingData.uniqueGAs + newData.uniqueGAs,
        uniqueSAs: existingData.uniqueSAs + newData.uniqueSAs,
        uniqueMGAs: existingData.uniqueMGAs + newData.uniqueMGAs,
        MGAs: newData.MGAs !== 'N/A' ? newData.MGAs : existingData.MGAs,
        GAs: newData.GAs !== 'N/A' ? newData.GAs : existingData.GAs,
        SAs: newData.SAs !== 'N/A' ? newData.SAs : existingData.SAs,
        uniqueLagnNames: existingData.uniqueLagnNames + newData.uniqueLagnNames,
        LagnNames: newData.LagnNames !== 'N/A' ? newData.LagnNames : existingData.LagnNames,
    };
    
    // Recalculate totalUniqueManagers with updated counts
    updatedData.totalUniqueManagers = updatedData.uniqueGAs + updatedData.uniqueSAs + updatedData.uniqueMGAs;

    return updatedData;
};



//for vip RGAs
const processVipDataRGAs = (vipData, rgaKeys) => {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    const output = {
        lastYear: initMonthData(lastYear),
        thisYear: initMonthData(currentYear)
    };

    rgaKeys.forEach(key => {
        if (vipData[key]) {
            Object.entries(vipData[key]).forEach(([date, count]) => {
                const [year, month] = date.split('-');
                const monthIndex = parseInt(month, 10) - 1;
                const monthName = new Date(year, monthIndex).toLocaleString('default', { month: 'short' });

                if (parseInt(year) === currentYear) {
                    output.thisYear[monthName] = (output.thisYear[monthName] || 0) + count;
                } else if (parseInt(year) === lastYear) {
                    output.lastYear[monthName] = (output.lastYear[monthName] || 0) + count;
                }
            });
        } else {
            console.log(`No VIP data for RGA key: ${key}`);
        }
    });

    return output;
};

//for code RGAs
const processCodeDataRGAs = (codeData, rgaKeys) => {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    const output = {
        lastYear: initMonthData(lastYear),
        thisYear: initMonthData(currentYear)
    };

    rgaKeys.forEach(key => {
        const monthsData = codeData[key] || {};
        Object.entries(monthsData).forEach(([date, count]) => {
            const [year, month] = date.split('-');
            const monthIndex = parseInt(month, 10) - 1;
            const monthName = new Date(year, monthIndex).toLocaleString('default', { month: 'short' });

            if (parseInt(year) === currentYear) {
                output.thisYear[monthName] = (output.thisYear[monthName] || 0) + count;
            } else if (parseInt(year) === lastYear) {
                output.lastYear[monthName] = (output.lastYear[monthName] || 0) + count;
            }
        });
    });

    return output;
};

//for submitting RGAs
const processSubmittingDataRGAs = (submittingData, rgaKeys) => {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    const output = {
        lastYear: initMonthData(lastYear),
        thisYear: initMonthData(currentYear)
    };

    rgaKeys.forEach(key => {
        const entries = submittingData[key] || {};
        Object.entries(entries).forEach(([date, count]) => {
            const [year, month] = date.split('-');
            const monthIndex = parseInt(month, 10) - 1;
            const monthName = new Date(year, monthIndex).toLocaleString('default', { month: 'short' });

            count = parseFloat(count); // Ensure count is a number (if it's not already)

            if (parseInt(year) === currentYear) {
                output.thisYear[monthName] = (output.thisYear[monthName] || 0) + count;
                output.thisYear[monthName] = parseFloat(output.thisYear[monthName].toFixed(2)); // Round to two decimal places
            } else if (parseInt(year) === lastYear) {
                output.lastYear[monthName] = (output.lastYear[monthName] || 0) + count;
                output.lastYear[monthName] = parseFloat(output.lastYear[monthName].toFixed(2)); // Round to two decimal places
            }
        });
    });

    return output;
};


const handleRgaChange = (selectedOption) => {
    if (selectedOption) {
        setSelectedRga(selectedOption);
        const rgaKeys = selectedOption.rgaValue ? selectedOption.rgaValue.split(',').map(key => key.trim()) : [];
        const rollups = selectedOption.rollups || '';
        const rgaRollups = selectedOption.rgaRollups || '';
        console.log('Selected rollups for RGA:', rollups, ' and RGA_Rollups:', rgaRollups);
        fetchDataForRga(rgaKeys, rollups, rgaRollups);
    } else {
        // Reset to 'Total' if nothing is selected
        setSelectedRga(null);
        fetchAllData('Total');
    }
};


const fetchDataForRga = (rgaKeys, rollups, rgaRollups) => {
    // Define the endpoints to fetch data from
    const urls = {
        netData: `https://ariaslogin-4a95935f6093.herokuapp.com/api/net-by-mga-month?keys=${rgaKeys.join(', ')}`,
        agentData: `https://ariaslogin-4a95935f6093.herokuapp.com/api/agent-count-by-mga-and-date?keys=${rgaKeys.join(', ')}`,
        managerData: `https://ariaslogin-4a95935f6093.herokuapp.com/api/manager-count-by-mga-and-date?keys=${rgaKeys.join(', ')}`,
        vipData: `https://ariaslogin-4a95935f6093.herokuapp.com/api/vip-by-mga-and-date?keys=${rgaKeys.join(', ')}`,
        codeData: `https://ariaslogin-4a95935f6093.herokuapp.com/api/code-by-mga-and-date?keys=${rgaKeys.join(', ')}`,
        submittingData: `https://ariaslogin-4a95935f6093.herokuapp.com/api/submitting-by-mga-and-date?keys=${rgaKeys.join(', ')}`

    };

    // Fetch data from multiple endpoints
    axios.all([
        axios.get(urls.netData),
        axios.get(urls.agentData),
        axios.get(urls.managerData),
        axios.get(urls.vipData),
        axios.get(urls.codeData),
        axios.get(urls.submittingData)
        // Add more axios.get calls for additional data as necessary
    ]).then(axios.spread((netResponse, agentResponse, managerData, vipData, codeData, submittingData) => {
        // Process each response with the appropriate function
        const netDataOutput = processMultipleRgaData(netResponse.data, rgaKeys, rgaRollups);
        const agentDataOutput = reformatAgentCountsRGAs(agentResponse.data, rgaKeys, rollups, rgaRollups);
        const managerDataOutput = processDataRGAs(managerData.data, rgaKeys, rollups, rgaRollups);
        const vipDataOutput = processVipDataRGAs(vipData.data, rgaKeys, rollups, rgaRollups);
        const codeDataOutput = processCodeDataRGAs(codeData.data, rgaKeys, rollups, rgaRollups);
        const submittingDataOutput = processSubmittingDataRGAs(submittingData.data, rgaKeys, rollups, rgaRollups);

        // Update state or context with processed data
        setData(netDataOutput);
        setAgentCounts(agentDataOutput);
        setManagerCounts(managerDataOutput);
        setVipData(vipDataOutput);
        setCodeData(codeDataOutput);
        setSubmittingCounts(submittingDataOutput);

        console.log('Fetched and Processed RGA Data:', {
            netDataOutput,
            agentDataOutput,
            managerDataOutput,
            vipDataOutput,
            codeDataOutput,
            submittingDataOutput

        });
    })).catch(error => {
        console.error("Failed to fetch RGA data", error);
        setError(error);
    });
};
    const formatCurrency = (value) => {
        return `$${Number(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    }

    //for net alp
    const reformatData = (rawData, personKey = 'Total') => {
        const output = {
            lastYear: {},
            thisYear: {}
        };
        const currentYear = new Date().getFullYear();
        const lastYear = currentYear - 1;

        Object.entries(rawData[personKey] || rawData).forEach(([date, total]) => {
            const [month, year] = date.split('/');
            const monthIndex = parseInt(month, 10) - 1;
            const monthName = new Date(year, monthIndex).toLocaleString('default', { month: 'short' });

            if (parseInt(year) === currentYear) {
                output.thisYear[monthName] = total;
            } else if (parseInt(year) === lastYear) {
                output.lastYear[monthName] = total;
            }
        });

        return output;
    };
    //for agent count
    const reformatAgentCounts = (agentData, personKey = 'Total') => {
        const output = { lastYear: {}, thisYear: {} };
        const currentYear = new Date().getFullYear();
        const lastYear = currentYear - 1;

        // Initialize the structure for months
        output.lastYear = initMonthData(lastYear);
        output.thisYear = initMonthData(currentYear);

        // If 'Total' is a specific key in your data, handle it directly:
        if (agentData.hasOwnProperty(personKey)) {
            Object.entries(agentData[personKey]).forEach(([date, count]) => {
                const [year, month] = date.split('-');
                const monthIndex = parseInt(month, 10) - 1;
                const monthName = new Date(year, monthIndex).toLocaleString('default', { month: 'short' });

                if (parseInt(year) === currentYear) {
                    output.thisYear[monthName] = (output.thisYear[monthName] || 0) + count;
                } else if (parseInt(year) === lastYear) {
                    output.lastYear[monthName] = (output.lastYear[monthName] || 0) + count;
                }
            });
        } else {
            // If the key doesn't exist and isn't 'Total', this part will effectively do nothing
            console.warn(`Key '${personKey}' not found in data.`);
        }

        return output;
    };

    // Helper function to initialize month data
    const initMonthData = (year) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const data = {};
        months.forEach(month => {
            const monthIndex = months.indexOf(month);
            const monthName = new Date(year, monthIndex).toLocaleString('default', { month: 'short' });
            data[monthName] = 0;
        });
        return data;
    };

    // For processing manager counts including unique GAs and SAs
    const processData = (data, personKey = 'Total') => {
        const output = {
            lastYear: initMonthData(new Date().getFullYear() - 1),
            thisYear: initMonthData(new Date().getFullYear())
        };

        if (data.hasOwnProperty(personKey)) {
            Object.entries(data[personKey]).forEach(([date, details]) => {
                const [year, month] = date.split('-');
                const monthIndex = parseInt(month, 10) - 1;
                const monthName = new Date(year, monthIndex).toLocaleString('default', { month: 'short' });

                const monthData = {
                    uniqueGAs: details.uniqueGAs || 0,
                    uniqueSAs: details.uniqueSAs || 0,
                    uniqueMGAs: details.uniqueMGAs || 0,
                    MGAs: details.MGAs || 'N/A',
                    GAs: details.GAs || 'N/A',
                    SAs: details.SAs || 'N/A',
                    uniqueLagnNames: details.uniqueLagnNames || 0,
                    LagnNames: details.LagnNames || 'N/A'
                };

                if (parseInt(year) === new Date().getFullYear()) {
                    output.thisYear[monthName] = monthData;
                } else if (parseInt(year) === new Date().getFullYear() - 1) {
                    output.lastYear[monthName] = monthData;
                }
            });
        }

        return output;
    };

    //for vips
    const processVipData = (vipData, personKey = 'Total') => {
        const currentYear = new Date().getFullYear();
        const lastYear = currentYear - 1;
        const output = {
            lastYear: initMonthData(lastYear),
            thisYear: initMonthData(currentYear)
        };

        // Aggregate counts for the months
        Object.entries(vipData).forEach(([name, months]) => {
            if (personKey === 'Total' || name === personKey) {
                Object.entries(months).forEach(([date, count]) => {
                    const [year, month] = date.split('-');
                    const monthIndex = parseInt(month, 10) - 1;
                    const monthName = new Date(year, monthIndex).toLocaleString('default', { month: 'short' });

                    if (parseInt(year) === currentYear) {
                        output.thisYear[monthName] = (output.thisYear[monthName] || 0) + count;
                    } else if (parseInt(year) === lastYear) {
                        output.lastYear[monthName] = (output.lastYear[monthName] || 0) + count;
                    }
                });
            }
        });

        return output;
    };

    //for codes
    const processCodeData = (codeData, personKey = 'Total') => {
        const currentYear = new Date().getFullYear();
        const lastYear = currentYear - 1;
        const output = {
            lastYear: initMonthData(lastYear),
            thisYear: initMonthData(currentYear)
        };

        // If the personKey is 'Total' and it explicitly exists in the data, use it directly
        if (personKey === 'Total' && codeData.hasOwnProperty('Total')) {
            Object.entries(codeData['Total']).forEach(([date, count]) => {
                const [year, month] = date.split('-');
                const monthIndex = parseInt(month, 10) - 1;
                const monthName = new Date(year, monthIndex).toLocaleString('default', { month: 'short' });

                if (parseInt(year) === currentYear) {
                    output.thisYear[monthName] = (output.thisYear[monthName] || 0) + count;
                } else if (parseInt(year) === lastYear) {
                    output.lastYear[monthName] = (output.lastYear[monthName] || 0) + count;
                }
            });
        } else {
            // Process other keys or all data if 'Total' key does not exist
            Object.entries(codeData).forEach(([name, months]) => {
                if (name !== 'Total' && (personKey === name || personKey !== 'Total')) {
                    Object.entries(months).forEach(([date, count]) => {
                        const [year, month] = date.split('-');
                        const monthIndex = parseInt(month, 10) - 1;
                        const monthName = new Date(year, monthIndex).toLocaleString('default', { month: 'short' });

                        if (parseInt(year) === currentYear) {
                            output.thisYear[monthName] = (output.thisYear[monthName] || 0) + count;
                        } else if (parseInt(year) === lastYear) {
                            output.lastYear[monthName] = (output.lastYear[monthName] || 0) + count;
                        }
                    });
                }
            });
        }

        return output;
    };


    const processSubmittingData = (submittingData, personKey = 'Total') => {
        const currentYear = new Date().getFullYear();
        const lastYear = currentYear - 1;
        const output = {
            lastYear: initMonthData(lastYear),
            thisYear: initMonthData(currentYear)
        };

        // If the personKey is 'Total' and it explicitly exists in the data, use it directly
        if (personKey === 'Total' && submittingData.hasOwnProperty('Total')) {
            processEntries(submittingData['Total'], output, currentYear, lastYear);
        } else if (submittingData.hasOwnProperty(personKey)) {  // Process only the specific personKey if it exists
            processEntries(submittingData[personKey], output, currentYear, lastYear);
        }

        return output;
    };

    function processEntries(entries, output, currentYear, lastYear) {
        Object.entries(entries).forEach(([date, count]) => {
            const [year, month] = date.split('-');
            const monthIndex = parseInt(month, 10) - 1;
            const monthName = new Date(year, monthIndex).toLocaleString('default', { month: 'short' });

            count = parseFloat(count); // Ensure count is a number (if it's not already)

            if (parseInt(year) === currentYear) {
                output.thisYear[monthName] = (output.thisYear[monthName] || 0) + count;
                output.thisYear[monthName] = parseFloat(output.thisYear[monthName].toFixed(2)); // Round to two decimal places
            } else if (parseInt(year) === lastYear) {
                output.lastYear[monthName] = (output.lastYear[monthName] || 0) + count;
                output.lastYear[monthName] = parseFloat(output.lastYear[monthName].toFixed(2)); // Round to two decimal places
            }
        });
    }


    
    //for selecting MGA/RGA
    const handleSelectChange = selectedOptions => {
        setSelectedPerson(selectedOptions);
        const keys = selectedOptions && selectedOptions.length > 0 ? selectedOptions.map(option => option.value).join(',') : 'Total';
    
        // Construct the URLs using the joined keys
        axios.all([
            axios.get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/net-by-mga-month?keys=${keys}`),
            axios.get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/agent-count-by-mga-and-date?keys=${keys}`),
            axios.get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/manager-count-by-mga-and-date?keys=${keys}`),
            axios.get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/vip-by-mga-and-date?keys=${keys}`),
            axios.get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/code-by-mga-and-date?keys=${keys}`),
            axios.get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/submitting-by-mga-and-date?keys=${keys}`)
        ]).then(axios.spread((netData, agentData, managerData, vipData, codeData, submittingData) => {
            // Aggregate and process the data for all selected keys
            const netDataOutput = processMultipleRgaData(netData.data, keys.split(','));  // Assuming processMultipleRgaData can handle array of keys
            const agentDataOutput = reformatAgentCountsRGAs(agentData.data, keys.split(','));  // Your data processing functions need to be able to handle multiple keys
            const managerDataOutput = processDataRGAs(managerData.data, keys.split(','));
            const vipDataOutput = processVipDataRGAs(vipData.data, keys.split(','));
            const codeDataOutput = processCodeDataRGAs(codeData.data, keys.split(','));
            const submittingDataOutput = processSubmittingDataRGAs(submittingData.data, keys.split(','));
    
            setData(netDataOutput);
            setAgentCounts(agentDataOutput);
            setManagerCounts(managerDataOutput);
            setVipData(vipDataOutput);
            setCodeData(codeDataOutput);
            setSubmittingCounts(submittingDataOutput);
    
            console.log('Fetched and Processed Data:', {
                netDataOutput,
                agentDataOutput,
                managerDataOutput,
                vipDataOutput,
                codeDataOutput,
                submittingDataOutput
            });
        })).catch(error => {
            console.error("Failed to fetch data for keys: " + keys, error);
            setError(error);
        });
    };
    

    
const fetchAllData = (key) => {
    axios.all([
        axios.get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/net-by-mga-month?key=${key}`),
        axios.get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/agent-count-by-mga-and-date?key=${key}`),
        axios.get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/manager-count-by-mga-and-date?key=${key}`),
        axios.get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/vip-by-mga-and-date?key=${key}`),
        axios.get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/code-by-mga-and-date?key=${key}`),
        axios.get(`https://ariaslogin-4a95935f6093.herokuapp.com/api/submitting-by-mga-and-date?key=${key}`)
    ]).then(axios.spread((netData, agentData, managerData, vipData, codeData, submittingData) => {
        setData(reformatData(netData.data, key));
        setAgentCounts(reformatAgentCounts(agentData.data, key));
        setManagerCounts(processData(managerData.data, key));
        setVipData(processVipData(vipData.data, key));
        setCodeData(processCodeData(codeData.data, key));
        setSubmittingCounts(processSubmittingData(submittingData.data, key));
    })).catch(err => {
        console.error("Failed to fetch data for key: " + key, err);
        setError(err);
    });
};



    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const calculateGrowth = (thisYearValue, lastYearValue) => {
        if (thisYearValue !== undefined && lastYearValue !== undefined) {
            return formatCurrency(thisYearValue - lastYearValue);
        }
        return 'N/A'; // Return 'N/A' if either value is missing
    }

    //calculations

    const calculateQtdAverage = (values) => {
        const { sum, count } = values.reduce((acc, val) => {
            if (val !== 0 && val !== undefined && val !== null) {
                return {
                    sum: acc.sum + val,
                    count: acc.count + 1
                };
            }
            return acc;
        }, { sum: 0, count: 0 });

        return count > 0 ? (sum / count).toFixed(2) : 'N/A';
    };
    const calculateQtdSubAverage = (values) => {
        let sum = 0;
        let count = 0;

        values.forEach(val => {
            if (val !== 'N/A' && parseFloat(val) !== 0) {
                sum += parseFloat(val);
                count += 1;
            }
        });

        return count > 0 ? (sum / count).toFixed(2) : 'N/A';
    };

    const calculateYearlyGrowth = (current, last) => {
        const currentValue = current.data && current.submitting ? (current.data / current.submitting) : null;
        const lastValue = last.data && last.submitting ? (last.data / last.submitting) : null;

        if (currentValue !== null && lastValue !== null) {
            return (currentValue - lastValue).toFixed(2);
        }
        return 'N/A';
    };
    const handleCellClick = (dataKey, month) => {
        const currentYear = new Date().getFullYear();
        const monthIndex = months.indexOf(month);
        const now = new Date();
        const currentMonthIndex = now.getMonth();
    
        // Determine whether the clicked month belongs to this year or last year
        let yearToUse;
        if (monthIndex > currentMonthIndex) {
            yearToUse = currentYear - 1; // Last year if the month index is greater than the current month index
        } else {
            yearToUse = currentYear; // This year otherwise
        }
    
        const yearData = data[yearToUse];
        if (!yearData || !yearData[month]) {
            console.error(`No data available for ${month} in ${yearToUse}`);
            return; // Prevent further execution if data is not available
        }
    
        const monthData = yearData[month];
    
        let detailData = {
            dataKey: yearToUse.toString(),
            month: month,
            value: monthData.total || 0,  // Default to 0 if total is not defined
            contributions: monthData.contributions || {}
        };
    
        setSelectedDetail(detailData);
    };
    
    

    
    
    return (
        <div className="container">


            <div className="growth-data-container">

            <DetailModal 
    isOpen={selectedDetail != null}
    onClose={() => setSelectedDetail(null)}
    detail={selectedDetail}
/>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '20px', marginBottom: '20px' }}>
            <Select
    styles={{ container: (base) => ({ ...base, flex: 1, marginRight: '10px' }), menu: (base) => ({ ...base, zIndex: 2000 }) }}
    options={options}
    onChange={handleSelectChange}
    value={selectedPerson}
    placeholder="Select MGAs"
    isClearable={true}
    isMulti={true} // Enable multiple selections
/>

            <Select
                styles={{ container: (base) => ({ ...base, flex: 1 }), menu: (base) => ({ ...base, zIndex: 2000 }) }}
                options={rgaOptions}
                onChange={handleRgaChange}
                value={selectedRga}
                placeholder="Select an RGA"
                isClearable={true}
            />
        </div>


                <table >
                    <thead className="growth-table-thead">
                        <tr>
                            <th className="top-table-header ">
                                <div style={{ minWidth: "160px" }} className="top-table-header-div">2024</div>
                            </th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>Jan</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>Feb</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>Mar</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>1st QTD</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>Apr</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>May</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>Jun</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>2nd QTD</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>Jul</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>Aug</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>Sep</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>3rd QTD</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>Oct</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>Nov</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>Dec</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>4th QTD</th>
                            <th className='top-table-header' style={{ backgroundColor: "#244062" }}>YTD</th>
                        </tr>

                    </thead>
                    {/* Net ALP */}


                    <tr>
                        <th className="top-table-header" style={{ backgroundColor: "#244062" }}>Net ALP</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>

                    </tr>
                    <tr>
                        <th style={{ backgroundColor: "white", color: "red" }} className="first-row-header">2024 Net ALP</th>
                        <td className="first-row-cells" onClick={() => handleCellClick('thisYear', 'Jan')}>
    {data.thisYear['Jan'] ? formatCurrency(data.thisYear['Jan']) : 0}
</td>
                        <td className="first-row-cells">{data.thisYear['Feb'] ? formatCurrency(data.thisYear['Feb']) : 0}</td>
                        <td className="first-row-cells">{data.thisYear['Mar'] ? formatCurrency(data.thisYear['Mar']) : 0}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {formatCurrency([
                                data.thisYear['Jan'],
                                data.thisYear['Feb'],
                                data.thisYear['Mar']
                            ].reduce((acc, val) => acc + (val || 0), 0))}
                        </th>
                        <td className="first-row-cells">{data.thisYear['Apr'] ? formatCurrency(data.thisYear['Apr']) : 0}</td>
                        <td className="first-row-cells">{data.thisYear['May'] ? formatCurrency(data.thisYear['May']) : 0}</td>
                        <td className="first-row-cells">{data.thisYear['Jun'] ? formatCurrency(data.thisYear['Jun']) : 0}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {formatCurrency([
                                data.thisYear['Apr'],
                                data.thisYear['May'],
                                data.thisYear['Jun']
                            ].reduce((acc, val) => acc + (val || 0), 0))}
                        </th>
                        <td className="first-row-cells">{data.thisYear['Jul'] ? formatCurrency(data.thisYear['Jul']) : 0}</td>
                        <td className="first-row-cells">{data.thisYear['Aug'] ? formatCurrency(data.thisYear['Aug']) : 0}</td>
                        <td className="first-row-cells">{data.thisYear['Sep'] ? formatCurrency(data.thisYear['Sep']) : 0}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {formatCurrency([
                                data.thisYear['Jul'],
                                data.thisYear['Aug'],
                                data.thisYear['Sep']
                            ].reduce((acc, val) => acc + (val || 0), 0))}
                        </th>
                        <td className="first-row-cells">{data.thisYear['Oct'] ? formatCurrency(data.thisYear['Oct']) : 0}</td>
                        <td className="first-row-cells">{data.thisYear['Nov'] ? formatCurrency(data.thisYear['Nov']) : 0}</td>
                        <td className="first-row-cells">{data.thisYear['Dec'] ? formatCurrency(data.thisYear['Dec']) : 0}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {formatCurrency([
                                data.thisYear['Oct'],
                                data.thisYear['Nov'],
                                data.thisYear['Dec']
                            ].reduce((acc, val) => acc + (val || 0), 0))}
                        </th>
                        <th style={{ backgroundColor: "#FFFF99", color: "red" }} className="total-column-cells">
                            {formatCurrency(Object.values(data.thisYear).reduce((acc, val) => acc + (val || 0), 0))}
                        </th>
                    </tr>

                    <tr>
                        <th style={{ backgroundColor: "white", color: "black" }} className="second-row-header">2023 Net ALP </th>
                        <td className="second-row-cells">{data.lastYear['Jan'] ? formatCurrency(data.lastYear['Jan']) : 0}</td>
                        <td className="second-row-cells">{data.lastYear['Feb'] ? formatCurrency(data.lastYear['Feb']) : 0}</td>
                        <td className="second-row-cells">{data.lastYear['Mar'] ? formatCurrency(data.lastYear['Mar']) : 0}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {formatCurrency([
                                data.lastYear['Jan'],
                                data.lastYear['Feb'],
                                data.lastYear['Mar']
                            ].reduce((acc, val) => acc + (val || 0), 0))}
                        </th>
                        <td className="second-row-cells">{data.lastYear['Apr'] ? formatCurrency(data.lastYear['Apr']) : 0}</td>
                        <td className="second-row-cells">{data.lastYear['May'] ? formatCurrency(data.lastYear['May']) : 0}</td>
                        <td className="second-row-cells">{data.lastYear['Jun'] ? formatCurrency(data.lastYear['Jun']) : 0}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {formatCurrency([
                                data.lastYear['Apr'],
                                data.lastYear['May'],
                                data.lastYear['Jun']
                            ].reduce((acc, val) => acc + (val || 0), 0))}
                        </th>            <td className="second-row-cells">{data.lastYear['Jul'] ? formatCurrency(data.lastYear['Jul']) : 0}</td>
                        <td className="second-row-cells">{data.lastYear['Aug'] ? formatCurrency(data.lastYear['Aug']) : 0}</td>
                        <td className="second-row-cells">{data.lastYear['Sep'] ? formatCurrency(data.lastYear['Sep']) : 0}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {formatCurrency([
                                data.lastYear['Jul'],
                                data.lastYear['Aug'],
                                data.lastYear['Sep']
                            ].reduce((acc, val) => acc + (val || 0), 0))}
                        </th>            <td className="second-row-cells">{data.lastYear['Oct'] ? formatCurrency(data.lastYear['Oct']) : 0}</td>
                        <td className="second-row-cells">{data.lastYear['Nov'] ? formatCurrency(data.lastYear['Nov']) : 0}</td>
                        <td className="second-row-cells">{data.lastYear['Dec'] ? formatCurrency(data.lastYear['Dec']) : 0}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {formatCurrency([
                                data.lastYear['Oct'],
                                data.lastYear['Nov'],
                                data.lastYear['Dec']
                            ].reduce((acc, val) => acc + (val || 0), 0))}
                        </th>
                        <th style={{ backgroundColor: "#FFFF99", color: "black" }} className="total-column-cells">
                            {formatCurrency(Object.values(data.lastYear).reduce((acc, val) => acc + (val || 0), 0))}
                        </th>


                    </tr>
                    <tr>
                        <th style={{ backgroundColor: "#D8E4BC", color: "red" }} className="third-row-header">2024 Growth</th>
                        <td style={{ backgroundColor: "#D8E4BC" }} className="third-row-cells">
                            {calculateGrowth(data.thisYear['Jan'], data.lastYear['Jan'])}
                        </td>
                        <td style={{ backgroundColor: "#D8E4BC" }} className="third-row-cells">
                            {calculateGrowth(data.thisYear['Feb'], data.lastYear['Feb'])}
                        </td>
                        <td style={{ backgroundColor: "#D8E4BC" }} className="third-row-cells">
                            {calculateGrowth(data.thisYear['Mar'], data.lastYear['Mar'])}
                        </td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {formatCurrency([
                                data.thisYear['Jan'] - data.lastYear['Jan'],
                                data.thisYear['Feb'] - data.lastYear['Feb'],
                                data.thisYear['Mar'] - data.lastYear['Mar']
                            ].reduce((acc, val) => acc + (val || 0), 0))}
                        </th>
                        <td style={{ backgroundColor: "#D8E4BC" }} className="third-row-cells">
                            {calculateGrowth(data.thisYear['Apr'], data.lastYear['Apr'])}
                        </td>
                        <td style={{ backgroundColor: "#D8E4BC" }} className="third-row-cells">
                            {calculateGrowth(data.thisYear['May'], data.lastYear['May'])}
                        </td>
                        <td style={{ backgroundColor: "#D8E4BC" }} className="third-row-cells">
                            {calculateGrowth(data.thisYear['Jun'], data.lastYear['Jun'])}
                        </td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {formatCurrency([
                                data.thisYear['Apr'] - data.lastYear['Apr'],
                                data.thisYear['May'] - data.lastYear['May'],
                                data.thisYear['Jun'] - data.lastYear['Jun']
                            ].reduce((acc, val) => acc + (val || 0), 0))}
                        </th>
                        <td style={{ backgroundColor: "#D8E4BC" }} className="third-row-cells">
                            {calculateGrowth(data.thisYear['Jul'], data.lastYear['Jul'])}
                        </td>
                        <td style={{ backgroundColor: "#D8E4BC" }} className="third-row-cells">
                            {calculateGrowth(data.thisYear['Aug'], data.lastYear['Aug'])}
                        </td>
                        <td style={{ backgroundColor: "#D8E4BC" }} className="third-row-cells">
                            {calculateGrowth(data.thisYear['Sep'], data.lastYear['Sep'])}
                        </td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {formatCurrency([
                                data.thisYear['Jul'] - data.lastYear['Jul'],
                                data.thisYear['Aug'] - data.lastYear['Aug'],
                                data.thisYear['Sep'] - data.lastYear['Sep']
                            ].reduce((acc, val) => acc + (val || 0), 0))}
                        </th>
                        <td style={{ backgroundColor: "#D8E4BC" }} className="third-row-cells">
                            {calculateGrowth(data.thisYear['Oct'], data.lastYear['Oct'])}
                        </td>
                        <td style={{ backgroundColor: "#D8E4BC" }} className="third-row-cells">
                            {calculateGrowth(data.thisYear['Nov'], data.lastYear['Nov'])}
                        </td>
                        <td style={{ backgroundColor: "#D8E4BC" }} className="third-row-cells">
                            {calculateGrowth(data.thisYear['Dec'], data.lastYear['Dec'])}
                        </td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {formatCurrency([
                                data.thisYear['Oct'] - data.lastYear['Oct'],
                                data.thisYear['Nov'] - data.lastYear['Nov'],
                                data.thisYear['Dec'] - data.lastYear['Dec']
                            ].reduce((acc, val) => acc + (val || 0), 0))}
                        </th>
                        <th style={{ backgroundColor: "#FF0000", color: "white" }} className="total-column-cells">
                            {formatCurrency(Object.keys(data.thisYear).reduce((acc, month) => {
                                return acc + (data.thisYear[month] || 0) - (data.lastYear[month] || 0);
                            }, 0))}
                        </th>
                    </tr>


                    <tr></tr>
                    {/* Codes */}

                    <tr>
                        <th className="top-table-header" style={{ backgroundColor: "#244062" }}>Codes</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>

                    </tr>
                    <tr>
                        <th className="first-row-header">2024</th>
                        <td className="first-row-cells">
                            {codeData.thisYear['Jan'] ? codeData.thisYear['Jan'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {codeData.thisYear['Feb'] ? codeData.thisYear['Feb'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {codeData.thisYear['Mar'] ? codeData.thisYear['Mar'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            codeData.thisYear['Jan'],
                            codeData.thisYear['Feb'],
                            codeData.thisYear['Mar']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>             <td className="first-row-cells">
                            {codeData.thisYear['Apr'] ? codeData.thisYear['Apr'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {codeData.thisYear['May'] ? codeData.thisYear['May'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {codeData.thisYear['Jun'] ? codeData.thisYear['Jun'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            codeData.thisYear['Apr'],
                            codeData.thisYear['May'],
                            codeData.thisYear['Jun']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>             <td className="first-row-cells">
                            {codeData.thisYear['Jul'] ? codeData.thisYear['Jul'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {codeData.thisYear['Aug'] ? codeData.thisYear['Aug'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {codeData.thisYear['Sep'] ? codeData.thisYear['Sep'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            codeData.thisYear['Jul'],
                            codeData.thisYear['Aug'],
                            codeData.thisYear['Sep']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>             <td className="first-row-cells">
                            {codeData.thisYear['Oct'] ? codeData.thisYear['Oct'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {codeData.thisYear['Nov'] ? codeData.thisYear['Nov'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {codeData.thisYear['Dec'] ? codeData.thisYear['Dec'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            codeData.thisYear['Oct'],
                            codeData.thisYear['Nov'],
                            codeData.thisYear['Dec']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <th style={{ color: "red" }} className="total-column-cells">{[
                            codeData.thisYear['Jan'],
                            codeData.thisYear['Feb'],
                            codeData.thisYear['Mar'],
                            codeData.thisYear['Apr'],
                            codeData.thisYear['May'],
                            codeData.thisYear['Jun'],
                            codeData.thisYear['Jul'],
                            codeData.thisYear['Aug'],
                            codeData.thisYear['Sep'],
                            codeData.thisYear['Oct'],
                            codeData.thisYear['Nov'],
                            codeData.thisYear['Dec']
                        ].reduce((acc, val) => acc + (val || 0), 0)}</th>
                    </tr>
                    <tr>
                        <th className="second-row-header">2023 Results</th>
                        <td className="second-row-cells">
                            {codeData.lastYear['Jan'] ? codeData.lastYear['Jan'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {codeData.lastYear['Feb'] ? codeData.lastYear['Feb'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {codeData.lastYear['Mar'] ? codeData.lastYear['Mar'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            codeData.lastYear['Jan'],
                            codeData.lastYear['Feb'],
                            codeData.lastYear['Mar']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="second-row-cells">
                            {codeData.lastYear['Apr'] ? codeData.lastYear['Apr'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {codeData.lastYear['May'] ? codeData.lastYear['May'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {codeData.lastYear['Jun'] ? codeData.lastYear['Jun'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            codeData.lastYear['Apr'],
                            codeData.lastYear['May'],
                            codeData.lastYear['Jun']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>                <td className="second-row-cells">
                            {codeData.lastYear['Jul'] ? codeData.lastYear['Jul'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {codeData.lastYear['Aug'] ? codeData.lastYear['Aug'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {codeData.lastYear['Sep'] ? codeData.lastYear['Sep'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            codeData.lastYear['Jul'],
                            codeData.lastYear['Aug'],
                            codeData.lastYear['Sep']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="second-row-cells">
                            {codeData.lastYear['Oct'] ? codeData.lastYear['Oct'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {codeData.lastYear['Nov'] ? codeData.lastYear['Nov'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {codeData.lastYear['Dec'] ? codeData.lastYear['Dec'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            codeData.lastYear['Oct'],
                            codeData.lastYear['Nov'],
                            codeData.lastYear['Dec']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <th style={{ color: "black" }} className="total-column-cells">{[
                            codeData.lastYear['Jan'],
                            codeData.lastYear['Feb'],
                            codeData.lastYear['Mar'],
                            codeData.lastYear['Apr'],
                            codeData.lastYear['May'],
                            codeData.lastYear['Jun'],
                            codeData.lastYear['Jul'],
                            codeData.lastYear['Aug'],
                            codeData.lastYear['Sep'],
                            codeData.lastYear['Oct'],
                            codeData.lastYear['Nov'],
                            codeData.lastYear['Dec']
                        ].reduce((acc, val) => acc + (val || 0), 0)}</th>
                    </tr>

                    <tr>
                        <th className="third-row-header">2024 Growth </th>
                        <td className="third-row-cells">{codeData.thisYear['Jan'] - codeData.lastYear['Jan']}</td>
                        <td className="third-row-cells">{codeData.thisYear['Feb'] - codeData.lastYear['Feb']}</td>
                        <td className="third-row-cells">{codeData.thisYear['Mar'] - codeData.lastYear['Mar']}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {[
                                codeData.thisYear['Jan'] - codeData.lastYear['Jan'],
                                codeData.thisYear['Feb'] - codeData.lastYear['Feb'],
                                codeData.thisYear['Mar'] - codeData.lastYear['Mar']
                            ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <td className="third-row-cells">{codeData.thisYear['Apr'] - codeData.lastYear['Apr']}</td>
                        <td className="third-row-cells">{codeData.thisYear['May'] - codeData.lastYear['May']}</td>
                        <td className="third-row-cells">{codeData.thisYear['Jun'] - codeData.lastYear['Jun']}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {[
                                codeData.thisYear['Apr'] - codeData.lastYear['Apr'],
                                codeData.thisYear['May'] - codeData.lastYear['May'],
                                codeData.thisYear['Jun'] - codeData.lastYear['Jun']
                            ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="third-row-cells">{codeData.thisYear['Jul'] - codeData.lastYear['Jul']}</td>
                        <td className="third-row-cells">{codeData.thisYear['Aug'] - codeData.lastYear['Aug']}</td>
                        <td className="third-row-cells">{codeData.thisYear['Sep'] - codeData.lastYear['Sep']}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {[
                                codeData.thisYear['Jul'] - codeData.lastYear['Jul'],
                                codeData.thisYear['Aug'] - codeData.lastYear['Aug'],
                                codeData.thisYear['Sep'] - codeData.lastYear['Sep']
                            ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="third-row-cells">{codeData.thisYear['Oct'] - codeData.lastYear['Oct']}</td>
                        <td className="third-row-cells">{codeData.thisYear['Nov'] - codeData.lastYear['Nov']}</td>
                        <td className="third-row-cells">{codeData.thisYear['Dec'] - codeData.lastYear['Dec']}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {[
                                codeData.thisYear['Oct'] - codeData.lastYear['Oct'],
                                codeData.thisYear['Nov'] - codeData.lastYear['Nov'],
                                codeData.thisYear['Dec'] - codeData.lastYear['Dec']
                            ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <th style={{ color: "black" }} className="total-column-cells">
                            {[
                                codeData.thisYear['Jan'] - codeData.lastYear['Jan'],
                                codeData.thisYear['Feb'] - codeData.lastYear['Feb'],
                                codeData.thisYear['Mar'] - codeData.lastYear['Mar'],
                                codeData.thisYear['Apr'] - codeData.lastYear['Apr'],
                                codeData.thisYear['May'] - codeData.lastYear['May'],
                                codeData.thisYear['Jun'] - codeData.lastYear['Jun'],
                                codeData.thisYear['Jul'] - codeData.lastYear['Jul'],
                                codeData.thisYear['Aug'] - codeData.lastYear['Aug'],
                                codeData.thisYear['Sep'] - codeData.lastYear['Sep'],
                                codeData.thisYear['Oct'] - codeData.lastYear['Oct'],
                                codeData.thisYear['Nov'] - codeData.lastYear['Nov'],
                                codeData.thisYear['Dec'] - codeData.lastYear['Dec']
                            ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>

                    </tr>
                    <tr></tr>
                    {/* VIPs */}

                    <tr>
                        <th className="top-table-header" style={{ backgroundColor: "#244062" }}>VIPs</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>

                    </tr>
                    <tr>
                        <th className="first-row-header">2024 </th>
                        <td className="first-row-cells">
                            {vipData.thisYear['Jan'] ? vipData.thisYear['Jan'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {vipData.thisYear['Feb'] ? vipData.thisYear['Feb'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {vipData.thisYear['Mar'] ? vipData.thisYear['Mar'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            vipData.thisYear['Jan'],
                            vipData.thisYear['Feb'],
                            vipData.thisYear['Mar']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>             <td className="first-row-cells">
                            {vipData.thisYear['Apr'] ? vipData.thisYear['Apr'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {vipData.thisYear['May'] ? vipData.thisYear['May'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {vipData.thisYear['Jun'] ? vipData.thisYear['Jun'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            vipData.thisYear['Apr'],
                            vipData.thisYear['May'],
                            vipData.thisYear['Jun']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>             <td className="first-row-cells">
                            {vipData.thisYear['Jul'] ? vipData.thisYear['Jul'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {vipData.thisYear['Aug'] ? vipData.thisYear['Aug'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {vipData.thisYear['Sep'] ? vipData.thisYear['Sep'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            vipData.thisYear['Jul'],
                            vipData.thisYear['Aug'],
                            vipData.thisYear['Sep']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>             <td className="first-row-cells">
                            {vipData.thisYear['Oct'] ? vipData.thisYear['Oct'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {vipData.thisYear['Nov'] ? vipData.thisYear['Nov'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {vipData.thisYear['Dec'] ? vipData.thisYear['Dec'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            vipData.thisYear['Oct'],
                            vipData.thisYear['Nov'],
                            vipData.thisYear['Dec']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <th style={{ color: "red" }} className="total-column-cells">{[
                            vipData.thisYear['Jan'],
                            vipData.thisYear['Feb'],
                            vipData.thisYear['Mar'],
                            vipData.thisYear['Apr'],
                            vipData.thisYear['May'],
                            vipData.thisYear['Jun'],
                            vipData.thisYear['Jul'],
                            vipData.thisYear['Aug'],
                            vipData.thisYear['Sep'],
                            vipData.thisYear['Oct'],
                            vipData.thisYear['Nov'],
                            vipData.thisYear['Dec']
                        ].reduce((acc, val) => acc + (val || 0), 0)}</th>
                    </tr>
                    <tr>
                        <th className="second-row-header">2023 Results</th>
                        <td className="second-row-cells">
                            {vipData.lastYear['Jan'] ? vipData.lastYear['Jan'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {vipData.lastYear['Feb'] ? vipData.lastYear['Feb'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {vipData.lastYear['Mar'] ? vipData.lastYear['Mar'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            vipData.lastYear['Jan'],
                            vipData.lastYear['Feb'],
                            vipData.lastYear['Mar']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="second-row-cells">
                            {vipData.lastYear['Apr'] ? vipData.lastYear['Apr'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {vipData.lastYear['May'] ? vipData.lastYear['May'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {vipData.lastYear['Jun'] ? vipData.lastYear['Jun'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            vipData.lastYear['Apr'],
                            vipData.lastYear['May'],
                            vipData.lastYear['Jun']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>                <td className="second-row-cells">
                            {vipData.lastYear['Jul'] ? vipData.lastYear['Jul'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {vipData.lastYear['Aug'] ? vipData.lastYear['Aug'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {vipData.lastYear['Sep'] ? vipData.lastYear['Sep'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            vipData.lastYear['Jul'],
                            vipData.lastYear['Aug'],
                            vipData.lastYear['Sep']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="second-row-cells">
                            {vipData.lastYear['Oct'] ? vipData.lastYear['Oct'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {vipData.lastYear['Nov'] ? vipData.lastYear['Nov'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {vipData.lastYear['Dec'] ? vipData.lastYear['Dec'] : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            vipData.lastYear['Oct'],
                            vipData.lastYear['Nov'],
                            vipData.lastYear['Dec']
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <th style={{ color: "black" }} className="total-column-cells">{[
                            vipData.lastYear['Jan'],
                            vipData.lastYear['Feb'],
                            vipData.lastYear['Mar'],
                            vipData.lastYear['Apr'],
                            vipData.lastYear['May'],
                            vipData.lastYear['Jun'],
                            vipData.lastYear['Jul'],
                            vipData.lastYear['Aug'],
                            vipData.lastYear['Sep'],
                            vipData.lastYear['Oct'],
                            vipData.lastYear['Nov'],
                            vipData.lastYear['Dec']
                        ].reduce((acc, val) => acc + (val || 0), 0)}</th>
                    </tr>

                    <tr>
                        <th className="third-row-header">2024 Growth </th>
                        <td className="third-row-cells">{vipData.thisYear['Jan'] - vipData.lastYear['Jan']}</td>
                        <td className="third-row-cells">{vipData.thisYear['Feb'] - vipData.lastYear['Feb']}</td>
                        <td className="third-row-cells">{vipData.thisYear['Mar'] - vipData.lastYear['Mar']}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {[
                                vipData.thisYear['Jan'] - vipData.lastYear['Jan'],
                                vipData.thisYear['Feb'] - vipData.lastYear['Feb'],
                                vipData.thisYear['Mar'] - vipData.lastYear['Mar']
                            ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <td className="third-row-cells">{vipData.thisYear['Apr'] - vipData.lastYear['Apr']}</td>
                        <td className="third-row-cells">{vipData.thisYear['May'] - vipData.lastYear['May']}</td>
                        <td className="third-row-cells">{vipData.thisYear['Jun'] - vipData.lastYear['Jun']}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {[
                                vipData.thisYear['Apr'] - vipData.lastYear['Apr'],
                                vipData.thisYear['May'] - vipData.lastYear['May'],
                                vipData.thisYear['Jun'] - vipData.lastYear['Jun']
                            ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="third-row-cells">{vipData.thisYear['Jul'] - vipData.lastYear['Jul']}</td>
                        <td className="third-row-cells">{vipData.thisYear['Aug'] - vipData.lastYear['Aug']}</td>
                        <td className="third-row-cells">{vipData.thisYear['Sep'] - vipData.lastYear['Sep']}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {[
                                vipData.thisYear['Jul'] - vipData.lastYear['Jul'],
                                vipData.thisYear['Aug'] - vipData.lastYear['Aug'],
                                vipData.thisYear['Sep'] - vipData.lastYear['Sep']
                            ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="third-row-cells">{vipData.thisYear['Oct'] - vipData.lastYear['Oct']}</td>
                        <td className="third-row-cells">{vipData.thisYear['Nov'] - vipData.lastYear['Nov']}</td>
                        <td className="third-row-cells">{vipData.thisYear['Dec'] - vipData.lastYear['Dec']}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {[
                                vipData.thisYear['Oct'] - vipData.lastYear['Oct'],
                                vipData.thisYear['Nov'] - vipData.lastYear['Nov'],
                                vipData.thisYear['Dec'] - vipData.lastYear['Dec']
                            ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <th style={{ color: "black" }} className="total-column-cells">
                            {[
                                vipData.thisYear['Jan'] - vipData.lastYear['Jan'],
                                vipData.thisYear['Feb'] - vipData.lastYear['Feb'],
                                vipData.thisYear['Mar'] - vipData.lastYear['Mar'],
                                vipData.thisYear['Apr'] - vipData.lastYear['Apr'],
                                vipData.thisYear['May'] - vipData.lastYear['May'],
                                vipData.thisYear['Jun'] - vipData.lastYear['Jun'],
                                vipData.thisYear['Jul'] - vipData.lastYear['Jul'],
                                vipData.thisYear['Aug'] - vipData.lastYear['Aug'],
                                vipData.thisYear['Sep'] - vipData.lastYear['Sep'],
                                vipData.thisYear['Oct'] - vipData.lastYear['Oct'],
                                vipData.thisYear['Nov'] - vipData.lastYear['Nov'],
                                vipData.thisYear['Dec'] - vipData.lastYear['Dec']
                            ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>

                    </tr>

                    <tr></tr>
                    {/* Submitting Agent Count */}

                    <tr>
                        <th className="top-table-header" style={{ backgroundColor: "#244062" }}>Submitting Agent Count</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>AVG</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>AVG</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>AVG</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>AVG</th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>AVG</th>

                    </tr>
                    <tr>
                        <th className="first-row-header">2024 </th>
                        <td className="first-row-cells">
                            {submittingCounts.thisYear['Jan'] ? submittingCounts.thisYear['Jan'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {submittingCounts.thisYear['Feb'] ? submittingCounts.thisYear['Feb'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {submittingCounts.thisYear['Mar'] ? submittingCounts.thisYear['Mar'] : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdAverage([
                                submittingCounts.thisYear['Jan'],
                                submittingCounts.thisYear['Feb'],
                                submittingCounts.thisYear['Mar']
                            ])}
                        </th>            <td className="first-row-cells">
                            {submittingCounts.thisYear['Apr'] ? submittingCounts.thisYear['Apr'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {submittingCounts.thisYear['May'] ? submittingCounts.thisYear['May'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {submittingCounts.thisYear['Jun'] ? submittingCounts.thisYear['Jun'] : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdAverage([
                                submittingCounts.thisYear['Apr'],
                                submittingCounts.thisYear['May'],
                                submittingCounts.thisYear['Jun']
                            ])}
                        </th>
                        <td className="first-row-cells">
                            {submittingCounts.thisYear['Jul'] ? submittingCounts.thisYear['Jul'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {submittingCounts.thisYear['Aug'] ? submittingCounts.thisYear['Aug'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {submittingCounts.thisYear['Sep'] ? submittingCounts.thisYear['Sep'] : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdAverage([
                                submittingCounts.thisYear['Jul'],
                                submittingCounts.thisYear['Aug'],
                                submittingCounts.thisYear['Sep']
                            ])}
                        </th>
                        <td className="first-row-cells">
                            {submittingCounts.thisYear['Oct'] ? submittingCounts.thisYear['Oct'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {submittingCounts.thisYear['Nov'] ? submittingCounts.thisYear['Nov'] : 0}
                        </td>
                        <td className="first-row-cells">
                            {submittingCounts.thisYear['Dec'] ? submittingCounts.thisYear['Dec'] : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdAverage([
                                submittingCounts.thisYear['Oct'],
                                submittingCounts.thisYear['Nov'],
                                submittingCounts.thisYear['Dec']
                            ])}
                        </th>
                        <th style={{ color: "red" }} className="total-column-cells">
                            {calculateQtdAverage([
                                submittingCounts.thisYear['Jan'],
                                submittingCounts.thisYear['Feb'],
                                submittingCounts.thisYear['Mar'],
                                submittingCounts.thisYear['Apr'],
                                submittingCounts.thisYear['May'],
                                submittingCounts.thisYear['Jun'],
                                submittingCounts.thisYear['Jul'],
                                submittingCounts.thisYear['Aug'],
                                submittingCounts.thisYear['Sep'],
                                submittingCounts.thisYear['Oct'],
                                submittingCounts.thisYear['Nov'],
                                submittingCounts.thisYear['Dec']
                            ])}
                        </th>
                    </tr>
                    <tr>
                        <th className="second-row-header">2023 Results</th>
                        <td className="second-row-cells">
                            {submittingCounts.lastYear['Jan'] ? submittingCounts.lastYear['Jan'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {submittingCounts.lastYear['Feb'] ? submittingCounts.lastYear['Feb'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {submittingCounts.lastYear['Mar'] ? submittingCounts.lastYear['Mar'] : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdAverage([
                                submittingCounts.lastYear['Jan'],
                                submittingCounts.lastYear['Feb'],
                                submittingCounts.lastYear['Mar']
                            ])}
                        </th>
                        <td className="second-row-cells">
                            {submittingCounts.lastYear['Apr'] ? submittingCounts.lastYear['Apr'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {submittingCounts.lastYear['May'] ? submittingCounts.lastYear['May'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {submittingCounts.lastYear['Jun'] ? submittingCounts.lastYear['Jun'] : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdAverage([
                                submittingCounts.lastYear['Apr'],
                                submittingCounts.lastYear['May'],
                                submittingCounts.lastYear['Jun']
                            ])}
                        </th>
                        <td className="second-row-cells">
                            {submittingCounts.lastYear['Jul'] ? submittingCounts.lastYear['Jul'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {submittingCounts.lastYear['Aug'] ? submittingCounts.lastYear['Aug'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {submittingCounts.lastYear['Sep'] ? submittingCounts.lastYear['Sep'] : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdAverage([
                                submittingCounts.lastYear['Jul'],
                                submittingCounts.lastYear['Aug'],
                                submittingCounts.lastYear['Sep']
                            ])}
                        </th>
                        <td className="second-row-cells">
                            {submittingCounts.lastYear['Oct'] ? submittingCounts.lastYear['Oct'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {submittingCounts.lastYear['Nov'] ? submittingCounts.lastYear['Nov'] : 0}
                        </td>
                        <td className="second-row-cells">
                            {submittingCounts.lastYear['Dec'] ? submittingCounts.lastYear['Dec'] : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdAverage([
                                submittingCounts.lastYear['Oct'],
                                submittingCounts.lastYear['Nov'],
                                submittingCounts.lastYear['Dec']
                            ])}
                        </th>
                        <th style={{ color: "black" }} className="total-column-cells">
                            {calculateQtdAverage([
                                submittingCounts.lastYear['Jan'],
                                submittingCounts.lastYear['Feb'],
                                submittingCounts.lastYear['Mar'],
                                submittingCounts.lastYear['Apr'],
                                submittingCounts.lastYear['May'],
                                submittingCounts.lastYear['Jun'],
                                submittingCounts.lastYear['Jul'],
                                submittingCounts.lastYear['Aug'],
                                submittingCounts.lastYear['Sep'],
                                submittingCounts.lastYear['Oct'],
                                submittingCounts.lastYear['Nov'],
                                submittingCounts.lastYear['Dec']
                            ])}
                        </th>
                    </tr>
                    <tr>
                        <th className="third-row-header">2024 Growth </th>
                        <td className="third-row-cells">{(submittingCounts.thisYear['Jan'] - submittingCounts.lastYear['Jan']).toFixed(2)}</td>
                        <td className="third-row-cells">{(submittingCounts.thisYear['Feb'] - submittingCounts.lastYear['Feb']).toFixed(2)}</td>
                        <td className="third-row-cells">{(submittingCounts.thisYear['Mar'] - submittingCounts.lastYear['Mar']).toFixed(2)}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {(calculateQtdAverage([
                                submittingCounts.thisYear['Jan'],
                                submittingCounts.thisYear['Feb'],
                                submittingCounts.thisYear['Mar']
                            ]) - calculateQtdAverage([
                                submittingCounts.lastYear['Jan'],
                                submittingCounts.lastYear['Feb'],
                                submittingCounts.lastYear['Mar']
                            ])).toFixed(2)}
                        </th>
                        <td className="third-row-cells">{(submittingCounts.thisYear['Apr'] - submittingCounts.lastYear['Apr']).toFixed(2)}</td>
                        <td className="third-row-cells">{(submittingCounts.thisYear['May'] - submittingCounts.lastYear['May']).toFixed(2)}</td>
                        <td className="third-row-cells">{(submittingCounts.thisYear['Jun'] - submittingCounts.lastYear['Jun']).toFixed(2)}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {(calculateQtdAverage([
                                submittingCounts.thisYear['Apr'],
                                submittingCounts.thisYear['May'],
                                submittingCounts.thisYear['Jun']
                            ]) - calculateQtdAverage([
                                submittingCounts.lastYear['Apr'],
                                submittingCounts.lastYear['May'],
                                submittingCounts.lastYear['Jun']
                            ])).toFixed(2)}
                        </th>       <td className="third-row-cells">{(submittingCounts.thisYear['Jul'] - submittingCounts.lastYear['Jul']).toFixed(2)}</td>
                        <td className="third-row-cells">{(submittingCounts.thisYear['Aug'] - submittingCounts.lastYear['Aug']).toFixed(2)}</td>
                        <td className="third-row-cells">{(submittingCounts.thisYear['Sep'] - submittingCounts.lastYear['Sep']).toFixed(2)}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {(calculateQtdAverage([
                                submittingCounts.thisYear['Jul'],
                                submittingCounts.thisYear['Aug'],
                                submittingCounts.thisYear['Sep']
                            ]) - calculateQtdAverage([
                                submittingCounts.lastYear['Jul'],
                                submittingCounts.lastYear['Aug'],
                                submittingCounts.lastYear['Sep']
                            ])).toFixed(2)}
                        </th>          <td className="third-row-cells">{(submittingCounts.thisYear['Oct'] - submittingCounts.lastYear['Oct']).toFixed(2)}</td>
                        <td className="third-row-cells">{(submittingCounts.thisYear['Nov'] - submittingCounts.lastYear['Nov']).toFixed(2)}</td>
                        <td className="third-row-cells">{(submittingCounts.thisYear['Dec'] - submittingCounts.lastYear['Dec']).toFixed(2)}</td>
                        <th style={{ backgroundColor: "#DCE6F1" }} className="qtd-column-cells">
                            {(calculateQtdAverage([
                                submittingCounts.thisYear['Oct'],
                                submittingCounts.thisYear['Nov'],
                                submittingCounts.thisYear['Dec']
                            ]) - calculateQtdAverage([
                                submittingCounts.lastYear['Oct'],
                                submittingCounts.lastYear['Nov'],
                                submittingCounts.lastYear['Dec']
                            ])).toFixed(2)}
                        </th>
                        <th style={{ color: "black" }} className="total-column-cells">
                            {(calculateQtdAverage([
                                submittingCounts.thisYear['Jan'],
                                submittingCounts.thisYear['Feb'],
                                submittingCounts.thisYear['Mar'],
                                submittingCounts.thisYear['Apr'],
                                submittingCounts.thisYear['May'],
                                submittingCounts.thisYear['Jun'],
                                submittingCounts.thisYear['Jul'],
                                submittingCounts.thisYear['Aug'],
                                submittingCounts.thisYear['Sep'],
                                submittingCounts.thisYear['Oct'],
                                submittingCounts.thisYear['Nov'],
                                submittingCounts.thisYear['Dec']
                            ]) -
                                calculateQtdAverage([
                                    submittingCounts.lastYear['Jan'],
                                    submittingCounts.lastYear['Feb'],
                                    submittingCounts.lastYear['Mar'],
                                    submittingCounts.lastYear['Apr'],
                                    submittingCounts.lastYear['May'],
                                    submittingCounts.lastYear['Jun'],
                                    submittingCounts.lastYear['Jul'],
                                    submittingCounts.lastYear['Aug'],
                                    submittingCounts.lastYear['Sep'],
                                    submittingCounts.lastYear['Oct'],
                                    submittingCounts.lastYear['Nov'],
                                    submittingCounts.lastYear['Dec']
                                ])).toFixed(2)}
                        </th>

                    </tr>

                    <tr></tr>
                    {/* Submitting Agent Premium */}

                    <tr>
                        <th className="top-table-header" style={{ backgroundColor: "#244062" }}>Submitting Agent Premium</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>AVG</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>AVG</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>AVG</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>AVG</th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>AVG</th>

                    </tr>
                    <tr>
                        <th className="first-row-header">2024 </th>
                        <td className="first-row-cells">
                            {data.thisYear['Jan'] && submittingCounts.thisYear['Jan']
                                ? (data.thisYear['Jan'] / submittingCounts.thisYear['Jan']).toFixed(2)
                                : 0}
                        </td>
                        <td className="first-row-cells">
                            {data.thisYear['Feb'] && submittingCounts.thisYear['Feb']
                                ? (data.thisYear['Feb'] / submittingCounts.thisYear['Feb']).toFixed(2)
                                : 0}
                        </td>
                        <td className="first-row-cells">
                            {data.thisYear['Mar'] && submittingCounts.thisYear['Mar']
                                ? (data.thisYear['Mar'] / submittingCounts.thisYear['Mar']).toFixed(2)
                                : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdSubAverage([
                                data.thisYear['Jan'] && submittingCounts.thisYear['Jan'] ? (data.thisYear['Jan'] / submittingCounts.thisYear['Jan']).toFixed(2) : 0,
                                data.thisYear['Feb'] && submittingCounts.thisYear['Feb'] ? (data.thisYear['Feb'] / submittingCounts.thisYear['Feb']).toFixed(2) : 0,
                                data.thisYear['Mar'] && submittingCounts.thisYear['Mar'] ? (data.thisYear['Mar'] / submittingCounts.thisYear['Mar']).toFixed(2) : 0
                            ])}
                        </th>
                        <td className="first-row-cells">
                            {data.thisYear['Apr'] && submittingCounts.thisYear['Apr']
                                ? (data.thisYear['Apr'] / submittingCounts.thisYear['Apr']).toFixed(2)
                                : 0}
                        </td>
                        <td className="first-row-cells">
                            {data.thisYear['May'] && submittingCounts.thisYear['May']
                                ? (data.thisYear['May'] / submittingCounts.thisYear['May']).toFixed(2)
                                : 0}
                        </td>
                        <td className="first-row-cells">
                            {data.thisYear['Jun'] && submittingCounts.thisYear['Jun']
                                ? (data.thisYear['Jun'] / submittingCounts.thisYear['Jun']).toFixed(2)
                                : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdSubAverage([
                                data.thisYear['Apr'] && submittingCounts.thisYear['Apr'] ? (data.thisYear['Apr'] / submittingCounts.thisYear['Jan']).toFixed(2) : 0,
                                data.thisYear['May'] && submittingCounts.thisYear['May'] ? (data.thisYear['May'] / submittingCounts.thisYear['Feb']).toFixed(2) : 0,
                                data.thisYear['Jun'] && submittingCounts.thisYear['Jun'] ? (data.thisYear['Jun'] / submittingCounts.thisYear['Mar']).toFixed(2) : 0
                            ])}
                        </th>            <td className="first-row-cells">
                            {data.thisYear['Jul'] && submittingCounts.thisYear['Jul']
                                ? (data.thisYear['Jul'] / submittingCounts.thisYear['Jul']).toFixed(2)
                                : 0}
                        </td>
                        <td className="first-row-cells">
                            {data.thisYear['Aug'] && submittingCounts.thisYear['Aug']
                                ? (data.thisYear['Aug'] / submittingCounts.thisYear['Aug']).toFixed(2)
                                : 0}
                        </td>
                        <td className="first-row-cells">
                            {data.thisYear['Sep'] && submittingCounts.thisYear['Sep']
                                ? (data.thisYear['Sep'] / submittingCounts.thisYear['Sep']).toFixed(2)
                                : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdSubAverage([
                                data.thisYear['Jul'] && submittingCounts.thisYear['Jul'] ? (data.thisYear['Jul'] / submittingCounts.thisYear['Jan']).toFixed(2) : 0,
                                data.thisYear['Aug'] && submittingCounts.thisYear['Aug'] ? (data.thisYear['Aug'] / submittingCounts.thisYear['Feb']).toFixed(2) : 0,
                                data.thisYear['Sep'] && submittingCounts.thisYear['Sep'] ? (data.thisYear['Sep'] / submittingCounts.thisYear['Mar']).toFixed(2) : 0
                            ])}
                        </th>            <td className="first-row-cells">
                            {data.thisYear['Oct'] && submittingCounts.thisYear['Oct']
                                ? (data.thisYear['Oct'] / submittingCounts.thisYear['Oct']).toFixed(2)
                                : 0}
                        </td>
                        <td className="first-row-cells">
                            {data.thisYear['Nov'] && submittingCounts.thisYear['Nov']
                                ? (data.thisYear['Nov'] / submittingCounts.thisYear['Nov']).toFixed(2)
                                : 0}
                        </td>
                        <td className="first-row-cells">
                            {data.thisYear['Dec'] && submittingCounts.thisYear['Dec']
                                ? (data.thisYear['Dec'] / submittingCounts.thisYear['Dec']).toFixed(2)
                                : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdSubAverage([
                                data.thisYear['Oct'] && submittingCounts.thisYear['Oct'] ? (data.thisYear['Oct'] / submittingCounts.thisYear['Jan']).toFixed(2) : 0,
                                data.thisYear['Nov'] && submittingCounts.thisYear['Nov'] ? (data.thisYear['Nov'] / submittingCounts.thisYear['Feb']).toFixed(2) : 0,
                                data.thisYear['Dec'] && submittingCounts.thisYear['Dec'] ? (data.thisYear['Dec'] / submittingCounts.thisYear['Mar']).toFixed(2) : 0
                            ])}
                        </th>
                        <th style={{ color: "red" }} className="total-column-cells">
                            {calculateQtdSubAverage([
                                data.thisYear['Jan'] && submittingCounts.thisYear['Jan'] ? (data.thisYear['Jan'] / submittingCounts.thisYear['Jan']).toFixed(2) : 0,
                                data.thisYear['Feb'] && submittingCounts.thisYear['Feb'] ? (data.thisYear['Feb'] / submittingCounts.thisYear['Feb']).toFixed(2) : 0,
                                data.thisYear['Mar'] && submittingCounts.thisYear['Mar'] ? (data.thisYear['Mar'] / submittingCounts.thisYear['Mar']).toFixed(2) : 0,
                                data.thisYear['Apr'] && submittingCounts.thisYear['Apr'] ? (data.thisYear['Apr'] / submittingCounts.thisYear['Jan']).toFixed(2) : 0,
                                data.thisYear['May'] && submittingCounts.thisYear['May'] ? (data.thisYear['May'] / submittingCounts.thisYear['Feb']).toFixed(2) : 0,
                                data.thisYear['Jun'] && submittingCounts.thisYear['Jun'] ? (data.thisYear['Jun'] / submittingCounts.thisYear['Mar']).toFixed(2) : 0,
                                data.thisYear['Jul'] && submittingCounts.thisYear['Jul'] ? (data.thisYear['Jul'] / submittingCounts.thisYear['Jan']).toFixed(2) : 0,
                                data.thisYear['Aug'] && submittingCounts.thisYear['Aug'] ? (data.thisYear['Aug'] / submittingCounts.thisYear['Feb']).toFixed(2) : 0,
                                data.thisYear['Sep'] && submittingCounts.thisYear['Sep'] ? (data.thisYear['Sep'] / submittingCounts.thisYear['Mar']).toFixed(2) : 0,
                                data.thisYear['Oct'] && submittingCounts.thisYear['Oct'] ? (data.thisYear['Oct'] / submittingCounts.thisYear['Oct']).toFixed(2) : 0,
                                data.thisYear['Nov'] && submittingCounts.thisYear['Nov'] ? (data.thisYear['Nov'] / submittingCounts.thisYear['Nov']).toFixed(2) : 0,
                                data.thisYear['Dec'] && submittingCounts.thisYear['Dec'] ? (data.thisYear['Dec'] / submittingCounts.thisYear['Dec']).toFixed(2) : 0
                            ])}
                        </th>
                    </tr>
                    <tr>
                        <th className="second-row-header">2023 Results</th>
                        <td className="second-row-cells">
                            {data.lastYear['Jan'] && submittingCounts.lastYear['Jan']
                                ? (data.lastYear['Jan'] / submittingCounts.lastYear['Jan']).toFixed(2)
                                : 0}
                        </td>
                        <td className="second-row-cells">
                            {data.lastYear['Feb'] && submittingCounts.lastYear['Feb']
                                ? (data.lastYear['Feb'] / submittingCounts.lastYear['Feb']).toFixed(2)
                                : 0}
                        </td>
                        <td className="second-row-cells">
                            {data.lastYear['Mar'] && submittingCounts.lastYear['Mar']
                                ? (data.lastYear['Mar'] / submittingCounts.lastYear['Mar']).toFixed(2)
                                : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdSubAverage([
                                data.lastYear['Jan'] && submittingCounts.lastYear['Jan'] ? (data.lastYear['Jan'] / submittingCounts.lastYear['Jan']).toFixed(2) : 0,
                                data.lastYear['Feb'] && submittingCounts.lastYear['Feb'] ? (data.lastYear['Feb'] / submittingCounts.lastYear['Feb']).toFixed(2) : 0,
                                data.lastYear['Mar'] && submittingCounts.lastYear['Mar'] ? (data.lastYear['Mar'] / submittingCounts.lastYear['Mar']).toFixed(2) : 0
                            ])}
                        </th>
                        <td className="second-row-cells">
                            {data.lastYear['Apr'] && submittingCounts.lastYear['Apr']
                                ? (data.lastYear['Apr'] / submittingCounts.lastYear['Apr']).toFixed(2)
                                : 0}
                        </td>
                        <td className="second-row-cells">
                            {data.lastYear['May'] && submittingCounts.lastYear['May']
                                ? (data.lastYear['May'] / submittingCounts.lastYear['May']).toFixed(2)
                                : 0}
                        </td>
                        <td className="second-row-cells">
                            {data.lastYear['Jun'] && submittingCounts.lastYear['Jun']
                                ? (data.lastYear['Jun'] / submittingCounts.lastYear['Jun']).toFixed(2)
                                : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdSubAverage([
                                data.lastYear['Apr'] && submittingCounts.lastYear['Apr'] ? (data.lastYear['Apr'] / submittingCounts.lastYear['Jan']).toFixed(2) : 0,
                                data.lastYear['May'] && submittingCounts.lastYear['May'] ? (data.lastYear['May'] / submittingCounts.lastYear['Feb']).toFixed(2) : 0,
                                data.lastYear['Jun'] && submittingCounts.lastYear['Jun'] ? (data.lastYear['Jun'] / submittingCounts.lastYear['Mar']).toFixed(2) : 0
                            ])}
                        </th>            <td className="second-row-cells">
                            {data.lastYear['Jul'] && submittingCounts.lastYear['Jul']
                                ? (data.lastYear['Jul'] / submittingCounts.lastYear['Jul']).toFixed(2)
                                : 0}
                        </td>
                        <td className="second-row-cells">
                            {data.lastYear['Aug'] && submittingCounts.lastYear['Aug']
                                ? (data.lastYear['Aug'] / submittingCounts.lastYear['Aug']).toFixed(2)
                                : 0}
                        </td>
                        <td className="second-row-cells">
                            {data.lastYear['Sep'] && submittingCounts.lastYear['Sep']
                                ? (data.lastYear['Sep'] / submittingCounts.lastYear['Sep']).toFixed(2)
                                : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdSubAverage([
                                data.lastYear['Jul'] && submittingCounts.lastYear['Jul'] ? (data.lastYear['Jul'] / submittingCounts.lastYear['Jan']).toFixed(2) : 0,
                                data.lastYear['Aug'] && submittingCounts.lastYear['Aug'] ? (data.lastYear['Aug'] / submittingCounts.lastYear['Feb']).toFixed(2) : 0,
                                data.lastYear['Sep'] && submittingCounts.lastYear['Sep'] ? (data.lastYear['Sep'] / submittingCounts.lastYear['Mar']).toFixed(2) : 0
                            ])}
                        </th>            <td className="second-row-cells">
                            {data.lastYear['Oct'] && submittingCounts.lastYear['Oct']
                                ? (data.lastYear['Oct'] / submittingCounts.lastYear['Oct']).toFixed(2)
                                : 0}
                        </td>
                        <td className="second-row-cells">
                            {data.lastYear['Nov'] && submittingCounts.lastYear['Nov']
                                ? (data.lastYear['Nov'] / submittingCounts.lastYear['Nov']).toFixed(2)
                                : 0}
                        </td>
                        <td className="second-row-cells">
                            {data.lastYear['Dec'] && submittingCounts.lastYear['Dec']
                                ? (data.lastYear['Dec'] / submittingCounts.lastYear['Dec']).toFixed(2)
                                : 0}
                        </td>
                        <th className="qtd-column-cells">
                            {calculateQtdSubAverage([
                                data.lastYear['Oct'] && submittingCounts.lastYear['Oct'] ? (data.lastYear['Oct'] / submittingCounts.lastYear['Jan']).toFixed(2) : 0,
                                data.lastYear['Nov'] && submittingCounts.lastYear['Nov'] ? (data.lastYear['Nov'] / submittingCounts.lastYear['Feb']).toFixed(2) : 0,
                                data.lastYear['Dec'] && submittingCounts.lastYear['Dec'] ? (data.lastYear['Dec'] / submittingCounts.lastYear['Mar']).toFixed(2) : 0
                            ])}
                        </th>
                        <th style={{ color: "black" }} className="total-column-cells">
                            {calculateQtdSubAverage([
                                data.lastYear['Jan'] && submittingCounts.lastYear['Jan'] ? (data.lastYear['Jan'] / submittingCounts.lastYear['Jan']).toFixed(2) : 0,
                                data.lastYear['Feb'] && submittingCounts.lastYear['Feb'] ? (data.lastYear['Feb'] / submittingCounts.lastYear['Feb']).toFixed(2) : 0,
                                data.lastYear['Mar'] && submittingCounts.lastYear['Mar'] ? (data.lastYear['Mar'] / submittingCounts.lastYear['Mar']).toFixed(2) : 0,
                                data.lastYear['Apr'] && submittingCounts.lastYear['Apr'] ? (data.lastYear['Apr'] / submittingCounts.lastYear['Jan']).toFixed(2) : 0,
                                data.lastYear['May'] && submittingCounts.lastYear['May'] ? (data.lastYear['May'] / submittingCounts.lastYear['Feb']).toFixed(2) : 0,
                                data.lastYear['Jun'] && submittingCounts.lastYear['Jun'] ? (data.lastYear['Jun'] / submittingCounts.lastYear['Mar']).toFixed(2) : 0,
                                data.lastYear['Jul'] && submittingCounts.lastYear['Jul'] ? (data.lastYear['Jul'] / submittingCounts.lastYear['Jan']).toFixed(2) : 0,
                                data.lastYear['Aug'] && submittingCounts.lastYear['Aug'] ? (data.lastYear['Aug'] / submittingCounts.lastYear['Feb']).toFixed(2) : 0,
                                data.lastYear['Sep'] && submittingCounts.lastYear['Sep'] ? (data.lastYear['Sep'] / submittingCounts.lastYear['Mar']).toFixed(2) : 0,
                                data.lastYear['Oct'] && submittingCounts.lastYear['Oct'] ? (data.lastYear['Oct'] / submittingCounts.lastYear['Oct']).toFixed(2) : 0,
                                data.lastYear['Nov'] && submittingCounts.lastYear['Nov'] ? (data.lastYear['Nov'] / submittingCounts.lastYear['Nov']).toFixed(2) : 0,
                                data.lastYear['Dec'] && submittingCounts.lastYear['Dec'] ? (data.lastYear['Dec'] / submittingCounts.lastYear['Dec']).toFixed(2) : 0
                            ])}
                        </th>
                    </tr>
                    <tr>
                        <th className="third-row-header">2024 Growth </th>
                        <td className="third-row-cells">
                            {calculateYearlyGrowth(
                                { data: data.thisYear['Jan'], submitting: submittingCounts.thisYear['Jan'] },
                                { data: data.lastYear['Jan'], submitting: submittingCounts.lastYear['Jan'] }
                            )}
                        </td>
                        <td className="third-row-cells">
                            {calculateYearlyGrowth(
                                { data: data.thisYear['Feb'], submitting: submittingCounts.thisYear['Feb'] },
                                { data: data.lastYear['Feb'], submitting: submittingCounts.lastYear['Feb'] }
                            )}
                        </td>
                        <td className="third-row-cells">
                            {calculateYearlyGrowth(
                                { data: data.thisYear['Mar'], submitting: submittingCounts.thisYear['Mar'] },
                                { data: data.lastYear['Mar'], submitting: submittingCounts.lastYear['Mar'] }
                            )}
                        </td>
                        <th className="qtd-column-cells">
                            {(calculateQtdSubAverage([
                                data.thisYear['Jan'] && submittingCounts.thisYear['Jan'] ? (data.thisYear['Jan'] / submittingCounts.thisYear['Jan']).toFixed(2) : 0,
                                data.thisYear['Feb'] && submittingCounts.thisYear['Feb'] ? (data.thisYear['Feb'] / submittingCounts.thisYear['Feb']).toFixed(2) : 0,
                                data.thisYear['Mar'] && submittingCounts.thisYear['Mar'] ? (data.thisYear['Mar'] / submittingCounts.thisYear['Mar']).toFixed(2) : 0
                            ]) -
                                calculateQtdSubAverage([
                                    data.lastYear['Jan'] && submittingCounts.lastYear['Jan'] ? (data.lastYear['Jan'] / submittingCounts.lastYear['Jan']).toFixed(2) : 0,
                                    data.lastYear['Feb'] && submittingCounts.lastYear['Feb'] ? (data.lastYear['Feb'] / submittingCounts.lastYear['Feb']).toFixed(2) : 0,
                                    data.lastYear['Mar'] && submittingCounts.lastYear['Mar'] ? (data.lastYear['Mar'] / submittingCounts.lastYear['Mar']).toFixed(2) : 0
                                ])).toFixed(2)}
                        </th>
                        <td className="third-row-cells">
                            {calculateYearlyGrowth(
                                { data: data.thisYear['Apr'], submitting: submittingCounts.thisYear['Apr'] },
                                { data: data.lastYear['Apr'], submitting: submittingCounts.lastYear['Apr'] }
                            )}
                        </td>
                        <td className="third-row-cells">
                            {calculateYearlyGrowth(
                                { data: data.thisYear['May'], submitting: submittingCounts.thisYear['May'] },
                                { data: data.lastYear['May'], submitting: submittingCounts.lastYear['May'] }
                            )}
                        </td>
                        <td className="third-row-cells">
                            {calculateYearlyGrowth(
                                { data: data.thisYear['Jun'], submitting: submittingCounts.thisYear['Jun'] },
                                { data: data.lastYear['Jun'], submitting: submittingCounts.lastYear['Jun'] }
                            )}
                        </td>
                        <th className="qtd-column-cells">
                            {(calculateQtdSubAverage([
                                data.thisYear['Apr'] && submittingCounts.thisYear['Apr'] ? (data.thisYear['Apr'] / submittingCounts.thisYear['Jan']).toFixed(2) : 0,
                                data.thisYear['May'] && submittingCounts.thisYear['May'] ? (data.thisYear['May'] / submittingCounts.thisYear['Feb']).toFixed(2) : 0,
                                data.thisYear['Jun'] && submittingCounts.thisYear['Jun'] ? (data.thisYear['Jun'] / submittingCounts.thisYear['Mar']).toFixed(2) : 0
                            ]) -
                                calculateQtdSubAverage([
                                    data.lastYear['Apr'] && submittingCounts.lastYear['Apr'] ? (data.lastYear['Apr'] / submittingCounts.lastYear['Jan']).toFixed(2) : 0,
                                    data.lastYear['May'] && submittingCounts.lastYear['May'] ? (data.lastYear['May'] / submittingCounts.lastYear['Feb']).toFixed(2) : 0,
                                    data.lastYear['Jun'] && submittingCounts.lastYear['Jun'] ? (data.lastYear['Jun'] / submittingCounts.lastYear['Mar']).toFixed(2) : 0
                                ])).toFixed(2)}
                        </th>
                        <td className="third-row-cells">
                            {calculateYearlyGrowth(
                                { data: data.thisYear['Jul'], submitting: submittingCounts.thisYear['Jul'] },
                                { data: data.lastYear['Jul'], submitting: submittingCounts.lastYear['Jul'] }
                            )}
                        </td>
                        <td className="third-row-cells">
                            {calculateYearlyGrowth(
                                { data: data.thisYear['Aug'], submitting: submittingCounts.thisYear['Aug'] },
                                { data: data.lastYear['Aug'], submitting: submittingCounts.lastYear['Aug'] }
                            )}
                        </td>
                        <td className="third-row-cells">
                            {calculateYearlyGrowth(
                                { data: data.thisYear['Sep'], submitting: submittingCounts.thisYear['Sep'] },
                                { data: data.lastYear['Sep'], submitting: submittingCounts.lastYear['Sep'] }
                            )}
                        </td>
                        <th className="qtd-column-cells">
                            {(calculateQtdSubAverage([
                                data.thisYear['Jul'] && submittingCounts.thisYear['Jul'] ? (data.thisYear['Jul'] / submittingCounts.thisYear['Jan']).toFixed(2) : 0,
                                data.thisYear['Aug'] && submittingCounts.thisYear['Aug'] ? (data.thisYear['Aug'] / submittingCounts.thisYear['Feb']).toFixed(2) : 0,
                                data.thisYear['Sep'] && submittingCounts.thisYear['Sep'] ? (data.thisYear['Sep'] / submittingCounts.thisYear['Mar']).toFixed(2) : 0
                            ]) -
                                calculateQtdSubAverage([
                                    data.lastYear['Jul'] && submittingCounts.lastYear['Jul'] ? (data.lastYear['Jul'] / submittingCounts.lastYear['Jan']).toFixed(2) : 0,
                                    data.lastYear['Aug'] && submittingCounts.lastYear['Aug'] ? (data.lastYear['Aug'] / submittingCounts.lastYear['Feb']).toFixed(2) : 0,
                                    data.lastYear['Sep'] && submittingCounts.lastYear['Sep'] ? (data.lastYear['Sep'] / submittingCounts.lastYear['Mar']).toFixed(2) : 0
                                ])).toFixed(2)}
                        </th>
                        <td className="third-row-cells">
                            {calculateYearlyGrowth(
                                { data: data.thisYear['Oct'], submitting: submittingCounts.thisYear['Oct'] },
                                { data: data.lastYear['Oct'], submitting: submittingCounts.lastYear['Oct'] }
                            )}
                        </td>
                        <td className="third-row-cells">
                            {calculateYearlyGrowth(
                                { data: data.thisYear['Nov'], submitting: submittingCounts.thisYear['Nov'] },
                                { data: data.lastYear['Nov'], submitting: submittingCounts.lastYear['Nov'] }
                            )}
                        </td>
                        <td className="third-row-cells">
                            {calculateYearlyGrowth(
                                { data: data.thisYear['Dec'], submitting: submittingCounts.thisYear['Dec'] },
                                { data: data.lastYear['Dec'], submitting: submittingCounts.lastYear['Dec'] }
                            )}
                        </td>
                        <th className="qtd-column-cells">
                            {(calculateQtdSubAverage([
                                data.thisYear['Oct'] && submittingCounts.thisYear['Oct'] ? (data.thisYear['Oct'] / submittingCounts.thisYear['Oct']).toFixed(2) : 0,
                                data.thisYear['Nov'] && submittingCounts.thisYear['Nov'] ? (data.thisYear['Nov'] / submittingCounts.thisYear['Nov']).toFixed(2) : 0,
                                data.thisYear['Dec'] && submittingCounts.thisYear['Dec'] ? (data.thisYear['Dec'] / submittingCounts.thisYear['Dec']).toFixed(2) : 0
                            ]) -
                                calculateQtdSubAverage([
                                    data.lastYear['Oct'] && submittingCounts.lastYear['Oct'] ? (data.lastYear['Oct'] / submittingCounts.lastYear['Jan']).toFixed(2) : 0,
                                    data.lastYear['Nov'] && submittingCounts.lastYear['Nov'] ? (data.lastYear['Nov'] / submittingCounts.lastYear['Feb']).toFixed(2) : 0,
                                    data.lastYear['Dec'] && submittingCounts.lastYear['Dec'] ? (data.lastYear['Dec'] / submittingCounts.lastYear['Mar']).toFixed(2) : 0
                                ])).toFixed(2)}
                        </th>
                        <th style={{ color: "black" }} className="total-column-cells">
                            {(calculateQtdSubAverage([
                                data.thisYear['Jan'] && submittingCounts.thisYear['Jan'] ? (data.thisYear['Jan'] / submittingCounts.thisYear['Jan']).toFixed(2) : 0,
                                data.thisYear['Feb'] && submittingCounts.thisYear['Feb'] ? (data.thisYear['Feb'] / submittingCounts.thisYear['Feb']).toFixed(2) : 0,
                                data.thisYear['Mar'] && submittingCounts.thisYear['Mar'] ? (data.thisYear['Mar'] / submittingCounts.thisYear['Mar']).toFixed(2) : 0,
                                data.thisYear['Apr'] && submittingCounts.thisYear['Apr'] ? (data.thisYear['Apr'] / submittingCounts.thisYear['Jan']).toFixed(2) : 0,
                                data.thisYear['May'] && submittingCounts.thisYear['May'] ? (data.thisYear['May'] / submittingCounts.thisYear['Feb']).toFixed(2) : 0,
                                data.thisYear['Jun'] && submittingCounts.thisYear['Jun'] ? (data.thisYear['Jun'] / submittingCounts.thisYear['Mar']).toFixed(2) : 0,
                                data.thisYear['Jul'] && submittingCounts.thisYear['Jul'] ? (data.thisYear['Jul'] / submittingCounts.thisYear['Jan']).toFixed(2) : 0,
                                data.thisYear['Aug'] && submittingCounts.thisYear['Aug'] ? (data.thisYear['Aug'] / submittingCounts.thisYear['Feb']).toFixed(2) : 0,
                                data.thisYear['Sep'] && submittingCounts.thisYear['Sep'] ? (data.thisYear['Sep'] / submittingCounts.thisYear['Mar']).toFixed(2) : 0,
                                data.thisYear['Oct'] && submittingCounts.thisYear['Oct'] ? (data.thisYear['Oct'] / submittingCounts.thisYear['Oct']).toFixed(2) : 0,
                                data.thisYear['Nov'] && submittingCounts.thisYear['Nov'] ? (data.thisYear['Nov'] / submittingCounts.thisYear['Nov']).toFixed(2) : 0,
                                data.thisYear['Dec'] && submittingCounts.thisYear['Dec'] ? (data.thisYear['Dec'] / submittingCounts.thisYear['Dec']).toFixed(2) : 0
                            ]) -
                                calculateQtdSubAverage([
                                    data.lastYear['Jan'] && submittingCounts.lastYear['Jan'] ? (data.lastYear['Jan'] / submittingCounts.lastYear['Jan']).toFixed(2) : 0,
                                    data.lastYear['Feb'] && submittingCounts.lastYear['Feb'] ? (data.lastYear['Feb'] / submittingCounts.lastYear['Feb']).toFixed(2) : 0,
                                    data.lastYear['Mar'] && submittingCounts.lastYear['Mar'] ? (data.lastYear['Mar'] / submittingCounts.lastYear['Mar']).toFixed(2) : 0,
                                    data.lastYear['Apr'] && submittingCounts.lastYear['Apr'] ? (data.lastYear['Apr'] / submittingCounts.lastYear['Jan']).toFixed(2) : 0,
                                    data.lastYear['May'] && submittingCounts.lastYear['May'] ? (data.lastYear['May'] / submittingCounts.lastYear['Feb']).toFixed(2) : 0,
                                    data.lastYear['Jun'] && submittingCounts.lastYear['Jun'] ? (data.lastYear['Jun'] / submittingCounts.lastYear['Mar']).toFixed(2) : 0,
                                    data.lastYear['Jul'] && submittingCounts.lastYear['Jul'] ? (data.lastYear['Jul'] / submittingCounts.lastYear['Jan']).toFixed(2) : 0,
                                    data.lastYear['Aug'] && submittingCounts.lastYear['Aug'] ? (data.lastYear['Aug'] / submittingCounts.lastYear['Feb']).toFixed(2) : 0,
                                    data.lastYear['Sep'] && submittingCounts.lastYear['Sep'] ? (data.lastYear['Sep'] / submittingCounts.lastYear['Mar']).toFixed(2) : 0,
                                    data.lastYear['Oct'] && submittingCounts.lastYear['Oct'] ? (data.lastYear['Oct'] / submittingCounts.lastYear['Oct']).toFixed(2) : 0,
                                    data.lastYear['Nov'] && submittingCounts.lastYear['Nov'] ? (data.lastYear['Nov'] / submittingCounts.lastYear['Nov']).toFixed(2) : 0,
                                    data.lastYear['Dec'] && submittingCounts.lastYear['Dec'] ? (data.lastYear['Dec'] / submittingCounts.lastYear['Dec']).toFixed(2) : 0
                                ])).toFixed(2)}
                        </th>

                    </tr>

                    <tr></tr>
                    {/* SA Count */}

                    <tr>
                        <th className="top-table-header" style={{ backgroundColor: "#244062" }}>SA Count</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>

                    </tr>
                    <tr>
                        <th className="second-row-header">2024 </th>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Jan'] ? managerCounts.thisYear['Jan'].uniqueSAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Feb'] ? managerCounts.thisYear['Feb'].uniqueSAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Mar'] ? managerCounts.thisYear['Mar'].uniqueSAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //managerCounts.thisYear['Jan'].uniqueSAs,
                            //managerCounts.thisYear['Feb'].uniqueSAs,
                            managerCounts.thisYear['Mar'].uniqueSAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Apr'] ? managerCounts.thisYear['Apr'].uniqueSAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['May'] ? managerCounts.thisYear['May'].uniqueSAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Jun'] ? managerCounts.thisYear['Jun'].uniqueSAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            // managerCounts.thisYear['Apr'].uniqueSAs,
                            // managerCounts.thisYear['May'].uniqueSAs,
                            managerCounts.thisYear['Jun'].uniqueSAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="second-row-cells">
                            {managerCounts.thisYear['Jul'] ? managerCounts.thisYear['Jul'].uniqueSAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Aug'] ? managerCounts.thisYear['Aug'].uniqueSAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Sep'] ? managerCounts.thisYear['Sep'].uniqueSAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            // managerCounts.thisYear['Jul'].uniqueSAs,
                            //  managerCounts.thisYear['Aug'].uniqueSAs,
                            managerCounts.thisYear['Sep'].uniqueSAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="second-row-cells">
                            {managerCounts.thisYear['Oct'] ? managerCounts.thisYear['Oct'].uniqueSAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Nov'] ? managerCounts.thisYear['Nov'].uniqueSAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Dec'] ? managerCounts.thisYear['Dec'].uniqueSAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.thisYear['Oct'].uniqueSAs,
                            //   managerCounts.thisYear['Nov'].uniqueSAs,
                            //   managerCounts.thisYear['Dec'].uniqueSAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <th style={{ color: "black" }} className="total-column-cells">{[
                            //   managerCounts.thisYear['Jan'].uniqueSAs,
                            //   managerCounts.thisYear['Feb'].uniqueSAs,
                            //   managerCounts.thisYear['Mar'].uniqueSAs,
                            //   managerCounts.thisYear['Apr'].uniqueSAs,
                            //   managerCounts.thisYear['May'].uniqueSAs,
                            //   managerCounts.thisYear['Jun'].uniqueSAs,
                            //   managerCounts.thisYear['Jul'].uniqueSAs,
                            //   managerCounts.thisYear['Aug'].uniqueSAs,
                            //   managerCounts.thisYear['Sep'].uniqueSAs,
                            //   managerCounts.thisYear['Oct'].uniqueSAs,
                            //   managerCounts.thisYear['Nov'].uniqueSAs,
                            managerCounts.thisYear['Dec'].uniqueSAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}</th>

                    </tr>
                    <tr>
                        <th className="third-row-header">2023</th>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Jan'] ? managerCounts.lastYear['Jan'].uniqueSAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Feb'] ? managerCounts.lastYear['Feb'].uniqueSAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Mar'] ? managerCounts.lastYear['Mar'].uniqueSAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.lastYear['Jan'].uniqueSAs,
                            //  managerCounts.lastYear['Feb'].uniqueSAs,
                            managerCounts.lastYear['Mar'].uniqueSAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Apr'] ? managerCounts.lastYear['Apr'].uniqueSAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['May'] ? managerCounts.lastYear['May'].uniqueSAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Jun'] ? managerCounts.lastYear['Jun'].uniqueSAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //   managerCounts.lastYear['Apr'].uniqueSAs,
                            //   managerCounts.lastYear['May'].uniqueSAs,
                            managerCounts.lastYear['Jun'].uniqueSAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="third-row-cells">
                            {managerCounts.lastYear['Jul'] ? managerCounts.lastYear['Jul'].uniqueSAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Aug'] ? managerCounts.lastYear['Aug'].uniqueSAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Sep'] ? managerCounts.lastYear['Sep'].uniqueSAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.lastYear['Jul'].uniqueSAs,
                            //  managerCounts.lastYear['Aug'].uniqueSAs,
                            managerCounts.lastYear['Sep'].uniqueSAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="third-row-cells">
                            {managerCounts.lastYear['Oct'] ? managerCounts.lastYear['Oct'].uniqueSAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Nov'] ? managerCounts.lastYear['Nov'].uniqueSAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Dec'] ? managerCounts.lastYear['Dec'].uniqueSAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.lastYear['Oct'].uniqueSAs,
                            //  managerCounts.lastYear['Nov'].uniqueSAs,
                            managerCounts.lastYear['Dec'].uniqueSAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <th style={{ color: "red" }} className="total-column-cells">{[
                            // managerCounts.lastYear['Jan'].uniqueSAs,
                            // managerCounts.lastYear['Feb'].uniqueSAs,
                            // managerCounts.lastYear['Mar'].uniqueSAs,
                            // managerCounts.lastYear['Apr'].uniqueSAs,
                            //  managerCounts.lastYear['May'].uniqueSAs,
                            // managerCounts.lastYear['Jun'].uniqueSAs,
                            // managerCounts.lastYear['Jul'].uniqueSAs,
                            // managerCounts.lastYear['Aug'].uniqueSAs,
                            // managerCounts.lastYear['Sep'].uniqueSAs,
                            // managerCounts.lastYear['Oct'].uniqueSAs,
                            // managerCounts.lastYear['Nov'].uniqueSAs,
                            managerCounts.lastYear['Dec'].uniqueSAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}</th>

                    </tr>

                    <tr></tr>
                    {/* GA Count */}

                    <tr>
                        <th className="top-table-header" style={{ backgroundColor: "#244062" }}>GA Count</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>

                    </tr>
                    <tr>
                        <th className="second-row-header">2024 </th>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Jan'] ? managerCounts.thisYear['Jan'].uniqueGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Feb'] ? managerCounts.thisYear['Feb'].uniqueGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Mar'] ? managerCounts.thisYear['Mar'].uniqueGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            // managerCounts.thisYear['Jan'].uniqueGAs,
                            //  managerCounts.thisYear['Feb'].uniqueGAs,
                            managerCounts.thisYear['Mar'].uniqueGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Apr'] ? managerCounts.thisYear['Apr'].uniqueGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['May'] ? managerCounts.thisYear['May'].uniqueGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Jun'] ? managerCounts.thisYear['Jun'].uniqueGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.thisYear['Apr'].uniqueGAs,
                            //  managerCounts.thisYear['May'].uniqueGAs,
                            managerCounts.thisYear['Jun'].uniqueGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="second-row-cells">
                            {managerCounts.thisYear['Jul'] ? managerCounts.thisYear['Jul'].uniqueGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Aug'] ? managerCounts.thisYear['Aug'].uniqueGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Sep'] ? managerCounts.thisYear['Sep'].uniqueGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            // managerCounts.thisYear['Jul'].uniqueGAs,
                            // managerCounts.thisYear['Aug'].uniqueGAs,
                            managerCounts.thisYear['Sep'].uniqueGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="second-row-cells">
                            {managerCounts.thisYear['Oct'] ? managerCounts.thisYear['Oct'].uniqueGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Nov'] ? managerCounts.thisYear['Nov'].uniqueGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Dec'] ? managerCounts.thisYear['Dec'].uniqueGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            // managerCounts.thisYear['Oct'].uniqueGAs,
                            // managerCounts.thisYear['Nov'].uniqueGAs,
                            managerCounts.thisYear['Dec'].uniqueGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <th style={{ color: "black" }} className="total-column-cells">{[
                            //  managerCounts.thisYear['Jan'].uniqueGAs,
                            //  managerCounts.thisYear['Feb'].uniqueGAs,
                            //   managerCounts.thisYear['Mar'].uniqueGAs,
                            //  managerCounts.thisYear['Apr'].uniqueGAs,
                            //  managerCounts.thisYear['May'].uniqueGAs,
                            //  managerCounts.thisYear['Jun'].uniqueGAs,
                            //  managerCounts.thisYear['Jul'].uniqueGAs,
                            //  managerCounts.thisYear['Aug'].uniqueGAs,
                            //  managerCounts.thisYear['Sep'].uniqueGAs,
                            //  managerCounts.thisYear['Oct'].uniqueGAs,
                            //   managerCounts.thisYear['Nov'].uniqueGAs,
                            managerCounts.thisYear['Dec'].uniqueGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}</th>

                    </tr>
                    <tr>
                        <th className="third-row-header">2023</th>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Jan'] ? managerCounts.lastYear['Jan'].uniqueGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Feb'] ? managerCounts.lastYear['Feb'].uniqueGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Mar'] ? managerCounts.lastYear['Mar'].uniqueGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.lastYear['Jan'].uniqueGAs,
                            //  managerCounts.lastYear['Feb'].uniqueGAs,
                            managerCounts.lastYear['Mar'].uniqueGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Apr'] ? managerCounts.lastYear['Apr'].uniqueGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['May'] ? managerCounts.lastYear['May'].uniqueGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Jun'] ? managerCounts.lastYear['Jun'].uniqueGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.lastYear['Apr'].uniqueGAs,
                            //  managerCounts.lastYear['May'].uniqueGAs,
                            managerCounts.lastYear['Jun'].uniqueGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="third-row-cells">
                            {managerCounts.lastYear['Jul'] ? managerCounts.lastYear['Jul'].uniqueGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Aug'] ? managerCounts.lastYear['Aug'].uniqueGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Sep'] ? managerCounts.lastYear['Sep'].uniqueGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.lastYear['Jul'].uniqueGAs,
                            //  managerCounts.lastYear['Aug'].uniqueGAs,
                            managerCounts.lastYear['Sep'].uniqueGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="third-row-cells">
                            {managerCounts.lastYear['Oct'] ? managerCounts.lastYear['Oct'].uniqueGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Nov'] ? managerCounts.lastYear['Nov'].uniqueGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Dec'] ? managerCounts.lastYear['Dec'].uniqueGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.lastYear['Oct'].uniqueGAs,
                            //  managerCounts.lastYear['Nov'].uniqueGAs,
                            managerCounts.lastYear['Dec'].uniqueGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <th style={{ color: "red" }} className="total-column-cells">{[
                            //  managerCounts.lastYear['Jan'].uniqueGAs,
                            //  managerCounts.lastYear['Feb'].uniqueGAs,
                            //  managerCounts.lastYear['Mar'].uniqueGAs,
                            //   managerCounts.lastYear['Apr'].uniqueGAs,
                            //  managerCounts.lastYear['May'].uniqueGAs,
                            //  managerCounts.lastYear['Jun'].uniqueGAs,
                            //  managerCounts.lastYear['Jul'].uniqueGAs,
                            //   managerCounts.lastYear['Aug'].uniqueGAs,
                            //  managerCounts.lastYear['Sep'].uniqueGAs,
                            //  managerCounts.lastYear['Oct'].uniqueGAs,
                            //  managerCounts.lastYear['Nov'].uniqueGAs,
                            managerCounts.lastYear['Dec'].uniqueGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}</th>

                    </tr>

                    <tr></tr>
                    {/* MGA Count */}

                    <tr>
                        <th className="top-table-header" style={{ backgroundColor: "#244062" }}>MGA Count</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>

                    </tr>
                    <tr>
                        <th className="second-row-header">2024 </th>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Jan'] ? managerCounts.thisYear['Jan'].uniqueMGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Feb'] ? managerCounts.thisYear['Feb'].uniqueMGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Mar'] ? managerCounts.thisYear['Mar'].uniqueMGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                          //  managerCounts.thisYear['Jan'].uniqueMGAs,
                          //  managerCounts.thisYear['Feb'].uniqueMGAs,
                            managerCounts.thisYear['Mar'].uniqueMGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Apr'] ? managerCounts.thisYear['Apr'].uniqueMGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['May'] ? managerCounts.thisYear['May'].uniqueMGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Jun'] ? managerCounts.thisYear['Jun'].uniqueMGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                          //  managerCounts.thisYear['Apr'].uniqueMGAs,
                          //  managerCounts.thisYear['May'].uniqueMGAs,
                            managerCounts.thisYear['Jun'].uniqueMGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="second-row-cells">
                            {managerCounts.thisYear['Jul'] ? managerCounts.thisYear['Jul'].uniqueMGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Aug'] ? managerCounts.thisYear['Aug'].uniqueMGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Sep'] ? managerCounts.thisYear['Sep'].uniqueMGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                           // managerCounts.thisYear['Jul'].uniqueMGAs,
                           // managerCounts.thisYear['Aug'].uniqueMGAs,
                            managerCounts.thisYear['Sep'].uniqueMGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="second-row-cells">
                            {managerCounts.thisYear['Oct'] ? managerCounts.thisYear['Oct'].uniqueMGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Nov'] ? managerCounts.thisYear['Nov'].uniqueMGAs : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Dec'] ? managerCounts.thisYear['Dec'].uniqueMGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                           // managerCounts.thisYear['Oct'].uniqueMGAs,
                           // managerCounts.thisYear['Nov'].uniqueMGAs,
                            managerCounts.thisYear['Dec'].uniqueMGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <th style={{ color: "black" }} className="total-column-cells">{[
                           // managerCounts.thisYear['Jan'].uniqueMGAs,
                           // managerCounts.thisYear['Feb'].uniqueMGAs,
                           // managerCounts.thisYear['Mar'].uniqueMGAs,
                           // managerCounts.thisYear['Apr'].uniqueMGAs,
                           // managerCounts.thisYear['May'].uniqueMGAs,
                           // managerCounts.thisYear['Jun'].uniqueMGAs,
                           // managerCounts.thisYear['Jul'].uniqueMGAs,
                           // managerCounts.thisYear['Aug'].uniqueMGAs,
                           // managerCounts.thisYear['Sep'].uniqueMGAs,
                           // managerCounts.thisYear['Oct'].uniqueMGAs,
                           // managerCounts.thisYear['Nov'].uniqueMGAs,
                            managerCounts.thisYear['Dec'].uniqueMGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}</th>

                    </tr>
                    <tr>
                        <th className="third-row-header">2023</th>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Jan'] ? managerCounts.lastYear['Jan'].uniqueMGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Feb'] ? managerCounts.lastYear['Feb'].uniqueMGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Mar'] ? managerCounts.lastYear['Mar'].uniqueMGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.lastYear['Jan'].uniqueMGAs,
                            //   managerCounts.lastYear['Feb'].uniqueMGAs,
                            managerCounts.lastYear['Mar'].uniqueMGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Apr'] ? managerCounts.lastYear['Apr'].uniqueMGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['May'] ? managerCounts.lastYear['May'].uniqueMGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Jun'] ? managerCounts.lastYear['Jun'].uniqueMGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.lastYear['Apr'].uniqueMGAs,
                            //  managerCounts.lastYear['May'].uniqueMGAs,
                            managerCounts.lastYear['Jun'].uniqueMGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="third-row-cells">
                            {managerCounts.lastYear['Jul'] ? managerCounts.lastYear['Jul'].uniqueMGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Aug'] ? managerCounts.lastYear['Aug'].uniqueMGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Sep'] ? managerCounts.lastYear['Sep'].uniqueMGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.lastYear['Jul'].uniqueMGAs,
                            //  managerCounts.lastYear['Aug'].uniqueMGAs,
                            managerCounts.lastYear['Sep'].uniqueMGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="third-row-cells">
                            {managerCounts.lastYear['Oct'] ? managerCounts.lastYear['Oct'].uniqueMGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Nov'] ? managerCounts.lastYear['Nov'].uniqueMGAs : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Dec'] ? managerCounts.lastYear['Dec'].uniqueMGAs : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.lastYear['Oct'].uniqueMGAs,
                            //  managerCounts.lastYear['Nov'].uniqueMGAs,
                            managerCounts.lastYear['Dec'].uniqueMGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <th style={{ color: "red" }} className="total-column-cells">{[
                            //  managerCounts.lastYear['Jan'].uniqueMGAs,
                            //  managerCounts.lastYear['Feb'].uniqueMGAs,
                            //  managerCounts.lastYear['Mar'].uniqueMGAs,
                            //  managerCounts.lastYear['Apr'].uniqueMGAs,
                            //  managerCounts.lastYear['May'].uniqueMGAs,
                            //   managerCounts.lastYear['Jun'].uniqueMGAs,
                            //  managerCounts.lastYear['Jul'].uniqueMGAs,
                            //  managerCounts.lastYear['Aug'].uniqueMGAs,
                            //  managerCounts.lastYear['Sep'].uniqueMGAs,
                            //  managerCounts.lastYear['Oct'].uniqueMGAs,
                            //  managerCounts.lastYear['Nov'].uniqueMGAs,
                            managerCounts.lastYear['Dec'].uniqueMGAs
                        ].reduce((acc, val) => acc + (val || 0), 0)}</th>

                    </tr>

                    <tr></tr>

                    {/* Total Count */}

                    <tr>
                        <th className="top-table-header" style={{ backgroundColor: "#244062" }}>Total Management Count</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th colSpan={3} className="top-table-header" style={{ backgroundColor: "white" }}></th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Current</th>
                        <th className="top-table-header" style={{ backgroundColor: "white", color: "#0558bc" }}>Tot</th>

                    </tr>
                    <tr>
                        <th className="second-row-header">2024 </th>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Jan'] ? managerCounts.thisYear['Jan'].totalUniqueManagers : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Feb'] ? managerCounts.thisYear['Feb'].totalUniqueManagers : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Mar'] ? managerCounts.thisYear['Mar'].totalUniqueManagers : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                          //  managerCounts.thisYear['Jan'].totalUniqueManagers,
                          //  managerCounts.thisYear['Feb'].totalUniqueManagers,
                            managerCounts.thisYear['Mar'].totalUniqueManagers
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Apr'] ? managerCounts.thisYear['Apr'].totalUniqueManagers : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['May'] ? managerCounts.thisYear['May'].totalUniqueManagers : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Jun'] ? managerCounts.thisYear['Jun'].totalUniqueManagers : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                          //  managerCounts.thisYear['Apr'].totalUniqueManagers,
                          //  managerCounts.thisYear['May'].totalUniqueManagers,
                            managerCounts.thisYear['Jun'].totalUniqueManagers
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="second-row-cells">
                            {managerCounts.thisYear['Jul'] ? managerCounts.thisYear['Jul'].totalUniqueManagers : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Aug'] ? managerCounts.thisYear['Aug'].totalUniqueManagers : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Sep'] ? managerCounts.thisYear['Sep'].totalUniqueManagers : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                           // managerCounts.thisYear['Jul'].totalUniqueManagers,
                           // managerCounts.thisYear['Aug'].totalUniqueManagers,
                            managerCounts.thisYear['Sep'].totalUniqueManagers
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="second-row-cells">
                            {managerCounts.thisYear['Oct'] ? managerCounts.thisYear['Oct'].totalUniqueManagers : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Nov'] ? managerCounts.thisYear['Nov'].totalUniqueManagers : 0}
                        </td>
                        <td className="second-row-cells">
                            {managerCounts.thisYear['Dec'] ? managerCounts.thisYear['Dec'].totalUniqueManagers : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                           // managerCounts.thisYear['Oct'].totalUniqueManagers,
                           // managerCounts.thisYear['Nov'].totalUniqueManagers,
                            managerCounts.thisYear['Dec'].totalUniqueManagers
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <th style={{ color: "black" }} className="total-column-cells">{[
                           // managerCounts.thisYear['Jan'].totalUniqueManagers,
                           // managerCounts.thisYear['Feb'].totalUniqueManagers,
                           // managerCounts.thisYear['Mar'].totalUniqueManagers,
                           // managerCounts.thisYear['Apr'].totalUniqueManagers,
                           // managerCounts.thisYear['May'].totalUniqueManagers,
                           // managerCounts.thisYear['Jun'].totalUniqueManagers,
                           // managerCounts.thisYear['Jul'].totalUniqueManagers,
                           // managerCounts.thisYear['Aug'].totalUniqueManagers,
                           // managerCounts.thisYear['Sep'].totalUniqueManagers,
                           // managerCounts.thisYear['Oct'].totalUniqueManagers,
                           // managerCounts.thisYear['Nov'].totalUniqueManagers,
                            managerCounts.thisYear['Dec'].totalUniqueManagers
                        ].reduce((acc, val) => acc + (val || 0), 0)}</th>

                    </tr>
                    <tr>
                        <th className="third-row-header">2023</th>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Jan'] ? managerCounts.lastYear['Jan'].totalUniqueManagers : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Feb'] ? managerCounts.lastYear['Feb'].totalUniqueManagers : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Mar'] ? managerCounts.lastYear['Mar'].totalUniqueManagers : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.lastYear['Jan'].totalUniqueManagers,
                            //   managerCounts.lastYear['Feb'].totalUniqueManagers,
                            managerCounts.lastYear['Mar'].totalUniqueManagers
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Apr'] ? managerCounts.lastYear['Apr'].totalUniqueManagers : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['May'] ? managerCounts.lastYear['May'].totalUniqueManagers : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Jun'] ? managerCounts.lastYear['Jun'].totalUniqueManagers : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.lastYear['Apr'].totalUniqueManagers,
                            //  managerCounts.lastYear['May'].totalUniqueManagers,
                            managerCounts.lastYear['Jun'].totalUniqueManagers
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="third-row-cells">
                            {managerCounts.lastYear['Jul'] ? managerCounts.lastYear['Jul'].totalUniqueManagers : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Aug'] ? managerCounts.lastYear['Aug'].totalUniqueManagers : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Sep'] ? managerCounts.lastYear['Sep'].totalUniqueManagers : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.lastYear['Jul'].totalUniqueManagers,
                            //  managerCounts.lastYear['Aug'].totalUniqueManagers,
                            managerCounts.lastYear['Sep'].totalUniqueManagers
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>            <td className="third-row-cells">
                            {managerCounts.lastYear['Oct'] ? managerCounts.lastYear['Oct'].totalUniqueManagers : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Nov'] ? managerCounts.lastYear['Nov'].totalUniqueManagers : 0}
                        </td>
                        <td className="third-row-cells">
                            {managerCounts.lastYear['Dec'] ? managerCounts.lastYear['Dec'].totalUniqueManagers : 0}
                        </td>
                        <th className="qtd-column-cells">{[
                            //  managerCounts.lastYear['Oct'].totalUniqueManagers,
                            //  managerCounts.lastYear['Nov'].totalUniqueManagers,
                            managerCounts.lastYear['Dec'].totalUniqueManagers
                        ].reduce((acc, val) => acc + (val || 0), 0)}
                        </th>
                        <th style={{ color: "red" }} className="total-column-cells">{[
                            //  managerCounts.lastYear['Jan'].totalUniqueManagers,
                            //  managerCounts.lastYear['Feb'].totalUniqueManagers,
                            //  managerCounts.lastYear['Mar'].totalUniqueManagers,
                            //  managerCounts.lastYear['Apr'].totalUniqueManagers,
                            //  managerCounts.lastYear['May'].totalUniqueManagers,
                            //   managerCounts.lastYear['Jun'].totalUniqueManagers,
                            //  managerCounts.lastYear['Jul'].totalUniqueManagers,
                            //  managerCounts.lastYear['Aug'].totalUniqueManagers,
                            //  managerCounts.lastYear['Sep'].totalUniqueManagers,
                            //  managerCounts.lastYear['Oct'].totalUniqueManagers,
                            //  managerCounts.lastYear['Nov'].totalUniqueManagers,
                            managerCounts.lastYear['Dec'].totalUniqueManagers
                        ].reduce((acc, val) => acc + (val || 0), 0)}</th>

                    </tr>


                    <tbody>



                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RGaGrowth;
