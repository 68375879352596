import React, { useState, useEffect, useRef } from 'react';
import ActiveUsersTable from './ActivityTable';
import LoggedInAgentForm from './LoggedInAgentForm';
import ActivityCards from '../Dashboard/ActivityCard';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DateRangePicker } from 'react-date-range';
import FiltersContainer from '../utils/FiltersContainer';

const Report = ({ saData, dailyActivityData, activeUsersData }) => {
    const [reportFormData, setReportFormData] = useState(() => {
        const savedData = localStorage.getItem('reportFormData');
        return savedData ? JSON.parse(savedData) : {};
    });
    const [isDataFetched, setIsDataFetched] = useState(false);
    const userRole = localStorage.getItem('userRole');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [view, setView] = useState('myview');
    const [filters, setFilters] = useState({
        tree: [],
        legacy: [],
        rga: [],
        mga: [],
        agent: [],
    });

    const [filterOptions, setFilterOptions] = useState({
        trees: [],
        legacies: [],
        rgas: [],
        mgas: [],
        agents: [],
    });

    const [displayMode, setDisplayMode] = useState('both'); // 'both', 'calendar', 'presets'
    const [isPickerVisible, setIsPickerVisible] = useState(false);
    const [isDateRangeHovered, setIsDateRangeHovered] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState(''); // State to store selected agent

    const toggleDisplayMode = () => {
        setDisplayMode(displayMode === 'calendar' ? 'presets' : 'calendar');
    };

    const calculateDateRange = () => {
        const today = new Date();
        const dayOfWeek = today.getDay(); // Sunday - 0, Monday - 1, ..., Saturday - 6
        const dayOfMonth = today.getDate();
        const currentDay = today.getDay();
        const daysToLastMonday = currentDay === 0 ? 6 : dayOfWeek - 1;

        // Calculate the most recent Monday
        const mostRecentMonday = new Date(today);
        mostRecentMonday.setDate(dayOfMonth - daysToLastMonday);
        mostRecentMonday.setHours(0, 0, 0, 0); // Start of the day

        // Calculate the following Sunday by adding 6 days to the most recent Monday
        const nextSunday = new Date(mostRecentMonday);
        nextSunday.setDate(mostRecentMonday.getDate() + 6);
        nextSunday.setHours(23, 59, 59, 999); // End of the day

        return [
            {
                startDate: mostRecentMonday,
                endDate: nextSunday,
                key: 'selection',
            },
        ];
    };

    const [dateRange, setDateRange] = useState(calculateDateRange());

    const sortOptionsAlphabetically = (options) => {
        return options.sort((a, b) => {
            // Ensure both a and b have labels
            if (!a.label || !b.label) return 0; // Consider how you want to handle undefined labels
            return a.label.localeCompare(b.label);
        });
    };

    const prevAgentsRef = useRef();

    useEffect(() => {
        if (isDataFetched) {
            const savedAgents = JSON.parse(localStorage.getItem('selectedAgents'));
            if (savedAgents && savedAgents.length > 0 && JSON.stringify(prevAgentsRef.current) !== JSON.stringify(filterOptions.agents.map(option => option.value))) {
                const validAgents = savedAgents.filter(agent => filterOptions.agents.some(option => option.value === agent));
                if (validAgents.length > 0) {
                    setFilters(prevFilters => ({ ...prevFilters, agent: validAgents }));
                }
                prevAgentsRef.current = filterOptions.agents.map(option => option.value);
            }
        }
    }, [isDataFetched, filterOptions.agents]);

    const initialSetupDone = useRef(false); // Ref to track if initial setup is done

    useEffect(() => {
        if (!initialSetupDone.current) { // Check if the initial setup hasn't been done yet
            const role = localStorage.getItem('userRole');
            const mgaRgaData = localStorage.getItem('mgaRgaData');
            if (mgaRgaData) {
                const { MGA } = JSON.parse(mgaRgaData);
                if (MGA) {
                    if (role === 'RGA') {
                        setFilters(prevFilters => ({
                            ...prevFilters,
                            rga: [MGA]
                        }));
                    } else if (role === 'MGA') {
                        setFilters(prevFilters => ({
                            ...prevFilters,
                            mga: [MGA]
                        }));
                    }
                }
            }
            initialSetupDone.current = true; // Mark the initial setup as done
        }
    }, []);

    useEffect(() => {
        applyHierarchicalFilters();
    }, [data, dateRange, filters]);

    const parseDateAsLocal = (input) => {
        const [year, month, day] = input.split('-').map(val => parseInt(val, 10));
        return new Date(year, month - 1, day);
    };

    const applyHierarchicalFilters = () => {
        let currentFilteredData = data.filter(item => {
            const itemDate = parseDateAsLocal(item.date);
            const startDate = new Date(dateRange[0].startDate).setHours(0, 0, 0, 0);
            const endDate = new Date(dateRange[0].endDate).setHours(23, 59, 59, 999);
            return itemDate >= startDate && itemDate <= endDate;
        });

        if (filters.tree.length) {
            currentFilteredData = currentFilteredData.filter(item => filters.tree.includes(item.tree));
        }
        if (filters.legacy.length) {
            currentFilteredData = currentFilteredData.filter(item => filters.legacy.includes(item.legacy));
        }
        if (filters.rga.length) {
            currentFilteredData = currentFilteredData.filter(item =>
                filters.rga.includes(item.rga) || filters.rga.includes(item.mga)
            );
        }
        if (filters.mga.length) {
            currentFilteredData = currentFilteredData.filter(item => filters.mga.includes(item.mga));
        }
        if (filters.agent.length && !filters.agent.includes('all')) {
            currentFilteredData = currentFilteredData.filter(item => filters.agent.includes(item.agent));
        }

        setFilteredData(currentFilteredData);
        updateDropdownOptions(currentFilteredData); // Pass the filtered data for dropdown update
    };

    const datePickerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = event => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setIsPickerVisible(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const updateDropdownOptions = (dateFilteredData) => {
        const legaciesFilteredData = filters.tree.length ? dateFilteredData.filter(item => filters.tree.includes(item.tree)) : dateFilteredData;
        const rgasFilteredData = filters.legacy.length ? legaciesFilteredData.filter(item => filters.legacy.includes(item.legacy)) : legaciesFilteredData;
        const mgasFilteredData = filters.rga.length ? rgasFilteredData.filter(item => filters.rga.includes(item.rga)) : rgasFilteredData;
        const agentsFilteredData = filters.mga.length ? mgasFilteredData.filter(item => filters.mga.includes(item.mga)) : mgasFilteredData;

        setFilterOptions({
            trees: sortOptionsAlphabetically(getUniqueOptions(dateFilteredData, 'tree')),
            legacies: sortOptionsAlphabetically(getUniqueOptions(legaciesFilteredData, 'legacy')),
            rgas: sortOptionsAlphabetically(getUniqueOptions(rgasFilteredData, 'rga')),
            mgas: sortOptionsAlphabetically(getUniqueOptions(mgasFilteredData, 'mga')),
            agents: sortOptionsAlphabetically(getUniqueOptions(agentsFilteredData, 'agent')),
        });
    };

    const getUniqueOptions = (data, key) => {
        const unique = Array.from(new Set(data.map(item => item[key])))
            .map(value => ({ value, label: value }));
        return unique;
    };

    useEffect(() => {
        if (!filterOptions.agents.find(option => option.value === 'all')) {
            setFilterOptions(prevOptions => ({
                ...prevOptions,
                agents: [{ value: 'all', label: 'Agency Wide Reported Activity' }, ...prevOptions.agents],
            }));
        }
    }, [filterOptions.agents, isDataFetched]);

    useEffect(() => {
        const savedAgent = JSON.parse(localStorage.getItem('agentSelection'));
        if (savedAgent && savedAgent.length > 0) {
            setFilters(prevFilters => ({
                ...prevFilters,
                agent: savedAgent,
            }));
        }

        if (userRole === 'mga') {
            const savedMGA = JSON.parse(localStorage.getItem('mgaSelection'));
            const savedRGA = JSON.parse(localStorage.getItem('rgaSelection'));

            if (savedMGA && savedMGA.length > 0) {
                setFilters(prevFilters => ({
                    ...prevFilters,
                    mga: savedMGA,
                }));
            }

            if (savedRGA && savedRGA.length > 0) {
                setFilters(prevFilters => ({
                    ...prevFilters,
                    rga: savedRGA,
                }));
            }
        }
    }, [userRole]);

    const handleSelect = (ranges) => {
        const { selection } = ranges;
        let startDate = new Date(selection.startDate);
        let endDate = new Date(selection.endDate);

        startDate.setHours(0, 0, 0, 0);

        if (startDate.toDateString() === endDate.toDateString()) {
            endDate.setHours(23, 59, 59, 999);
        } else {
            endDate.setHours(23, 59, 59, 999);
        }

        setDateRange([{ startDate, endDate, key: 'selection' }]);
        applyHierarchicalFilters();
    };

    const togglePicker = () => {
        setIsPickerVisible(!isPickerVisible);
    };

    useEffect(() => {
        localStorage.setItem('reportFormData', JSON.stringify(reportFormData));
    }, [reportFormData]);

    const handleDateChange = (startDate, endDate) => {
        setDateRange([{ startDate, endDate }]);
    };

    return (
        <div className='app-container'>
        <div className='large-app-container'>
            <div>
                <div className="tabs">
                    <input
                        type="radio"
                        id="myview"
                        name="view_type"
                        value="myview"
                        checked={view === 'myview'}
                        onChange={() => setView('myview')}
                    />
                    <label htmlFor="myview">My View</label>
                    <input
                        type="radio"
                        id="team"
                        name="view_type"
                        value="team"
                        checked={view === 'team'}
                        onChange={() => setView('team')}
                    />
                    <label htmlFor="team">Team View</label>
                </div>

                {view === 'myview' && <LoggedInAgentForm onAgentChange={setSelectedAgent} />}
                {view === 'team' && <ActiveUsersTable />}
            </div>
            <div className="header-with-toggle-and-filters">
                <div className="date-picker-and-filter-group"
                    style={{ position: 'relative' }}
                    onMouseEnter={() => setIsDateRangeHovered(true)}
                    onMouseLeave={() => setIsDateRangeHovered(false)}
                >
                    <button onClick={togglePicker} className="calendar-button">
                        <button className="toggle-display-mode-button" onClick={toggleDisplayMode}>
                            <i className="fas fa-sync"></i> |
                        </button>
                        <i className="fas fa-calendar-alt"></i>
                        <span>{dateRange[0].startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</span>
                        <span> - </span>
                        <span>{dateRange[0].endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</span>
                    </button>
                    {isPickerVisible && (
                        <div ref={datePickerRef} className={`date-picker-overlay ${displayMode === 'presets' ? 'show-presets' : ''}`}>
                            <DateRangePicker ranges={dateRange} onChange={handleSelect} direction='vertical' months={1} />
                        </div>
                    )}
                </div>
                {(view === 'none' || view === 'none') && (
                    <div className="filters-container">
                        <FiltersContainer filters={filters} setFilters={setFilters} filterOptions={filterOptions} />
                    </div>
                )}
            </div>
            <ActivityCards
                startDate={dateRange[0].startDate}
                endDate={dateRange[0].endDate}
                view={view}
                selectedAgent={selectedAgent} // Pass the selected agent
                isDateRangeHovered={false} // Adjust this as needed
            />
        </div>
        </div>
    );
};

export default Report;
