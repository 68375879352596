// src/components/Recruiting/steps.js
export const UNIFORM_STEPS = [
    'Recruiting Lead',
    'Overview',
    'Careers Form',
    'Final',
    'Pre-Lic',
    'Test',
    'BG Check',
    'Compliance',
    'AOB',
    'Agent #',
    'Impact Setup',
    'Training',
    'Coded',
    'VIP 1',
    'VIP 2',
    'VIP 3',
  ];
  