// src/components/Admin/DailyActivityUpdate.js

import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const DailyActivityUpdate = () => {
  const [newEsid, setNewEsid] = useState('');
  const [oldEsid, setOldEsid] = useState('');
  const [newAgent, setNewAgent] = useState('');
  const [oldAgent, setOldAgent] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    const updateData = {
      newEsid,
      oldEsid,
      newAgent,
      oldAgent,
    };

    // Replace this with your actual API endpoint and method for updating the MySQL table
    fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/update-daily-activity', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log('Update successful!');
          // Reset form fields if needed
          setNewEsid('');
          setOldEsid('');
          setNewAgent('');
          setOldAgent('');
        } else {
          console.error('Update failed:', data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formNewESID">
        <Form.Label>New ESID</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter new ESID"
          value={newEsid}
          onChange={(e) => setNewEsid(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group controlId="formOldESID" className="mt-3">
        <Form.Label>Old ESID</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter old ESID"
          value={oldEsid}
          onChange={(e) => setOldEsid(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group controlId="formNewAgent" className="mt-3">
        <Form.Label>New Agent</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter new agent"
          value={newAgent}
          onChange={(e) => setNewAgent(e.target.value)}
          required
        />
      </Form.Group>

      <Form.Group controlId="formOldAgent" className="mt-3">
        <Form.Label>Old Agent</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter old agent"
          value={oldAgent}
          onChange={(e) => setOldAgent(e.target.value)}
          required
        />
      </Form.Group>

      <Button variant="primary" type="submit" className="mt-3">
        Submit
      </Button>
    </Form>
  );
};

export default DailyActivityUpdate;
